import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { HasGroupDirective } from './directives/has-group/has-group.directive';
import { ExcludeRoleDirective } from './directives/exclude-role/exclude-role.directive';
import { ExcludeGroupDirective } from './directives/exclude-group/exclude-group.directive';


@NgModule({
  declarations: [
    HasRoleDirective,
    HasGroupDirective,
    ExcludeRoleDirective,
    ExcludeGroupDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    // HasRoleDirective,
    // HasGroupDirective,
    // ExcludeRoleDirective,
    // ExcludeGroupDirective
  ]
})
export class AclModule { }
