import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BonusConsumptionPoint } from 'src/app/common/models/bonus';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from 'src/app/helpers/http';

@Injectable({
  providedIn: 'root'
})
export class PointCoreService {
  #coreEndPoint = environment.coreEndPoint;
  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  #point$ = new BehaviorSubject<{ count: number; loading: boolean }>({ count: 0, loading: false });
  point$ = this.#point$.asObservable();

  constructor(
    private http: HttpHelperService,
  ) {

  }

  set loading(value: boolean) {
    this.#loading$.next(value);
  }

  set point(value: { count: number; loading: boolean }) {
    this.#point$.next(value);
  }

  get count$(): Observable<number> {
    return this.count_sync().pipe(
      map(res => res.count ?? 0)
    )
  }

  search<T>(body?: any): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#coreEndPoint}/member/point/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false),
    );
  }


  count_sync(): Observable<IHttpResponse> {
    this.loading = true;
    this.point = { count: 0, loading: true };
    const path = 'member/point/count';
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false),
      tap((res) => {
        const { error, count } = res;
        this.point = { count: count ?? 0, loading: false }
      }),
    );
  }


  record_search(body?: any): Observable<IHttpResponse> {
    const path = 'member/point/search';
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = res.result.map(item => new BonusConsumptionPoint(item));
        }
        return res;
      })
    );
  }
}
