import { ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { TrackByIndexFn } from 'src/app/common/functions';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { IRegion } from 'src/app/common/interfaces/region';
import { IBtcShippingPackagePreset } from 'src/app/common/interfaces/shipping';
import { Rate } from 'src/app/common/models/rate';
import { BtcService } from 'src/app/common/models/service';
import { BTCRateService } from 'src/app/core/btc';

import { ServiceRateDetailComponent } from '../service-rate-detail/service-rate-detail.component';

@Component({
  selector: 'service-rate',
  templateUrl: './service-rate.component.html',
  styleUrls: ['./service-rate.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRateComponent implements OnInit {

  @Input() initiationRegion: IRegion;
  @Input() destinationRegion: IRegion;
  @Input() service: BtcService;
  @Input() reqBody: any;
  @Output() rateChange = new EventEmitter<Rate | null>();
  @Output() serviceChange = new EventEmitter<any>();
  error: IHttpResponseError = null;
  rate: Rate;
  isLoading = false;
  trackByIndex = TrackByIndexFn;
  private presets: IBtcShippingPackagePreset[] = [];
  constructor(
    private btcRateService: BTCRateService,
    private detection: ChangeDetectorRef,
    private drawerService: NzDrawerService
  ) { }

  ngOnInit(): void {
    const { initiation, destination, paks, code } = this.reqBody;
    this.presets = paks.map(item => {
      const { pak, additional, code: innerCode, destination: innerDestination } = item;
      const contentId = this.service.contents.find(content => content.code === innerCode)?.id;
      return {
        ...{
          contentId, additional, destination: {
            ...innerDestination,
            ...{ region: undefined, pca: undefined, state: undefined }
          }
        },
        ...pak
      };
    });
    const body = {
      initiation: { ...initiation, ... { region: undefined, pca: undefined, state: undefined } },
      destination: { ...destination, ...{ region: undefined, pca: undefined, state: undefined } },
      paks: this.presets,
      serviceId: this.service.id,
      serviceContentId: this.service.multiDestination ? null : this.service.contents.find(content => content.code === code)?.id,
      multiDestination: this.service.multiDestination ? 1 : 0,
      useCache: true
    };

    this.search(body);
  }

  search(body: any): void {
    this.isLoading = true;
    this.btcRateService.estimate(body).pipe(
      take(1),
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.rate = res.result;
        this.error = res?.error ?? null;
        this.rateChange.emit(this.rate);
        this.detection.detectChanges();
      }
    });
  }

  select(): void {
    this.serviceChange.emit({
      service: this.service,
      content: this.service.multiDestination ? null : this.service.contents[0],
      presets: this.presets
    });
  }

  detail(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: '价格详情',
      nzContent: ServiceRateDetailComponent,
      nzContentParams: {
        drawer: true,
        rate: this.rate
      },
      nzKeyboard: false,
      nzHeight: '100vh',
      nzPlacement: 'top',
    });

    drawerRef.afterClose.pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        if (res) {
          this.serviceChange.emit(res);
        }
      }
    });
  }
}
