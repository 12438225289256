import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { IBreadcrumb } from './interfaces';
import { StateService } from '../../state';
import { CallbackFunction } from '../../common/interfaces';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<IBreadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();
  private livemode = true;
  constructor(private router: Router, private stateService: StateService) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd),
        delay(1000)
      )
      .subscribe((event) => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;

        const breadcrumbs: IBreadcrumb[] = [];
        this.addBreadcrumb(root, breadcrumbs);
        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
      });

    this.stateService.livemode$.subscribe({
      next: livemode => this.livemode = livemode
    });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot | null,
    breadcrumbs: IBreadcrumb[]
  ) {

    if (route) {
      // Add an element for the current route part
      const items = route?.data?.breadcrumbs as IBreadcrumb[] ?? [];
      if (items.length) {
        for (const item of items) {
          // item.url = this.getUrl(item.url)
          if (!breadcrumbs.find(b => b.label === item.label)) {
            if (typeof item.label === 'function') {
              item.label = item.label() as string;
            }

            breadcrumbs.push(item);
          }
        }
      }

      if (route.data.breadcrumb) {
        const breadcrumb = {
          label: this.getLabel(route.data),
          icon: route.data?.icon ?? null,
          url: this.getUrl(route.data)
        };
        breadcrumbs.push(breadcrumb);
      }


      this.addBreadcrumb(route.firstChild, breadcrumbs);
    }
  }


  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.breadcrumb === 'function'
      ? data.breadcrumb(data)
      : data.breadcrumb;
  }

  private getUrl(data: Data): string | null {
    return typeof data.url === 'function'
      ? this.state(data.url(data))
      : this.state(data?.url);
  }

  private state(url: string | null = null): string | null {
    if (!url) {
      return null;
    }
    return this.livemode ? url : `/test${url}`;
  }
}
