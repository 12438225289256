import { IAccount, IAccountBusiness, IAccountBusinessProfile, IUserContact, IUserName } from '../../interfaces/account';
import * as _ from 'lodash';
import { ISOCode2, Region } from '../../interfaces/region';
export class Account {

    private _account: IAccount | null = null;

    constructor(data: IAccount = null) {
        this._account = data;
    }

    get account(): IAccount {
        return this._account;
    }

    set account(data: IAccount) {
        this._account = data;
    }

    get isLoggedIn(): boolean {
        return this._account ? true : false;
    }


    get profile(): IAccount {
        return this._account;
    }

    get zoneCode(): Region | null {
        return this._account?.zoneCode ?? null;
    }

    get countryCode(): ISOCode2 | null {
        return this._account?.countryCode ?? null;
    }

    get contact(): IUserContact | null {
        return this._account?.contact ?? null;
    }

    get name(): IUserName | null {
        return this._account?.name ?? null;
    }


    get businessAccount(): boolean {
        return this._account?.businessAccount ?? false;
    }

    get businessVerified(): boolean {
        return this._account?.businessVerified ?? false;
    }

    get business(): IAccountBusiness | null {
        return this._account?.business ?? null;
    }



    hasZone = (codes: Region[]) => this._account?.zoneCode ? codes.includes(this._account.zoneCode) : false;
    hasCountry = (codes: ISOCode2[]) => this._account?.countryCode ? codes.includes(this._account.countryCode) : false;

    hasRole = (rolesGroups: string[]) => {
        const roleCount = _.intersection(rolesGroups, this.roleCode);
        return roleCount.length > 0 ? true : false;
    }

    hasGroup = (groups: string[]) => {
        const count = _.intersection(groups, this.groupCode);
        return count.length > 0 ? true : false;
    }

    private get roleCode(): string[] {
        if (!this._account) {
            return [];
        }

        return _.map(this.roles, role => role.code);
    }


    private get groupCode(): string[] {
        if (!this._account) {
            return [];
        }

        return _.map(this.groups, group => group.code);
    }

    get roles(): any[] {
        if (!this._account) {
            return [];
        }

        return this._account.roles;
    }

    get groups(): any[] {
        if (!this._account) {
            return [];
        }

        return this._account.groups;
    }
}
