import { SHIPPING_TYPE } from '../../constants/shipping';
import { ITag } from '../../interfaces/common';
import { SizeProp } from '../../interfaces/pak';
import { ISOCode2 } from '../../interfaces/region';
import { IBtcShippingAdditionalItem } from '../../interfaces/service';
import { SizeUnit } from '../../interfaces/setting';
import { IBtcShippingPackage, IShippingType, IShippingWeight } from '../../interfaces/shipping';
import { BtcService, BtcServiceContent } from '../service';
import { BtcShippingAdditional } from './btc-shipping.additional.model';
export enum EntityPanel {
    entity = 'entity',
    waybill = 'waybill'
}
export class BtcShippingPackage {

    private _data: IBtcShippingPackage;
    private _entityPanel = false;
    private _waybillPanel = false;
    private _addressPanel = false;
    private _labelPanel = false;
    private _declarationPanel = false;
    constructor(data: IBtcShippingPackage) {

        this._data = data;
        if (!this.destinationId) {
            this.addressPanel = true;
        }
        if (!this.submitted) {
            this._entityPanel = true;
        }
        if (this.submitted) {
            this._waybillPanel = true;
        }
    }

    get id(): string {
        return this._data?.id ?? null;
    }

    get number(): string {
        return this._data?.packageNumber;
    }

    get ref(): string {
        return this._data?.packageNumber?.split('-')[1] ?? '未知';
    }

    get sequenceNumber(): string {
        const StrLength = this._data?.packageNumber?.length ?? 0;
        return this._data?.packageNumber.split('-')[1] ?? '未知';
    }


    get shippingId(): string {
        return this._data?.shipping;
    }

    get createdAt(): Date {
        return this._data?.createdAt ?? null;
    }

    get updatedAt(): Date {
        return this._data?.updatedAt ?? null;
    }

    get status(): ITag[] {

        const items = [];
        const voided = this.status_parse('voided', 1, ['default', '已取消']);
        const settled = this.status_parse('settled', 1, ['default', '已结算']);

        // const waybillCreatedPending = this.status_parse('waybillCreated', 2, ['orange', '运单创建中']);
        const waybillSubmitted = this.status_parse('waybillSubmitted', 1, ['geekblue', '物流已确认']);
        const waybillSubmittedPending = this.status_parse('waybillSubmitted', 2, ['orange', '物流确认中']);
        const waybillSubmittedFailed = this.status_parse('waybillSubmitted', 4, ['red', '物流确认失败']);
        const waybillVoided = this.status_parse('waybillVoided', 1, ['default', '物流已注销']);
        const waybillVoidedPending = this.status_parse('waybillVoided', 2, ['orange', '物流注销中']);
        const waybillVoidedFailed = this.status_parse('waybillVoided', 4, ['red', '物流注销失败']);

        // if (waybillCreatedPending) {
        //     items.push(waybillCreatedPending);
        // }

        if (waybillSubmitted && !waybillVoided) {
            items.push(waybillSubmitted);
        }

        if (waybillSubmittedPending) {
            items.push(waybillSubmittedPending);
        }

        if (waybillSubmittedFailed) {
            items.push(waybillSubmittedFailed);
        }

        if (waybillVoided) {
            items.push(waybillVoided);
        }

        if (waybillVoidedFailed) {
            items.push(waybillVoidedFailed);
        }

        if (waybillVoidedPending) {
            items.push(waybillVoidedPending);
        }


        if (voided) {
            items.push(voided);
        }
        if (settled) {
            items.push(settled);
        }

        return items;

    }

    get submitted(): boolean {
        return this._data?.submitted === 1 ? true : false;
    }

    get voided(): boolean {
        return this._data?.voided === 1 ? true : false;
    }

    get settled(): boolean {
        return this._data?.settled === 1 ? true : false;
    }

    get waybillCreated(): boolean {
        return this._data.waybillCreated === 1 ? true : false;
    }

    get waybillCreatedPending(): boolean {
        return this._data.waybillCreated === 2 ? true : false;
    }

    get waybillCreatedFailed(): boolean {
        return this._data.waybillCreated === 4 ? true : false;
    }

    get waybillSubmitted(): boolean {
        return this._data.waybillSubmitted === 1 ? true : false;
    }

    get waybillSubmittedPending(): boolean {
        return this._data.waybillSubmitted === 2 ? true : false;
    }

    get waybillSubmittedFailed(): boolean {
        return this._data.waybillSubmitted === 4 ? true : false;
    }

    get waybillVoided(): boolean {
        return this._data.waybillVoided === 1 ? true : false;
    }

    get waybillVoidedPending(): boolean {
        return this._data.waybillVoided === 2 ? true : false;
    }

    get waybillVoidedFailed(): boolean {
        return this._data.waybillVoided === 4 ? true : false;
    }


    get livemode(): boolean {
        return this._data?.livemode ? true : false;
    }

    get type(): IShippingType {
        let type = null;
        if (this._data.parcel) {
            type = SHIPPING_TYPE.parcel;
        }
        if (this._data.env) {
            type = SHIPPING_TYPE.env;
        }

        return type;
    }

    get initiationRegionCode(): ISOCode2 {
        return this._data.initiationRegionCode;
    }

    get destinationRegionCode(): ISOCode2 {
        return this._data.destinationRegionCode;
    }

    get multiDestination(): boolean {
        return this._data?.multiDestination ? true : false;
    }

    get initiationId(): string {
        return this._data?.initiation;
    }

    set initiationId(value: string) {
        this._data.initiation = value;
    }

    get destinationId(): string {
        return this._data?.destination;
    }

    set destinationId(value: string) {
        this._data.destination = value;
    }

    get service(): BtcService {
        if (!this._data.service) {
            return null;
        }
        if (typeof this._data.service === 'string') {
            return null;
        } else {
            return new BtcService(this._data.service);
        }
    }

    get serviceId(): string {
        if (!this._data.service) {
            return null;
        }
        if (typeof this._data.service === 'string') {
            return this._data.service;
        } else {
            return this.service?.id ?? null;
        }
    }

    get serviceContent(): BtcServiceContent {
        if (!this._data.serviceContent) {
            return null;
        }
        if (typeof this._data.serviceContent === 'string') {
            return null;
        } else {
            return new BtcServiceContent(this._data.serviceContent);
        }
    }

    get serviceContentId(): string {
        if (!this._data.serviceContent) {
            return null;
        }
        if (typeof this._data.serviceContent === 'string') {
            return this._data.serviceContent;
        } else {
            return this.serviceContent?.id ?? null;
        }
    }

    get size(): Record<SizeUnit, Record<SizeProp, number>> {
        return this._data?.size;
    }

    get sizeOriginal(): Record<SizeUnit, Record<SizeProp, number>> {
        return this._data?.sizeOriginal;
    }

    get weight(): IShippingWeight {
        return { ...this._data?.weight, };
    }

    get weightOriginal(): IShippingWeight {
        return this._data?.weightOriginal;
    }

    get export_shipping(): boolean {
        return this._data.initiationRegionCode !== this._data.destinationRegionCode ? true : false;
    }

    get entityPanel(): boolean {
        return this._entityPanel;
    }

    set entityPanel(value: boolean) {
        this._entityPanel = value;
    }

    set waybillPanel(value: boolean) {
        this._waybillPanel = value;
    }

    get waybillPanel(): boolean {
        return this._waybillPanel;
    }

    set addressPanel(value: boolean) {
        this._addressPanel = value;
    }

    get addressPanel(): boolean {
        return this._addressPanel;
    }

    get labelPanel(): boolean {
        return this._labelPanel;
    }

    set labelPanel(value: boolean) {
        this._labelPanel = value;
    }

    get declarationPanel(): boolean {
        return this._declarationPanel;
    }

    set declarationPanel(value: boolean) {
        this._declarationPanel = value;
    }

    get additional(): BtcShippingAdditional {
        return this._data.additional ? new BtcShippingAdditional(this._data.additional) : null;
    }


    get customReference(): string {
        return this._data?.customReference;
    }

    get customMemo(): string {
        return this._data?.customMemo;
    }

    get insurance(): IBtcShippingAdditionalItem {
        return this._data?.insurance;
    }

    get rfe(): IBtcShippingAdditionalItem {
        return this._data?.rfe;
    }

    get version(): number {
        return this._data.version;
    }


    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {
        if (prop in this._data) {
            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }
}
