import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'issueText'
})
export class IssueTextPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    const text = value as string ?? null;
    if (!text) {
      return text;
    }

    if (text.includes('包裹编号')) {
      const pretext = text.split(' ').join('').split(':')[0] ?? '';
      const packageNumber = text.split(' ').join('').split(':')[1] ?? '';
      if (packageNumber) {
        if (packageNumber.split('-')[1]) {
          return pretext.split(',').join(', ') + ' ' + packageNumber.split('-')[1];
        } else {
          return pretext.split(',').join(', ') + ' ' + packageNumber;
        }
      }
    }

    return text;
  }

}
