import * as _ from 'lodash';
import { ValueRange } from '../../interfaces/common/range.interface';
import { IBtcService, IBtcServiceAdditional, RestrictionSchema, ServiceAdditionalItem } from '../../interfaces/service';
import { Rate } from '../rate';
import { BtcServiceContent } from './btc-service-content.class';

export class BtcService {
    private _data: IBtcService;
    private _rate: Rate;

    constructor(data: IBtcService) {
        this._data = data;
    }

    get data(): IBtcService {
        return this._data;
    }

    set service(value: IBtcService) {
        this._data = value;
    }

    get id(): string {
        return this._data.id;
    }

    get multiDestination(): boolean {
        return this._data.multiDestination ? true : false;
    }

    get name(): { text: string, color: string } {
        if (!this._data || !this._data.name) {
            return {
                text: '未知',
                color: 'magenta'
            };
        }

        return {
            text: `${this._data.name} - ${this.label}`,
            color: _.includes(this.label, '包税') || _.includes(this.label, '代购') ? 'success' : null
        };
    }

    get label(): string {
        if (!this._data || !this._data.label) {
            return '未知';
        }

        return this._data.label;
    }

    get RouteIcons(): any {
        let icons = [];

        if (this._data.transit === 0) {
            icons = [
                {
                    src: this.carrierIcon(this._data.transitService0.carrierCode)
                },
                {
                    src: this.transportationIcon(this._data.transitService0.carrierServiceCode)
                }
            ];
            return icons;
        }

        if (this._data.transit > 0) {
            icons.push({
                src: this.carrierIcon(this._data.transitService0.carrierCode)
            });
            icons.push({
                src: this.WarehouseIcon
            });
            icons.push({
                src: this.transportationIcon(this._data.transitService1.carrierServiceCode)
            });

            return icons;
        }
    }

    get count(): Record<ValueRange, number> {
        return {
            max: this._data.countMax,
            min: this._data.countMin
        };
    }

    private carrierIcon(carrierCode: string): string {
        return `/assets/img/carrier/${carrierCode}.png`;
    }

    private transportationIcon(carrierServiceCode: string): string {
        let landTransportation = false;
        let seaTransportation = false;
        if (['int.ip.surf'].includes(carrierServiceCode)) {
            seaTransportation = true;
        }

        if (['PurolatorGround', 'dom.ep', 'dom.xp', 'N.L'].includes(carrierServiceCode)) {
            landTransportation = true;
        }

        if (seaTransportation) {
            return `/assets/img/svg/shop/013-ship.svg`;
        } else if (landTransportation) {
            return `/assets/img/svg/parcel/025-truck.svg`;
        } else {
            return `/assets/img/svg/parcel/027-plane.svg`;
        }
    }

    get WarehouseIcon(): string {
        return '/assets/img/svg/parcel/003-warehouse.svg';
    }

    get restriction(): RestrictionSchema {
        return this._data.restriction;
    }

    get contents(): BtcServiceContent[] {
        return this._data.contents.map(content => new BtcServiceContent(content));
    }

    set contents(value: BtcServiceContent[]) {
        this._data.contents = value.map(v => v.data);
    }

    get additional(): IBtcServiceAdditional {
        return this._data.additional;
    }

    get options(): ServiceAdditionalItem[] {
        if (!this._data.additional) {
            return [];
        }
        const options = [];
        for (const i in this._data.additional) {
            if (Object.prototype.hasOwnProperty.call(this._data.additional, i)) {
                const item = this._data.additional[i];
                if (item) {
                    options.push(item);
                }
            }
        }

        return options;
    }

    set rate(value: Rate) {
        this._rate = value;
    }

    get rate(): Rate {
        return this._rate ? this._rate : null;
    }
}
