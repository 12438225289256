import { Pipe, PipeTransform } from '@angular/core';
import { SIZE_UNITS } from 'src/app/common/constants/unit';
import { SizeUnit } from 'src/app/common/interfaces/setting';
import { IShippingSize } from 'src/app/common/interfaces/shipping';

@Pipe({
  name: 'sizeUnit'
})
export class SizeUnitPipe implements PipeTransform {

  transform(
    value: { [key in SizeUnit]: IShippingSize },
    unit: SizeUnit,
    returnString: boolean = false,
    prop?: keyof IShippingSize | null): unknown {

    if (returnString) {
      const size = value[unit];
      switch (prop) {
        case 'girth':
          return `${size.girth} ${SIZE_UNITS[unit].name}`;
        case 'sum':
          return `${size.sum} ${SIZE_UNITS[unit].name}`;
        case 'length':
          return `${size.length} ${SIZE_UNITS[unit].name}`;
        case 'width':
          return `${size.width} ${SIZE_UNITS[unit].name}`;
        case 'height':
          return `${size.height} ${SIZE_UNITS[unit].name}`;
        default:
          return `${size.length} x ${size.width} x ${size.height} ${SIZE_UNITS[unit].name}`;
      }

    } else {
      if (prop) {
        return value[unit][prop];
      } else {
        return value[unit];
      }
    }
  }

}
