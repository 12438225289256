import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { take } from 'rxjs/operators';
import { BtcShippingWaybill } from 'src/app/common/models/shipping';
import { BtcLabelService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';
import { IndicatorService } from 'src/app/shared/indicator';
import { LabelPreviewComponent } from '../label-preview/label-preview.component';

@Component({
  selector: 'label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelListComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  @Input() set groupId(value: string) {
    this.validateForm.patchValue({
      groupId: value
    });
  }

  queryParams = {
    page: 1,
    skip: 0,
    limit: 10,
    sort: []
  };
  count = 0;

  validateForm = new UntypedFormGroup({
    keywords: this.fb.control(null, [Validators.minLength(4)]),
    waybillCarrierCode: this.fb.control(null),
    groupId: this.fb.control(null)
  });

  waybills: BtcShippingWaybill[] = [];

  // TODO checkbox
  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;
  langCode: string;
  constructor(
    private labelService: BtcLabelService,
    private detection: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private indicatorService: IndicatorService,
    private i18n: NzI18nService,
    private drawerService: NzDrawerService,
  ) {
    this.langCode = this.i18n.getLocaleId();
  }

  ngOnInit(): void {

  }


  on_page_change(i: number): void {
    this.queryParams.skip = (i - 1) * this.queryParams.limit;
    this.on_submit();
  }

  on_size_change(size: number): void {
    this.queryParams.limit = size;
    this.queryParams.skip = 0;
    this.queryParams.page = 1;
    this.on_submit();
  }

  on_query_change(data: NzTableQueryParams): void {
    const { sort } = data;

    this.queryParams.sort = sort.filter(({ value }) => value).map((item) => {
      const element = [item.key, item.value === 'ascend' ? 'asc' : 'desc'];
      return element.join(':');
    });
    this.on_submit();
  }

  keywords_reset(): void {
    this.validateForm.patchValue({
      keywords: null
    });
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.waybills
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.waybills;
    this.checked = listOfEnabledData.length && listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id)) ? true : false;
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }


  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      const body = { ...this.validateForm.value, ...this.queryParams };
      this.search(body);
    }
  }

  preview(data?: BtcShippingWaybill): void {
    const d = this.drawerService.create({
      nzTitle: `运单Label ${data?.waybillNumber ? data?.waybillNumber : ''}`,
      nzContent: LabelPreviewComponent,
      nzContentParams: {
        waybillIds: data ? [data.id] : this.waybills.filter(({ id }) => this.setOfCheckedId.has(id)).map(w => w.id)
      },
      nzHeight: 'auto',
      nzPlacement: 'top'
    });

    d.afterClose.subscribe({
      next: () => {
        d.nzHeight = '110vh';
      }
    });
  }

  send(data?: BtcShippingWaybill): void {
    const d = this.indicatorService.loading({
      nzContentParams: {
        title: '面单发送中...'
      }
    });
    const body = {
      waybills: data ? [data.id] : this.waybills.filter(({ id }) => this.setOfCheckedId.has(id)).map(w => w.id)
    };
    this.labelService.send(body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        d.close();
        if (res?.error) {
          this.indicatorService.alert({
            nzContentParams: {
              type: 'error',
              message: res?.error?.message,
              problems: res?.error?.problems
            }
          });
        }
      }
    });
  }

  private search(body: any): void {
    this.isLoading = true;
    this.labelService.search(body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.waybills = res?.result ?? [];
        this.count = res?.count ?? 0;
        this.isLoading = false;
      }
    });
  }


}
