<nz-timeline>
  <ng-container *ngFor="let step of steps; trackBy: trackByIndex">
    <nz-timeline-item [nzColor]="step.statusCode | stepColor">
      <span *ngIf="step.status" [innerHTML]="step.status | break: true"> </span>
      <span *ngIf="step.context" [innerHTML]="step.context | break: true">
      </span>
      <span *ngIf="step.time" [innerHTML]="step.time | break: true"> </span>
      <span *ngIf="step.areaName" [innerHTML]="step.areaName"> </span>
    </nz-timeline-item>
  </ng-container>
</nz-timeline>
