<div class="indicator-body">
  <div class="indicator-item indicator--w-100">
    <nz-alert
      [nzType]="type"
      [nzMessage]="message"
      [nzBanner]="banner"
      [nzDescription]="DescTpl"
    >
      <ng-template #DescTpl>
        <p *ngIf="desc">
          {{ desc }}
        </p>
        <ng-container *ngIf="problems && problems.length">
          <ul>
            <li *ngFor="let problem of problems">
              {{ problem | issueText }}
              <ng-container
                *ngIf="problem | issueLink: (livemode$ | async) as link"
              >
                <a [routerLink]="[link]" nz-button nzType="link">查看</a>
              </ng-container>
            </li>
          </ul>
        </ng-container>
      </ng-template>
    </nz-alert>
  </div>
  <div
    class="indicator-item indicator--w-100 indicator--p-t-20"
    *ngIf="buttons"
  >
    <ng-container *ngFor="let btn of buttons">
      <button
        nz-button
        [nzType]="btn.type"
        [nzDanger]="btn.danger"
        [nzSize]="btn.size"
        [nzGhost]="btn.ghost"
        [nzBlock]="btn.block"
        (click)="clicked(btn.onClick)"
      >
        <ng-container *ngIf="btn.icon">
          <i nz-icon [nzType]="btn.icon"></i>
        </ng-container>
        {{ btn.label }}
      </button>
    </ng-container>
  </div>
</div>
