import { TemplateRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { PickupEvent } from 'src/app/common/interfaces/pickup';
import { PageHeaderService } from 'src/app/core/page-header';

@Component({
  selector: 'pickup-header-list',
  templateUrl: './pickup-header-list.component.html',
  styleUrls: ['./pickup-header-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupHeaderListComponent implements OnInit, AfterViewInit {

  @Output() eventChange = new EventEmitter<PickupEvent>();
  @ViewChild('buttonsTpl') buttonsTpl: TemplateRef<any>;
  constructor(
    public ph: PageHeaderService,

  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.view_update();
  }

  create(): void {
    this.eventChange.emit(PickupEvent.create);
  }


  view_update(): void {
    this.ph.buttons = this.buttonsTpl;
  }


}

