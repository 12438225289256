import { OptionFn } from 'src/app/common/functions';
import { ISelectOption } from '../../common/interfaces';


export const ApiStatuses = {
    normal: '正常',
    abnormal: '异常',
    maintenance: '维护中',
    outage: '停运',
} as const;

export const ApiStatusOptions: ISelectOption[] = OptionFn(ApiStatuses);
