<div class="indicator-body">
  <div class="indicator-item">
    <p *ngIf="title">
      {{ title }}
    </p>
    <p *ngIf="desc" [innerHTML]="desc"></p>
  </div>
  <div class="indicator-item indicator--w-100">
    <ng-container *ngFor="let btn of buttons">
      <button
        nz-button
        [nzType]="btn.type"
        [nzDanger]="btn.danger"
        [nzGhost]="btn.ghost"
        [nzBlock]="btn.block"
        (click)="clicked(btn.onClick)"
      >
        <ng-container *ngIf="btn.icon">
          <i nz-icon [nzType]="btn.icon"></i>
        </ng-container>
        {{ btn.label }}
      </button>
    </ng-container>
  </div>
</div>
