import { ServiceTicketTypeCode, ServiceTicketProp, ServiceTicketStateProp } from '../../interfaces/ticket';

export const SERVICE_TICKET_TYPES: ServiceTicketProp = {
    order: {
        name: '订单',
        code: ServiceTicketTypeCode.order
    },
    invoice: {
        name: '收据/发票',
        code: ServiceTicketTypeCode.invoice
    },
    shipping: {
        name: '运单',
        code: ServiceTicketTypeCode.shipping
    },
    waybill: {
        name: '物流',
        code: ServiceTicketTypeCode.waybill
    },
    payment: {
        name: '支付',
        code: ServiceTicketTypeCode.payment
    },
    doc: {
        name: '文件 (身份证件等)',
        code: ServiceTicketTypeCode.doc
    },
    initiation: {
        name: '寄件地址',
        code: ServiceTicketTypeCode.initiation
    },
    destination: {
        name: '收件地址',
        code: ServiceTicketTypeCode.destination
    },
    other: {
        name: '其他',
        code: ServiceTicketTypeCode.other
    }
};

export const SERVICE_TICKET_STATES: ServiceTicketStateProp = {
    4: {
        name: '需要处理',
        code: 4,
        query: {
            status: 4
        }
    },
    1: {
        name: '客服处理中',
        code: 1,
        query: {
            status: 1
        }
    },
    2: {
        name: '客服已答复',
        code: 2,
        query: {
            status: 2
        }
    },
    3: {
        name: '已完结',
        code: 3,
        query: {
            status: 3
        }
    },
};

