import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeSelectorComponent } from './components/type-selector/type-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { IndexSelectorComponent } from './components/index-selector/index-selector.component';
import { ServiceSelectorComponent } from './components/service-selector/service-selector.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ServiceRestrictionComponent } from './components/service-restriction/service-restriction.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { UnitModule } from '../unit/unit.module';
import { IndicatorSharedModule } from '../indicator';
import { ServiceRateSharedModule } from './rate';
import { ServiceAddressSharedModule } from './address';
import { ServiceShippingPackageModule } from './package';
import { FormSharedModule } from '../utils/form';

@NgModule({
  declarations: [
    TypeSelectorComponent,
    IndexSelectorComponent,
    ServiceSelectorComponent,
    ServiceRestrictionComponent,

  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzFormModule,
    NzRadioModule,
    NzCheckboxModule,
    NzDividerModule,
    NzCardModule,
    NzButtonModule,
    NzIconModule,
    NzEmptyModule,
    NzTypographyModule,
    NzListModule,
    NzDescriptionsModule,
    NzTagModule,
    UnitModule,
    IndicatorSharedModule,
    ServiceRateSharedModule,
    ServiceAddressSharedModule,
    ServiceShippingPackageModule
  ],
  exports: [
    TypeSelectorComponent,
    IndexSelectorComponent,
    ServiceSelectorComponent,
    ServiceRestrictionComponent,
    ServiceRateSharedModule,
    ServiceShippingPackageModule
  ]
})
export class ServiceSharedModule { }
