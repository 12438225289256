import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';

import { VoucherSeal } from 'src/app/common/models/auth/voucher-seal.model';
import { Voucher } from 'src/app/common/models/auth/voucher.model';
import { IHttpResponse } from 'src/app/helpers/http/interfaces';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from 'src/app/helpers/http';


@Injectable({
  providedIn: 'root'
})
export class VoucherCoreService {
  #endPoint: string;
  count$ = new BehaviorSubject<number>(0);

  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  constructor(
    private http: HttpHelperService,
    private msg: NzMessageService
  ) {
    this.#endPoint = environment.saleEndPoint;
  }


  set loading(value: boolean) {
    this.#loading$.next(value);
  }



  count_sync(body?: any): Observable<boolean> {
    this.loading = true;
    const path = `${this.#endPoint}/discount/voucher/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false),
      switchMap((res: IHttpResponse) => {
        const { count } = res;
        return this.count_update(count);
      }),
    );
  }


  search(body?: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/discount/voucher/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res) => {
        res.result = _.map(res && res.result, row => new Voucher(row));
        return res;
      }),
    );
  }

  searchRecord<T>(body?: any): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `${this.#endPoint}/discount/voucher/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false)
    );
  }

  search_by_user(body?: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/discount/voucher/service`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res) => {
        res.result = _.map(res && res.result, row => new Voucher(row));
        return res;
      }),
    );
  }

  search_by_code(body?: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/discount/voucher/service/code`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res) => {
        res.result = _.map(res && res.result, row => new Voucher(row));
        return res;
      }),
    );
  }

  exchange_search(): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/discount/voucher/exchange/search`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      map((res) => {
        res.result = _.map(res && res.result, row => new VoucherSeal(row));
        return res;
      }),

    );
  }

  exchange_submit(body: { modelId: string, exchangeQty: number }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/discount/voucher/exchange`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
    );
  }

  private count_update(num: number = 0): Observable<boolean> {
    this.count$.next(num);
    return of(true);
  }

}
