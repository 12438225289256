import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SIGNATURE_TYPE } from 'src/app/common/constants/service/service-option';
import { SoItem } from 'src/app/common/interfaces/service';
import { BtcShippingAdditional } from 'src/app/common/models/shipping';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'service-additional-form-so',
  templateUrl: './service-additional-form-so.component.html',
  styleUrls: ['./service-additional-form-so.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAdditionalFormSoComponent implements OnInit {

  @Input() current: BtcShippingAdditional;
  @Input() option: SoItem<any>;

  private _validateForm: UntypedFormGroup;
  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
    this.type_set(this.option);
  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  types = [];

  constructor(
    public fv: FormValidationService,
    private fb: UntypedFormBuilder,
  ) {


  }


  ngOnInit(): void {
  }

  on_state_change(state: boolean) {
    if (state) {
      const { code, blocked } = this.option;
      const value = this.current?.so?.code ? this.current.so.code : code?.value;
      this._validateForm.addControl('code', this.fb.control(null, [Validators.required]));
      this._validateForm.patchValue({
        code: value
      });

      if (blocked) {
        this._validateForm.get('code').disable();
      }

    } else {
      this._validateForm.removeControl('code');
    }
  }

  type_set(value: SoItem<any>): void {
    const { state, code, carrier, blocked } = value;
    this._validateForm.addControl('state', this.fb.control(state?.value));

    for (const i in SIGNATURE_TYPE[carrier]) {
      if (Object.prototype.hasOwnProperty.call(SIGNATURE_TYPE[carrier], i)) {
        const item = SIGNATURE_TYPE[carrier][i];
        if (code?.options?.length && !code.options.some((key) => key === i)) {
          continue;
        }
        this.types.push(item);
      }
    }

    if (blocked) {
      this._validateForm.disable();
    }

    if (state?.value && code?.value) {
      this.on_state_change(state.value);
    }

    if (typeof this.current?.so?.state === 'boolean') {
      this.validateForm.patchValue(this.current.so);
      this.on_state_change(this.current.so.state);
    }

  }

}
