import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { sizeUnitOptions, weightUnitOptions } from 'src/app/common/constants/setting/unit';
import { USER_SPACE_UNIT } from 'src/app/common/constants/storage';
import { IUnit } from 'src/app/common/interfaces/setting';
import { Unit } from 'src/app/common/models/setting';
import { StorageService } from '../storage';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  sizeUnitOptions = sizeUnitOptions;
  weightUnitOptions = weightUnitOptions;
  unit$ = new BehaviorSubject<IUnit>(new Unit());
  constructor(
    private storage: StorageService,
    private message: NzMessageService
  ) { }

  init(): Observable<boolean> {
    return this.storage.get(USER_SPACE_UNIT).pipe(
      take(1),
      switchMap(data => {
        if (data && data.size && data.weight) {
          this.unit$.next(data);
          return of(true);
        } else {
          return this.save(new Unit().unit);
        }
      }

      ), catchError((e) => {
        this.save(new Unit().unit);
        return of(true);
      })
    );
  }

  private save(unit: IUnit): Observable<boolean> {
    return this.storage.set(USER_SPACE_UNIT, unit).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  update(isKilo: boolean, isCentimeter: boolean): Observable<boolean> {
    const unit = new Unit(isKilo, isCentimeter).unit;
    return this.save(unit).pipe(
      switchMap((res) => {
        if (res) {
          this.unit$.next(unit);
          return of(true);
        } else {
          return of(false);
        }
      }),
      tap(
        next => {
          if (next) {
            this.message.create('success', '计量单位已更新');
          } else {
            this.message.create('error', '计量单位更新失败');
          }
        },

      ));
  }

}
