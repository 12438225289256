import { BreakpointObserver, } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BREAK_POINTS } from './constants';
import { BreakpointCode, Breakpoints } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class BreakpointHelperService {
  private _bp: Breakpoints = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false
  };
  private _bp$ = new BehaviorSubject<Breakpoints>(this._bp);
  bp$ = this._bp$.asObservable();

  private _small$ = new BehaviorSubject<boolean>(false);
  small$ = this._small$.asObservable();

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.screen_watch();
  }

  private screen_watch() {
    BREAK_POINTS.forEach(item => {
      this.breakpointObserver
        .observe(item.mediaQuery).subscribe(result => {
          if (result.matches) {
            this.on_matches(item.code);
          }
        });
    });

  }

  private on_matches(key: BreakpointCode) {
    for (const i in this._bp) {
      if (Object.prototype.hasOwnProperty.call(this._bp, i)) {
        const code = i as BreakpointCode;
        if (i === key) {
          this._bp[code] = true;
        } else {
          this._bp[code] = false;
        }
      }
    }

    this._bp$.next(this._bp);
    if (['xs', 'sm', 'md'].includes(key)) {
      this.small = true;
    } else {
      this.small = false;
    }
  }

  private set small(value: boolean) {
    this._small$.next(value);
  }


}

