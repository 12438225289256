import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BTCShippingDoc, BtcShippingIdcard } from 'src/app/common/models/shipping';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BtcShippingDocService {

  constructor(
    private http: HttpService,
  ) { }

  search(groupId: string, body: any = {}): Observable<IHttpResponse> {
    const path = `shipping/${groupId}/docs/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        res.result = res.result.map(item => new BTCShippingDoc(item));
        return res;
      }),
    );
  }

  idcard_search(groupId: string, body: any = {}): Observable<IHttpResponse> {
    const path = `shipping/${groupId}/docs/idcard/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BtcShippingIdcard(res.result);
        }
        return res;
      }),
    );
  }

  idcard_update(groupId: string, body: any = {}): Observable<IHttpResponse> {
    const path = `shipping/${groupId}/docs/idcard/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler())
    );
  }

  idcard_delete(groupId: string, docId: string): Observable<IHttpResponse> {
    const path = `shipping/${groupId}/docs/${docId}/idcard/delete`;
    return this.http.delete(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler())
    );
  }

  idcard_upload(groupId: string, docId: string, body: any): Observable<IHttpResponse> {
    const path = `shipping/${groupId}/docs/${docId}/idcard/upload`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap(this.http.error_msg_handler())
    );
  }
}
