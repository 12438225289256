export class InteractiveFind {
    /* Default values of FIND v2.10 */
    protocol = 'https://'; // default protocol https
    version = '2.1'; // default version
    url = 'ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/';
    method = 'json3ex.ws';
    key = 'unknow';
    SearchTerm = '';  /* The search term to find. If the LastId is provided,
                                the SearchTerm searches within the results from the LastId */
    LastId = '';  //
    SearchFor = 'Everything'; // default Filters the search results. Everything -- PostalCodes / Companies / Places
    country = 'CAN'; // default country Canada ISO 2 or 3 Charactor
    LanguagePreference = 'en'; /* default language English. The 2 or 4 character language preference
        identifier e.g. (en, en-gb, en-us etc).*/
    MaxSuggestions = 7;  // The maximum number of autocomplete suggestions to return.
    MaxResults = 100; // The maximum number of retrievable address results to return.

    Init(data) {

        if (data.key) {
            this.key = data.key;
        }

        switch (data.protocol) {
            case 'http':
                this.protocol = 'http://';
                break;
            default:
                break;
        }

        if (data.version) {
            this.version = data.version;
        }

        switch (data.method) {
            case 'xmla':
                this.method = 'xmla.ws';
                break;
            case 'xmle':
                this.method = 'xmle.ws';
                break;
            case 'json':
                this.method = 'json.ws';
                break;
            case 'json2':
                this.method = 'json2.ws';
                break;
            case 'json3':
                this.method = 'json3.ws';
                break;
            default:
                break;
        }

        if (data.country) {
            this.country = data.country;
        }

        if (data.SearchTerm ) {
            this.SearchTerm = data.SearchTerm;
        }

        if (data.LastId || data.LastId === '') {
            this.LastId = data.LastId;
        }

        if (data.LanguagePreference) {
            this.LanguagePreference = data.LanguagePreference;
        }

        switch (data.SearchFor) {
            case 'PostalCodes':
                this.SearchFor = 'PostalCodes';
                break;
            case 'Companies':
                this.SearchFor = 'Companies';
                break;
            case 'Places':
                this.SearchFor = 'Places';
                break;
            default:
                break;
        }

        if (Number.isInteger(data.MaxResults)) {
            this.MaxResults = data.MaxResults;
        }

        if (Number.isInteger(data.MaxSuggestions)) {
            this.MaxSuggestions = data.MaxSuggestions;
        }

        const params = [
            { name: 'country', value: this.country },
            { name: 'SearchTerm', value: this.SearchTerm },
            { name: 'LastId', value: this.LastId },
            { name: 'LanguagePreference', value: this.LanguagePreference },
            { name: 'SearchFor', value: this.SearchFor },
            { name: 'MaxSuggestions', value: this.MaxSuggestions },
            { name: 'MaxResults', value: this.MaxResults },
        ];

        return {
            protocol: this.protocol,
            url: this.url + this.version + '/',
            key: this.key,
            method: this.method,
            params
        };
    }
}
