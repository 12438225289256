import { ITag } from '../../interfaces/common';
import { IBtcPaymentRefund, PaymentRefundMethodName } from '../../interfaces/payment';

export class BTCPaymentRefund {
    private _data: IBtcPaymentRefund;

    constructor(data: IBtcPaymentRefund) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id ?? '无效';
    }

    get status(): ITag[] {

        const items = [];
        const locked = this.status_parse('unlocked', 0, ['orange', '冻结中']);

        const failed = this.status_parse('failed', 1, ['red', '失败']);
        const succeeded = this.status_parse('succeeded', 1, ['green', '成功']);
        const credited = this.status_parse('credited', 1, ['green', '到账']);
        const pending = this.status_parse('pending', 1, ['orange', '受理中']);

        if (locked) {
            items.push(locked);
        }

        // if (credited) {
        //     items.push(credited);
        // }

        if (failed) {
            items.push(failed);
        }

        if (pending) {
            items.push(pending);
        }

        if (succeeded) {
            items.push(succeeded);
        }

        return items;

    }

    get updatedAt(): Date {
        return this._data.updatedAt;
    }

    get unlockedAt(): Date {
        return this._data.unlockedAt;
    }

    get creditedAt(): Date {
        return this._data.creditedAt;
    }

    get credited(): boolean {
        return this._data.credited ? true : false;
    }

    get locked(): boolean {
        return this._data.unlocked ? false : true;
    }

    get failed(): boolean {
        return this._data.failed ? true : false;
    }

    get pending(): boolean {
        return this._data.pending ? true : false;
    }

    get succeeded(): boolean {
        return this._data.succeeded ? true : false;
    }

    get amount(): number {
        return this._data?.amount ?? 0;
    }

    get refundedReason(): string {
        return this._data?.refundedReason ?? '无';
    }

    get rbc(): boolean {
        return this._data.rbc ? true : false;
    }

    get currency(): string {
        return this._data?.currency?.toUpperCase() ?? 'CAD';
    }

    get livemode(): boolean {
        return this._data?.livemode ? true : false;
    }

    get version(): number {
        return this._data?.version;
    }

    get methodName(): PaymentRefundMethodName {
        return this.rbc ? PaymentRefundMethodName.rbc : PaymentRefundMethodName.rbb;
    }

    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {

        if (prop in this._data) {

            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }
}
