
import { IPriceDetail, IQuote } from '../../interfaces';
import { RateQuote } from './rate-quote.model';

export class PickupQuote extends RateQuote {

    constructor(private data: IQuote) {
        super(data);
    }

    get priceDetails(): IPriceDetail[] {
        return this.data?.priceDetails;
    }

}
