import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IBtcServiceAdditional } from 'src/app/common/interfaces/service';
import { BtcShippingAdditional } from 'src/app/common/models/shipping';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'service-additional-form',
  templateUrl: './service-additional-form.component.html',
  styleUrls: ['./service-additional-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAdditionalFormComponent implements OnInit {


  @Input() current: BtcShippingAdditional;

  @Input() additional: IBtcServiceAdditional;
  additionalForm: UntypedFormGroup;
  private _validateForm: UntypedFormGroup;

  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
    this.build();
  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  constructor(
    public fv: FormValidationService,
    private fb: UntypedFormBuilder
  ) {

  }

  ngOnInit(): void {

  }

  on_size_change(i: number) {

  }

  build(): void {
    const group = this.validateForm.get('additional') as UntypedFormGroup;
    if (this.additional?.so) {
      group.addControl('so', this.fb.group({}));
    }

    if (this.additional?.dc) {
      group.addControl('dc', this.fb.group({}));
    }

    if (this.additional?.ndh) {
      group.addControl('ndh', this.fb.group({}));
    }

    this.additionalForm = group;

  }


}
