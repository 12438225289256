import { Pipe, PipeTransform } from '@angular/core';
import { carriers } from 'src/app/common/constants/carrier';
import { CarrierCode } from 'src/app/common/interfaces/carrier';

@Pipe({
  name: 'waybillName'
})
export class WaybillNamePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string | null {
    const code = value as CarrierCode ?? null;
    const carrier = carriers[code] ?? null;
    if (!carrier) {
      return null;
    }

    return carrier.name;
  }

}
