import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, zip } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { IRegion, IRegionListItem, IRegionServiceControl, Region } from 'src/app/common/interfaces/region';
import { RegionService } from 'src/app/core/region';


@Component({
  selector: 'region-service-control',
  templateUrl: './region-service-control.component.html',
  styleUrls: ['./region-service-control.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionServiceControlComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() initiationRegion: IRegion;
  @Input() destinationRegion: IRegion;
  @Input() regionServiceControl: IRegionServiceControl;
  @Output() initiationRegionChange = new EventEmitter<IRegion>();
  @Output() destinationRegionChange = new EventEmitter<IRegion>();
  @Output() regionServiceControlChange = new EventEmitter<IRegionServiceControl>();
  @Output() isLoadingChange = new EventEmitter<boolean>();
  initiationRegionList: IRegionListItem[] = [];
  destinationRegionList: IRegionListItem[] = [];
  isLoading = false;
  validateForm = new UntypedFormGroup({
    initiation: this.fb.control(null, [Validators.required]),
    destination: this.fb.control(null, [Validators.required])
  });

  error: IHttpResponseError | null = null;

  constructor(
    private fb: UntypedFormBuilder,
    private regionService: RegionService,
  ) { }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.watch_region_list();
  }

  private get_initiation_region() {
    return this.regionService.departure_region_search();
  }

  private get_destination_region() {
    return this.regionService.destination_region_search({ limit: 100 });
  }

  private watch_region_list(): void {
    this.isLoading = true;
    this.isLoadingChange.emit(true);
    zip(this.get_initiation_region(), this.get_destination_region()).pipe(
      takeUntil(this.destroy$),
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.isLoadingChange.emit(false);
        this.initiationRegionList = res[0]?.result ?? [];
        this.validateForm.patchValue({
          initiation: this.initiationRegionList && this.initiationRegionList[0]?.value
        });

        this.destinationRegionList = res[1]?.result ?? [];
        this.validateForm.patchValue({
          destination: this.destinationRegionList && this.destinationRegionList[0]?.value
        });
        this.on_region_change();
      }
    });
  }

  on_initiation_region_change(region: IRegion): void {
    this.initiationRegionChange.emit(region);
    this.on_region_change();
  }


  on_destination_region_change(region: IRegion): void {
    this.destinationRegionChange.emit(region);
    this.on_region_change();
  }


  private service_control_get(body: any): void {
    this.isLoadingChange.emit(true);
    this.regionService.region_service_control(body).pipe(
      takeUntil(this.destroy$),
    ).subscribe({
      next: (res) => {
        this.isLoadingChange.emit(false);
        this.error = res?.error ?? null;
        const ctrl = res?.result as IRegionServiceControl ?? null;
        this.regionServiceControlChange.emit(ctrl);
      }
    });
  }

  private on_region_change(): void {

    if (this.validateForm.valid) {
      const { initiation, destination } = this.validateForm.value;
      this.service_control_get({
        initiationRegionCode: initiation?.code,
        destinationRegionCode: destination?.code
      });

    }
  }


}
