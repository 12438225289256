import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelListComponent } from './label-list/label-list.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { LabelSharedModule } from 'src/app/shared/label';

@NgModule({
  declarations: [
    LabelListComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzIconModule,
    NzEmptyModule,
    NzCardModule,
    NzButtonModule,
    NzTypographyModule,
    NzDividerModule,
    NzListModule,
    NzAvatarModule,
    NzDrawerModule,
    IndicatorSharedModule,
    LabelSharedModule,
  ],
  exports: [
    LabelListComponent,
  ]
})
export class ShippingLabelSharedModule { }
