import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { take } from 'rxjs/operators';
import { Carrier, CarrierCode } from 'src/app/common/interfaces/carrier';
import { PickupEvent } from 'src/app/common/interfaces/pickup';
import { BTCPickup } from 'src/app/common/models/pickup';
import { BtcPickupService } from 'src/app/core';
import { NavigationService } from 'src/app/core/navigation';
import { UnitService } from 'src/app/core/unit';
import { IndicatorService } from 'src/app/shared/indicator';

@Component({
  templateUrl: './pickup-page-detail.component.html',
  styleUrls: ['./pickup-page-detail.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupPageDetailComponent implements OnInit {
  private drawerRef: NzDrawerRef;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  private pickupId: string;
  unit$ = this.unitService.unit$;
  pickup: BTCPickup;
  constructor(
    private nav: NavigationService,
    private pickupServoce: BtcPickupService,
    private route: ActivatedRoute,
    private detection: ChangeDetectorRef,
    public i18n: NzI18nService,
    private indicatorService: IndicatorService,
    private unitService: UnitService
  ) {
    const { id } = this.route.snapshot.params;
    this.pickupId = id;
  }

  ngOnInit(): void {
    this.detail_get(this.pickupId);
  }

  on_event_change(event: PickupEvent): void {
    switch (event) {
      case PickupEvent.edit:
        this.edit(this.pickupId);
        break;
      case PickupEvent.delete:
        this.delete_confirm(this.pickupId);
        break;
      case PickupEvent.submit:
        this.submit_confirm(this.pickupId, { version: this.pickup.version });
        break;
      case PickupEvent.void:
        this.void_confirm(this.pickupId, { version: this.pickup.version }, this.pickup.carrier.code);
        break;
      default:
        break;
    }
  }

  private detail_get(id: string): void {
    this.isLoading = true;
    this.pickupServoce.detail(id).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.pickup = res?.result;
        this.isLoading = false;
      }
    });
  }

  private edit(id: string): void {
    this.nav.to(`/app/pickups/edit/${id}`);
  }

  private delete_confirm(id: string): void {
    this.drawerRef = this.indicatorService.confirm({
      nzContentParams: {
        title: '删除预约',
        desc: '是否确认删除当前预约?'
      }
    });

    this.drawerRef.afterClose.subscribe({
      next: res => {
        if (res) {
          this.delete(id);
        }
      }
    });

  }

  private delete(id: string): void {
    this.drawerRef = this.indicatorService.loading({
      nzContentParams: {
        title: '删除中, 请稍后...'
      }
    });

    this.pickupServoce.delete(id).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.drawerRef?.close();
        if (res?.error) {
          this.drawerRef = this.indicatorService.alert({
            nzContentParams: {
              message: res.error.message,
              problems: res.error.problems,
              type: 'error'
            }
          });
        }
        if (res?.result) {
          this.nav.to(`/app/pickups`);
        }
      }
    });
  }

  private submit_confirm(id: string, body: any): void {
    this.drawerRef = this.indicatorService.confirm({
      nzContentParams: {
        title: '提交预约申请',
        desc: '确认提交当前预约? 提交后将无法更改'
      }
    });

    this.drawerRef.afterClose.subscribe({
      next: res => {
        if (res) {
          this.submit(id, body);
        }
      }
    });

  }

  private submit(id: string, body: any): void {
    this.drawerRef = this.indicatorService.loading({
      nzContentParams: {
        title: '提交中, 请稍后...'
      }
    });

    this.pickupServoce.submit(id, body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.drawerRef?.close();
        if (res?.error) {
          this.drawerRef = this.indicatorService.alert({
            nzContentParams: {
              message: res.error.message,
              problems: res.error.problems,
              type: 'error'
            }
          });
        }
        if (res?.success) {
          this.detail_get(id);
        }
      }
    });
  }

  private void_confirm(id: string, body: any, carrier: Carrier): void {
    this.drawerRef = this.indicatorService.confirm({
      nzContentParams: {
        title: '取消预约申请',
        desc: `确认取消当前预约? 提交后将无法更改. ${carrier === CarrierCode.canpost ? '<br>(注意: 加拿大邮政包裹将无法再次预约同一时段取货)' : ''}`
      }
    });

    this.drawerRef.afterClose.subscribe({
      next: res => {
        if (res) {
          this.void(id, body);
        }
      }
    });

  }

  private void(id: string, body: any): void {
    this.drawerRef = this.indicatorService.loading({
      nzContentParams: {
        title: '提交中, 请稍后...'
      }
    });

    this.pickupServoce.void(id, body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.drawerRef?.close();
        if (res?.error) {
          this.drawerRef = this.indicatorService.alert({
            nzContentParams: {
              message: res.error.message,
              problems: res.error.problems,
              type: 'error'
            }
          });
        }
        if (res?.success) {
          this.detail_get(id);
        }
      }
    });
  }
}
