export enum ContractorCode {
    canpost = 'canpost',
    ups = 'ups',
    jiayun = 'jiayun',
    vipshipper = 'vipshipper'
}

export type ContractorCodeType = keyof typeof ContractorCode;

export type Contractor = Partial<Record<ContractorCode, IContractor>>;

export interface IContractor {
    text: string;
    code: ContractorCode;
    query?: any;
}
