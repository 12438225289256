import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formGroup',
  pure: false
})
export class FormGroupPipe implements PipeTransform {

  transform(value: UntypedFormGroup, ...args: string[]): UntypedFormGroup | null {
    const [f1] = args;

    const form = value && value.get(f1) as UntypedFormGroup;

    return form;
  }

}
