import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { Cashback } from 'src/app/common/models/cashback';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';
import { ICashback, TCashback } from 'src/app/common/interfaces/cashback';

@Injectable({
  providedIn: 'root'
})
export class CashbackCoreService {
  #coreEndPoint = environment.coreEndPoint;
  private _count$ = new BehaviorSubject<number>(0);
  count$ = this._count$.asObservable();
  private _pending$ = new BehaviorSubject<number>(0);
  pending$ = this._pending$.asObservable();


  #cashback$ = new BehaviorSubject<TCashback>({
    data: {
      CAD: {
        count: 0, pending: 0
      },
      EUR: {
        count: 0, pending: 0
      }
    }, loading: false
  })
  cashback$ = this.#cashback$.asObservable();

  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  constructor(
    private http: HttpService
  ) {

  }


  set loading(value: boolean) {
    this.#loading$.next(value);
  }

  set cashback(value: TCashback) {
    this.#cashback$.next(value);
  }

  set count(value: number) {
    this._count$.next(value);
  }

  set pending(value: number) {
    this._pending$.next(value);
  }

  count_sync(): Observable<IHttpResponse> {

    this.cashback = {
      data: {
        CAD: { count: 0, pending: 0 },
        EUR: { count: 0, pending: 0 },
      },
      loading: true
    }

    const path = 'cashback/count';
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        const { count, pending } = res;
        this.count = count;
        this.pending = pending;
        this.cashback = {
          data: {
            CAD: { count, pending },
            EUR: { count: 0, pending: 0 },
          },
          loading: false
        }
      }),
    );
  }


  search<T>(body?: any): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#coreEndPoint}/cashback/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false),
    );
  }


  record_search(body?: any): Observable<IHttpResponse> {
    const path = 'cashback/search';
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = res.result.map(item => new Cashback(item));
        }
        return res;
      })
    );
  }

  transfer_to_balance(body?: any): Observable<IHttpResponse> {
    const path = 'cashback/transfer';
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap((res: IHttpResponse) => {
        if (res?.success) {
          this.count_sync().pipe(
            take(1)
          ).subscribe();
        }
      })
    );
  }

  withdraw_demand(body?: any): Observable<IHttpResponse> {
    const path = 'cashback/withdraw/demand';
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap((res: IHttpResponse) => {
        if (res?.success) {
          this.count_sync().pipe(
            take(1)
          ).subscribe();
        }
      })
    );
  }

  withdraw_search(body?: any): Observable<IHttpResponse> {
    const path = 'cashback/withdraw/search';
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
    );
  }
}
