import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'issueLink'
})
export class IssueLinkPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): string | null {
    if (!value) {
      return null;
    }

    const livemode = (args[0] && typeof args[0] === 'boolean') ?? true;

    if (value.includes('包裹编号')) {
      const packageNumber = value.split(' ').join('').split(':')[1] ?? '';
      const shippingNumber = packageNumber.split('-')[0];

      if (shippingNumber && packageNumber) {
        const link = `/b2c/packages/records/detail/${packageNumber}`;
        return livemode ? link : `/test${link}`;
      }

    }
    return null;
  }

}
