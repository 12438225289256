<form nz-form [formGroup]="validateForm" nzLayout="horizontal">
  <ng-container *ngIf="!option.hidden">
    <nz-form-item>
      <nz-form-control [nzErrorTip]="fv.message(validateForm, 'amount')">
        <nz-input-group
          nzAddOnBefore="保额(承运商)"
          nzAddOnAfter="$"
          style="width: auto"
        >
          <nz-input-number
            nzPlaceHolder="可选填"
            nzPrecision="2"
            nzStep="10"
            nzMin="0"
            formControlName="amount"
            style="min-width: 200px"
          ></nz-input-number>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-divider nzPlain></nz-divider>
  </ng-container>
</form>
