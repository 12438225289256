import { Carrier } from '../carrier';
import { IBtcShippingAddress, IBtcShippingWaybill } from '../shipping';
import { StateSchema } from '../state';
export * from './pickup.timetable.interface';


export enum PickupEvent {
    create = 'create',
    delete = 'delete',
    submit = 'submit',
    void = 'void',
    update = 'update',
    edit = 'edit',
}

export interface IBtcPickup {
    createdAt: Date;
    updatedAt: Date;
    id: string;
    livemode: number;
    version: number;
    status: number;
    user: string;
    waybillCarrierCode: Carrier;
    instructions: string;
    qty: number;
    email: string;
    date: IBtcPickupDate;
    location: IBtcShippingAddress;
    submitted: number;
    voided: number;
    waybills?: IBtcShippingWaybill[];
}


export enum PickupStateCode {
    pending = 'pending',
    submitted = 'submitted',
    submittedPending = 'submittedPending',
    failed = 'failed',
    voided = 'voided',
    voidedPending = 'voidedPending',
    voidedFailed = 'voidedFailed'
}


export interface IBtcPickupDate {
    pickupDate: string;
    readyTime: string;
    closeTime: string;
}

export type PickupState = keyof typeof PickupStateCode;

export type PickupStateProps = {
    [type in PickupStateCode]: StateSchema<type>;
};
