import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Voucher } from 'src/app/common/models/auth/voucher.model';
import { VoucherCoreService } from 'src/app/core/account';
import { FormValidationService } from 'src/app/core/form';
import { IndicatorService } from 'src/app/shared/indicator';

@Component({
  selector: 'voucher-form',
  templateUrl: './voucher-form.component.html',
  styleUrls: ['./voucher-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoucherFormComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  @Input() serviceCode: string;
  @Input() amount = 0;
  @Output() valueChange = new EventEmitter<Voucher | null>();

  selectorVisible = false;

  voucher: any;

  validateForm = new UntypedFormGroup({
    code: this.fb.control(null, [Validators.required, Validators.minLength(5)]),
    serviceCode: this.fb.control(null, [Validators.required]),
    amount: this.fb.control(null, [Validators.min(0)])
  });

  constructor(
    private detection: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private voucherService: VoucherCoreService,
    private indicator: IndicatorService
  ) { }

  ngOnInit(): void {
    this.validateForm.patchValue({
      serviceCode: this.serviceCode,
      amount: this.amount
    });
  }


  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      const body = this.validateForm.value;
      this.search(body);
    }

  }

  private search(body: any): void {
    this.isLoading = true;
    this.voucherService.search_by_code(body).pipe(
      take(1)
    ).subscribe({
      next: res => {

        const vouchers = res?.result ?? [];

        if (vouchers.length) {
          this.voucher = vouchers[0];
          this.valueChange.emit(vouchers[0]);
        } else {
          this.indicator.alert({
            nzContentParams: {
              type: 'warning',
              message: '没有找到可用的代金券'
            }
          });
          this.code_reset();
        }

        this.isLoading = false;
      }
    });
  }

  on_voucher_change(voucher: Voucher | null) {
    this.voucher = voucher;
    this.valueChange.emit(this.voucher);
    this.detection.detectChanges();
  }

  reset(): void {
    this.voucher = null;
    this.code_reset();
    this.valueChange.emit(null);
    this.detection.detectChanges();
  }

  code_reset(): void {
    this.validateForm.patchValue({
      code: null
    });
  }

  selector(): void {
    this.selectorVisible = true;
  }

  close(): void {
    this.selectorVisible = false;
  }


}
