import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { carriers } from 'src/app/common/constants/carrier';
import { CarrierCode, CarrierPropItem } from 'src/app/common/interfaces/carrier';
const allowedCarrier = [CarrierCode.canpost, CarrierCode.dhl, CarrierCode.ups, CarrierCode.purolator];

@Component({
  selector: 'label-search-form',
  templateUrl: './label-search-form.component.html',
  styleUrls: ['./label-search-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelSearchFormComponent implements OnInit {
  @Input() validateForm: UntypedFormGroup;
  @Output() formChange = new EventEmitter<any>();
  carriers: CarrierPropItem<CarrierCode>[] = [];
  constructor() {

    for (const i in carriers) {
      if (Object.prototype.hasOwnProperty.call(carriers, i)) {
        const carrier = carriers[i] as CarrierPropItem<CarrierCode>;
        if (allowedCarrier.includes(carrier.code)) {
          this.carriers.push(carrier);
        }
      }
    }
  }

  ngOnInit(): void {
  }

  keywords_reset(): void {
    this.validateForm.patchValue({
      keywords: null
    });
    this.formChange.emit();
  }
}
