<form nz-form [formGroup]="validateForm">
  <div formGroupName="additional">
    <ng-container *ngIf="additionalForm | hasControl: 'ndh'">
      <service-additional-form-ndh
        [current]="current"
        [option]="additional.ndh"
        [validateForm]="additionalForm | formGroup: 'ndh'"
      ></service-additional-form-ndh>
    </ng-container>

    <ng-container *ngIf="additionalForm | hasControl: 'dc'">
      <service-additional-form-dc
        [current]="current"
        [option]="additional.dc"
        [validateForm]="additionalForm | formGroup: 'dc'"
      ></service-additional-form-dc>
    </ng-container>

    <ng-container *ngIf="additionalForm | hasControl: 'so'">
      <service-additional-form-so
        [current]="current"
        [option]="additional.so"
        [validateForm]="additionalForm | formGroup: 'so'"
      ></service-additional-form-so>
    </ng-container>
  </div>
</form>
