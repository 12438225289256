import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingRfeSharedModule } from './rfe';
import { ShippingItemSharedModule } from './items';
import { ShippingDocSharedModule } from './doc';



@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    ShippingItemSharedModule,
    ShippingRfeSharedModule,
    ShippingDocSharedModule
  ],
  exports: [
    ShippingRfeSharedModule,
    ShippingItemSharedModule,
    ShippingDocSharedModule
  ]
})
export class ShippingExportationSharedModule { }
