import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IBtcServiceCode, IBtcServiceIndexListItem } from 'src/app/common/interfaces/service';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { HttpService } from '../http';
import { BtcService, BtcServiceContent } from 'src/app/common/models/service';
import { DEFAULT_SEARCH_RESULT } from 'src/app/common/constants/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BtcServiceService {
  #coreEndpoint: string;
  constructor(
    private http: HttpService
  ) {
    this.#coreEndpoint = environment.coreEndPoint;
  }

  public single_index_search(body: any = null): Observable<IBtcServiceIndexListItem[]> {
    const path = `${this.#coreEndpoint}/service/index/single`;
    return this.http.post(path, body).pipe(
      map((res: IHttpResponse) => {
        const { result: index } = res;
        return index.map(item => {
          return {
            label: item.label,
            value: item
          };
        });
      }),
      catchError(() => of([]))
    );
  }


  search(body: any): Observable<BtcService[]> {
    const path = `${this.#coreEndpoint}/service/search`;
    return this.http.post(path, body).pipe(
      map((res: IHttpResponse) => {
        const { result } = res;
        return result.map(item => new BtcService(item));
      }),
      catchError(() => of([]))
    );
  }

  index_search(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/service/index/search`;
    return this.http.post(path, body).pipe(

      catchError(() => of(DEFAULT_SEARCH_RESULT))
    );
  }

  content_search(serviceId: string, body: any = {}): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/b2c/service/${serviceId}/contents/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = res.result.map(item => new BtcServiceContent(item));
        }
        return res;
      })
    );
  }

  code_search(body: any = {}): Observable<IHttpResponse> {
    const path = `service/code/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = res.result.map(item => item as IBtcServiceCode);
        }
        return res;
      })
    );
  }

}
