import { Injectable, Injector } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApiStatusCoreService } from '../api-status';
import { AuthCoreService } from 'src/app/core/auth';
import { UnitService } from '../unit/unit.service';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  constructor(
    private injector: Injector
  ) { }

  load() {
    const authService = this.injector.get(AuthCoreService);
    const unitService = this.injector.get(UnitService);
    const apiStatusService = this.injector.get(ApiStatusCoreService);
    return new Promise((resolve, reject) => {
      authService.access_token_init().pipe(
        take(1),
        switchMap((res) => {
          if (!res) {
            return of(true);
          }
          return forkJoin([
            // btcApiService.sync(),
            apiStatusService.core_api_status_get(),
            unitService.init()
          ]).pipe(
            switchMap(() => of(true)),
            catchError((e) => {
              return of(false);
            })
          );
        })
      )
        .subscribe({
          next: (res) => {
            resolve(true);
          }
        });

    });
  }

}
