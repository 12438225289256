import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IHttpResponse } from '../http/interfaces';
import { HttpHelperService } from 'src/app/helpers/http';


@Injectable({
  providedIn: 'root'
})
export class BillingAddressApiService {
  #saleEndPoint = environment.saleEndPoint;
  #validating$ = new BehaviorSubject<boolean>(false);
  validating$ = this.#validating$.asObservable();

  constructor(
    private http: HttpHelperService
  ) {

  }

  set validating(value: boolean) {
    this.#validating$.next(value);
  }

  vatNumberValidate<T>(body: { vatNumber: string; countryCode: string }): Observable<IHttpResponse<T>> {
    this.validating = true;
    const path = `${this.#saleEndPoint}/vies/vat/check`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.validating = false)
    )
  }

}
