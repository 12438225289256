import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowAllComponent } from './show-all.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';



@NgModule({
  declarations: [
    ShowAllComponent
  ],
  imports: [
    CommonModule,
    NzDividerModule,
    NzButtonModule,
    NzIconModule
  ],
  exports: [
    ShowAllComponent
  ]
})
export class ShowAllModule { }
