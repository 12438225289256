<ng-container *ngIf="!isLoading; else isLoadingTpl">
  <ng-container
    *ngIf="tracking; then stepTpl; else trackingNotFoundTpl"
  ></ng-container>
  <ng-container *ngIf="error; then errorTpl"></ng-container>
</ng-container>

<ng-template #isLoadingTpl>
  <i nz-icon nzType="loading"></i> 加载中...
</ng-template>

<ng-template #trackingNotFoundTpl>
  <nz-empty
    nzNotFoundContent="没有物流信息"
    [nzNotFoundImage]="ImgTpl"
  ></nz-empty>
  <ng-template #ImgTpl>
    <img src="/assets/img/svg/parcel/029-track.svg" width="75" />
  </ng-template>
</ng-template>

<ng-template #errorTpl>
  <indicator-alert
    type="error"
    [message]="error?.message"
    [problems]="error?.problems"
    [buttons]="null"
  ></indicator-alert>
</ng-template>

<ng-template #stepTpl>
  <indicator-alert
    *ngIf="tracking?.notices?.length"
    [problems]="tracking?.notices"
    [buttons]="null"
    type="orange"
  ></indicator-alert>
  <nz-steps
    [nzCurrent]="tracking?.current"
    [nzProgressDot]="progressTemplate"
    [nzDirection]="(small$ | async) ? 'vertical' : 'horizontal'"
  >
    <ng-container
      *ngFor="let step of tracking?.steps; let i = index; trackBy: trackByIndex"
    >
      <nz-step [nzTitle]="step.title" [nzDescription]="stepContent"></nz-step>

      <ng-template #stepContent>
        <ng-container *ngIf="step.location; then location"></ng-container>
        <ng-container *ngIf="step?.eventTime; then eventTime"> </ng-container>
        <ng-container *ngIf="step?.status; then states"> </ng-container>
        <ng-container *ngIf="step?.carrier; then carrier"> </ng-container>
        <ng-container *ngIf="step?.waybillReference; then ref"> </ng-container>
      </ng-template>
    </ng-container>
  </nz-steps>

  <ng-template #progressTemplate let-dot let-status="status" let-index="index">
    <ng-container *ngIf="tracking?.steps[index]?.desc as desc">
      <span
        nz-popover
        [nzPopoverContent]="popoverTpl"
        style="margin-left: -100%"
      >
        <ng-template #dotTpl [ngTemplateOutlet]="dot"></ng-template>
      </span>

      <ng-template #popoverTpl>
        {{ desc }}
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!tracking?.steps[index]?.desc">
      <ng-template #dotTpl [ngTemplateOutlet]="dot"></ng-template>
    </ng-container>
  </ng-template>

  <ng-template #desc let-desc>
    <div>
      {{ desc }}
    </div>
  </ng-template>

  <ng-template #location let-location>
    <div>
      {{ location }}
    </div>
  </ng-template>

  <ng-template #eventTime let-eventTime>
    <div>
      <small nz-typography nzType="secondary">
        {{ eventTime | amLocale: i18n.getLocaleId() | amDateFormat: "ll" }}
      </small>
    </div>
  </ng-template>

  <ng-template #states let-status>
    <div>
      <ng-container *ngFor="let tag of status">
        <nz-tag [nzColor]="tag?.color">{{ tag?.text }}</nz-tag>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #carrier let-carrier>
    <div>
      <small nz-typography nzType="secondary" *ngIf="carrier?.trackingUrl">
        <a [href]="carrier.trackingUrl" target="_blank">
          {{ carrier.name }}
        </a>
      </small>
      <small nz-typography nzType="secondary" *ngIf="!carrier?.trackingUrl">
        {{ carrier.name }}
      </small>
    </div>
  </ng-template>
  <ng-template #ref let-waybillReference>
    <div>
      <br />
      <button
        nz-button
        nzType="primary"
        nzGhost
        nzShape="round"
        nzDanger
        nzSize="small"
        (click)="searchDetail(waybillReference)"
      >
        查询详情
      </button>
    </div>
  </ng-template>
  <show-all [show]="tracking?.detail"></show-all>
</ng-template>
