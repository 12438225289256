import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { map, take, tap } from 'rxjs/operators';
import { ADDRESS_TYPES } from 'src/app/common/constants/address';
import { IPcaOption, IPcaResult } from 'src/app/common/interfaces/pca';
import { IRegion, IRegionStateListItem } from 'src/app/common/interfaces/region';
import { FormValidationService } from 'src/app/core/form';
import { PcaService } from 'src/app/core/pca';
import { RegionService } from 'src/app/core/region';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormComponent implements OnInit {


  private _region: IRegion;
  formSchema: any = {};
  isLoading = false;
  regionLabel = '';


  stateList: IRegionStateListItem[] = [];
  addressList = [];
  addressTypeList = ADDRESS_TYPES.filter(item => item.value);
  openPcaPanel = false;

  private _validateForm: UntypedFormGroup;

  @Input()
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }
  set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
  }

  @Input()
  set region(value: IRegion) {
    this._region = value;
    this.build_schema(this.region.quoteSchema);
    this.stateList = [];
    this.addressList = [];
    this.validateForm.patchValue({
      regionCode: this.region.isoCode2
    });
  }
  get region(): IRegion {
    return this._region;
  }

  constructor(
    private fv: FormValidationService,
    private fb: UntypedFormBuilder,
    private pca: PcaService,
    private regionService: RegionService,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.build();
  }

  private build_schema(schema: any) {

    if (!schema) {
      return false;
    }
    this.formSchema = schema;
    const keys = Object.keys(schema);

    for (const key of keys) {

      if (!schema[key] && this.validateForm.get(key)) {
        this.validateForm.removeControl(key);
      }

      if (schema[key] && this.validateForm.get(key)) {
        this.validateForm.get(key).clearValidators();
        this.validateForm.get(key).setValidators(this.fv.parseValidations(schema[key]));
        this.validateForm.get(key).setValue(schema[key].value);
        this.validateForm.get(key).updateValueAndValidity();
      }

      if (schema[key] && !this.validateForm.get(key)) {
        this.validateForm.addControl(key, this.fb.control(
          schema[key].value,
          this.fv.parseValidations(schema[key])
        ));
      }
    }



  }

  build(): void {
    if (this._region) {
      this.regionLabel = `${this._region.nameLocale} (${this._region.isoCode2})`;
      this.validateForm.patchValue({
        regionCode: this.region.isoCode2
      });
    } else {
      this.validateForm.patchValue({
        regionCode: null
      });
    }
  }


  on_address_search(value: string = null, LastId = ''): void {

    this.isLoading = true;
    this.pca.find(value, this.region.isoCode2, LastId).pipe(
      take(1),
      map((items: IPcaOption[]) => {
        const options = items.map(item => {
          return {
            label: item.Text + ' ' + item.Description,
            value: item
          };
        });
        if (LastId !== '') {
          this.openPcaPanel = true;
        }
        return options;
      })
    ).subscribe({
      next: (data) => {
        this.addressList = data;
        this.detection.detectChanges();
      }
    });
  }

  on_address_change(model) {
    if (!model) {
      return false;
    }
    const { Next, Id: LastId } = model;

    if (Next === 'Retrieve') {
      const pathData = this.pca.optionParse(model, this.region) as IPcaResult;
      if (pathData) {
        this.validateForm.patchValue(pathData);
        this.on_province_search(pathData.stateCode);
      }
    }

    if (Next === 'Find') {
      this.on_address_search(null, LastId);
    }
  }

  on_province_search(stateCode: string): void {

    this.isLoading = true;

    this.regionService.province_search({
      regionCode: this.region.isoCode2,
      code: stateCode
    }).pipe(
      take(1),
      tap(next => this.isLoading = false, e => this.isLoading = false)
    )
      .subscribe({
        next: res => {
          if (res?.result?.length === 1) {
            this.validateForm.patchValue({
              province: res.result[0].value
            });
          }
          this.stateList = res.result;
          this.detection.detectChanges();
        }
      });
  }

  on_state_change(model) {
    if (!model) {
      return false;
    }

    const { code, name } = model;

    this.validateForm.patchValue({
      stateCode: code,
      state: name
    });
  }

  on_blur() {

  }

}
