import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { RegionService } from 'src/app/core/region';

@Component({
  selector: 'region-doc-viewer',
  templateUrl: './region-doc-viewer.component.html',
  styleUrls: ['./region-doc-viewer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionDocViewerComponent implements OnInit {
  @Input() isoCode2: string;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  base64src: string;

  constructor(
    private regionService: RegionService,
    private detection: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.search({ isoCode2: this.isoCode2 });
  }

  private search(body: any): void {
    this.isLoading = true;
    this.regionService.doc_base64(body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.base64src = res?.result;
        this.isLoading = false;
      }
    });
  }

}
