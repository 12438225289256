import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { lightboxAnimation } from '@ngx-gallery/lightbox/lib/lightbox.animation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IRegion } from 'src/app/common/interfaces/region';
import { IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { IUnit } from 'src/app/common/interfaces/setting';
import { BtcServiceContent } from 'src/app/common/models/service';
import { UnitService } from 'src/app/core/unit';

@Component({
  selector: 'shipping-package',
  templateUrl: './shipping-package.component.html',
  styleUrls: ['./shipping-package.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingPackageComponent implements OnInit {
  private destroy$ = new Subject<void>();
  private _index: IBtcServiceIndex;
  selectedIndex = 0;
  paks: UntypedFormArray;
  @Input() validateForm: UntypedFormArray;

  @Input() countMax = 1;
  @Input() countMin = 1;
  @Input() content: BtcServiceContent;
  @Input() multiDestination: number;
  @Input() destinationRegion: IRegion;
  @Input() indexes: IBtcServiceIndex[] = [];
  @Input()
  set index(value: IBtcServiceIndex) {
    this._index = value;

    for (const group of this.validateForm.controls as UntypedFormGroup[]) {
      const pak = group.get('pak') as UntypedFormGroup;
      if (this.index?.weight?.value) {
        pak?.patchValue({
          weight: this.unit.weight.isKilo ? this.index?.weight.value.kg : this.index?.weight.value.lb
        });
      } else {
        pak.patchValue({
          weight: null
        });
      }

      const dimension = {
        length: undefined,
        width: undefined,
        height: undefined
      };

      if (this.index?.size?.length) {
        dimension.length = this.unit.size.isCentimeter ? this.index?.size?.length.value.cm : this.index?.size?.length.value.in;
      }

      if (this.index?.size?.width) {
        dimension.width = this.unit.size.isCentimeter ? this.index?.size?.width.value.cm : this.index?.size?.width.value.in;
      }

      if (this.index?.size?.height) {
        dimension.height = this.unit.size.isCentimeter ? this.index?.size?.height.value.cm : this.index?.size?.height.value.in;
      }

      pak.patchValue({
        size: dimension
      });
    }
  }
  get index(): IBtcServiceIndex {
    return this._index;
  }
  unit: IUnit;
  constructor(
    private fb: UntypedFormBuilder,
    private unitService: UnitService
  ) {
    this.unit_watch();
  }

  private unit_watch(): void {
    this.unitService.unit$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: unit => this.unit = unit
    });
  }

  ngOnInit(): void {
    if (!this.validateForm?.controls?.length) {
      for (let i = 0; i < this.countMin; i++) {
        this.add();
      }
    }
  }

  closeTab({ index }: { index: number }): void {
    this.validateForm.removeAt(index);
  }

  add(): void {
    const count = this.validateForm.controls.length;

    if (this.countMax > count) {

      const pakForm = new UntypedFormGroup({
        pak: this.fb.group({
          size: this.fb.group({
            length: this.fb.control(null, [Validators.required]),
            width: this.fb.control(null, [Validators.required]),
            height: this.fb.control(null, [Validators.required]),
          }),
          weight: this.fb.control(null, [Validators.required]),
          isKilo: this.fb.control(null, [Validators.required]),
          isCentimeter: this.fb.control(null, [Validators.required])
        }),
        serviceContentId: this.fb.control(null, []),
        additional: this.fb.group({})
      });

      if (this.multiDestination) {
        pakForm.addControl('destination', this.fb.group({
          region: this.fb.control(null, [Validators.required]),
          regionCode: this.fb.control(null, [Validators.required])
        }));
        pakForm.addControl('code', this.fb.control(null, [Validators.required]));

        pakForm.patchValue({
          destination: {
            region: this.destinationRegion,
            regionCode: this.destinationRegion?.isoCode2
          }
        });
      }



      this.validateForm.push(pakForm);


    }
  }

  trackByIndex = (i: number, _: any): number => {
    return i;
  }

}
