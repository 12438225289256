<ng-container *ngIf="!isLoading; else loadingTpl">
  <ng-container *ngIf="rate; else emptyTpl">
    <!-- RATE -->
    <ng-container *ngIf="rate">
      <ng-template #beforeTaxTpl>
        <strong nz-typography>优邮价格</strong>
      </ng-template>
      <ng-template #taxTpl>
        <strong nz-typography>税</strong>
      </ng-template>
      <ng-template #discountTpl>
        <strong nz-typography>折扣</strong>
      </ng-template>
      <ng-template #totalTpl>
        <strong nz-typography>总计</strong>
      </ng-template>
      <nz-descriptions nzSize="small">
        <ng-container *ngIf="rate?.eta as eta">
          <nz-descriptions-item nzTitle="预计送达" [nzSpan]="3">
            {{ eta }}
          </nz-descriptions-item>
          <nz-divider></nz-divider>
        </ng-container>
        <ng-container *ngIf="rate.charge.msrp">
          <nz-descriptions-item nzTitle="柜台价" [nzSpan]="3">
            <span nz-typography nzType="danger">
              <del>
                {{
                  rate.charge.msrp
                    | currency : rate.charge.currency : "symbol-narrow"
                }}
              </del>
            </span>
          </nz-descriptions-item>
        </ng-container>

        <nz-descriptions-item [nzTitle]="beforeTaxTpl" [nzSpan]="3">
          {{
            rate.charge.base + rate.charge.basePlus
              | currency : rate.charge.currency : "symbol-narrow"
          }}
        </nz-descriptions-item>
        <!-- <nz-descriptions-item nzTitle="基础运费" [nzSpan]="3">
          {{
            rate.charge.base | currency: rate.charge.currency:"symbol-narrow"
          }}
        </nz-descriptions-item>

        <ng-container *ngIf="rate.charge.basePlus">
          <nz-descriptions-item nzTitle="其他" [nzSpan]="3">
            {{
              rate.charge.basePlus
                | currency: rate.charge.currency:"symbol-narrow"
            }}
          </nz-descriptions-item>
        </ng-container> -->
        <nz-descriptions-item [nzTitle]="taxTpl" [nzSpan]="3">
          <ng-container *ngIf="!rate.charge.taxes.length"> $0 </ng-container>
          <ng-container *ngFor="let tax of rate.charge.taxes">
            <nz-descriptions nzSize="small">
              <nz-descriptions-item [nzTitle]="tax.name" [nzSpan]="3">
                {{
                  tax.amount | currency : rate.charge.currency : "symbol-narrow"
                }}
              </nz-descriptions-item>
            </nz-descriptions>
          </ng-container>
        </nz-descriptions-item>

        <ng-container *ngIf="rate.charge.discount">
          <nz-descriptions-item [nzTitle]="discountTpl" [nzSpan]="3">
            {{
              rate.charge.discount
                | currency : rate.charge.currency : "symbol-narrow"
            }}
          </nz-descriptions-item>
        </ng-container>

        <nz-descriptions-item [nzTitle]="totalTpl" [nzSpan]="3">
          {{
            rate.charge.due | currency : rate.charge.currency : "symbol-narrow"
          }}
        </nz-descriptions-item>
      </nz-descriptions>
    </ng-container>
  </ng-container>
</ng-container>

<!-- DEFAULT TPL -->
<ng-template #emptyTpl>
  <p style="text-align: center">
    无法获得报价
    <button nz-button nzSize="small" nzType="link" (click)="onSubmit()">
      <i nz-icon nzType="sync"></i> 刷新
    </button>
  </p>
</ng-template>

<ng-template #loadingTpl>
  <p style="text-align: center">
    <i nz-icon nzType="loading"></i> 运费获取中,请稍后...
  </p>
</ng-template>
