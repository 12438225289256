import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketSummaryComponent } from './ticket-summary/ticket-summary.component';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { TicketFormComponent } from './ticket-form/ticket-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { MomentModule } from 'ngx-moment';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { LayoutModule } from 'src/app/shared/layout';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  declarations: [
    TicketSummaryComponent,
    TicketFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzCardModule,
    NzInputModule,
    NzSelectModule,
    NzListModule,
    NzCommentModule,
    NzAvatarModule,
    NzButtonModule,
    NzIconModule,
    NzTypographyModule,
    NzDividerModule,
    MomentModule,
    NzI18nModule,
    NzTagModule,
    LayoutModule
  ],
  exports: [
    TicketSummaryComponent,
    TicketFormComponent
  ]
})
export class ShippingTicketSharedModule { }
