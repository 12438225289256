import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PickupPageCreateComponent } from './pickup-page-create/pickup-page-create.component';
import { PickupPageDetailComponent } from './pickup-page-detail/pickup-page-detail.component';
import { PickupPageEditComponent } from './pickup-page-edit/pickup-page-edit.component';
import { PickupPageListComponent } from './pickup-page-list/pickup-page-list.component';

const routes: Routes = [
  {
    path: '',
    component: PickupPageListComponent,
    data: {
      breadcrumb: null,
      url: null
    },
  },
  {
    path: 'detail/:id',
    component: PickupPageDetailComponent,
    data: {
      breadcrumb: '预约详情',
      url: null
    },
  },
  {
    path: 'edit/:id',
    component: PickupPageEditComponent,
    data: {
      breadcrumb: '修改预约',
      url: null
    },
  },
  {
    path: 'create',
    component: PickupPageCreateComponent,
    data: {
      breadcrumb: '创建预约',
      url: null
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PickupRoutingModule { }
