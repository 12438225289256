import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Carrier } from 'src/app/common/interfaces/carrier';
import { IPickupTimetable } from 'src/app/common/interfaces/pickup';
import { BtcPickupService } from 'src/app/core';

@Component({
  selector: 'pickup-time-selector',
  templateUrl: './pickup-time-selector.component.html',
  styleUrls: ['./pickup-time-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupTimeSelectorComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;

    this.detection.detectChanges();
  }

  @Input() validateForm: UntypedFormGroup;
  private _waybillCarrierCode: Carrier;
  @Input()
  set waybillCarrierCode(value: Carrier) {
    if (this._waybillCarrierCode !== value) {
      this._waybillCarrierCode = value;
      if (value) {
        this.search(value);
      } else {
        this.on_time_change(null);
        this.validateForm.patchValue({
          time: null
        });
        this.timetables = [];
        this.detection.detectChanges();
      }
    }

    this.validateForm.patchValue({
      readyTime: null,
      closeTime: null
    });

  }
  get waybillCarrierCode(): Carrier {
    return this._waybillCarrierCode;
  }
  @Input() layout = 'inline';

  timetables = [];

  constructor(
    private pickupService: BtcPickupService,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  on_time_change(item: IPickupTimetable | null): void {

    this.validateForm.get('date')?.patchValue({
      readyTime: item?.readyTime ?? null,
      closeTime: item?.closeTime ?? null
    });
  }

  private search(carrierCode: Carrier): void {
    const body = {
      carrierCode
    };

    this.isLoading = true;
    this.pickupService.timetable(body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.timetables = res.result;
        if (this.timetables.length) {
          const currentValue = this.validateForm?.value?.time ?? null;
          const time = this.timetables.find(item => item.value.id === currentValue?.id);

          this.validateForm.patchValue({
            time: time ? time.value : null
          });
        }
        this.isLoading = false;
      }
    });

  }

}
