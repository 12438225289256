
/**
 * @SIGNATURE
 */

import { Carrier, CarrierCode } from '../carrier';
import { Region } from '../region';
import { AdditionalOption } from './btc-service-option.interface';


export enum SignatureFedexCode {
    SERVICE_DEFAULT = 'SERVICE_DEFAULT',
    NO_SIGNATURE_REQUIRED = 'NO_SIGNATURE_REQUIRED',
    INDIRECT = 'INDIRECT',
    DIRECT = 'DIRECT',
    ADULT = 'ADULT'
}

export enum SignatureCanpostCode {
    SO = 'SO',
    PA18 = 'PA18',
    PA19 = 'PA19',
}

export type SignatureFedex = keyof typeof SignatureFedexCode;
export type SignatureCanpost = keyof typeof SignatureCanpostCode;

export type Signature = {
    [CarrierCode.fedex]: Record<SignatureFedexCode, AdditionalOption<SignatureFedexCode>>;
    [CarrierCode.canpost]: Record<SignatureCanpostCode, AdditionalOption<SignatureCanpostCode>>;
};

export type SoItem<T> = {
    carrier: Carrier;
    blocked?: boolean;
    state?: {
        value?: boolean;
        required?: boolean;
        blocked?: boolean;
    };
    code?: {
        value?: T;
        blocked?: boolean;
        options?: T[]
    };
    hidden?: boolean;
    shipTo?: Region;
};

