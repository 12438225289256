import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'address-search-form',
  templateUrl: './address-search-form.component.html',
  styleUrls: ['./address-search-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSearchFormComponent implements OnInit {
  @Input() validateForm: UntypedFormGroup;

  @Output() valueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }


  keyword_reset(): void {
    this.validateForm.patchValue({
      keywords: null
    });
    this.valueChange.emit();
  }

  on_submit(): void {
    this.valueChange.emit();
  }

}
