<form nz-form [formGroup]="validateForm">
  <nz-form-item *ngIf="validateForm | hasControl: 'memo'">
    <nz-form-label
      nzFor="memo"
      [nzRequired]="validateForm | isRequired: 'memo'"
    >
      备注 Memo
    </nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'memo'">
      <input type="text" nz-input formControlName="memo" placeholder="" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="validateForm | hasControl: 'name'">
    <nz-form-label
      nzFor="name"
      [nzTooltipTitle]="item?.name?.tip?.title | nzSafeNull: ''"
      [nzTooltipIcon]="item?.name?.tip?.icon"
      [nzRequired]="validateForm | isRequired: 'name'"
    >
      物品名称 Name
    </nz-form-label>
    <nz-form-control
      [nzErrorTip]="validateForm | formError: 'name':item?.name?.errors"
    >
      <input
        type="text"
        nz-input
        formControlName="name"
        [placeholder]="item?.name?.placeholder | nzSafeNull: ''"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="validateForm | hasControl: 'qty'">
    <nz-form-label
      nzFor="nameLocale"
      nzTooltipTitle=""
      [nzRequired]="validateForm | isRequired: 'qty'"
    >
      数量 Qty
    </nz-form-label>
    <nz-form-control
      [nzErrorTip]="validateForm | formError: 'qty':item?.qty?.errors"
    >
      <nz-input-group [nzAddOnAfter]="addOnAfter">
        <nz-input-number
          style="width: 100%"
          formControlName="qty"
          [nzPlaceHolder]="item?.qty?.placeholder | nzSafeNull: '0'"
          [nzMin]="1"
          [nzMax]="999"
          [nzStep]="1"
          [nzPrecision]="0"
        ></nz-input-number>
      </nz-input-group>

      <ng-template #addOnAfter>
        <nz-select formControlName="unit" style="min-width: 150px">
          <nz-option
            *ngFor="let unit of itemUnits"
            [nzLabel]="unit.name"
            [nzValue]="unit.code"
          >
          </nz-option>
        </nz-select>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="validateForm | hasControl: 'price'">
    <nz-form-label
      nzFor="price"
      nzTooltipTitle=""
      [nzRequired]="validateForm | isRequired: 'price'"
    >
      单价 Unit Price
    </nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'price'">
      <nz-input-group [nzAddOnAfter]="'$CAD'">
        <nz-input-number
          style="width: 100%"
          formControlName="price"
          [nzPlaceHolder]="'0'"
          [nzMin]="0.1"
          [nzStep]="1"
          [nzPrecision]="1"
        ></nz-input-number>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="validateForm | hasControl: 'weight'">
    <nz-form-label
      nzFor="weight"
      nzTooltipTitle=""
      [nzRequired]="validateForm | isRequired: 'weight'"
    >
      重量(单件) Unit Weight
    </nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'weight'">
      <nz-input-group [nzAddOnAfter]="unit.weight.name">
        <nz-input-number
          style="width: 100%"
          formControlName="weight"
          [nzPlaceHolder]="'0'"
          [nzMin]="0.001"
          [nzStep]="3"
          [nzPrecision]="1"
        ></nz-input-number>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</form>
