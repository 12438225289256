import { SHIPPING_TYPE } from '../../constants/shipping';
import { ITag } from '../../interfaces/common';
import { ISOCode2 } from '../../interfaces/region';
import { IBtcShipping, IShippingType, IShippingWeight } from '../../interfaces/shipping';
import { BtcService, BtcServiceContent } from '../service';
import { BtcShippingAdditional } from './btc-shipping.additional.model';
import { BtcShippingPackage } from './btc-shipping.package.model';

export class BtcShipping {

    private _data: IBtcShipping;

    constructor(data: IBtcShipping) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id ?? null;
    }

    get number(): string {
        return this._data?.shippingNumber ?? '未知';
    }

    get createdAt(): Date {
        return this._data?.createdAt ?? null;
    }

    get updatedAt(): Date {
        return this._data?.updatedAt ?? null;
    }

    get submittedAt(): Date {
        return this._data?.submittedAt ?? null;
    }

    get voidedAt(): Date {
        return this._data?.voidedAt ?? null;
    }

    get settledAt(): Date {
        return this._data?.settledAt ?? null;
    }

    get status(): ITag[] {

        const items = [];
        const submitted = this.status_parse('submitted', 1, ['blue', '已提交'], ['default', '预录']);
        const voidFailed = this.status_parse('voided', 4, ['red', '取消失败']);
        const voidPending = this.status_parse('voided', 2, ['orange', '取消申请处理中']);
        const voided = this.status_parse('voided', 1, ['default', '已取消']);
        const settled = this.status_parse('settled', 1, ['default', '已结算']);
        const paid = this.status_parse('paid', 1, ['cyan', '已支付']);
        const refunded = this.status_parse('refunded', 1, ['default', '全额退款']);
        const refundedPartial = this.status_parse('refunded', 3, ['default', '部分退款']);
        const refundPending = this.status_parse('refunded', 2, ['orange', '退款受理中']);
        const refundFailed = this.status_parse('refunded', 4, ['red', '退款失败']);
        const waybillCreatedPending = this.status_parse('waybillCreated', 2, ['orange', '处理中']);
        const waybillSubmittedPending = this.status_parse('waybillSubmitted', 2, ['orange', '物流确认中']);
        const waybillSubmittedFailed = this.status_parse('waybillSubmitted', 4, ['red', '物流提交失败']);

        if (submitted) {
            items.push(submitted);
        }
        if (paid) {
            items.push(paid);
        }

        if (waybillCreatedPending) {
            items.push(waybillCreatedPending);
        }

        if (waybillSubmittedPending) {
            items.push(waybillCreatedPending);
        }

        if (waybillSubmittedFailed) {
            items.push(waybillSubmittedFailed);
        }

        if (voidFailed) {
            items.push(voidFailed);
        }

        if (voidPending) {
            items.push(voidPending);
        }

        if (voided) {
            items.push(voided);
        }

        if (refundPending) {
            items.push(refundPending);
        }
        if (refundedPartial) {
            items.push(refundedPartial);
        }
        if (refunded) {
            items.push(refunded);
        }
        if (refundFailed) {
            items.push(refundFailed);
        }
        if (settled) {
            items.push(settled);
        }


        return items;

    }

    get submitted(): boolean {
        return this._data?.submitted === 1 ? true : false;
    }

    get voidPending(): boolean {
        return this._data?.voided === 2 ? true : false;
    }

    get voidedFailed(): boolean {
        return this._data?.voided === 4 ? true : false;
    }

    get voided(): boolean {
        return this._data?.voided === 1 ? true : false;
    }

    get refunded(): boolean {
        return this._data.refunded === 1 ? true : false;
    }

    get refundedPending(): boolean {
        return this._data.refunded === 2 ? true : false;
    }

    get refundedFailed(): boolean {
        return this._data.refunded === 4 ? true : false;
    }

    get refundedPartial(): boolean {
        return this._data.refunded === 3 ? true : false;
    }

    get paid(): boolean {
        return this._data?.paid === 1 ? true : false;
    }

    get settled(): boolean {
        return this._data?.settled === 1 ? true : false;
    }

    get livemode(): boolean {
        return this._data?.livemode ? true : false;
    }

    get type(): IShippingType {
        let type = null;
        if (this._data.parcel) {
            type = SHIPPING_TYPE.parcel;
        }
        if (this._data.env) {
            type = SHIPPING_TYPE.env;
        }

        return type;
    }

    get initiationRegionCode(): ISOCode2 {
        return this._data.initiationRegionCode;
    }

    get destinationRegionCode(): ISOCode2 {
        return this._data.destinationRegionCode;
    }

    get multiDestination(): boolean {
        return this._data?.multiDestination ? true : false;
    }

    get initiationId(): string {
        return this._data?.initiation;
    }

    set initiationId(id: string) {
        this._data.initiation = id;
    }

    get destinationId(): string {
        return this._data?.destination;
    }

    set destinationId(id: string) {
        this._data.destination = id;
    }

    get service(): BtcService {
        return this._data.service && new BtcService(this._data.service);
    }

    get serviceId(): string {
        if (!this._data.service) {
            return null;
        }
        return typeof this._data.service === 'string' ? this._data.service : this.service?.id;
    }

    get serviceContent(): BtcServiceContent {
        return this._data.serviceContent && new BtcServiceContent(this._data.serviceContent);
    }

    get serviceContentId(): string | null {
        if (!this._data.serviceContent) {
            return null;
        }
        return typeof this._data.serviceContent === 'string' ? this._data.serviceContent : this.serviceContent?.id;
    }


    get count(): number {
        return (this._data.packages && this._data.packages.length) ?? 0;
    }

    get totalWeight(): IShippingWeight {
        if (!this._data?.packages) {
            return null;
        }

        const total = this._data.packages.reduce((sum, pack) => {

            const { weight } = pack;
            sum.kg += weight.kg;
            sum.lb += weight.lb;
            return sum;
        }, {
            kg: 0,
            lb: 0
        });

        return total;
    }


    get packages(): BtcShippingPackage[] {
        return this._data.packages.map(pack => new BtcShippingPackage(pack));
    }

    set shipping_remove(key: string) {
        this._data.packages = this._data.packages.filter(pack => pack.packageNumber !== key);
    }

    get orderNumber(): string {
        return this._data.orderNumber;
    }

    get orderId(): string {
        return this._data.order;
    }


    get version(): number {
        return this._data.version;
    }

    get additional(): BtcShippingAdditional {
        return this._data.additional ? new BtcShippingAdditional(this._data.additional) : null;
    }

    get avatar(): string {
        if (this._data?.multiDestination) {
            return '/assets/img/svg/parcel/012-cart.svg';
        }

        if (this._data?.parcel) {
            return '/assets/img/svg/parcel/026-open box.svg';
        }
        if (this._data?.env) {
            return '/assets/img/svg/parcel/033-letter.svg';
        }

        return null;
    }

    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {

        if (prop in this._data) {

            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }
}
