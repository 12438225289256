import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, EventEmitter, Output, TemplateRef, ViewChild, Input } from '@angular/core';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { PickupEvent } from 'src/app/common/interfaces/pickup';
import { BTCPickup } from 'src/app/common/models/pickup';
import { NavigationService } from 'src/app/core/navigation';
import { PageHeaderService } from 'src/app/core/page-header';

@Component({
  selector: 'pickup-header-detail',
  templateUrl: './pickup-header-detail.component.html',
  styleUrls: ['./pickup-header-detail.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupHeaderDetailComponent implements OnInit, AfterViewInit {
  private _pickup: BTCPickup;
  @Input() set pickup(value: BTCPickup) {
    this._pickup = value;
    this.ph.avatar = this.pickup?.avatar;
  }

  get pickup(): BTCPickup {
    return this._pickup;
  }

  @Output() eventChange = new EventEmitter<PickupEvent>();
  @ViewChild('buttonsTpl') buttonsTpl: TemplateRef<any>;
  @ViewChild('subtitleTpl') subtitleTpl: TemplateRef<any>;
  @ViewChild('contentTpl') contentTpl: TemplateRef<any>;
  constructor(
    public ph: PageHeaderService,
    public i18n: NzI18nService,
    public nav: NavigationService
  ) { }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    this.view_update();
  }


  view_update(): void {
    this.ph.avatar = this.pickup?.avatar;
    // this.ph.tags = this.group.status;
    this.ph.subtitle = this.subtitleTpl;
    this.ph.buttons = this.buttonsTpl;
    this.ph.content = this.contentTpl;
  }

  delete(): void {
    this.eventChange.emit(PickupEvent.delete);
  }


  edit(): void {
    this.eventChange.emit(PickupEvent.edit);
  }


  submit(): void {
    this.eventChange.emit(PickupEvent.submit);
  }


  void(): void {
    this.eventChange.emit(PickupEvent.void);
  }

}

