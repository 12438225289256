<form nz-form [formGroup]="validateForm" [nzLayout]="layout">
  <nz-form-item nzJustify="start" *ngIf="validateForm | hasControl: 'time'">
    <nz-form-label nzRequired> 取货时段 </nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'time'">
      <nz-select
        [nzLoading]="isLoading"
        formControlName="time"
        nzPlaceHolder="请选择取货时间"
        (ngModelChange)="on_time_change($event)"
      >
        <nz-option
          style="min-width: 250px"
          *ngFor="let item of timetables"
          [nzLabel]="item.label"
          [nzValue]="item.value"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>
