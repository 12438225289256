import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LivemodeSwitchComponent } from './components/livemode-switch';
import { SidemenuLayout } from './components/sidemenu';
import { RouterModule } from '@angular/router';
import { ActivatedRoutePipe, StateRoutePipe } from './pipes';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NavBarVerticalComponent } from './components/nav-bar-vertical';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NavBarHorizontalComponent } from './components/nav-bar-horizontal';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NavBreadcrumbComponent } from './components/nav-breadcrumb';
import { LivemodeIndicatorComponent } from './components/livemode-indicator/livemode-indicator.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { FullscreenSwitchDirective } from './directives/fullscreen-switch/fullscreen-switch.directive';
import { IndicatorSharedModule } from '../indicator';
import { FixedLayout } from './components/fixed/fixed-layout';
import { NzCardModule } from 'ng-zorro-antd/card';
import { SupportModule } from '../support/support.module';
import { NavBarMainHorizontalComponent } from './components/nav-bar-main-horizontal';
import { NavBarMainVerticalComponent } from './components/nav-bar-main-vertical/nav-bar-main-vertical.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ShowAllComponent } from './components/show-all/show-all.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { MessageSharedModule } from '../message/message-shared.module';
import { NavBarGridHorizontalComponent } from './components/nav-bar-grid-horizontal/nav-bar-grid-horizontal.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { HeaderModule } from 'src/app/core/layout/header';
import { HasRegionDirective } from 'src/app/core/acl/directives/has-region';
import { AclCoreModule } from 'src/app/core/acl';
import { BusinessAccountDirective } from 'src/app/core/acl/directives/business-account';

@NgModule({
  declarations: [
    LivemodeSwitchComponent,
    SidemenuLayout,
    StateRoutePipe,
    ActivatedRoutePipe,
    NavBarVerticalComponent,
    NavBarHorizontalComponent,
    NavBreadcrumbComponent,
    LivemodeIndicatorComponent,

    FullscreenSwitchDirective,
    FixedLayout,
    NavBarMainHorizontalComponent,
    NavBarMainVerticalComponent,
    ShowAllComponent,
    NavBarGridHorizontalComponent,
  ],
  providers: [
    StateRoutePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IndicatorSharedModule,
    AclCoreModule,
    SupportModule,
    MessageSharedModule,
    HeaderModule,

    NzTypographyModule,
    NzSwitchModule,
    NzMenuModule,
    NzModalModule,
    NzDividerModule,
    NzLayoutModule,
    NzGridModule,
    NzIconModule,
    NzDrawerModule,
    NzBadgeModule,
    NzBreadCrumbModule,
    NzPageHeaderModule,
    NzTagModule,
    NzCardModule,
    NzAvatarModule,
    NzToolTipModule,
    NzButtonModule,
    NzSpinModule,

    HasRegionDirective,
    BusinessAccountDirective
  ],
  exports: [
    LivemodeSwitchComponent,
    NavBreadcrumbComponent,
    SidemenuLayout,
    StateRoutePipe,
    ActivatedRoutePipe,
    FullscreenSwitchDirective,
    FixedLayout,
    NavBarMainVerticalComponent,
    NavBarMainHorizontalComponent,
    ShowAllComponent,
    NavBarGridHorizontalComponent
  ]
})
export class LayoutModule { }
