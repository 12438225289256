import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ShippingAddressSharedModule } from './components/address';
import { ShippingOrderSharedModule } from './components/order';
import { ShippingGroupSharedModule } from './components/group';
import { ShippingRateSharedModule } from './components/rate';
import { ShippingVoucherSharedModule } from './components/voucher';
import { ShippingEntitySharedModule } from './components/entity';
import { ShippingFormatSharedModule } from './components/format';
import { IndicatorSharedModule } from '../indicator';
import { ShippingServiceSharedModule } from './components/service';
import { ShippingReferenceSharedModule } from './components/reference';
import { ShippingWaybillSharedModule } from './components/waybill';
import { ShippingTicketSharedModule } from './components/ticket';
import { ShippingLabelSharedModule } from './components/label';
import { WaybillTotalWeightPipe } from './pipes/waybill-total-weight/waybill-total-weight.pipe';
import { WaybillNamePipe } from './pipes/waybill-name/waybill-name.pipe';

@NgModule({
  declarations: [
    WaybillTotalWeightPipe,
    WaybillNamePipe
  ],
  imports: [
    CommonModule,
    IndicatorSharedModule,
    ShippingAddressSharedModule,
    ShippingFormatSharedModule,
    ShippingOrderSharedModule,
    ShippingGroupSharedModule,
    ShippingRateSharedModule,
    ShippingVoucherSharedModule,
    ShippingEntitySharedModule,
    ShippingServiceSharedModule,
    ShippingReferenceSharedModule,
    ShippingWaybillSharedModule,
    ShippingTicketSharedModule,
    ShippingLabelSharedModule,

  ],
  exports: [
    ShippingAddressSharedModule,
    ShippingOrderSharedModule,
    ShippingGroupSharedModule,
    ShippingRateSharedModule,
    ShippingVoucherSharedModule,
    ShippingEntitySharedModule,
    ShippingFormatSharedModule,
    ShippingServiceSharedModule,
    ShippingReferenceSharedModule,
    ShippingWaybillSharedModule,
    ShippingTicketSharedModule,
    WaybillTotalWeightPipe,
    WaybillNamePipe
  ]
})
export class ShippingSharedModule { }
