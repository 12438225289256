<form nz-form [formGroup]="validateForm" (submit)="on_submit()">
  <region-selector
    [regionCode]="regionCode"
    [validateForm]="validateForm"
    [allowClear]="false"
    [type]="type"
    (regionChange)="region_change($event)"
  ></region-selector>

  <address-form
    [type]="type"
    [validateForm]="validateForm"
    [schema]="region?.addressSchema"
  ></address-form>

  <div class="text-right">
    <a nz-button nzType="link" (click)="close()"> 返回 </a>
    <nz-divider nzType="vertical"></nz-divider>
    <button
      nz-button
      [nzType]="'primary'"
      [nzDanger]="true"
      [nzLoading]="isLoading"
    >
      <i nz-icon nzType="check-circle"></i> 提交
    </button>
  </div>
</form>
