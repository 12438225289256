import { IShippingType, ShippingGroupStateCode, ShippingGroupStateProps, ShippingType } from '../../interfaces/shipping';

export const SHIPPING_TYPE: Record<ShippingType, IShippingType> = {
    parcel: {
        label: '包裹',
        code: 'parcel',
        icon: 'assets/img/svg/parcel/026-open box.svg'
    },
    env: {
        label: '信件',
        code: 'env',
        icon: 'assets/img/svg/parcel/033-letter.svg'
    },

};

export const SHIPPING_GROUP_STATES: ShippingGroupStateProps = {
    pending: {
        text: '预录',
        code: ShippingGroupStateCode.pending,
        query: {
            submitted: 0
        }
    },
    submitted: {
        text: '已提交',
        code: ShippingGroupStateCode.submitted,
        query: {
            submitted: 1
        }
    },

    invoiced: {
        text: '已支付',
        code: ShippingGroupStateCode.invoiced,
        query: {
            invoiced: 1
        }
    },

    voided: {
        text: '已取消',
        code: ShippingGroupStateCode.voided,
        query: {
            voided: 1
        }
    },

    voidedPending: {
        text: '取消处理中',
        code: ShippingGroupStateCode.voidedPending,
        query: {
            voided: 2
        }
    },
    voidedFailed: {
        text: '取消失败',
        code: ShippingGroupStateCode.voidedFailed,
        query: {
            voided: 4
        }
    },

    refunded: {
        text: '已退款',
        code: ShippingGroupStateCode.refunded,
        query: {
            refunded: 1
        }
    },

    refundedPending: {
        text: '退款受理中',
        code: ShippingGroupStateCode.refundedPending,
        query: {
            refunded: 2
        }
    },
    refundedFailed: {
        text: '退款失败',
        code: ShippingGroupStateCode.refundedFailed,
        query: {
            refunded: 4
        }
    },


};

