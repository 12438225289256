<nz-card nzSize="small" nzHoverable *ngIf="service">
  <div nz-row nzJustify="space-between" nzAlign="middle">
    <div nz-col>
      <div nz-row [nzGutter]="[16, 16]" nzAlign="middle">
        <div nz-col nzSpan="24">
          <nz-tag [nzColor]="service.name.color">
            {{ service.name.text }}
          </nz-tag>
        </div>
        <div nz-col nzSpan="24">
          <div nz-row nzJustify="start" nzGutter="16">
            <ng-container
              *ngFor="
                let icon of service.RouteIcons;
                let i = index;
                trackBy: trackByIndex
              "
            >
              <div nz-col>
                <img [src]="icon.src" class="max-h-6" />
              </div>
              <div nz-col *ngIf="i + 1 !== service.RouteIcons.length">
                <span> &rarr;</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div nz-col>
      <div nz-row nzJustify="space-between" nzGutter="24">
        <div nz-col>
          <div nz-row nzAlign="middle" nzJustify="center">
            <ng-container *ngIf="rate?.charge?.cashback || rate?.charge?.point">
              <div nz-col>
                <nz-tag nzColor="magenta">优邮Bonus</nz-tag>
                <nz-divider nzType="vertical"></nz-divider>
              </div>
            </ng-container>
            <ng-container *ngIf="rate?.charge?.msrp as msrp">
              <div nz-col>
                柜台价:
                <span nz-typography nzType="danger">
                  <del>
                    {{
                      rate.charge.msrp
                        | currency : rate.charge.currency : "symbol-narrow"
                    }}
                  </del>
                </span>
                <nz-divider nzType="vertical"></nz-divider>
              </div>
            </ng-container>
            <div nz-col>
              <ng-container *ngIf="rate?.charge as charge">
                价格:
                {{
                  charge.due | currency : rate.charge.currency : "symbol-narrow"
                }}

                <button nz-button nzType="link" (click)="detail()">详情</button>
                <nz-divider nzType="vertical"></nz-divider>
              </ng-container>
            </div>
            <ng-container *ngIf="rate?.eta as eta">
              <div nz-col>
                预计送达:
                {{ eta }}
              </div>
            </ng-container>
          </div>
        </div>
        <div nz-col>
          <button
            nz-button
            nzShape="round"
            nzType="dashed"
            [disabled]="!rate"
            (click)="select()"
          >
            寄快递
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="cover" *ngIf="!rate">
    <ng-container *ngIf="error">
      <div class="cover-text cover-text-light">
        <!-- <p>服务不可用</p> -->
        <indicator-alert
          [message]="error?.message"
          [problems]="error?.problems"
          [buttons]="null"
          type="error"
        ></indicator-alert>
      </div>

      <div class="cover-bg cover-bg-color-dark"></div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <div class="cover-text cover-text-dark">
        <p style="text-align: center">
          <img
            src="/assets/img/svg/parcel/018-calculator.svg"
            alt=""
            class="max-h-8 mx-auto"
          /><br />
          <i nz-icon [nzType]="'loading'"></i>
          查询中...
        </p>
      </div>
      <div class="cover-bg cover-bg-light"></div>
    </ng-container>
  </div>
</nz-card>
