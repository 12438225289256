import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { Voucher } from 'src/app/common/models/auth/voucher.model';

@Component({
  selector: 'voucher-preview',
  templateUrl: './voucher-preview.component.html',
  styleUrls: ['./voucher-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoucherPreviewComponent implements OnInit {

  @Input() canReset = false;
  @Input() voucher: Voucher | null = null;
  @Output() event = new EventEmitter<never>();


  constructor(
    public i18n: NzI18nService
  ) { }

  ngOnInit(): void {
  }

}
