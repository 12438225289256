import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { NzModalService } from 'ng-zorro-antd/modal';
import { take } from 'rxjs/operators';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { BtcShippingPackageTracking } from 'src/app/common/models/shipping/btc-shipping-package.tracking.model';
import { BreakpointService } from 'src/app/core/breakpoint';
import { BtcTrackingService } from 'src/app/core/btc/btc-tracking.service';
import { Package } from 'src/app/core/package/models';
import { BtcTrackingResultTimelineDetailComponent } from 'src/app/core/tracking/components/btc-tracking-result-timeline-detail/btc-tracking-result-timeline-detail.component';

@Component({
  selector: 'tracking-steps',
  templateUrl: './tracking-steps.component.html',
  styleUrls: ['./tracking-steps.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingStepsComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  @Input() package: Package;
  tracking: BtcShippingPackageTracking;
  small$ = this.bpService.small$;

  error: IHttpResponseError;

  constructor(
    private bpService: BreakpointService,
    private trackingService: BtcTrackingService,
    private detection: ChangeDetectorRef,
    public i18n: NzI18nService,
    private modalService: NzModalService
  ) { }

  ngOnInit(): void {
    this.track(this.package?.number);
  }

  private track(packageId: string): void {
    this.isLoading = true;
    this.trackingService.tracking(packageId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.tracking = res?.result;
        this.error = res?.error;
        this.isLoading = false;
      }
    });
  }

  trackByIndex = (i: number): number => {
    return i;
  }

  searchDetail(ref: string): void {

    const modal = this.modalService.create({
      nzTitle: `查询物流详情`,
      nzContent: BtcTrackingResultTimelineDetailComponent,
      nzComponentParams: {
        id: ref
      },
      nzFooter: [{
        type: 'primary',
        label: '关闭',
        shape: 'round',
        onClick: () => {
          modal.close();
        }
      }]
    });
  }
}
