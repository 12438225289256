import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Rate } from 'src/app/common/models/rate';

@Component({
  selector: 'service-rate-detail',
  templateUrl: './service-rate-detail.component.html',
  styleUrls: ['./service-rate-detail.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRateDetailComponent implements OnInit {
  @Input() rate: Rate;
  @Input() drawer: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
