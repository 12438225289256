import { Injectable } from '@angular/core';
import { StorageMap, JSONSchema } from '@ngx-pwa/local-storage';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: StorageMap
  ) { }


  public get(key: string): Observable<any> {
    return this.storage.get(key);
  }

  public getList<T>(index: string, schema: JSONSchema): Observable<T> {
    return this.storage.get<T>(index, schema);
  }

  public set(key: string, value: any): Observable<boolean> {
    return this.storage.set(key, value).pipe(
      catchError(() => of(false)),
      map(() => true)
    );
  }

  public delete(key: string): Observable<boolean> {
    return this.storage.delete(key).pipe(
      catchError(() => of(false)),
      map(() => true)
    );
  }

  public clear(): Observable<boolean> {
    return this.storage.clear().pipe(
      catchError(() => of(false)),
      map(() => true)
    );
  }
}
