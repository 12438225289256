import { Signature, SignatureCanpostCode, SignatureFedexCode } from '../../interfaces/service';
import { DeliveryConfirmation } from '../../interfaces/service';
import { ReasonForExport, ReasonForExportCanpostCode } from '../../interfaces/service';
import { NoDeliveryHandle, NoDeliveryHandleCodeCanpost } from '../../interfaces/service/additional-ndh.interface';

export const SIGNATURE_TYPE: Signature = {
    canpost: {
        SO: {
            label: 'Signature',
            code: SignatureCanpostCode.SO
        },
        PA18: {
            label: 'Proof of Age Required - 18',
            code: SignatureCanpostCode.PA18
        },
        PA19: {
            label: 'Proof of Age Required - 19',
            code: SignatureCanpostCode.PA19
        }
    },
    fedex: {
        SERVICE_DEFAULT: {
            label: 'Service default Signature',
            code: SignatureFedexCode.SERVICE_DEFAULT
        },
        NO_SIGNATURE_REQUIRED: {
            label: 'No signature Required',
            code: SignatureFedexCode.NO_SIGNATURE_REQUIRED
        },
        INDIRECT: {
            label: 'Indirect Signature Required',
            code: SignatureFedexCode.INDIRECT,
            note: 'Indirect Signature Required is available for residential shipments only'
        },
        DIRECT: {
            label: 'Direct Signature Required',
            code: SignatureFedexCode.DIRECT
        },
        ADULT: {
            label: 'Adult Signature Required',
            code: SignatureFedexCode.ADULT
        },
    }
};


export const REASON_FOR_EXPORT: ReasonForExport = {
    canpost: {
        DOC: {
            label: 'Document',
            code: ReasonForExportCanpostCode.DOC
        },
        SAM: {
            label: 'Commercial Sample',
            code: ReasonForExportCanpostCode.SAM
        },
        REP: {
            label: 'Repair Or Warranty',
            code: ReasonForExportCanpostCode.REP
        },
        SOG: {
            label: 'Sale Of Goods',
            code: ReasonForExportCanpostCode.SOG
        },
        OTH: {
            label: 'Other reason',
            code: ReasonForExportCanpostCode.OTH
        },
    }
};

export const DELIVERY_CONFIRMATION: DeliveryConfirmation = {
    ups: {
        ca: {
            1: {
                label: 'No Signature',
                code: 1
            },
            2: {
                label: 'Signature Required',
                code: 2
            },
            3: {
                label: 'Adult Signature Required',
                code: 3,
            }
        },
        int: {
            1: {
                label: 'Signature Required',
                code: 1
            },
            2: {
                label: 'Adult Signature Required',
                code: 2
            },
        },
        us: {
            1: {
                label: 'Signature Required',
                code: 1
            },
            2: {
                label: 'Adult Signature Required',
                code: 2
            },
        },
        cn: {
            1: {
                label: 'Signature Required',
                code: 1
            },
            2: {
                label: 'Adult Signature Required',
                code: 2
            },
        }
    }
};


export const NO_DELIVERY_HANDLE: NoDeliveryHandle = {
    canpost: {
        usa_ep: {
            RASE: {
                label: '退回, 物流费用由寄件人承担 (RASE)',
                code: NoDeliveryHandleCodeCanpost.RASE,
            },
            ABAN: {
                label: '放弃包裹 Abandon (ABAN)',
                code: NoDeliveryHandleCodeCanpost.ABAN,
            }
        },
        usa_xp: {
            RASE: {
                label: '退回, 物流费用由寄件人承担 (RASE)',
                code: NoDeliveryHandleCodeCanpost.RASE,
            },
            ABAN: {
                label: '放弃包裹 Abandon (ABAN)',
                code: NoDeliveryHandleCodeCanpost.ABAN,
            }
        },
        usa_tp: {
            RTS: {
                label: '退回',
                code: NoDeliveryHandleCodeCanpost.RTS,
            },
            ABAN: {
                label: '放弃包裹 Abandon (ABAN)',
                code: NoDeliveryHandleCodeCanpost.ABAN,
            }
        },
        int_xp: {
            RASE: {
                label: '退回, 物流费用由寄件人承担 (RASE)',
                code: NoDeliveryHandleCodeCanpost.RASE,
            },
            ABAN: {
                label: '放弃包裹 Abandon (ABAN)',
                code: NoDeliveryHandleCodeCanpost.ABAN,
            }
        },
        int_tp: {
            RTS: {
                label: '退回',
                code: NoDeliveryHandleCodeCanpost.RTS,
            },
            ABAN: {
                label: '放弃包裹 Abandon (ABAN)',
                code: NoDeliveryHandleCodeCanpost.ABAN,
            }
        }
    }
};
