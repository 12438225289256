import { carriers } from '../../constants/carrier';
import { SHIPPING_TYPE } from '../../constants/shipping';
import { CarrierProp, CarrierPropItem } from '../../interfaces/carrier';
import { ITag } from '../../interfaces/common';
import { SizeProp } from '../../interfaces/pak';
import { CarrierCode, IBtcShippingAdditionalItem } from '../../interfaces/service';
import { SizeUnit } from '../../interfaces/setting';
import { IBtcShippingWaybill, IShippingType, IShippingWeight } from '../../interfaces/shipping';
import { BtcShippingAdditional } from './btc-shipping.additional.model';
import { BtcShippingAddress } from './btc-shipping.address.model';

export class BtcShippingWaybill {
    private _data: IBtcShippingWaybill;
    private _avatar: string;
    constructor(data: IBtcShippingWaybill) {
        this._data = data;
        this.avatar = data.waybillCarrierCode;
    }

    get id(): string {
        return this._data?.id ?? null;
    }

    get reference(): string {
        return this._data?.waybillReference ?? '未知';
    }

    get createdAt(): Date {
        return this._data?.createdAt ?? null;
    }

    get updatedAt(): Date {
        return this._data?.updatedAt ?? null;
    }

    get data(): IBtcShippingWaybill {
        return this._data;
    }

    get status(): ITag[] {

        const items = [];
        const pending = this.status_parse('submitted', 0, ['default', '待提交']);
        const submitted = this.status_parse('submitted', 1, ['default', '已确认']);
        const submittedPending = this.status_parse('submitted', 2, ['default', '提交中']);
        const submittedFailed = this.status_parse('submitted', 4, ['red', '提交失败']);
        const voided = this.status_parse('voided', 1, ['default', '已取消']);
        const voidedFailed = this.status_parse('voided', 4, ['red', '取消失败']);
        const delivered = this.status_parse('delivered', 1, ['green', '送达']);
        const rts = this.status_parse('rts', 1, ['red', '退回']);
        const abnormal = this.status_parse('abnormal', 1, ['magenta', '物流异常']);
        const inTransit = this.status_parse('inTransit', 1, ['cyan', '在途']);

        if (pending) {
            items.push(pending);
        }

        if (submittedPending) {
            items.push(submittedPending);
        }

        if (submitted) {
            items.push(submitted);
        }

        if (submittedFailed) {
            items.push(submittedFailed);
        }

        if (inTransit) {
            items.push(inTransit);
        }

        if (rts) {
            items.push(rts);
        }

        if (delivered) {
            items.push(delivered);
        }

        if (voided) {
            items.push(voided);
        }

        if (voidedFailed) {
            items.push(voidedFailed);
        }

        if (abnormal) {
            items.push(abnormal);
        }

        return items;

    }
    get pending(): boolean {
        return this._data?.submitted === 0 ? true : false;
    }
    get submitted(): boolean {
        return this._data?.submitted === 1 ? true : false;
    }

    get submittedPending(): boolean {
        return this._data?.submitted === 2 ? true : false;
    }

    get submittedFailed(): boolean {
        return this._data?.submitted === 4 ? true : false;
    }

    get inTransit(): boolean {
        return this._data?.inTransit === 1 ? true : false;
    }

    get voided(): boolean {
        return this._data?.voided === 1 ? true : false;
    }

    get voidedPending(): boolean {
        return this._data?.voided === 2 ? true : false;
    }

    get voidedFailed(): boolean {
        return this._data?.voided === 4 ? true : false;
    }

    get subscribed(): boolean {
        return this._data?.subscribed === 1 ? true : false;
    }

    get synced(): boolean {
        return this._data?.synced === 1 ? true : false;
    }

    get delivered(): boolean {
        return this._data?.delivered === 1 ? true : false;
    }

    get rts(): boolean {
        return this._data?.rts === 1 ? true : false;
    }

    get abnormal(): boolean {
        return this._data?.abnormal === 1 ? true : false;
    }

    get end(): boolean {
        return this._data?.end ? true : false;
    }

    get transit(): number {
        return this._data?.transit;
    }

    get livemode(): boolean {
        return this._data?.livemode ? true : false;
    }

    get type(): IShippingType {
        let type = null;
        if (this._data.parcel) {
            type = SHIPPING_TYPE.parcel;
        }
        if (this._data.env) {
            type = SHIPPING_TYPE.env;
        }

        return type;
    }

    get transitSerivceName(): string {
        return this._data?.carrierService?.name;
    }

    get size(): Record<SizeUnit, Record<SizeProp, number>> {
        return this._data?.size;
    }

    get sizeOriginal(): Record<SizeUnit, Record<SizeProp, number>> {
        return this._data?.sizeOriginal;
    }

    get weight(): IShippingWeight {
        return { ...this._data?.weight, };
    }

    get weightOriginal(): IShippingWeight {
        return this._data?.weightOriginal;
    }


    get additional(): BtcShippingAdditional {
        return this._data.additional ? new BtcShippingAdditional(this._data.additional) : null;
    }


    get customReference(): string {
        return this._data?.customReference;
    }

    get customMemo(): string {
        return this._data?.customMemo;
    }

    get insurance(): IBtcShippingAdditionalItem {
        return this._data?.insurance;
    }

    get rfe(): IBtcShippingAdditionalItem {
        return this._data?.rfe;
    }

    get version(): number {
        return this._data.version;
    }

    get carrierName(): string {
        return this.carrier?.name;
    }

    get carrier(): CarrierPropItem<CarrierCode> {
        const carrier: CarrierPropItem<CarrierCode> = carriers[this._data?.carrierService?.carrierCode];
        return carrier;
    }

    get avatar(): string {
        return this._avatar;
    }

    set avatar(carrierCode: string) {
        this._avatar = `/assets/img/carrier/${carrierCode}.png`;
    }

    get waybillNumber(): string {
        return this._data.waybillNumber;
    }

    get shippingNumber(): string {
        return this._data.shippingNumber;
    }

    get from(): BtcShippingAddress {
        return this._data.from ? new BtcShippingAddress(this._data.from) : null;
    }

    get to(): BtcShippingAddress {
        return this._data.to ? new BtcShippingAddress(this._data.to) : null;
    }

    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {

        if (prop in this._data) {

            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }



}
