import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DELIVERY_CONFIRMATION } from 'src/app/common/constants/service/service-option';
import { CarrierCode } from 'src/app/common/interfaces/carrier';
import { RegionCode } from 'src/app/common/interfaces/region';
import { DcItem } from 'src/app/common/interfaces/service';
import { BtcShippingAdditional } from 'src/app/common/models/shipping';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'service-additional-form-dc',
  templateUrl: './service-additional-form-dc.component.html',
  styleUrls: ['./service-additional-form-dc.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAdditionalFormDcComponent implements OnInit {

  @Input() current: BtcShippingAdditional;

  @Input() option: DcItem<any>;

  private _validateForm: UntypedFormGroup;

  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
    this.type_set(this.option);
  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  types = [];

  constructor(
    public fv: FormValidationService,
    private fb: UntypedFormBuilder,
  ) {

  }


  ngOnInit(): void {
  }

  on_state_change(state: boolean) {
    if (state) {
      const { carrier, code, blocked } = this.option;
      if (CarrierCode[carrier] && CarrierCode[carrier] !== 'canpost') {
        const value = this.current?.dc?.code ? this.current.dc.code : code?.value;
        this._validateForm.addControl('code', this.fb.control(null, [Validators.required]));
        this._validateForm.patchValue({
          code: value
        });
      }

      if (blocked) {
        this._validateForm.get('code').disable();
      }

    } else {
      this._validateForm.removeControl('code');
    }
  }

  type_set(value: DcItem<any>): void {
    const { state, code, carrier, blocked, shipTo } = value;
    this._validateForm.addControl('state', this.fb.control(state?.value));

    if (state?.blocked) {
      this._validateForm.get('state').disable();
    }

    const regionCode = RegionCode[shipTo];
    if (regionCode && DELIVERY_CONFIRMATION[carrier][regionCode]) {
      const items = DELIVERY_CONFIRMATION[carrier][regionCode];
      for (const i in items) {
        if (Object.prototype.hasOwnProperty.call(items, i)) {
          const item = items[i];
          if (code?.options?.length && !code.options.some((key) => key === i)) {
            continue;
          }
          this.types.push(item);
        }
      }
    }

    if (blocked) {
      this._validateForm.disable();
    }


    if (state?.value && code?.value) {
      this.on_state_change(state.value);
    }

    if (typeof this.current?.dc?.state === 'boolean') {
      this.validateForm.patchValue(this.current.dc);
      this.on_state_change(this.current.dc.state);
    }
  }
}
