<form nz-form [formGroup]="validateForm" nzLayout="inline">
  <ng-container *ngIf="!option?.hidden">
    <ng-container *ngIf="!option?.code?.hidden">
      <nz-form-item nzFor="code" *ngIf="validateForm | hasControl: 'code'">
        <nz-form-label
          nzFor="code"
          [nzRequired]="validateForm | isRequired: 'code'"
        >
          申报类型
        </nz-form-label>
        <nz-form-control [nzErrorTip]="validateForm | formError: 'code'">
          <nz-radio-group
            formControlName="code"
            (ngModelChange)="on_code_change($event)"
          >
            <label nz-radio [nzValue]="item.code" *ngFor="let item of codes">
              {{ item.label }}
              <nz-form-item
                *ngIf="item.code === 'OTH' && validateForm.value.code === 'OTH'"
              >
                <nz-form-control
                  [nzErrorTip]="validateForm | formError: 'note'"
                >
                  <input
                    type="text"
                    formControlName="note"
                    nz-input
                    placeholder="请填写申报原因 (英文)"
                    style="min-width: 250px"
                  />
                </nz-form-control>
              </nz-form-item>
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </ng-container>

    <ng-container *ngIf="!option?.amount?.hidden">
      <ng-container *ngIf="option?.cappedValue">
        <nz-form-item>
          <nz-form-label
            nzFor="cappedValue"
            [nzRequired]="validateForm | isRequired: 'cappedValue'"
          >
            邮寄品价值
          </nz-form-label>
          <nz-form-control
            [nzErrorTip]="validateForm | formError: 'cappedValue'"
          >
            <nz-radio-group
              formControlName="cappedValue"
              (ngModelChange)="on_capped_change($event)"
            >
              <label
                nz-radio
                [nzValue]="item.value"
                *ngFor="let item of cappedValues"
              >
                {{ item.label }}
                <ng-container *ngIf="validateForm.value.cappedValue !== null">
                  <ng-container
                    *ngIf="
                      !item.value && !validateForm.value.cappedValue;
                      then amountFormTpl
                    "
                  >
                  </ng-container>
                </ng-container>
              </label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <ng-container *ngIf="!option?.cappedValue; then amountFormTpl">
      </ng-container>

      <ng-template #amountFormTpl>
        <nz-form-item
          style="margin-top: 10px !important"
          nzTooltipTitle="申报价值"
          *ngIf="validateForm | hasControl: 'amount'"
        >
          <nz-form-label
            nzFor="amount"
            [nzRequired]="validateForm | isRequired: 'amount'"
          >
            申报价值
          </nz-form-label>
          <nz-form-control [nzErrorTip]="validateForm | formError: 'amount'">
            <nz-input-group nzAddOnAfter="$">
              <nz-input-number
                formControlName="amount"
                [nzMin]="1"
                [nzPrecision]="2"
                [nzStep]="10"
                [nzPlaceHolder]="'0'"
                style="width: 100%"
                (nzBlur)="on_amount_change()"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </ng-template>
    </ng-container>

    <nz-divider nzPlain></nz-divider>
  </ng-container>
</form>
