import { CarrierCode, CarrierProp } from "../../interfaces/carrier";

export const carriers: Partial<CarrierProp> = {
  canpost: {
    name: "Canada Post",
    code: CarrierCode.canpost,
  },
  ups: {
    name: "UPS Canada",
    code: CarrierCode.ups,
  },
  dhl: {
    name: "DHL Canada",
    code: CarrierCode.dhl,
  },
  dhl_be: {
    name: "DHL International",
    code: CarrierCode.dhl_be,
  },
  postnl_be: {
    name: "Post NL",
    code: CarrierCode.postnl_be,
  },
  purolator: {
    name: "Purolator",
    code: CarrierCode.purolator,
  },
  fedex: {
    name: "Fedex Canada",
    code: CarrierCode.fedex,
  },
  shunfeng: {
    name: "顺丰",
    code: CarrierCode.shunfeng,
  },
  zhongtong: {
    name: "中通",
    code: CarrierCode.zhongtong,
  },
  youzhengguonei: {
    name: "邮政快递包裹",
    code: CarrierCode.youzhengguonei,
  },
  igcaexpress: {
    name: "优邮转运",
    code: CarrierCode.igcaexpress,
  },
};
