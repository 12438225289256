<ng-container *ngIf="!order">
  <nz-empty [nzNotFoundContent]="'没有找到订单信息'"> </nz-empty>
</ng-container>

<ng-container *ngIf="order">
  <nz-descriptions>
    <nz-descriptions-item nzTitle="Order Nr. 订单号" [nzSpan]="3">
      {{ order.orderNumber }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="创建于" [nzSpan]="3">
      {{ order.createdAt | amLocale: i18n.getLocaleId() | amDateFormat: "ll" }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="最近更新" [nzSpan]="3">
      {{ order.updatedAt | amLocale: i18n.getLocaleId() | amDateFormat: "ll" }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="状态" [nzSpan]="3">
      <ng-container *ngFor="let tag of order.status">
        <nz-tag [nzColor]="tag.color">{{ tag.text }}</nz-tag>
      </ng-container>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="运费" [nzSpan]="3">
      <nz-descriptions nzSize="small">
        <nz-descriptions-item nzTitle="优邮价格" [nzSpan]="3">
          {{
            order.base + order.basePlus
              | currency: order.currency:"symbol-narrow"
          }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="税" [nzSpan]="3">
          {{ order.tax | currency: order.currency:"symbol-narrow" }}
        </nz-descriptions-item>
        <ng-container *ngIf="order.discount">
          <nz-descriptions-item nzTitle="折扣" [nzSpan]="3">
            {{ order.discount | currency: order.currency:"symbol-narrow" }}
          </nz-descriptions-item>
        </ng-container>
        <nz-descriptions-item nzTitle="总计" [nzSpan]="3">
          {{ order.due | currency: order.currency:"symbol-narrow" }}
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-descriptions-item>
  </nz-descriptions>

  <ng-container *ngIf="order.cashback || order.point">
    <nz-descriptions nzTitle="优邮 Bonus">
      <nz-descriptions-item
        nzTitle="积分"
        [nzSpan]="3"
        *ngIf="order.point as point"
      >
        {{ point.count }} 分
      </nz-descriptions-item>

      <nz-descriptions-item
        *ngIf="order.cashback as cashback"
        [nzSpan]="3"
        [nzTitle]="cashback?.event | cashbackName | nzSafeNull: '未知'"
      >
        {{ cashback.count | currency: cashback.currency:"symbol-narrow" }}
      </nz-descriptions-item>
    </nz-descriptions>
  </ng-container>

  <nz-descriptions *ngIf="order.payment as payment" nzTitle="支付信息">
    <nz-descriptions-item nzTitle="方式" [nzSpan]="3">
      {{ payment.methodCode | payment }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="状态" [nzSpan]="3">
      <ng-container *ngFor="let tag of payment.status">
        <nz-tag [nzColor]="tag.color">{{ tag.text }}</nz-tag>
      </ng-container>
    </nz-descriptions-item>

    <ng-container *ngIf="payment.refunded || payment.refundedPartial">
      <nz-descriptions-item nzTitle="退款金额" [nzSpan]="3">
        {{
          payment?.refundedAmount | currency: payment?.currency:"symbol-narrow"
        }}
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle="退款原因" [nzSpan]="3">
        {{ payment?.refundedReason }}
      </nz-descriptions-item>
    </ng-container>
  </nz-descriptions>
</ng-container>

<ng-container *ngIf="drawer; then footerTpl"></ng-container>

<ng-template #footerTpl>
  <div class="fixed-bottom right-0">
    <div nz-row nzJustify="end">
      <div nz-col>
        <button
          nz-button
          nzType="primary"
          [nzGhost]="fullscreen"
          [nzDanger]="!fullscreen"
          nzSize="small"
          nzShape="circle"
          (click)="fullscreen_switch()"
        >
          <i
            nz-icon
            [nzType]="fullscreen ? 'fullscreen' : 'fullscreen-exit'"
          ></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
