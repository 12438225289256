<address-search-form
  [validateForm]="validateForm"
  (valueChange)="on_submit()"
></address-search-form>
<nz-table
  #basicTable
  [nzTotal]="count"
  [nzPageSize]="queryParams.limit"
  [(nzPageIndex)]="queryParams.page"
  [nzData]="recordList"
  [nzFrontPagination]="false"
  nzShowSizeChanger
  [nzLoading]="isLoading"
  (nzPageIndexChange)="page_index_change($event)"
  (nzPageSizeChange)="page_size_change($event)"
  (nzQueryParams)="on_params_change($event)"
  [nzScroll]="{ x: '1000px', y: '100%' }"
>
  <thead>
    <tr>
      <th nzWidth="200px" nzColumnKey="contactPerson" [nzSortFn]="true">
        联系人
      </th>
      <th nzWidth="300px" nzColumnKey="addressLine1" [nzSortFn]="true">地址</th>
      <th nzWidth="150px" nzColumnKey="postalCode" [nzSortFn]="true">邮编</th>
      <th nzWidth="150px" nzColumnKey="state" [nzSortFn]="true">省/州</th>
      <th nzWidth="200px" nzColumnKey="contactPhone" [nzSortFn]="true">电话</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of basicTable.data; let i = index; trackBy: trackByIndex"
      (click)="select(i)"
    >
      <td>{{ data.contactPerson }}</td>
      <td>{{ data.addressLine1 }}</td>
      <td>{{ data.postalCode }}</td>
      <td>{{ data.stateName }}</td>
      <td>{{ data.contactPhone }}</td>
    </tr>
  </tbody>
</nz-table>
