import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrierSelectorComponent } from './components/carrier-selector/carrier-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSharedModule } from '../utils/form';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';



@NgModule({
  declarations: [
    CarrierSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzFormModule,
    NzSelectModule,
  ],
  exports: [
    CarrierSelectorComponent
  ]
})
export class CarrierSharedModule { }
