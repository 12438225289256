import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PickupEvent } from 'src/app/common/interfaces/pickup';
import { NavigationService } from 'src/app/core/navigation';

@Component({
  templateUrl: './pickup-page-list.component.html',
  styleUrls: ['./pickup-page-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupPageListComponent implements OnInit {

  constructor(
    private nav: NavigationService
  ) { }

  ngOnInit(): void {
  }

  on_event_change(event: PickupEvent): void {
    switch (event) {
      case PickupEvent.create:
        this.create();
        break;

      default:
        break;
    }
  }

  private create(): void {
    this.nav.to('/app/pickups/create');
  }

}
