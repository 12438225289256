import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { PakType } from 'src/app/common/interfaces/pak';
import { IRegion, IRegionServiceControl } from 'src/app/common/interfaces/region';
import { IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { BtcService } from 'src/app/common/models/service';
import { BtcServiceService } from 'src/app/core';
import { ServiceRateCompareDrawerComponent } from 'src/app/shared/service/rate/components';

@Component({
  selector: 'group-form-single',
  templateUrl: './group-form-single.component.html',
  styleUrls: ['./group-form-single.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupFormSingleComponent implements OnInit {

  serviceList: BtcService[] = [];
  index: IBtcServiceIndex;

  @Input() page = 1;

  @Input() isLoading = false;
  @Input() regionServiceControl: IRegionServiceControl;
  @Input() indexes: IBtcServiceIndex[] = [];
  @Input() initiationRegion: IRegion;
  @Input() destinationRegion: IRegion;
  @Input() pakType: PakType;
  @Output() serviceChange = new EventEmitter<any>();

  constructor(
    private btcService: BtcServiceService,
    private detection: ChangeDetectorRef,
    private drawerService: NzDrawerService,
  ) { }

  ngOnInit(): void {
  }

  on_index_change(index: IBtcServiceIndex): void {

    this.index = index;
    if (index) {
      const flattenContent = this.index.multiDestination ? 0 : 1;
      this.service_search({ code: index.code, flattenContent, sort: [{ position: 'asc' }] });
    }
  }

  private service_search(body: any): void {
    this.isLoading = true;
    this.btcService.search(body).pipe(
      take(1)
    ).subscribe({
      next: (services: BtcService[]) => {
        this.isLoading = false;
        this.serviceList = services;

        this.detection.detectChanges();
      }
    });
  }

  price_compare(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: '对比价格',
      nzContent: ServiceRateCompareDrawerComponent,
      nzContentParams: {
        regionServiceControl: this.regionServiceControl,
        initiationRegion: this.initiationRegion,
        destinationRegion: this.destinationRegion,
        multiDestination: 0,
        pakType: this.pakType,
        index: this.index,
        services: this.serviceList
      },
      nzKeyboard: false,
      nzHeight: '100%',
      nzPlacement: 'bottom',
      // nzClosable: false,
      nzMaskClosable: false,
    });

    drawerRef.afterClose.pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        if (res) {
          this.serviceChange.emit(res);
        }
      }
    });
  }

}
