export interface IUnit {
    size?: IUnitSize;
    weight?: IUnitWeight;
}


export interface IUnitSize {
    name: string;
    code: string;
    isCentimeter: boolean;
}
export interface IUnitWeight {
    name: string;
    code: WeightUnit;
    isKilo: boolean;
}

export enum WeightUnitCode {
    kg = 'kg',
    lb = 'lb'
}

export enum WeightUnitName {
    kg = '公斤',
    lb = '磅'
}

export type WeightUnit = keyof typeof WeightUnitCode;

export type WeightUnitProp = {
    [code in WeightUnit]: WeightUnitPropItem<code>
};

export type WeightUnitPropItem<T> = {
    name: WeightUnitName;
    code: T;
    isKilo: boolean;
};
export enum SizeUnitName {
    cm = '厘米',
    in = '英寸'
}
export enum SizeUnitCode {
    cm = 'cm',
    in = 'in'
}

export type SizeUnit = keyof typeof SizeUnitCode;
export type SizeUnitProp = {
    [code in SizeUnit]: SizeUnitPropItem<code>
};

export type SizeUnitPropItem<T> = {
    name: SizeUnitName;
    code: T;
    isCentimeter: boolean;
};
