import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InteractiveFind, InteractiveRetrieve } from 'src/app/common/models/pca';
import { App_Config } from 'src/app/app.config';
import { IRegion, ISOCode2 } from 'src/app/common/interfaces/region';
import { IPcaOption } from 'src/app/common/interfaces/pca';


@Injectable({
  providedIn: 'root'
})
export class PcaService {
  InteractiveFind = new InteractiveFind();
  InteractiveRetrieve = new InteractiveRetrieve();
  InteractiveParams;
  private apiKey = null;
  constructor(
    private http: HttpClient
  ) {
    const { pca } = App_Config;
    this.apiKey = pca;
  }

  pcaFind(data): Observable<any> {

    this.InteractiveParams = this.InteractiveFind.Init(data);
    return this.http.get(this.urlHandler(
      this.InteractiveParams.protocol + this.InteractiveParams.url,
      this.InteractiveParams.method,
      this.apiKey,
      this.InteractiveParams.params
    ));
  }

  retrieve(data): Observable<any> {
    // console.log(data);
    this.InteractiveParams = this.InteractiveRetrieve.Init(data);
    return this.http.get(this.urlHandler(
      this.InteractiveParams.protocol + this.InteractiveParams.url,
      this.InteractiveParams.method,
      this.apiKey,
      this.InteractiveParams.params
    ));
  }

  optionParse(item, region: IRegion = null) {

    if (!region) {
      alert(`Parse Oprion Error: Region should be defined`);
      return null;
    }

    const { isoCode2, isoCode3 } = region;
    const pathData: any = {};
    if (isoCode3 === 'CAN') {
      pathData.internalId = item.Id;
      pathData.addressLine1 = item.Text;

      const addressArray = item.Description.split(', ');

      pathData.stateCode = addressArray[1];
      pathData.postalCode = addressArray[addressArray.length - 1].replace(/\s/g, '');
      pathData.city = addressArray[0];

    } else if (isoCode2 === 'AU') {
      pathData.internalId = item.Id;
      pathData.addressLine1 = item.Text.split(', ')[0];

      const addressArray = item.Text.split(', ')[1].split(' ');

      pathData.stateCode = addressArray[addressArray.length - 2];
      pathData.postalCode = addressArray[addressArray.length - 1].replace(/\s/g, '');
      pathData.city = addressArray[0];

    } else if (isoCode3 === 'GBR') {
      pathData.internalId = item.Id;
      pathData.addressLine1 = item.Text;

      const addressArray = item.Description.split(', ');

      pathData.stateCode = addressArray[addressArray.length - 2];
      pathData.postalCode = addressArray[addressArray.length - 1].replace(/\s/g, '');
      pathData.city = addressArray[0];

    } else if (isoCode2 === 'US') {
      pathData.internalId = item.Id;
      pathData.addressLine1 = item.Text;
      const addressArray = item.Description.split(' ');
      pathData.stateCode = addressArray[addressArray.length - 2];
      pathData.postalCode = addressArray[addressArray.length - 1];
      pathData.city = item.Description.replace(pathData.stateCode, '').replace(pathData.postalCode, '');
    } else if (isoCode3 === 'SGP') {
      pathData.internalId = item.Id;
      pathData.addressLine1 = item.Text;
      const addressArray = item.Description.split(' ');
      pathData.stateCode = addressArray[addressArray.length - 2];
      pathData.postalCode = addressArray[addressArray.length - 1];
      pathData.city = item.Description.split(',')[0];
    } else {
      pathData.internalId = item.Id;
      pathData.addressLine1 = item.Text;
      const addressArray = item.Description.split(' ');
      pathData.postalCode = addressArray[addressArray.length - 2];
      pathData.city = addressArray[addressArray.length - 1];
    }

    pathData.label = this.parseLabel(pathData);

    return pathData;

  }

  find(event = null, regionCode: ISOCode2 = null, LastId = ''): Observable<IPcaOption[]> {
    return new Observable((obs) => {
      if (regionCode && (event || LastId)) {

        const queryCriteria: any = {};
        queryCriteria.SearchTerm = event;
        queryCriteria.LastId = LastId;
        queryCriteria.country = regionCode;

        this.pcaFind(queryCriteria)
          .subscribe(res => {

            if (res.Items.length === 0) {
              obs.next([]);
            }

            if (res.Items.length > 0) {
              if (typeof res.Items[0].Error !== 'undefined') {
                // Show the error message
                alert(res.Items[0].Description);
                obs.next([]);
              } else {
                obs.next(res.Items);
              }
            }
          });

      } else {
        // Show the error message
        // alert(`Region Undefined`);
        obs.next([]);
      }
    });


  }

  private parseLabel(values) {
    let label = '';
    const { provState, city, addressLine1, addressLine2, location, postalCode, stateCode } = values;

    if (location) {
      label += ' ' + location + ', ';
    }

    if (addressLine1) {
      label += ' ' + addressLine1 + ', ';
    }
    if (addressLine2) {
      label += ' ' + addressLine2 + ', ';
    }

    if (postalCode) {
      label += ' ' + postalCode;
    }

    if (city) {
      label += ' ' + city;
    }

    if (provState) {
      label += provState;
    }

    if (stateCode) {
      label += ' ' + stateCode;
    }

    return label;
  }

  private urlHandler(url, method, key, params) {
    const requestParams = this.requestParams(params);
    let finalUrl = '';
    finalUrl = url + method + '?Key=' + key + requestParams;
    return finalUrl;
  }

  private requestParams(params) {
    if (params) {
      const newArray = [];
      for (const element of params) {
        newArray.push('&' + element.name + '=' + element.value);
      }
      return newArray.join('');

    } else {
      return '';
    }

  }
}
