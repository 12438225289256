import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { defaultBalances } from 'src/app/common/constants/account';
import { TAccountBalances } from 'src/app/common/interfaces/balance';

import { BalanceAccount } from 'src/app/common/models/balance';
import { HttpHelperService } from 'src/app/helpers/http';
import { environment } from 'src/environments/environment';


import { IHttpResponse } from '../http/interfaces';

@Injectable({
  providedIn: 'root'
})
export class BalanceCoreService {
  #coreEndPoint = environment.coreEndPoint;
  private _count$ = new BehaviorSubject<number>(0);
  count$ = this._count$.asObservable();

  #balances$ = new BehaviorSubject<TAccountBalances>(defaultBalances);
  balances$ = this.#balances$.asObservable();
  #balancesLoading$ = new BehaviorSubject<boolean>(false);
  balancesLoading$ = this.#balancesLoading$.asObservable();
  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  constructor(
    private http: HttpHelperService
  ) {

  }

  set balances(value: TAccountBalances) {
    this.#balances$.next(value);
  }

  set balanceLoading(value: boolean) {
    this.#balancesLoading$.next(value);
  }

  set loading(value: boolean) {
    this.#loading$.next(value);
  }

  syncFromServer<TAccountBalance>(): Observable<any> {
    this.balanceLoading = true;
    const path = `${this.#coreEndPoint}/member/balance`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.balanceLoading = false),
      tap((res: IHttpResponse<TAccountBalance>) => {
        const { balance } = res;
        this.balances = balance ?? defaultBalances;
      }),
    );
  }


  count_sync(): Observable<IHttpResponse> {
    this.balanceLoading = true;
    const path = `${this.#coreEndPoint}/member/balance/count`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.balanceLoading = false),
      tap((res: IHttpResponse) => {
        const { count } = res;
        this.count = count;
      }),
    );
  }

  set count(value: number) {
    this._count$.next(value);
  }

  record_search(body?: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndPoint}/member/balance/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = res.result.map(item => new BalanceAccount(item));
        }
        return res;
      })
    );
  }

  search<T>(body?: any): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#coreEndPoint}/member/balance/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false),
    );
  }


}
