<ng-template #buttonsTpl>
  <ng-container *ngIf="type">
    <a
      nz-button
      [routerLink]="['/app/address/recipient/create']"
      nzType="primary"
    >
      <i nz-icon nzTheme="outline" nzType="plus-circle"></i>
      创建收件地址
    </a>
  </ng-container>
  <ng-container *ngIf="!type">
    <a nz-button [routerLink]="['/app/address/sender/create']" nzType="primary">
      <i nz-icon nzTheme="outline" nzType="plus-circle"></i>
      创建寄件地址
    </a>
  </ng-container>
</ng-template>
