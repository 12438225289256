import { IBtcShippingAdditional, IBtcShippingAdditionalItem } from '../../interfaces/service';

export class BtcShippingAdditional {
    private _data: IBtcShippingAdditional;

    constructor(data: IBtcShippingAdditional) {
        this._data = data;
    }

    get so(): IBtcShippingAdditionalItem {
        return this._data?.so ? this._data.so : null;
    }

    get dc(): IBtcShippingAdditionalItem {
        return this._data?.dc ? this._data.dc : null;
    }

    get rfe(): IBtcShippingAdditionalItem {
        return this._data?.rfe ? this._data.rfe : null;
    }

    get ndh(): IBtcShippingAdditionalItem {
        return this._data?.ndh ? this._data.ndh : null;
    }

    get insurance(): IBtcShippingAdditionalItem {
        return this._data?.insurance ? this._data.insurance : null;
    }

}
