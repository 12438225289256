import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { SERVICE_TICKET_TYPES } from 'src/app/common/constants/ticket';
import { BtcOrder } from 'src/app/common/models/order';
import { BtcShipping } from 'src/app/common/models/shipping';
import { BtcServiceTicket } from 'src/app/common/models/ticket';
import { BtcTicketService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';
import { IndicatorService } from 'src/app/shared/indicator';

@Component({
  selector: 'ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketFormComponent implements OnInit {
  validateForm = new UntypedFormGroup({
    type: this.fb.control(null, [Validators.required]),
    content: this.fb.control(null, [Validators.required, Validators.maxLength(255)])
  });
  list = [];



  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }


  @Input() shipping: BtcShipping;
  @Input() order: BtcOrder;
  @Output() ticketChange = new EventEmitter<BtcServiceTicket>();
  constructor(
    private fb: UntypedFormBuilder,
    public fv: FormValidationService,
    private ticketService: BtcTicketService,
    private detection: ChangeDetectorRef,
    private indicator: IndicatorService
  ) {

    for (const i in SERVICE_TICKET_TYPES) {
      if (Object.prototype.hasOwnProperty.call(SERVICE_TICKET_TYPES, i)) {
        const item = SERVICE_TICKET_TYPES[i];
        this.list.push(item);
      }
    }

  }

  ngOnInit(): void {

  }

  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      const body = this.validateForm.value;
      body.shippingId = this.shipping?.id;
      body.orderId = this.order?.id;
      this.create(body);
    }

  }

  private create(body: any): void {
    this.isLoading = true;
    this.ticketService.create(body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        if (res?.error) {
          this.indicator.alert({
            nzContentParams: {
              type: 'error',
              message: res?.error?.message,
              problems: res?.error?.problems
            }
          });
        }

        if (res?.result) {
          this.ticketChange.emit(res.result);
        }
        this.isLoading = false;
      }
    });
  }
}
