<ng-container *ngIf="record">
  <nz-card nzSize="small" nzBorderless>
    <span> {{ record?.com | carrierName }} : </span>
    <span nz-typography nzCopyable [nzCopyText]="record.waybillNumber">
      {{ record.waybillNumber }}
    </span>
  </nz-card>
  <nz-card nzSize="small" nzBorderless>
    <kuaidi100-tracking-step
      [steps]="record.steps | slice: 0:sliceCount"
    ></kuaidi100-tracking-step>

    <show-all
      *ngIf="record.steps.length > sliceCount"
      [shape]="'round'"
      [type]="'primary'"
      [ghost]="true"
      [icon]="'zoom-in'"
      [text]="'查看全部'"
      (event)="show_all()"
    ></show-all>
  </nz-card>
</ng-container>
