import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressEvent, AddressEventCode } from 'src/app/common/interfaces/address';
import { BtcShippingAddress } from 'src/app/common/models/shipping';

@Component({
  selector: 'initiation-address',
  templateUrl: './initiation-address.component.html',
  styleUrls: ['./initiation-address.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitiationAddressComponent implements OnInit {

  @Input() address: BtcShippingAddress;
  @Output() eventChange = new EventEmitter<AddressEvent>();

  constructor() { }

  ngOnInit(): void { }

  search(): void {
    this.eventChange.emit(AddressEventCode.search);
  }

  create(): void {
    this.eventChange.emit(AddressEventCode.create);
  }

}

