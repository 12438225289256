import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { BtcServiceContent } from 'src/app/common/models/service';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'service-content-form',
  templateUrl: './service-content-form.component.html',
  styleUrls: ['./service-content-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceContentFormComponent implements OnInit {

  @Input() contentList: BtcServiceContent[] = [];
  @Input() indexes: IBtcServiceIndex[] = [];
  content: any = null;
  private _validateForm: UntypedFormGroup;

  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;

  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  constructor(
    public fv: FormValidationService,
  ) {

  }

  ngOnInit(): void {
    const { serviceContentId } = this.validateForm.value;
    this.on_content_change(serviceContentId);
  }

  on_content_change($event): void {
    this.content = this.contentList.find(item => item.id === $event);
  }

}
