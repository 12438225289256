import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { IBtcServiceIntro } from 'src/app/common/interfaces/service';
import { BTCAgentOffice, BTCAgentOfficeAsset } from 'src/app/common/models/agent';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BtcMarketingService {


  #coreEndpoint: string;
  constructor(
    private http: HttpService
  ) {
    this.#coreEndpoint = environment.coreEndPoint;
  }
  resolve(): Observable<IBtcServiceIntro[]> {
    return this.service_intro_search().pipe(
      take(1),
      map((res: IHttpResponse) => res?.result ?? [])
    );
  }

  service_intro_search(): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/service/introduction/search`;
    return this.http.get(path).pipe(
      catchError(this.http.catch())
    );
  }


  service_intro_detail(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/service/introduction/detail`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch())
    );
  }

  service_article_search(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/service/article/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch())
    );
  }

  service_article_detail(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/service/article/detail`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch())
    );
  }

  service_faq_search(): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/service/faq/search`;
    return this.http.get(path).pipe(
      catchError(this.http.catch())
    );
  }

  service_faq_detail(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/service/faq/detail`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch())
    );
  }

  office_search(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/office/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const result = res?.result ?? [];
        res.result = result.map(row => new BTCAgentOffice(row));
        return res;
      })
    );
  }

  office_asset_search(officeId: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/posts/office/${officeId}/assets/search`;
    return this.http.post(path, body).pipe(
      map((res: IHttpResponse) => {
        const result = res?.result ?? [];
        res.result = result.map(row => new BTCAgentOfficeAsset(row));
        return res;
      })
    );
  }

}
