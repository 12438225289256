import { IBtcAgentOffice, IBtcAgentOfficeAsset, IBtcAgentOfficeContact } from '../../interfaces/agent';

export class BTCAgentOffice implements IBtcAgentOffice {
    assets: IBtcAgentOfficeAsset[] = [];
    createdAt: string;
    updatedAt: string;
    id: string;
    name: string;
    location: string;
    address: string;
    postalCode: string;
    city: string;
    provState: string;
    postalAbbrev: string;
    contact: IBtcAgentOfficeContact[] = [];
    businessHour: string;
    status: number;

    constructor(private data: IBtcAgentOffice) {
        Object.assign(this, this.data);
    }


    get fullAddress() {

        return `${this.address}, ${this.city}, ${this.postalCode}, ${this.provState}`;

    }

    get cover() {
        if (this.assets.length === 0) {
            return null;
        }

        const cover = this.assets.find(item => item.keys && item.keys.includes('cover'));

        return cover ? cover : this.assets[0];
    }

    safeImg(asset: IBtcAgentOfficeAsset) {
        const img = `data:${asset.type};base64,${asset.data}`;
        return img;
    }

    bgStyle(asset: IBtcAgentOfficeAsset) {
        const img = `data:${asset.type};base64,${asset.data}`;
        return {
            'background-image': `url("${img}")`,
            'background-size': 'cover',
            'background-position': 'center'
        };
    }

}
