import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressEvent, AddressEventCode } from 'src/app/common/interfaces/address';
import { BtcShippingAddress } from 'src/app/common/models/shipping';

@Component({
  selector: 'destination-address',
  templateUrl: './destination-address.component.html',
  styleUrls: ['./destination-address.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationAddressComponent implements OnInit {


  @Input() address: BtcShippingAddress;
  @Input() multiDestination = false;

  @Output() eventChange = new EventEmitter<AddressEvent>();

  constructor() { }

  ngOnInit(): void { }

  search(): void {
    this.eventChange.emit(AddressEventCode.search);
  }

  create(): void {
    this.eventChange.emit(AddressEventCode.create);
  }


}
