import { ChangeDetectorRef, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import * as moment from "moment";
import { NzI18nService } from "ng-zorro-antd/i18n";
import { carriers } from "src/app/common/constants/carrier";
import { PATTERNS } from "src/app/common/constants/pattern";
import { Carrier, CarrierCode } from "src/app/common/interfaces/carrier";
import { BTCPickup } from "src/app/common/models/pickup";
import { BtcShippingWaybill } from "src/app/common/models/shipping";
import { FormValidationService } from "src/app/core/form";
import { UnitService } from "src/app/core/unit";

@Component({
  selector: "pickup-form",
  templateUrl: "./pickup-form.component.html",
  styleUrls: ["./pickup-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupFormComponent implements OnInit {
  waybillCarrierCode: Carrier;
  current = 0;
  allowed = [
    carriers.canpost.code,
    carriers.ups.code,
    carriers.dhl.code,
    carriers.dhl_be.code,
    carriers.purolator.code,
    carriers.postnl_be.code,
  ];
  waybills: BtcShippingWaybill[] = [];
  @Input() pickup: BTCPickup;
  @Output() valueChange = new EventEmitter<any>();
  @Input() carrierCode: CarrierCode | null = null;
  validateForm = new UntypedFormGroup({
    waybillCarrierCode: this.fb.control(null, [Validators.required]),
    instructions: this.fb.control("front door", [
      Validators.required,
      Validators.maxLength(40),
      Validators.pattern(PATTERNS.alphabet_number.pattern),
    ]),
    date: this.fb.group({
      pickupDate: this.fb.control(null, [Validators.required]),
      readyTime: this.fb.control(null, [Validators.required]),
      closeTime: this.fb.control(null, [Validators.required]),
    }),
    time: this.fb.control(null, [Validators.required]),
  });

  unit$ = this.unitService.unit$;
  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private unitService: UnitService,
    public i18n: NzI18nService,
    private detection: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.build(this.pickup);
  }

  private build(pickup: BTCPickup): void {
    if (pickup) {
      this.validateForm.addControl("version", this.fb.control(null));
      const { instructions, date, carrier, version, waybills } = pickup;
      this.waybills = waybills;
      const pickupDate = moment(date.pickupDate, "YYYY-MM-DD").toDate();

      this.validateForm.patchValue({
        instructions,
        waybillCarrierCode: carrier.code,
        date: {
          pickupDate,
        },
        version,
      });
      this.carrierCode = carrier.code;
      this.detection.detectChanges();
    } else {
      this.validateForm.patchValue({
        waybillCarrierCode: this.carrierCode,
      });
    }
  }

  on_submit(): void {
    if (this.validateForm.valid && this.waybills.length) {
      const { date } = this.validateForm.value;
      console.log(date);

      const body = {
        ...this.validateForm.value,
        ...{
          date: {
            pickupDate: moment(date.pickupDate).format("YYYY-MM-DD"),
            readyTime: date.readyTime,
            closeTime: date.closeTime,
          },
        },
        ...{
          waybills: this.waybills.map(({ id }) => id),
        },
      };

      this.valueChange.emit(body);
    }
  }

  on_carrier_change($event) {
    this.carrierCode = $event;
  }

  to_waybill_list(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm, true);
    }

    if (this.validateForm.valid) {
      this.current = 1;
    }
  }

  to_summary(): void {
    this.current = 2;
  }
}
