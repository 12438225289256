
import * as _ from 'lodash';
import { sizeUnitOptions, weightUnitOptions } from '../../constants/setting/unit';
import { IUnitSize, IUnitWeight, IUnit } from '../../interfaces/setting';
export class Unit {

    private _size: IUnitSize;
    private _weight: IUnitWeight;

    constructor(isKilo = true, isCentimeter = true) {

        this.weight_update(isKilo);
        this.size_update(isCentimeter);
    }

    public get size(): IUnitSize {
        return this._size;
    }

    public get weight(): IUnitWeight {
        return this._weight;
    }

    private size_update(isCentimeter: boolean) {
        this._size = _.find(sizeUnitOptions, option => option.isCentimeter === isCentimeter);
    }

    private weight_update(isKilo: boolean) {
        this._weight = _.find(weightUnitOptions, option => option.isKilo === isKilo);
    }

    public get unit(): IUnit {
        return {
            size: this.size,
            weight: this.weight
        };
    }

}
