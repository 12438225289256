<ng-container *ngIf="address; then addressTpl; else EmptyTpl"></ng-container>

<ng-template #addressTpl>
  <nz-descriptions nzSize="small">
    <nz-descriptions-item [nzTitle]="contactPersonTpl" nzSpan="3">
      <ng-template #contactPersonTpl>
        <strong>
          {{ address.contactPerson }}
        </strong>
      </ng-template>

      {{ address.addressWithoutPerson }}
    </nz-descriptions-item>

    <ng-container *ngIf="address.contactPersonLocale">
      <nz-descriptions-item [nzTitle]="contactPersonLocaleTpl" nzSpan="3">
        <ng-template #contactPersonLocaleTpl>
          <strong>
            {{ address.contactPersonLocale }}
          </strong>
        </ng-template>

        {{ address.addressLocaleWithoutPerson }}
      </nz-descriptions-item>
    </ng-container>

    <nz-descriptions-item [nzTitle]="contactPhoneTpl" nzSpan="3">
      <ng-template #contactPhoneTpl>
        <strong> 电话 </strong>
      </ng-template>
      {{ address.contactPhone }}
    </nz-descriptions-item>
  </nz-descriptions>
</ng-template>
<ng-template #EmptyTpl>
  <nz-empty [nzNotFoundImage]="ImgTpl" [nzNotFoundContent]="notFoundFooter">
    <ng-template #ImgTpl>
      <img src="/assets/img/svg/parcel/037-location.svg" width="75" />
    </ng-template>
    <ng-template #notFoundFooter>
      <button nz-button nzType="primary" nzGhost nzDanger (click)="create()">
        <i nz-icon nzType="plus-circle"></i>
        添加新地址
      </button>
      <nz-divider nzType="vertical"></nz-divider>
      <button nz-button nzType="primary" nzGhost nzDanger (click)="search()">
        <i nz-icon nzType="contacts"></i>
        地址簿中查询
      </button>
    </ng-template>
  </nz-empty>
</ng-template>
