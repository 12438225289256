import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BtcServiceTicketDialog } from 'src/app/common/models/ticket';
import { BtcServiceTicket } from 'src/app/common/models/ticket/btc-service-ticket.model';
import { HttpService } from '../http';



@Injectable({
  providedIn: 'root'
})
export class BtcTicketService {

  constructor(
    private http: HttpService,
  ) {

  }

  search(body: any): Observable<IHttpResponse> {
    const path = `ticket/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = result.map(item => new BtcServiceTicket(item));
        }
        return res;
      }),
    );
  }

  create(body: any): Observable<IHttpResponse> {
    const path = `ticket/create`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcServiceTicket(res.result);
        }
        return res;
      }),
    );
  }

  reply(id: string, body: any): Observable<IHttpResponse> {
    const path = `ticket/${id}/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        res.result = res.result.map(item => new BtcServiceTicketDialog(item));
        return res;
      }),
    );
  }

  close(id: string, body: any): Observable<IHttpResponse> {
    const path = `ticket/${id}/close`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        res.result = res.result.map(item => new BtcServiceTicketDialog(item));
        return res;
      }),
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const ticketNumber = route.params?.ticketNumber ?? null;
    return of(ticketNumber);
  }
}
