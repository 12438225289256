import { InjectionToken } from '@angular/core';
import { IAppConfig } from 'src/domain/app.config';


export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');
// tslint:disable-next-line: variable-name
export const App_Config: IAppConfig = {
  pca: 'zw15-ny57-er23-uz14',
  apiEndPoint: 'http://192.168.0.173:1337',
  // apiEndPoint: 'http://192.168.43.222:1337'
  // apiEndPoint: 'https://api.youyou-express.com'
  // apiEndPoint: 'https://api2.youyou-express.com'
  // apiKey: "1234567"
};
