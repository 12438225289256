<ng-container *ngIf="!option?.hidden">
  <form nz-form [formGroup]="validateForm" nzLayout="inline">
    <nz-form-item nzFor="state" *ngIf="validateForm | hasControl: 'state'">
      <nz-form-control [nzErrorTip]="fv.message(validateForm, 'state')">
        <label
          nz-checkbox
          formControlName="state"
          (ngModelChange)="on_state_change($event)"
        >
          送达确认
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFor="state" *ngIf="validateForm | hasControl: 'code'">
      <nz-form-control [nzErrorTip]="fv.message(validateForm, 'code')">
        <nz-form-label nzFor="code" nzRequired>签字类型</nz-form-label>
        <nz-select
          formControlName="code"
          nzPlaceHolder="请选择"
          style="width: auto; min-width: 250px"
        >
          <nz-option
            *ngFor="let item of types"
            [nzLabel]="item.label"
            [nzValue]="item.code"
            [nzDisabled]="item.disabled"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
  <nz-divider nzPlain></nz-divider>
</ng-container>
