<ng-template #listHeaderTpl>
  <div nz-row [nzGutter]="[10, 40]" nzJustify="end">
    <div nz-col nzSpan="24">
      <pickup-search-form
        [validateForm]="validateForm"
        (formChange)="on_submit()"
      ></pickup-search-form>
    </div>
    <nz-col>
      <nz-pagination
        [(nzPageIndex)]="queryParams.page"
        [nzTotal]="count"
        nzShowSizeChanger
        [nzPageSize]="queryParams.limit"
        nzResponsive
        (nzPageIndexChange)="on_page_change($event)"
        (nzPageSizeChange)="on_size_change($event)"
      ></nz-pagination>
    </nz-col>
  </div>
</ng-template>

<ng-template #listFooterTpl>
  <div nz-row [nzGutter]="[10, 20]" nzJustify="end">
    <nz-col>
      <nz-pagination
        [(nzPageIndex)]="queryParams.page"
        [nzTotal]="count"
        nzShowSizeChanger
        [nzPageSize]="queryParams.limit"
        nzResponsive
        (nzPageIndexChange)="on_page_change($event)"
        (nzPageSizeChange)="on_size_change($event)"
      ></nz-pagination>
    </nz-col>
  </div>
</ng-template>

<nz-list
  [nzHeader]="listHeaderTpl"
  [nzFooter]="listFooterTpl"
  [nzLoading]="isLoading"
>
  <nz-list-empty [nzNoResult]="EmptyList" *ngIf="!count"></nz-list-empty>

  <nz-list-item *ngFor="let item of recordList; trackBy: trackByIndex">
    <nz-list-item-meta
      [nzTitle]="itemTitle"
      [nzAvatar]="itemAvatar"
      [nzDescription]="itemDesc"
    >
      <ng-template #itemTitle>
        {{ item.carrier?.name }}
        <nz-divider nzType="vertical"></nz-divider>
        <ng-container *ngFor="let tag of item.status">
          <nz-tag [nzColor]="tag.color">{{ tag.text }}</nz-tag>
        </ng-container>
      </ng-template>
      <ng-template #itemDesc>
        <div>
          <small nz-typography> 数量: {{ item.qty }} </small>
          <nz-divider nzType="vertical"></nz-divider>
          <small nz-typography>取件地址: {{ item.location.label }}</small>
        </div>
        <div>
          <small>
            创建:
            {{
              item.createdAt
                | amLocale : i18n.getLocaleId()
                | amDateFormat : "lll"
            }}
          </small>
          <nz-divider nzType="vertical"></nz-divider>
          <small>
            更新:
            {{ item.updatedAt | amLocale : i18n.getLocaleId() | amTimeAgo }}
          </small>
        </div>
      </ng-template>
      <ng-template #itemAvatar>
        <nz-avatar
          [nzShape]="'square'"
          [nzSrc]="item.avatar"
          [nzAlt]="item.id"
          [nzText]="item.id"
          [nzSize]="25"
        ></nz-avatar>
      </ng-template>
    </nz-list-item-meta>
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <a
          nz-button
          nzType="link"
          [routerLink]="[
            '/app/pickups/detail/' + item.id | stateRoute : item.livemode
          ]"
        >
          详情 <i nz-icon nzType="right"></i>
        </a>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
</nz-list>

<ng-template #EmptyList>
  <nz-empty
    nzNotFoundImage="/assets/img/svg/parcel/035-calendar.svg"
    [nzNotFoundContent]="contentTpl"
    [nzNotFoundFooter]="footerTpl"
  >
    <ng-template #contentTpl>
      <span> 没有记录 </span>
    </ng-template>
    <ng-template #footerTpl>
      <a
        [routerLink]="['/app/pickups/create']"
        nz-button
        nzType="primary"
        nzDanger
        nzGhost
      >
        <i nz-icon nzType="calendar"></i> 立即预约
      </a>
    </ng-template>
  </nz-empty>
</ng-template>
