export interface IBtcPaymentRefund {
    createdAt: Date;
    updatedAt: Date;
    unlockedAt: Date;
    unlocked?: number;
    creditedAt: Date;
    credited?: number;
    id: string;
    livemode: number;
    version: number;
    status: number;
    refund?: any;
    charge?: any;
    amount: number;
    currency: string;
    rbc: number;
    failed: number;
    succeeded: number;
    pending: number;
    refundedReason?: string;
    user: string;
    payment: string;
    order: string;
}


export enum PaymentRefundMethodName {
    rbc = '现金退款',
    rbb = '退款至账户余额'
}

export enum PaymentRefundMethodCode {
    rbc = 'rbc',
    rbb = 'rbb'
}

export type PaymentRefundMethod = keyof typeof PaymentRefundMethodCode;

export type PaymentRefundPropItem<T> = {
    name: PaymentRefundMethodName;
    code: T;
    query?: any;
    note?: string;
    value?: number;
};

export type PaymentRefundProp = {
    [code in PaymentRefundMethod]: PaymentRefundPropItem<code>;
};

