import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { IMessageSys } from 'src/app/common/interfaces/message';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageListComponent implements OnInit {
  private _list: IMessageSys[] = [];
  @Input()
  set list(value: IMessageSys[]) {
    this._list = value;
    this.detection.detectChanges();
  }

  get list(): IMessageSys[] {
    return this._list;
  }

  @Input() syncing = false;

  constructor(
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

}
