<pickup-header-edit [pickup]="pickup"></pickup-header-edit>

<ng-container *ngIf="!isLoading; else loadingTpl">
  <ng-container *ngIf="pickup; else emptyTpl">
    <pickup-form
      [pickup]="pickup"
      (valueChange)="update_confirm($event)"
    ></pickup-form>
  </ng-container>
</ng-container>

<ng-template #loadingTpl>
  <i nz-icon nzType="loading"></i> 加载中...
</ng-template>

<ng-template #emptyTpl>
  <nz-empty
    nzNotFoundImage="/assets/img/svg/parcel/035-calendar.svg"
    [nzNotFoundContent]="contentTpl"
    [nzNotFoundFooter]="footerTpl"
  >
    <ng-template #contentTpl>
      <span> 没有记录 </span>
    </ng-template>
    <ng-template #footerTpl>
      <a
        [routerLink]="['/app/pickups']"
        nz-button
        nzType="primary"
        nzDanger
        nzGhost
      >
        <i nz-icon nzType="arrow-left"></i> 返回列表
      </a>
    </ng-template>
  </nz-empty>
</ng-template>
