import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PATTERNS } from 'src/app/common/constants/pattern';
import { ITEM_UNITS } from 'src/app/common/constants/service/service-item-unit';
import {
  Item, ItemNameSchema, ItemPriceSchema, ItemQtySchema, ItemUnitCanpost,
  ItemUnitOption, ItemUnitSchema, ItemUnitUni, ItemWeightSchema
} from 'src/app/common/interfaces/service';
import { IUnit } from 'src/app/common/interfaces/setting';
import { ItemWeight } from 'src/app/common/interfaces/shipping';
import { BTCShippingItem } from 'src/app/common/models/shipping';

@Component({
  selector: 'items-form',
  templateUrl: './items-form.component.html',
  styleUrls: ['./items-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsFormComponent implements OnInit {
  @Input() item: Item;
  @Input() current: Partial<BTCShippingItem>;
  @Input() validateForm: UntypedFormGroup;
  @Input() unit: IUnit;

  itemUnits: ItemUnitOption<ItemUnitCanpost>[] | ItemUnitOption<ItemUnitUni>[] = [];
  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.build();
  }

  private build(): void {
    const { qty, name, unit, weight, price } = this.item;

    if (this.current) {
      this.validateForm.patchValue({
        memo: this.current.memo,
        name: this.current.name,
        qty: this.current.qty,
        unit: this.current.unit,
        weight: this.current.weight,
        price: this.current.price,
        version: this.current.version
      });
    }

    this.validateForm.patchValue({
      isKilo: this.unit.weight.isKilo,
    });

    this.name_set(name);
    this.qty_set(qty);
    this.unit_set(unit);
    this.weight_set(weight, this.unit, this.current?.weight);
    this.price_set(price, this.current?.price);
  }

  private name_set(name: ItemNameSchema, current?: string): boolean | never {

    if (!name) {
      return false;
    }

    const control = this.validateForm.get('name') as UntypedFormControl;
    const { value, blocked } = name;
    const pattern = PATTERNS[name.pattern];

    const validators: ValidatorFn[] = [Validators.required, Validators.maxLength(35)];
    if (pattern) {
      validators.push(Validators.pattern(pattern.pattern));
    }

    control.setValidators(validators);

    if (current) {
      control.setValue(current);
    } else if (value) {
      control.setValue(value);
    }


    if (blocked) {
      control.setValue(value);
      control.disable();
    }

  }

  private qty_set(qty: ItemQtySchema, current?: number): boolean | never {
    if (!qty) {
      return false;
    }

    const control = this.validateForm.get('qty') as UntypedFormControl;
    const { value, blocked, max, min } = qty;

    const validators: ValidatorFn[] = [Validators.required];

    if (max) {
      validators.push(Validators.max(max));
    }

    validators.push(typeof min === 'number' ? Validators.min(min) : Validators.min(1));

    control.setValidators(validators);

    if (current) {
      control.setValue(current);
    } else if (value) {
      control.setValue(value);
    }


    if (blocked) {
      control.setValue(value);
      control.disable();
    }


  }

  private unit_set(unit: ItemUnitSchema, current?: ItemUnitUni | ItemUnitCanpost): boolean | never {
    if (!unit) {
      return false;
    }

    const control = this.validateForm.get('unit') as UntypedFormControl;
    const { value, required, blocked, carrier } = unit;

    const units = ITEM_UNITS[carrier] ?? ITEM_UNITS.ups;
    const options = unit.options ?? [];
    for (const key in units) {
      if (Object.prototype.hasOwnProperty.call(units, key)) {
        const item = units[key] as ItemUnitOption<any>;
        if (options.length && !options.includes(item.code)) {
          continue;
        }

        this.itemUnits.push(item);

        if (item.default) {
          this.validateForm.patchValue({
            unit: item.code
          });
        }
      }
    }
    const validators: ValidatorFn[] = [];

    if (required) {
      validators.push(Validators.required);
    }

    control.setValidators(validators);

    if (current) {
      control.setValue(current);
    } else if (value) {
      control.setValue(value);
    }

    if (blocked) {
      control.setValue(value);
      control.disable();
    }

  }

  private weight_set(weight: ItemWeightSchema, unit: IUnit, current?: ItemWeight): boolean | never {
    if (!weight) {
      return false;
    }

    const control = this.fb.control(null);
    const { value, required, blocked, max, min } = weight;

    const validators: ValidatorFn[] = [];

    if (required) {
      validators.push(Validators.required);
    }

    if (max) {
      validators.push(Validators.max(max[unit.weight.code]));
    }

    if (min) {
      validators.push(Validators.min(min[unit.weight.code]));
    }

    control.setValidators(validators);

    if (current) {
      control.setValue(current);
    } else if (value) {
      control.setValue(value);
    }

    if (blocked) {
      control.setValue(value);
      control.disable();
    }

    this.validateForm.addControl('weight', control);

  }

  private price_set(price: ItemPriceSchema, current?: number): boolean | never {
    if (!price) {
      return false;
    }

    const control = this.fb.control(null);
    const { value, required, blocked, max, min } = price;

    const validators: ValidatorFn[] = [];

    if (required) {
      validators.push(Validators.required);
    }

    if (max) {
      validators.push(Validators.max(max));
    }

    if (min) {
      validators.push(Validators.min(min));
    }

    control.setValidators(validators);

    if (current) {
      control.setValue(current);
    } else if (value) {
      control.setValue(value);
    }

    if (blocked) {
      control.setValue(value);
      control.disable();
    }

    this.validateForm.addControl('price', control);
    this.validateForm.addControl('currency', this.fb.control('CAD'));
  }
}
