<form nz-form [formGroup]="validateForm" nzLayout="inline">
  <ng-container *ngIf="!option?.hidden">
    <nz-form-item nzFor="state" *ngIf="validateForm | hasControl: 'code'">
      <nz-form-label
        nzFor="code"
        [nzRequired]="fv.isRequired(validateForm, 'code')"
        >当无法送到时</nz-form-label
      >
      <nz-form-control [nzErrorTip]="fv.message(validateForm, 'code')">
        <nz-radio-group formControlName="code">
          <label nz-radio [nzValue]="item.code" *ngFor="let item of codes">
            {{ item.label }}
          </label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <nz-divider nzPlain></nz-divider>
  </ng-container>
</form>
