<div nz-row nzJustify="space-between" class="b-b">
  <div nz-col *ngIf="!type">
    <strong nz-typography nzType="warning">Ship From 寄件人</strong>
  </div>
  <div nz-col *ngIf="type">
    <strong nz-typography nzType="warning">Ship To 收件人</strong>
  </div>
  <div nz-col>
    <ng-container *ngIf="showExtra && address; then extraTpl"></ng-container>
  </div>
</div>

<ng-container *ngIf="!isLoading; else loadingTpl">
  <ng-container
    *ngIf="type; then destinationTpl; else initiationTpl"
  ></ng-container>
</ng-container>

<ng-template #initiationTpl>
  <initiation-address
    [address]="address"
    (eventChange)="event_change($event, type)"
  ></initiation-address>
</ng-template>

<ng-template #destinationTpl>
  <destination-address
    [multiDestination]="multiDestination"
    [address]="address"
    (eventChange)="event_change($event, type)"
  ></destination-address>
</ng-template>

<ng-template #loadingTpl>
  <i nz-icon nzType="loading"></i> 加载中...
</ng-template>

<ng-template #extraTpl>
  <button nz-button nzType="link" nzSize="small" nzBlock (click)="reset()">
    <i nz-icon nzType="edit"></i>
    修改
  </button>
</ng-template>
