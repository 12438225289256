import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceDetailComponent } from './reference-detail/reference-detail.component';
import { ReferenceFormComponent } from './reference-form/reference-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ReferenceEditComponent } from './reference-edit/reference-edit.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { FormSharedModule } from 'src/app/shared/utils/form';



@NgModule({
  declarations: [
    ReferenceDetailComponent,
    ReferenceFormComponent,
    ReferenceEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IndicatorSharedModule,
    FormSharedModule,
    NzFormModule,
    NzInputModule,
    NzTypographyModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzPipesModule
  ],
  exports: [
    ReferenceDetailComponent,
    ReferenceFormComponent
  ]
})
export class ShippingReferenceSharedModule { }
