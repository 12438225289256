import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingFormComponent } from './components/tracking-form/tracking-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzCardModule } from 'ng-zorro-antd/card';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { SmbTrackingResultComponent } from './components/smb-tracking-result/smb-tracking-result.component';
import { SmbTrackingResultItemComponent } from './components/smb-tracking-result-item/smb-tracking-result-item.component';
import { SmbTrackingResultTimelineComponent } from './components/smb-tracking-result-timeline/smb-tracking-result-timeline.component';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { MomentModule } from 'ngx-moment';
import { BtcTrackingResultComponent } from './components/btc-tracking-result/btc-tracking-result.component';

import { BtcTrackingResultItemComponent } from './components/btc-tracking-result-item/btc-tracking-result-item.component';
import { BtcTrackingResultTimelineComponent } from './components/btc-tracking-result-timeline/btc-tracking-result-timeline.component';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { SmbTrackingFormComponent } from './components/smb-tracking-form/smb-tracking-form.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { BtcTrackingResultTimelineDetailComponent } from './components/btc-tracking-result-timeline-detail/btc-tracking-result-timeline-detail.component';
import { Kuaidi100TrackingCoreModule } from './kuaidi100';



@NgModule({
  declarations: [
    TrackingFormComponent,
    SmbTrackingResultComponent,
    SmbTrackingResultItemComponent,
    SmbTrackingResultTimelineComponent,
    SmbTrackingFormComponent,
    BtcTrackingResultComponent,
    BtcTrackingResultItemComponent,
    BtcTrackingResultTimelineComponent,
    BtcTrackingResultTimelineDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    MomentModule,
    IndicatorSharedModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzCollapseModule,
    NzTypographyModule,
    NzEmptyModule,
    NzDividerModule,
    NzPipesModule,
    NzCardModule,
    NzStepsModule,
    NzTagModule,
    NzTimelineModule,
    NzAlertModule,
    NzPopoverModule,
    Kuaidi100TrackingCoreModule
  ],
  exports: [
    TrackingFormComponent,
    SmbTrackingFormComponent,
    BtcTrackingResultTimelineDetailComponent
  ]
})
export class TrackingCoreModule { }
