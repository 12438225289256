
import { IChargeQuote } from '../../interfaces';
import { IBonus, IBonusCashback } from '../../interfaces/rate/bonus';
import { RateQuote } from './rate-quote.model';

export class ChargeQuote extends RateQuote {

    constructor(private data: IChargeQuote) {
        super(data);
    }

    get cashback(): IBonusCashback {
        return this.data?.cashback ?? null;
    }

    get point(): IBonus {
        return this.data?.point ?? null;
    }
}
