<ng-container *ngIf="!isLoading; else LoadingTpl">
  <ng-container *ngIf="!recordList.length">
    <nz-empty></nz-empty>
  </ng-container>

  <ng-container *ngIf="recordList.length">
    <form nz-form [formGroup]="validateForm">
      <nz-radio-group
        formControlName="index"
        (ngModelChange)="on_change($event)"
      >
        <label *ngFor="let item of recordList" nz-radio [nzValue]="item">{{
          item.label
        }}</label>
      </nz-radio-group>
    </form>

    <ng-container *ngIf="validateForm?.value?.index?.notices?.length">
      <nz-divider></nz-divider>
      <indicator-alert
        type="warning"
        [desc]="'注意事项 (请认真阅读)'"
        [problems]="validateForm?.value?.index?.notices"
        [buttons]="null"
      ></indicator-alert>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #LoadingTpl>
  <indicator-loading title="查询中..."></indicator-loading>
</ng-template>
