import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { IRegion } from 'src/app/common/interfaces/region';

import { IBtcServiceAdditional, IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { InsuranceItem } from 'src/app/common/interfaces/service/additional-insurance';
import { IUnit } from 'src/app/common/interfaces/setting';
import { BtcService, BtcServiceContent } from 'src/app/common/models/service';
import { BtcShipping } from 'src/app/common/models/shipping';
import { BtcServiceService, BtcShippingService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'shipping-package-form',
  templateUrl: './shipping-package-form.component.html',
  styleUrls: ['./shipping-package-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingPackageFormComponent implements OnInit {
  private _service: BtcService;
  private _unit: IUnit;

  @Input() destinationRegion: IRegion;
  @Input() indexes: IBtcServiceIndex[] = [];
  @Input() set service(value: BtcService) {
    this._service = value;
    if (this._service?.id) {
      this.content_search(this._service.id);
    }

    this.service_additional_form_build(this._service.additional);

  }

  get service(): BtcService {
    return this._service;
  }

  @Input() group: BtcShipping;

  @Input()
  set unit(value: IUnit) {
    this._unit = value;
    this.validateForm.get('pak').patchValue({
      isKilo: value?.weight?.isKilo ?? true,
      isCentimeter: value?.size?.isCentimeter ?? true
    });
  }
  get unit(): IUnit {
    return this._unit;
  }

  @Output() MaskClosable = new EventEmitter<boolean>();
  @Output() Close = new EventEmitter<any>();


  additional: IBtcServiceAdditional;
  content: BtcServiceContent;
  insurance: InsuranceItem;
  serviceContentList: BtcServiceContent[] = [];
  isLoading = false;
  contentLoading = false;


  @Input() validateForm = new UntypedFormGroup({});

  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private btcService: BtcServiceService,
    private shippingService: BtcShippingService,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  on_submit(): void {

    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      this.validateForm.enable();
      const { value } = this.validateForm;
      const { additional } = value;
      for (const i in additional) {
        if (Object.prototype.hasOwnProperty.call(additional, i)) {
          const item = additional[i];
          if (!Object.keys(item).length) {
            value.additional[i] = undefined;
          }
        }
      }

      this.pak_add(this.group.id, value);
    }

  }

  private pak_add(shippingId: string, body: any) {
    this.isLoading = true;
    this.MaskClosable.emit(false);
    this.shippingService.package_add(shippingId, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        const { error } = res;
        if (res.result) {
          this.Close.emit(res.result);
        }
        this.detection.detectChanges();
      }
    });
  }

  private service_additional_form_build(additional: IBtcServiceAdditional): void {
    if (additional) {
      this.additional = additional;
      this.validateForm.addControl('additional', this.fb.group({}));
    }
  }



  private service_content_form_build(content: BtcServiceContent): void {
    if (content?.insurance) {
      this.insurance = content.insurance;
      this.validateForm.addControl('insurance', this.fb.group({}));
    } else {
      this.validateForm.removeControl('insurance');
      this.insurance = null;
    }

  }


  private content_search(serviceId: string): void {
    this.contentLoading = true;
    this.btcService.content_search(serviceId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.contentLoading = false;
        const { result } = res;
        this.serviceContentList = result;
        const content = this.serviceContentList.find(item => item.id === this.group.serviceContentId);
        this.service_content_form_build(content);
        this.validateForm.patchValue({
          serviceContentId: content?.id ?? null
        });
        this.detection.detectChanges();
      }
    });
  }

}
