import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { PakType } from 'src/app/common/interfaces/pak';
import { IRegion, IRegionServiceControl } from 'src/app/common/interfaces/region';
import { IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { BtcService } from 'src/app/common/models/service';

@Component({
  selector: 'service-rate-compare-drawer',
  templateUrl: './service-rate-compare-drawer.component.html',
  styleUrls: ['./service-rate-compare-drawer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRateCompareDrawerComponent implements OnInit {
  @Input() index: IBtcServiceIndex;
  @Input() services: BtcService[];
  @Input() regionServiceControl: IRegionServiceControl;
  @Input() initiationRegion: IRegion;
  @Input() destinationRegion: IRegion;
  @Input() multiDestination: number;
  @Input() indexes: IBtcServiceIndex[] = [];
  @Input() pakType: PakType;

  constructor(
    private drawerRef: NzDrawerRef
  ) { }

  ngOnInit(): void {
  }

  on_service_select(data: any): void {
    this.drawerRef.close(data);
  }

}
