import { carriers } from '../../constants/carrier';
import { CarrierCode, CarrierPropItem } from '../../interfaces/carrier';
import { ITag } from '../../interfaces/common';
import { IBtcShippingTrackingStep } from '../../interfaces/shipping/';

export class BtcShippingTrackingStep {
    private _data: IBtcShippingTrackingStep;
    private _avatar: string;
    constructor(data: IBtcShippingTrackingStep) {
        this._data = data;
        this.avatar = data.carrierCode;
    }

    get title(): string {
        return this._data?.title;
    }

    get desc(): string {
        return this._data?.desc;
    }

    get carrier(): CarrierPropItem<CarrierCode> {
        const carrier = carriers[this.carrierCode];
        if (carrier) {
            carrier.trackingUrl = this._data?.trackingUrl;
        }
        return carrier;
    }

    get carrierCode(): CarrierCode {
        return this._data.carrierCode;
    }

    get waybillReference(): string | null {
        return this._data.waybillReference;
    }

    get status(): ITag[] {

        const items = [];
        const pending = this.status_parse('submitted', 0, ['default', '待提交']);
        const submitted = this.status_parse('submitted', 1, ['default', '已确认']);
        const submittedPending = this.status_parse('submitted', 2, ['default', '提交中']);
        const submittedFailed = this.status_parse('submitted', 4, ['red', '提交失败']);
        const voided = this.status_parse('voided', 1, ['default', '已取消']);
        const voidedFailed = this.status_parse('voided', 4, ['red', '取消失败']);
        const delivered = this.status_parse('delivered', 1, ['green', '送达']);
        const delivering = this.status_parse('delivered', 2, ['lime', '投递中']);
        const deliveryFailed = this.status_parse('delivered', 4, ['orange', '投递失败']);
        const tscReceived = this.status_parse('tsc', 1, ['default', '到达']);
        const tscPending = this.status_parse('tsc', 2, ['orange', '处理中']);
        const tscReleased = this.status_parse('tsc', 3, ['blue', '放行']);
        const tscAbnormal = this.status_parse('tsc', 4, ['red', '异常']);
        const rts = this.status_parse('rts', 1, ['red', '退回']);
        const abnormal = this.status_parse('abnormal', 1, ['magenta', '物流异常']);
        const inTransit = this.status_parse('inTransit', 1, ['cyan', '在途']);

        if (pending) {
            items.push(pending);
        }

        if (submittedPending) {
            items.push(submittedPending);
        }

        if (submitted) {
            items.push(submitted);
        }

        if (submittedFailed) {
            items.push(submittedFailed);
        }

        if (inTransit && !delivered) {
            items.push(inTransit);
        }


        if (tscReceived) {
            items.push(tscReceived);
        }

        if (tscPending) {
            items.push(tscPending);
        }

        if (tscReleased) {
            items.push(tscReleased);
        }

        if (tscAbnormal) {
            items.push(tscAbnormal);
        }
        if (rts) {
            items.push(rts);
        }

        if (delivered) {
            items.push(delivered);
        }

        if (delivering) {
            items.push(delivering);
        }

        if (deliveryFailed) {
            items.push(deliveryFailed);
        }

        if (voided) {
            items.push(voided);
        }

        if (voidedFailed) {
            items.push(voidedFailed);
        }

        if (abnormal) {
            items.push(abnormal);
        }
        return items;

    }

    get avatar(): string {
        return this._avatar;
    }

    set avatar(carrierCode: string) {
        this._avatar = `/assets/img/carrier/${carrierCode}.png`;
    }

    get location(): string {
        return this._data?.location;
    }

    get eventTime(): Date {
        return this._data?.eventTime;
    }


    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {

        if (prop in this._data) {

            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }

}
