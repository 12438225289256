import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { IBtcShippingAdditionalItem } from 'src/app/common/interfaces/service';
import { InsuranceItem } from 'src/app/common/interfaces/service/additional-insurance';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'service-content-insurance-form',
  templateUrl: './service-content-insurance-form.component.html',
  styleUrls: ['./service-content-insurance-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceContentInsuranceFormComponent implements OnInit {
  @Input() current: IBtcShippingAdditionalItem;
  @Input() option: InsuranceItem;

  private _validateForm: UntypedFormGroup;
  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
    this.type_set(this.option);
  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  types = [];

  constructor(
    public fv: FormValidationService,
    private fb: UntypedFormBuilder,
  ) {

  }


  ngOnInit(): void {
  }


  type_set(value: InsuranceItem): void {
    const { amount, blocked } = value;
    this.validateForm.addControl('amount', this.fb.control(amount?.value));
    if (this.current) {
      this._validateForm.patchValue(this.current);
    }
    const validators: ValidatorFn[] = [];
    if (amount?.required) {
      validators.push(Validators.required);
    }
    if (amount?.max) {
      validators.push(Validators.max(amount.max));
    }

    if (amount?.min) {
      validators.push(Validators.min(amount.min));
    }


    this.validateForm.get('amount').setValidators(validators);
    this.validateForm.get('amount').updateValueAndValidity();

    if (blocked) {
      if (amount?.value) {
        this.validateForm.patchValue({
          amount: amount.value
        });
      }
      this.validateForm.disable();
    }


  }

}
