import { Injectable } from '@angular/core';
import { catchError, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';
import { IHttpResponse } from '../http/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ReferralApiService {
  #coreEndPoint = environment.coreEndPoint;

  constructor(
    private http: HttpService
  ) { }

  getReferralUrl(): Observable<IHttpResponse> {
    const path = `${this.#coreEndPoint}/member/referral/url`;
    return this.http.get(path).pipe(
      catchError(this.http.catch())
    )
  }

  sendReferralMail(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndPoint}/member/referral/mail/send`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler())
    );
  }

  getReferralToken({ ...args }) {
    const { criteria } = args;
    return this.http.get('/api/webhook/marketing/promote/refer/token', criteria);
  }


}
