import { Pipe, PipeTransform } from '@angular/core';
import { CashbackEventType } from 'src/app/common/interfaces/cashback';
import { CashbackEvent } from '../../interfaces';

@Pipe({
  name: 'cashbackName'
})
export class CashbackNamePipe implements PipeTransform {

  transform(value: unknown): string | null {
    const code = value as CashbackEventType ?? null;
    const name = CashbackEvent[code] ?? null;
    return name;
  }

}
