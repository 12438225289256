import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchModalComponent } from './search-modal/search-modal.component';

import { NewRecipientModalComponent } from './recipient/new-recipient-modal/new-recipient-modal.component';
import { EditRecipientModalComponent } from './recipient/edit-recipient-modal/edit-recipient-modal.component';
import { NewSenderModalComponent } from './sender/new-sender-modal/new-sender-modal.component';
import { EditSenderModalComponent } from './sender/edit-sender-modal/edit-sender-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IdCardPanelComponent } from './recipient/id-card-panel/id-card-panel.component';
import { SenderCountComponent } from './sender/sender-count/sender-count.component';
import { RecipientCountComponent } from './recipient/recipient-count/recipient-count.component';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { AddressSelectorComponent } from './components/address-selector';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { AddressHeaderListComponent } from './components/address-header-list/address-header-list.component';
import { AddressSearchFormComponent } from './components/address-search-form/address-search-form.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { FormSharedModule } from '../utils/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { RegionSharedModule } from '../region';
import { PcaSearchFormComponent } from './components/pca-search-form/pca-search-form.component';
import { AddressAddComponent } from './components/address-add/address-add.component';


@NgModule({
  declarations: [
    SearchModalComponent,
    NewSenderModalComponent,
    NewRecipientModalComponent,
    EditRecipientModalComponent,
    EditSenderModalComponent,
    IdCardPanelComponent,
    SenderCountComponent,
    RecipientCountComponent,
    AddressSelectorComponent,
    AddressHeaderListComponent,
    AddressSearchFormComponent,
    AddressFormComponent,
    PcaSearchFormComponent,
    AddressAddComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    RegionSharedModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTableModule,
    NzInputModule,
    NzCardModule,
    NzFormModule,
    NzSelectModule,
    NzDividerModule,
    NzSpinModule,
    NzButtonModule,
    NzTypographyModule,
    NzIconModule,
    NzListModule,
    NzUploadModule,
    NzStatisticModule,
    NzCheckboxModule,
    NzPaginationModule,
    NzSkeletonModule,
    NzEmptyModule,
    NzDrawerModule,
    NzPipesModule,
  ],
  exports: [
    SearchModalComponent,
    NewRecipientModalComponent,
    IdCardPanelComponent,
    SenderCountComponent,
    RecipientCountComponent,
    AddressSelectorComponent,
    AddressHeaderListComponent,
    AddressSearchFormComponent,
    AddressFormComponent,
    PcaSearchFormComponent,
    AddressAddComponent
  ],

})
export class AddressSharedModule { }
