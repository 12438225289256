import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceRateCompareComponent } from './components';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSharedModule } from '../../utils/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ServiceRateComponent } from './components/service-rate/service-rate.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ServiceShippingPackageModule } from '../package';
import { IndicatorSharedModule } from '../../indicator';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ServiceRateDetailComponent } from './components/service-rate-detail/service-rate-detail.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AclModule } from '../../acl/acl.module';
import { ServiceRateCompareDrawerComponent } from './components/service-rate-compare-drawer/service-rate-compare-drawer.component';
import { AddressSharedModule } from '../../address';
import { DetailSumPipe } from './pipes/detail-sum.pipe';
import { CashbackSharedModule } from '../../bonus/cashback';
import { NzPipesModule } from 'ng-zorro-antd/pipes';



@NgModule({
  declarations: [
    ServiceRateCompareComponent,
    ServiceRateComponent,
    ServiceRateDetailComponent,
    ServiceRateCompareDrawerComponent,
    DetailSumPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzStepsModule,
    NzGridModule,
    NzCardModule,
    NzTypographyModule,
    NzDividerModule,
    NzButtonModule,
    NzIconModule,
    NzTabsModule,
    NzTagModule,
    NzDrawerModule,
    NzDescriptionsModule,
    NzModalModule,
    NzCardModule,
    NzPipesModule,
    AddressSharedModule,
    ServiceShippingPackageModule,
    IndicatorSharedModule,
    CashbackSharedModule,
    AclModule
  ],
  exports: [
    ServiceRateCompareComponent,
    ServiceRateComponent,
    ServiceRateCompareDrawerComponent,
    DetailSumPipe
  ]
})
export class ServiceRateSharedModule { }
