import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParcelInfoComponent } from './parcel-info/parcel-info.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { ShippingFormatSharedModule } from '../format';
import { ShippingServiceSharedModule } from '../service';
import { ShippingReferenceSharedModule } from '../reference';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ParcelDeclarationComponent } from './parcel-declaration/parcel-declaration.component';
import { ShippingExportationSharedModule } from '../exportation';



@NgModule({
  declarations: [
    ParcelInfoComponent,
    ParcelDeclarationComponent
  ],
  imports: [
    CommonModule,
    NzCardModule,
    NzEmptyModule,
    NzButtonModule,
    NzTypographyModule,
    NzIconModule,
    NzDividerModule,
    ShippingFormatSharedModule,
    ShippingServiceSharedModule,
    ShippingReferenceSharedModule,
    ShippingExportationSharedModule,
  ],
  exports: [
    ParcelInfoComponent,
    ParcelDeclarationComponent
  ]
})
export class ShippingInfoSharedModule { }
