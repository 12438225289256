import { BreakPoint, BreakpointCode } from '../interfaces';

export const BREAK_POINTS: BreakPoint[] = [
    {
        code: BreakpointCode.xs,
        mediaQuery: ['(max-width: 575px)']
    },
    {
        code: BreakpointCode.sm,
        mediaQuery: ['(min-width: 576px) and (max-width: 767px)']
    },
    {
        code: BreakpointCode.md,
        mediaQuery: ['(min-width: 768px) and (max-width: 991px)']
    },
    {
        code: BreakpointCode.lg,
        mediaQuery: ['(min-width: 992px) and (max-width:1199px)']
    },
    {
        code: BreakpointCode.xl,
        mediaQuery: ['(min-width: 1200px) and (max-width: 1599px)']
    },
    {
        code: BreakpointCode.xxl,
        mediaQuery: ['(min-width: 1600px)']
    },
];
