import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { REFUND_METHODS } from 'src/app/common/constants/payment';
import { PaymentRefundMethodCode, PaymentRefundPropItem } from 'src/app/common/interfaces/payment';
import { BtcOrder } from 'src/app/common/models/order';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'order-void-form',
  templateUrl: './order-void-form.component.html',
  styleUrls: ['./order-void-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderVoidFormComponent implements OnInit {
  @Input() order: BtcOrder;
  @Input() drawer = false;

  @Output() cancel = new EventEmitter<any>();
  isLoading = false;
  list: PaymentRefundPropItem<PaymentRefundMethodCode>[] = [];

  validateForm = new UntypedFormGroup({
    version: this.fb.control(null, [Validators.required]),
    rbc: this.fb.control(null)
  });
  method: any;
  constructor(
    private fb: UntypedFormBuilder,
    public fv: FormValidationService,
    private detection: ChangeDetectorRef,
    private injector: Injector
  ) {
    for (const i in REFUND_METHODS) {
      if (Object.prototype.hasOwnProperty.call(REFUND_METHODS, i)) {
        const method = REFUND_METHODS[i];
        this.list.push(method);
      }
    }

  }

  ngOnInit(): void {
    this.validateForm.patchValue({
      version: this.order.version
    });

    if (['account_balance', 'group'].includes(this.order.payment.methodCode)) {
      this.list = this.list.filter(({ code }) => code === PaymentRefundMethodCode.rbb);
      this.validateForm.patchValue({
        rbc: 0
      });
    } else {
      this.validateForm.get('rbc').setValidators([Validators.required]);
    }
  }

  on_method_change($event: number): void {
    this.method = this.list.find(item => item.value === $event);

    this.detection.detectChanges();
  }


  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      this.close(this.validateForm.value);
    }
  }

  close(data?: any): void {
    if (this.drawer) {
      const drawerRef = this.injector.get(NzDrawerRef);
      drawerRef.close(data);
    } else {
      this.cancel.emit(data);
    }
  }

}
