import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { HasGroupDirective } from './directives/has-group/has-group.directive';
import { ExcludeRoleDirective } from './directives/exclude-role/exclude-role.directive';
import { ExcludeGroupDirective } from './directives/exclude-group/exclude-group.directive';

import { GroupTagsPipe } from './pipes/group-tags/group-tags.pipe';
import { RoleTagsPipe } from './pipes/role-tags/role-tags.pipe';


@NgModule({
  declarations: [
    HasRoleDirective,
    HasGroupDirective,
    ExcludeRoleDirective,
    ExcludeGroupDirective,
    GroupTagsPipe,
    RoleTagsPipe,

  ],
  imports: [
    CommonModule
  ],
  providers: [

  ],
  exports: [
    HasRoleDirective,
    HasGroupDirective,
    ExcludeRoleDirective,
    ExcludeGroupDirective,
    GroupTagsPipe,
    RoleTagsPipe,

  ]
})
export class AclCoreModule { }
