<ng-container *ngIf="show">
  <nz-divider [nzText]="tpl"></nz-divider>
  <ng-template #tpl>
    <button
      nz-button
      [nzType]="type"
      [nzDanger]="danger"
      [nzSize]="size"
      [nzShape]="shape"
      (click)="event.emit()"
    >
      <ng-container *ngIf="icon">
        <i nz-icon [nzType]="icon"></i>
      </ng-container>

      {{ text }}
    </button>
  </ng-template>
</ng-container>
