<ng-container *ngIf="voucher">
  <div nz-row>
    <div nz-col nzFlex="200">
      <ng-container *ngIf="voucher.data.code as code">
        折扣码: {{ code }} <br />
      </ng-container>

      {{ voucher.title }}<br />

      <small nz-typography nzDisabled *ngIf="voucher.expireAt">
        有效期:
        {{
          voucher.expireAt | amLocale: i18n.getLocaleId() | amDateFormat: "ll"
        }}
        前使用
      </small>
      <small nz-typography nzDisabled *ngIf="!voucher.expireAt">
        有效期: 永久
      </small>
      <ng-container>
        <p>
          额度:
          <span *ngIf="voucher.rate"> {{ voucher.rate }} % </span>
          <span *ngIf="voucher.amount">
            {{ voucher.amount | currency: "CAD":"symbol-narrow" }}
          </span>
        </p>
      </ng-container>
      <p>
        {{ voucher.descriptionShort }}
      </p>
    </div>

    <div nz-col nzFlex="fill">
      <nz-avatar
        [nzShape]="'square'"
        [nzSrc]="'/assets/img/svg/shop/029-coupon.svg'"
        [nzSize]="25"
      ></nz-avatar>
    </div>

    <div nz-col nzSpan="24">
      <button
        nz-button
        nzType="primary"
        nzGhost
        nzDanger
        nzBlock
        nzShape="round"
        (click)="event.emit()"
      >
        <i nz-icon nzType="redo"></i> 重置
      </button>
    </div>
  </div>
</ng-container>
