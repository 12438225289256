<label-search-form
  [validateForm]="validateForm"
  (formChange)="on_submit()"
></label-search-form>
<div nz-row nzJustify="end">
  <div nz-col>
    <button
      nz-button
      nzType="primary"
      nzGhost
      [disabled]="setOfCheckedId.size === 0"
      [nzLoading]="isLoading"
      (click)="preview()"
    >
      打印面单 {{ setOfCheckedId.size }} 张
    </button>
    <nz-divider nzType="vertical"></nz-divider>
    <button
      nz-button
      nzType="primary"
      nzGhost
      [disabled]="setOfCheckedId.size === 0"
      [nzLoading]="isLoading"
      (click)="send()"
    >
      发送至信箱 {{ setOfCheckedId.size }} 张
    </button>
  </div>
</div>
<br />
<nz-table
  #nzTable
  [nzData]="waybills"
  [nzLoading]="isLoading"
  [nzPageSize]="queryParams.limit"
  [(nzPageIndex)]="queryParams.page"
  [nzTotal]="count"
  nzSize="small"
  (nzPageIndexChange)="on_page_change($event)"
  (nzPageSizeChange)="on_size_change($event)"
  (nzQueryParams)="on_query_change($event)"
  nzFrontPagination="false"
  nzShowSizeChanger
  [nzScroll]="{ x: '630px' }"
>
  <thead>
    <tr>
      <th
        nzWidth="30px"
        nzLeft
        class="table-checkbox"
        [nzChecked]="checked"
        [nzIndeterminate]="indeterminate"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th nzWidth="100px" nzLeft [nzSortFn]="true" nzColumnKey="shippingNumber">
        运单编码
      </th>
      <th nzWidth="100px" [nzSortFn]="true" nzColumnKey="waybillCarrierCode">
        承运商
      </th>
      <th nzWidth="150px" [nzSortFn]="true" nzColumnKey="waybillNumber">
        物流单号
      </th>
      <th nzWidth="150px" [nzSortFn]="true" nzColumnKey="waybillNumber">
        提交时间
      </th>
      <th nzWidth="100px" nzRight>操作</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let i of nzTable.data">
      <td
        nzLeft
        class="table-checkbox"
        [nzChecked]="setOfCheckedId.has(i.id)"
        [nzDisabled]="i.disabled"
        (nzCheckedChange)="onItemChecked(i.id, $event)"
      ></td>
      <td nzLeft>{{ i.shippingNumber }}</td>
      <td>{{ i.carrierName }}</td>
      <td>{{ i.waybillNumber }}</td>
      <td>
        {{ i.data.submittedAt | amLocale: langCode | amDateFormat: "lll" }}
      </td>
      <td nzRight>
        <a (click)="preview(i)"> <i nz-icon nzType="printer"></i> 打印 </a>
        <nz-divider nzType="vertical"></nz-divider>
        <a (click)="send(i)"> <i nz-icon nzType="mail"></i> 发送邮件 </a>
      </td>
    </tr>
  </tbody>
</nz-table>
