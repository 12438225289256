import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelPageHeaderComponent } from './components/label-page-header/label-page-header.component';
import { LabelSearchFormComponent } from './components/label-search-form/label-search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { IndicatorSharedModule } from '../indicator';
import { FormSharedModule } from '../utils/form';
import { LabelListComponent } from './components/label-list/label-list.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { LabelPreviewComponent } from './components/label-preview/label-preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NzInputModule } from 'ng-zorro-antd/input';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';



@NgModule({
  declarations: [
    LabelPageHeaderComponent,
    LabelSearchFormComponent,
    LabelListComponent,
    LabelPreviewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    IndicatorSharedModule,
    MomentModule,
    QRCodeModule,
    NzFormModule,
    NzRadioModule,
    NzInputNumberModule,
    NzInputModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
    NzSpinModule,
    NzToolTipModule,
    NzSelectModule,
    NzDividerModule,
    NzTableModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    LabelPageHeaderComponent,
    LabelSearchFormComponent,
    LabelListComponent,
    LabelPreviewComponent
  ]
})
export class LabelSharedModule { }
