import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { PagesLoadedEvent } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent implements OnInit {

  @Input() base64src: string;
  @Input() drawer = true;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  height = '100vh';

  constructor(
    private detection: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

  }

  public onPagesLoaded(event: PagesLoadedEvent): void {
    this.height = '100%';
  }

}
