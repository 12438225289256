<form nz-form [formGroup]="validateForm" nzLayout="horizontal">
  <nz-tabset
    [(nzSelectedIndex)]="selectedIndex"
    nzType="editable-card"
    (nzAdd)="add()"
    (nzClose)="closeTab($event)"
    [nzHideAdd]="this.validateForm?.controls?.length >= this.countMax"
  >
    <nz-tab
      *ngFor="
        let control of this.validateForm?.controls;
        let i = index;
        trackBy: trackByIndex
      "
      [nzClosable]="i + 1 > countMin"
      [nzTitle]="titleTpl"
    >
      <ng-template #titleTpl>
        <span nz-typography [nzType]="control.invalid ? 'danger' : 'success'">
          包裹 #{{ i + 1 }}
        </span>
      </ng-template>
      <shipping-package-form
        [validateForm]="control"
        [destinationRegion]="destinationRegion"
        [indexes]="indexes"
        [unit]="unit"
      ></shipping-package-form>
    </nz-tab>
  </nz-tabset>
</form>
