import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BtcShippingWaybill } from 'src/app/common/models/shipping';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BtcLabelService {
  #corePoint;
  constructor(
    private http: HttpService
  ) {
    this.#corePoint = environment.coreEndPoint;
  }

  search(body: any): Observable<IHttpResponse> {
    const path = `${this.#corePoint}/label/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        res.result = res.result.map(item => new BtcShippingWaybill(item));
        return res;
      }),
    );
  }

  base64(body: any): Observable<IHttpResponse> {
    const path = `${this.#corePoint}/label/base64`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
    );
  }

  send(body: any): Observable<IHttpResponse> {
    const path = `${this.#corePoint}/label/send`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler())
    );
  }

}
