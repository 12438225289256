import { Currency } from '../rate/currency';
import { StateSchema } from '../state';
import { IBtcPaymentRefund } from './btc-payment.refund';

export interface IBtcPayment {
    createdAt: Date;
    updatedAt: Date;
    id: string;
    livemode: number;
    version: number;
    status: number;
    type: string;
    methodName?: any;
    methodCode: PaymentMethod;
    providerCode: string;
    amount: number;
    currency: string;
    transaction?: any;
    state?: any;
    failureCode?: any;
    failureMessage?: any;
    pending: number;
    chargeable: number;
    failed: number;
    canceled: number;
    expired: number;
    abort: number;
    paid: number;
    succeeded: number;
    refunded: number;
    refundedAmount: number;
    refundedReason?: any;
    user: string;
    refunds: IBtcPaymentRefund[];
    payments: IBtcPayment[]
}

export enum PaymentTypeCode {
    payout = 'payout',
    recharge = 'recharge',
    supplement = 'supplement'
}

export type PaymentType = keyof typeof PaymentTypeCode;

export interface IPaymentType {
    label: string;
    code: PaymentType;
}

export enum PaymentMethod {
    account_balance = 'account_balance',
    creditcard = 'creditcard',
    alipay = 'alipay',
    wechat = 'wechat',
    applepay = 'applepay',
    bancontact = 'bancontact',
    group = 'group'
}


export const PAYMENT_METHODS: Partial<Record<PaymentMethod, string>> = {
    account_balance: '账户余额',
    creditcard: '信用卡',
    alipay: '支付宝',
    wechat: '微信(网页版)',
    applepay: 'Apple Pay',
    bancontact: 'BANCONTACT',
    group: '组合支付 (账户余额 + 现金)'
};

export type PaymentMethodType = keyof typeof PaymentMethod;
export type PaymentMethodName = typeof PaymentMethod[keyof typeof PaymentMethod];

export interface IPaymentMethod {
    name: string;
    code: PaymentMethod;
    type: PaymentType[];
    min: number;
    icon: string;
    desc: string | null;
    currencies: Partial<Currency>[];
}

export enum PaymentStateCode {
    pending = 'pending',
    paid = 'paid',
    expired = 'expired',
    failed = 'failed',
    canceled = 'canceled',
    abort = 'abort',
    refunded = 'refunded',
    refundPending = 'refundPending'
}


export interface IPaymentState {
    chargeable: number;
    paid: number;
    succeeded: number;
    failed: number;
    pending: number;
    id: string;
}

export type PaymentState = keyof typeof PaymentStateCode;

export type PaymentStateProps = {
    [type in PaymentState]: StateSchema<type>;
};

