<nz-table
  #nzTable
  [nzData]="recordList"
  [nzLoading]="isLoading"
  [nzPageSize]="queryParams.limit"
  [(nzPageIndex)]="queryParams.page"
  [nzTotal]="count"
  (nzPageIndexChange)="on_page_change($event)"
  (nzPageSizeChange)="on_size_change($event)"
  (nzQueryParams)="on_query_change($event)"
  nzFrontPagination="false"
  nzShowSizeChanger
  [nzScroll]="{
    x: '1000px'
  }"
>
  <thead>
    <tr>
      <th nzWidth="100px"></th>
      <th nzWidth="300px" [nzSortFn]="true" nzColumnKey="shippingNumber">
        运单编码
      </th>
      <th nzWidth="300px" [nzSortFn]="true" nzColumnKey="waybillCarrierCode">
        取件地
      </th>
      <th nzWidth="200px" [nzSortFn]="true" nzColumnKey="waybillNumber">
        物流单号
      </th>
      <th nzWidth="100px" [nzSortFn]="true" nzColumnKey="weight">重量</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let i of nzTable.data">
      <td
        class="table-checkbox"
        [nzChecked]="setOfCheckedId.has(i.id)"
        [nzDisabled]="i.disabled"
        (nzCheckedChange)="onItemChecked(i.id, $event)"
      ></td>
      <td>{{ i.shippingNumber }}</td>
      <td>
        {{ i.from.addressLine1 }}, {{ i.from.city }} {{ i.from.postalCode }}
        {{ i.from.stateCode }}
      </td>
      <td>{{ i.waybillNumber }}</td>
      <td>
        {{ i.weight | weightUnit: unit?.weight.code:true }}
      </td>
    </tr>
  </tbody>
</nz-table>
