import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, ChangeDetectorRef, Injector, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { PATTERNS } from 'src/app/common/constants/pattern';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { BtcServiceContent } from 'src/app/common/models/service';
import { BtcShippingItemService } from 'src/app/core';
import { BreakpointService } from 'src/app/core/breakpoint';
import { FormValidationService } from 'src/app/core/form';
import { ServiceContent } from 'src/app/core/service/models';
import { UnitService } from 'src/app/core/unit';

@Component({
  selector: 'items-add',
  templateUrl: './items-add.component.html',
  styleUrls: ['./items-add.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsAddComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private _isLoading = false;
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  private smallscreen = false;
  private drawerRef: NzDrawerRef;

  @Input() drawer = false;
  @Input() serviceContent: ServiceContent;
  @Input() entityId: string;
  @Output() itemChange = new EventEmitter<any>();

  error: IHttpResponseError;

  validateForm = new UntypedFormGroup({
    memo: this.fb.control(null, [Validators.maxLength(20)]),
    name: this.fb.control(null, [Validators.required, Validators.maxLength(35), Validators.pattern(PATTERNS.item_name_uni.pattern)]),
    qty: this.fb.control(null, [Validators.required, Validators.min(1)]),
    unit: this.fb.control(null),
    // currency: this.fb.control(CurrencyCode.CAD),
    // weight: this.fb.control(null, [Validators.required]),
    // price: this.fb.control(null, [Validators.required]),
    // isKilo: this.fb.control(null),
  });

  unit$ = this.unitService.unit$;
  constructor(
    private itemService: BtcShippingItemService,
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private unitService: UnitService,
    private detection: ChangeDetectorRef,
    private bpService: BreakpointService,
    private injector: Injector
  ) {
    this.bp_watch();
  }

  ngOnInit(): void {
    this.drawer_update();
  }

  private bp_watch(): void {
    this.bpService.small$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (small) => this.smallscreen = small
    });
  }

  private drawer_update(): void {
    if (this.drawer) {
      this.drawerRef = this.injector.get(NzDrawerRef);
      setTimeout(() => {
        this.drawerRef.nzHeight = this.smallscreen ? '100%' : 'auto';
      }, 200);
    }
  }


  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      this.validateForm.enable();
      const body = this.validateForm.value;
      this.add(this.entityId, body);
    }
  }

  private add(packageId: string, body: any): void {
    this.isLoading = true;
    this.itemService.add(body, packageId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        if (res?.error) {
          this.error = res.error;
        }
        if (res?.result) {
          this.close(res.result);
        }
        this.isLoading = false;
      }
    });
  }

  private close(data: any) {
    if (this.drawer) {
      this.drawerRef?.close(data);
    } else {
      this.itemChange.emit(data);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
