export const environment = {
  appVersion: require("../../package.json").version,
  production: true,
  pca: "zw15-ny57-er23-uz14",
  partnerUrl: "https://partner.youyou-express.com",
  apiEndPoint: "https://api.youyou-express.com",
  btcEndPoint: "https://api.youyou-express.com",
  authEndPoint: "https://api.youyou-express.com/auth",
  saleEndPoint: "https://api.youyou-express.com/sale",
  coreEndPoint: "https://api.youyou-express.com/core",
  trackingEndPoint: "https://api.youyou-express.com/tracking",
  withCredentials: true,
};
