<ng-template #loadingTpl>
  <i nz-icon nzType="loading"></i> 加载中...
</ng-template>

<ng-container *ngIf="!isLoading; else loadingTpl">
  <ng-container *ngIf="base64src">
    <ngx-extended-pdf-viewer
      [base64Src]="base64src"
      backgroundColor="#888888"
      [useBrowserLocale]="true"
      [showZoomButtons]="false"
      [handTool]="false"
      [showHandToolButton]="true"
      [height]="height"
      (pagesLoaded)="onPagesLoaded($event)"
    >
    </ngx-extended-pdf-viewer>
  </ng-container>

  <ng-container *ngIf="error">
    <indicator-alert
      [message]="error.message"
      [problems]="error.problems"
      [buttons]="null"
      type="error"
    ></indicator-alert>
  </ng-container>
</ng-container>
