<form nz-form [formGroup]="validateForm" [nzLayout]="layout">
  <nz-form-item>
    <nz-form-label nzRequired> 取货日期 </nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'pickupDate'">
      <nz-date-picker
        style="min-width: 250px"
        nzFormat="yyyy-MM-dd"
        [nzDisabledDate]="disabledDate"
        formControlName="pickupDate"
        nzPlaceHolder="请选择"
      ></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
</form>
