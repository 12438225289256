import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsListComponent } from './items-list/items-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UnitModule } from 'src/app/shared/unit/unit.module';
import { ItemsAddComponent } from './items-add/items-add.component';
import { ItemsFormComponent } from './items-form/items-form.component';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { ItemsEditComponent } from './items-edit/items-edit.component';
import { ItemsAddPreComponent } from './items-add-pre/items-add-pre.component';
import { ItemsListPreComponent } from './items-list-pre/items-list-pre.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ItemsEditPreComponent } from './items-edit-pre/items-edit-pre.component';



@NgModule({
  declarations: [
    ItemsListComponent,
    ItemsAddComponent,
    ItemsFormComponent,
    ItemsEditComponent,
    ItemsAddPreComponent,
    ItemsListPreComponent,
    ItemsEditPreComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    UnitModule,
    FormSharedModule,
    NzListModule,
    NzEmptyModule,
    NzFormModule,
    NzInputModule,
    NzTypographyModule,
    NzInputNumberModule,
    NzSelectModule,
    NzDividerModule,
    NzBadgeModule,
    NzSkeletonModule,
    NzGridModule,
    NzPaginationModule,
    NzCardModule,
    NzToolTipModule,
    NzButtonModule,
    NzTableModule,
    NzIconModule,
    NzPipesModule,
    NzTagModule,
    UnitModule,
    IndicatorSharedModule
  ],
  exports: [
    ItemsListComponent,
    ItemsAddComponent,
    ItemsFormComponent,
    ItemsEditComponent,
    ItemsAddPreComponent,
    ItemsListPreComponent
  ]
})
export class ShippingItemSharedModule { }
