import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { PakType } from 'src/app/common/interfaces/pak';
import { IRegion, IRegionServiceControl, Region } from 'src/app/common/interfaces/region';
import { IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { IBtcShippingPackagePreset } from 'src/app/common/interfaces/shipping';
import { BtcService, BtcServiceContent } from 'src/app/common/models/service';
import { BtcShippingService } from 'src/app/core';
import { IndicatorAlertComponent, IndicatorLoadingComponent } from 'src/app/shared/indicator';

@Component({
  selector: 'group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupFormComponent implements OnInit {
  @Input() page = 1;
  @Input() initiationRegion: IRegion;
  @Input() destinationRegion: IRegion;
  @Input() multiDestination: number;
  @Input() pakType: PakType;
  @Input() indexes: IBtcServiceIndex[] = [];
  @Input() isLoading = false;
  @Input() regionServiceControl: IRegionServiceControl;
  constructor(
    private drawerService: NzDrawerService,
    private shippingService: BtcShippingService
  ) { }

  ngOnInit(): void {
  }

  service_panel(data: { service: BtcService, content?: BtcServiceContent, presets: IBtcShippingPackagePreset[] }): void {

    const drawer = this.drawerService.create({
      nzTitle: '提交服务',
      nzContent: IndicatorLoadingComponent,
      nzContentParams: {
        title: '提交中, 请稍后...'
      },
      nzPlacement: 'bottom',
      nzClosable: false,
      nzMaskClosable: false,
    });

    this.shippingService.create({
      serviceId: data?.service?.id,
      serviceContentId: data?.content?.id,
      destinationRegionCode: this.destinationRegion?.isoCode2
    }, data.presets).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        drawer.close();
        if (res.error) {
          this.error_panel(res.error);
        }
      }
    });

  }

  private error_panel(error: IHttpResponseError): void {
    const drawer = this.drawerService.create({
      nzTitle: '提交失败',
      nzContent: IndicatorAlertComponent,
      nzContentParams: {
        message: error.message,
        problems: error.problems,
        type: 'error',
        drawer: true
      },
      nzHeight: 'auto',
      nzPlacement: 'bottom',
      nzClosable: false,
      nzMaskClosable: false,
    });

  }


}
