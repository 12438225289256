import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, map, Observable } from 'rxjs';
import { IHttpResponse, IHttpResponseError } from 'src/app/common/interfaces/http';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { FileType } from 'src/app/helper/file-helper/interfaces';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  #endPoint: string;

  constructor(
    private http: HttpClient,
    private msgService: NzMessageService
  ) {
    this.#endPoint = environment.coreEndPoint;
  }

  public get(
    path: string,
    options?: any
  ): Observable<any> {
    const url = path.includes('http') ? path : `${this.#endPoint}/${path}`;
    return this.http
      .get(
        url,
        options
      );
  }

  public post(
    path: string,
    body: {},
    options?: any
  ): Observable<any> {
    const url = path.includes('http') ? path : `${this.#endPoint}/${path}`;
    return this.http
      .post(
        url,
        body,
        options
      );
  }

  public put(
    path: string,
    body: {},
    options?: any
  ): Observable<any> {
    const url = path.includes('http') ? path : `${this.#endPoint}/${path}`;
    return this.http
      .put(
        url,
        body,
        options
      );
  }


  public delete(
    path: string,
    options?: any
  ): Observable<any> {
    const url = path.includes('http') ? path : `${this.#endPoint}/${path}`;
    return this.http
      .delete(
        url,
        options
      );
  }

  public errorHandler(): any {
    return (res: IHttpResponse) => {
      if (res.error) {
        const message = _.get(res.error, ['message'], 'Unknown error');
        this.msgService.error(message);
        const problems = _.get(res.error, ['problems'], []);

        if (problems.length > 0) {
          for (const problem of problems) {
            this.msgService.error(problem);
          }
        }

        return of(null);
      } else {
        if (res.result) {
          return of(res.result);
        } else {
          return of(true);
        }
      }
    };
  }

  catch(): (res: IHttpResponse) => Observable<IHttpResponse> {
    return (res: IHttpResponse) => of(res);
  }

  error_msg_handler(): (res: IHttpResponse) => void {
    return (res: IHttpResponse | HttpErrorResponse) => {
      if ('ok' in res) {
        if (!res.status) {
          if (navigator.onLine) {
            this.msgService.create('error', `API地址: ${res.url} 不可用`);
          } else {
            this.msgService.create('error', `网络已离线`);
          }
        }
      } else {
        const { error } = res;
        if (error && error.message) {
          this.msgService.create('error', error.message);
        }
        if (error && error.problems) {
          for (const problem of error.problems) {
            this.msgService.create('error', JSON.stringify(problem));
          }
        }
      }
    };
  }

  msg_handler(type: 'success' | 'info' | 'warning' | 'error' | 'loading' | string = 'info'): (res: IHttpResponse) => void {
    return (res: IHttpResponse | HttpErrorResponse) => {
      if (!('ok' in res)) {
        if (res && res.message) {
          this.msgService.create(type, res.message);
        }
      }
    };
  }

  catch_blob_error(): (res: Blob) => Observable<IHttpResponse> {
    return (res: Blob) => {
      const data = res as unknown;
      const blob = data as Blob;
      if (blob.type === FileType.JSON) {
        const response = from(blob.text()).pipe(map((d) => {
          if (typeof d === 'string') {
            return JSON.parse(d);
          } else {
            return d;
          }
        }));
        return response;
      } else {
        return of(res);
      }
    };

  }
}
