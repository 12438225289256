import { Pipe, PipeTransform } from '@angular/core';
import { includes } from 'lodash';
@Pipe({
  name: 'activatedRoute'
})
export class ActivatedRoutePipe implements PipeTransform {

  transform(currentUrl: string, ...args: (string | string[])[]): boolean {
    if (!args[0]) {
      throw new Error('url undefined');
    }


    if (typeof args[0] === 'string') {
      const url = args[0];
      return includes(currentUrl, url);
    }

    if (Array.isArray(args[0]) && args[0].every(v => typeof v === 'string')) {
      for (const url of args[0]) {
        if (includes(currentUrl, url)) {
          return true;
        }
      }
    }



    return false;
  }

}
