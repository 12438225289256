import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { MomentModule } from 'ngx-moment';
import { UnitModule } from 'src/app/shared/unit/unit.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { GroupFormComponent } from './group-form/group-form.component';
import { GroupFormMultiComponent } from './group-form-multi/group-form-multi.component';
import { GroupFormSingleComponent } from './group-form-single/group-form-single.component';
import { ServiceSharedModule } from 'src/app/shared/service';
import { NzCardModule } from 'ng-zorro-antd/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { LayoutModule } from 'src/app/shared/layout';
import { GroupHeaderComponent } from './group-header/group-header.component';
import { RouterModule } from '@angular/router';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { GroupHeaderSubmitComponent } from './group-header-submit/group-header-submit.component';
import { GroupHeaderListComponent } from './group-header-list/group-header-list.component';
import { GroupHeaderCreateComponent } from './group-header-create/group-header-create.component';
import { GroupSearchFormComponent } from './group-search-form/group-search-form.component';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { RegionSharedModule } from 'src/app/shared/region';

@NgModule({
  declarations: [
    GroupDetailComponent,
    GroupFormComponent,
    GroupFormMultiComponent,
    GroupFormSingleComponent,
    GroupHeaderComponent,
    GroupHeaderSubmitComponent,
    GroupHeaderListComponent,
    GroupHeaderCreateComponent,
    GroupSearchFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzCheckboxModule,
    MomentModule,
    RouterModule,
    UnitModule,
    NzTagModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzTypographyModule,
    NzI18nModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
    NzCardModule,
    NzDrawerModule,
    NzAvatarModule,
    NzPipesModule,
    ServiceSharedModule,
    IndicatorSharedModule,
    LayoutModule,
    RegionSharedModule
  ],
  exports: [
    GroupDetailComponent,
    GroupFormComponent,
    GroupFormMultiComponent,
    GroupFormSingleComponent,
    GroupHeaderComponent,
    GroupHeaderSubmitComponent,
    GroupHeaderListComponent,
    GroupHeaderCreateComponent,
    GroupSearchFormComponent
  ]
})
export class ShippingGroupSharedModule { }
