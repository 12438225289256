import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashbackNamePipe } from './pipes/cashback-name/cashback-name.pipe';
import { CashbackLinkPipe } from './pipes/cashback-link/cashback-link.pipe';



@NgModule({
  declarations: [
    CashbackNamePipe,
    CashbackLinkPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CashbackNamePipe,
    CashbackLinkPipe
  ]
})
export class CashbackSharedModule { }
