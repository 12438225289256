import { PickupStateCode, PickupStateProps } from '../../interfaces/pickup';

export const PICKUP_STATES: PickupStateProps = {
    pending: {
        text: '未提交',
        code: PickupStateCode.pending,
        query: {
            submitted: 0
        }
    },
    submitted: {
        text: '已确认',
        code: PickupStateCode.submitted,
        query: {
            submitted: 1
        }
    },
    submittedPending: {
        text: '提交处理中',
        code: PickupStateCode.submittedPending,
        query: {
            submitted: 2
        }
    },

    failed: {
        text: '预约失败',
        code: PickupStateCode.failed,
        query: {
            submitted: 4
        }
    },

    voided: {
        text: '已取消',
        code: PickupStateCode.voided,
        query: {
            voided: 1
        }
    },
    voidedPending: {
        text: '取消申请处理中',
        code: PickupStateCode.voidedPending,
        query: {
            voided: 2
        }
    },
    voidedFailed: {
        text: '取消失败',
        code: PickupStateCode.voidedFailed,
        query: {
            voided: 4
        }
    },

};
