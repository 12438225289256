import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationService } from 'src/app/core/navigation';
import { PageHeaderService } from 'src/app/core/page-header';

@Component({
  selector: 'group-header-create',
  templateUrl: './group-header-create.component.html',
  styleUrls: ['./group-header-create.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupHeaderCreateComponent implements OnInit, AfterViewInit {
  @ViewChild('buttonsTpl') buttonsTpl: TemplateRef<any>;
  constructor(
    public ph: PageHeaderService,
    public nav: NavigationService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.ph.buttons = this.buttonsTpl;
  }


}
