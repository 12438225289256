import { Pipe, PipeTransform } from '@angular/core';

import { NzTimelineItemColor } from 'ng-zorro-antd/timeline';
import { KuaiDi100StateCode } from '../interfaces';

@Pipe({
  name: 'stepColor'
})
export class StepColorPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): NzTimelineItemColor {
    const state = value as KuaiDi100StateCode;
    let color: NzTimelineItemColor = 'grey';


    if (['4', '6', '13', '14', '401'].includes(state.toString())) {
      color = 'red';
    }

    if (['2', '201', '202', '203', '204', '205', '206', '207', '208', '209'].includes(state.toString())) {
      color = 'red';
    }


    if (['3', '301', '302', '303', '304'].includes(state.toString())) {
      color = 'green';
    }

    if (['1', '101', '102', '103'].includes(state.toString())) {
      color = 'blue';
    }

    if (['0', '5', '7', '1001', '1002', '1003'].includes(state.toString())) {
      color = 'blue';
    }

    if (['8', '10', '11', '12'].includes(state.toString())) {
      color = 'blue';
    }

    return color;
  }

}
