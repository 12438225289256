import { SERVICE_TICKET_TYPES } from '../../constants/ticket';
import { ITag } from '../../interfaces/common';
import { IBtcServiceTicket, ServiceTicketAuthorCode, ServiceTicketType } from '../../interfaces/ticket';

export class BtcServiceTicket {
    private _data: IBtcServiceTicket;
    constructor(data: IBtcServiceTicket) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id;
    }

    get number(): string {
        return this._data?.ticketNumber;
    }

    get orderNumber(): string {
        return this._data?.orderNumber;
    }

    get shippingNumber(): string {
        return this._data?.shippingNumber;
    }

    get status(): ITag[] {

        const items = [];

        const open = this.status_parse('status', 2, ['red', '需要处理']);
        const pending = this.status_parse('status', 1, ['orange', '客服处理中']);
        const completed = this.status_parse('status', 3, ['default', '已完结']);

        if (open) {
            items.push(open);
        }

        if (completed) {
            items.push(completed);
        }

        if (pending) {
            items.push(pending);
        }

        return items;

    }

    get content(): string {
        return this._data?.content;
    }

    get author(): string {
        return this._data.author === ServiceTicketAuthorCode.user ? '用户' : '优邮客服';
    }

    get avatar(): any {
        if (this._data.author === ServiceTicketAuthorCode.user) {
            return {
                text: '我',
                style: 'background-color: #3d3d3d'
            };
        }

        if (this._data.author === ServiceTicketAuthorCode.admin) {
            return {
                src: 'favicon.ico',
            };
        }
    }

    get type(): string {
        return SERVICE_TICKET_TYPES[this._data?.type]?.name ?? null;
    }

    get livemode(): boolean {
        return this._data?.livemode ? true : false;
    }

    get version(): number {
        return this._data?.version;
    }

    get createdAt(): Date {
        return this._data?.createdAt ?? null;
    }

    get updatedAt(): Date {
        return this._data?.updatedAt ?? null;
    }

    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {

        if (prop in this._data) {

            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }
}
