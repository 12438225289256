import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { NzButtonShape, NzButtonSize, NzButtonType } from 'ng-zorro-antd/button';

@Component({
  selector: 'show-all',
  templateUrl: './show-all.component.html',
  styleUrls: ['./show-all.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowAllComponent implements OnInit {
  @Output() event = new EventEmitter<never>();
  @Input() text = '查看详情';
  @Input() show = true;
  @Input() icon = 'down-square';
  @Input() shape: NzButtonShape = null;
  @Input() danger = false;
  @Input() type: NzButtonType = 'link';
  @Input() size: NzButtonSize = 'default';
  constructor() { }

  ngOnInit(): void {
  }

}
