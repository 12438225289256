import { carriers } from '../../constants/carrier';
import { Carrier, CarrierPropItem } from '../../interfaces/carrier';
import { ITag } from '../../interfaces/common';
import { IBtcPickup, IBtcPickupDate } from '../../interfaces/pickup';
import { status } from '../fn';
import { BtcShippingAddress, BtcShippingWaybill } from '../shipping';

export class BTCPickup {
    private _data: IBtcPickup;
    private _avatar: string;
    constructor(data: IBtcPickup) {
        this._data = data;
        this.avatar = data.waybillCarrierCode;
    }

    get id(): string {
        return this._data.id;
    }

    get status(): ITag[] {

        const items = [];
        const pending = status(this._data, 'submitted', 0, ['default', '未提交']);
        const submitted = status(this._data, 'submitted', 1, ['blue', '已确认']);
        const submittedUpdated = status(this._data, 'submitted', 3, ['orange', '已修正, 需重新提交']);
        const submittedPending = status(this._data, 'submitted', 2, ['orange', '处理中']);
        const submittedFailed = status(this._data, 'submitted', 4, ['red', '提交失败']);
        const voided = status(this._data, 'voided', 1, ['#cccccc', '已取消']);
        const voidedPending = status(this._data, 'voided', 2, ['orange', '处理中']);
        const voidedFailed = status(this._data, 'voided', 4, ['red', '取消失败']);


        if (pending) {
            items.push(pending);
        }

        if (submittedPending) {
            items.push(submittedPending);
        }

        if (submitted) {
            items.push(submitted);
        }

        if (submittedFailed) {
            items.push(submittedFailed);
        }

        if (submittedUpdated) {
            items.push(submittedUpdated);
        }

        if (voided) {
            items.push(voided);
        }

        if (voidedPending) {
            items.push(voidedPending);
        }

        if (voidedFailed) {
            items.push(voidedFailed);
        }


        return items;

    }

    get pending(): boolean {
        return this._data.submitted === 0 ? true : false;
    }

    get submitted(): boolean {
        return this._data.submitted === 1 ? true : false;
    }

    get submittedPending(): boolean {
        return this._data.submitted === 2 ? true : false;
    }

    get submittedFailed(): boolean {
        return this._data.submitted === 4 ? true : false;
    }

    get voided(): boolean {
        return this._data.voided === 1 ? true : false;
    }

    get voidedPending(): boolean {
        return this._data.voided === 2 ? true : false;
    }

    get voidedFailed(): boolean {
        return this._data.voided === 4 ? true : false;
    }

    get instructions(): string {
        return this._data?.instructions;
    }

    get carrierName(): string {
        return this.carrier?.name;
    }

    get carrier(): CarrierPropItem<Carrier> {
        const carrier = carriers[this.carrierCode];
        return carrier;
    }

    get carrierCode(): Carrier {
        return this._data?.waybillCarrierCode;
    }

    get createdAt(): Date {
        return this._data.createdAt;
    }

    get updatedAt(): Date {
        return this._data.updatedAt;
    }


    get location(): BtcShippingAddress {
        return this._data.location ? new BtcShippingAddress(this._data.location) : null;
    }

    get qty(): number {
        return this._data.qty;
    }

    get date(): IBtcPickupDate {
        return this._data.date;
    }

    get email(): string {
        return this._data.email;
    }

    get avatar(): string {
        return this._avatar;
    }

    set avatar(carrierCode: string) {
        this._avatar = `/assets/img/carrier/${carrierCode}.png`;
    }

    get waybills(): BtcShippingWaybill[] {
        return this._data.waybills ? this._data.waybills.map(item => new BtcShippingWaybill(item)) : [];
    }

    get version(): number {
        return this._data?.version;
    }
}

