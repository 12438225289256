import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { IRegion, IRegionListItem, ISOCode2 } from 'src/app/common/interfaces/region';
import { RegionService } from 'src/app/core/region';

@Component({
  selector: 'region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionSelectorComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  @Input() regionCode: ISOCode2;
  @Input() type: 0 | 1; // 0 sender, 1 recipient
  @Input() validateForm: UntypedFormGroup;
  @Input() placeholder = '请选择国家/地区...';
  @Input() label = '国家/地区';
  @Input() allowClear = true;
  @Input() allowDefault = true;
  @Output() regionChange = new EventEmitter<IRegion>();
  regionList: IRegionListItem[] = [];

  constructor(
    private detection: ChangeDetectorRef,
    private regionService: RegionService,
  ) { }

  ngOnInit(): void {
    const body = { ...{ limit: 100 }, ...{ regionCode: this.regionCode } };
    this.region_get(this.type, body);
  }

  private region_get(type: 0 | 1, body: any): void {
    this.isLoading = true;
    this.regionService.search(type, body).pipe(
      take(1)
    )
      .subscribe({
        next: res => {
          this.regionList = res?.result ?? [];
          let region = this.allowDefault ? this.regionList[0]?.value : null;
          if (this.regionCode) {
            region = this.regionList.find(({ value }) => value.isoCode2 === this.regionCode.toUpperCase())?.value;
          }

          this.validateForm.patchValue({
            region
          });
          this.isLoading = false;
        }
      });
  }

}
