import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BTCShippingItem } from 'src/app/common/models/shipping';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BtcShippingItemService {
  #coreEndpoint: string;
  constructor(
    private http: HttpService,
  ) {
    this.#coreEndpoint = environment.coreEndPoint;
  }


  search(body: any, id?: string): Observable<IHttpResponse> {
    const path = id ? `${this.#coreEndpoint}/b2c/shipping/${id}/goods/search` : `${this.#coreEndpoint}/b2c/shipping/goods/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result && Array.isArray(result)) {
          res.result = result.map(item => new BTCShippingItem(item));
        }
        return res;
      }),
    );
  }

  detail(packageId: string, itemId: string): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/b2c/shipping/${packageId}/goods/${itemId}/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BTCShippingItem(res.result);
        }
        return res;
      }),
    );
  }

  add(body: any, packageId: string): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/b2c/shipping/${packageId}/goods/add`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
    );
  }


  update(body: any, packageId: string, itemId: string): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/b2c/shipping/${packageId}/goods/${itemId}/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler())
    );
  }

  delete(packageId: string, itemId: string): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/b2c/shipping/${packageId}/goods/${itemId}/delete`;
    return this.http.delete(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler())
    );
  }

  validate(body: any, shippingId: string): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/b2c/shipping/${shippingId}/goods/validate`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch())
    );
  }
}
