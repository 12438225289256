import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'index-selector',
  templateUrl: './index-selector.component.html',
  styleUrls: ['./index-selector.component.less']
})
export class IndexSelectorComponent implements OnInit {
  @Input() isLoading = false;
  private _recordList: any[] = [];
  @Input() set recordList(value: any[]) {
    if (value !== this._recordList) {
      this._recordList = value;
      this.check();
    }
  }
  get recordList(): any[] {
    return this._recordList;
  }

  @Output() ngModel = new EventEmitter<any>();

  validateForm = new UntypedFormGroup({
    index: this.fb.control(null)
  });

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
  }

  trackByIndex = (i: number, _: any): number => {
    return i;
  }
  select(index: any): void {
  }

  on_change($event): void {
    const { index } = this.validateForm.value;
    this.ngModel.emit(index);
  }

  check(): void {
    const [index] = this.recordList;
    this.validateForm.patchValue({
      index: index ? index : null
    });
    this.on_change(index);
  }
}
