import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { PATTERNS } from 'src/app/common/constants/pattern';
import { IHttpResponseError } from 'src/app/common/interfaces/http';

import { BtcShippingService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';
import { Package } from 'src/app/core/package/models';

@Component({
  selector: 'reference-edit',
  templateUrl: './reference-edit.component.html',
  styleUrls: ['./reference-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferenceEditComponent implements OnInit {

  @Input() drawer = false;

  @Input() key: string;
  @Input() set shipping(value: Package) {
    if (value) {
      const { version, customReference, customMemo } = value;
      this.validateForm.patchValue({
        version,
        customReference,
        customMemo
      });
    }
  }

  validateForm = new UntypedFormGroup({
    version: this.fb.control(null, [Validators.required]),
    customReference: this.fb.control(null, [Validators.maxLength(30), Validators.pattern(PATTERNS.alphabet_number.pattern)]),
    customMemo: this.fb.control(null, [Validators.maxLength(128)]),
  });

  error: IHttpResponseError;
  isLoading = false;

  @Output() MaskClosable = new EventEmitter<boolean>();
  @Output() Close = new EventEmitter<any>();


  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private shippingService: BtcShippingService,
    private drawerRef: NzDrawerRef,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.build();
  }

  on_submit(): void {

    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm, true);
    }

    if (this.validateForm.valid) {
      const { value } = this.validateForm;
      this.update(this.key, value);
    }

  }


  private update(key: string, body: any) {
    this.isLoading = true;
    this.error = null;
    this.maskClosable(false);
    this.shippingService.memo_update(key, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        const { error } = res;
        this.error = error;
        if (res.result) {
          this.close(res.result);
        }
        this.detection.detectChanges();
      }
    });
  }

  private build(): void {

    this.validateForm.patchValue({

    });

  }


  error_reset(): void {
    this.maskClosable(true);
    this.error = null;
  }


  private maskClosable(state: boolean) {
    if (this.drawer) {
      this.drawerRef.nzClosable = state;
      this.drawerRef.nzMaskClosable = state;
    } else {
      this.MaskClosable.emit(state);
    }
  }

  private close(data: any) {
    if (this.drawer) {
      this.drawerRef.close(data);
    } else {
      this.Close.emit(data);
    }
  }
}
