import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { take } from 'rxjs/operators';
import { ISOCode2 } from 'src/app/common/interfaces/region';
import { BTCAddress, Recipient, Sender } from 'src/app/common/models/address';
import { AddressService } from 'src/app/core/address';

@Component({
  selector: 'address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSelectorComponent implements OnInit {
  private drawerRef: NzDrawerRef;
  private _regionCode: ISOCode2;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  validateForm = new UntypedFormGroup({
    keywords: this.fb.control(null, [Validators.minLength(1)]),
  });

  count = 0;
  queryParams = {
    page: 1,
    skip: 0,
    limit: 10,
    sort: []
  };

  recordList: BTCAddress[] = [];

  initiations: Sender[] = [];
  destinations: Recipient[] = [];

  @Input() drawer = true;
  @Input() type: 0 | 1;
  @Input()
  set regionCode(code: ISOCode2) {
    this._regionCode = code;
  }
  get regionCode(): ISOCode2 {
    return this._regionCode;
  }
  @Input() addressChange: EventEmitter<BTCAddress>;
  // @Output() addressChange = new EventEmitter<BTCAddress>();

  constructor(
    private detection: ChangeDetectorRef,
    private injector: Injector,
    private addressService: AddressService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
  }

  page_index_change(i: number): void {
    this.queryParams.skip = (i - 1) * this.queryParams.limit;
    this.on_submit();
  }

  page_size_change(size: number): void {
    this.queryParams.limit = size;
    this.queryParams.skip = 0;
    this.queryParams.page = 1;
    this.on_submit();
  }

  on_params_change(params: NzTableQueryParams): void {
    const { sort, filter } = params;

    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    if (sortField && sortOrder) {
      this.validateForm.patchValue({
        sort: sortOrder === 'ascend' ? [`${sortField}:asc`] : [`${sortField}:desc`]
      });
    }
    this.on_submit();
  }

  keyword_reset() {
    this.validateForm.patchValue({
      keywords: null
    });
    this.on_submit();
  }


  on_submit(): void {
    const { value } = this.validateForm;
    const body = {
      ...value,
      ...{
        regionCode: this.regionCode
      },
      ...this.queryParams
    };
    if (this.type) {
      this.destination_search(body);
    } else {
      this.initiation_search(body);
    }
  }

  private initiation_search(body: any): void {
    this.isLoading = true;
    this.addressService.sender_search(body).pipe(take(1)).subscribe({
      next: (res) => {
        const { count, result } = res;
        this.recordList = result;
        this.count = count;
        this.isLoading = false;
      }
    });
  }

  private destination_search(body: any): void {
    this.isLoading = true;
    this.addressService.recipient_search(body).pipe(take(1)).subscribe({
      next: (res) => {
        const { count, result } = res;
        this.recordList = result;
        this.count = count;
        this.isLoading = false;
      }
    });
  }

  select(i) {
    const address = this.recordList[i];
    this.addressChange.emit(address);
    // if (this.drawer) {
    //   this.drawerRef = this.injector.get(NzDrawerRef);
    //   this.drawerRef.close(address);
    // } else {

    // }
  }

  trackByIndex = (i: number, _: any): number => {
    return i;
  }
}
