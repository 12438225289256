import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, zip } from 'rxjs';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage';
import { USER_ACCOUNT_ACCESS_TOKEN, USER_AUTH_REFRESH_TOKEN } from 'src/app/common/constants/storage';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Account } from 'src/app/common/models/auth/account.model';
import { StateService } from '../state';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthCoreService {
  #accessToken$ = new BehaviorSubject<any>(null);
  public accessToken$ = this.#accessToken$.asObservable();
  #refreshToken$ = new BehaviorSubject<any>(null);
  public refreshToken$ = this.#refreshToken$.asObservable();
  #authUser$ = new BehaviorSubject<Account>(new Account());
  public authUser$ = this.#authUser$.asObservable();
  #endPoint: string;
  email$ = new BehaviorSubject<string | null>(null);

  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  constructor(
    private storageService: StorageService,
    private stateService: StateService,
    private http: HttpService,
    private message: NzMessageService
  ) {
    this.#endPoint = environment.authEndPoint;
  }

  watchUser(): Observable<Account> {
    return this.authUser$;
  }


  access_token_init(): Observable<Account> {
    return this.storageService.get(USER_ACCOUNT_ACCESS_TOKEN).pipe(
      take(1),
      switchMap(token => {
        this.accessToken = token;
        if (token) {
          return this.auth_user_get().pipe(take(1));
        } else {
          return of(new Account());
        }

      }),
    );
  }

  set email(value: string | null) {
    this.email$.next(value);
  }

  get email(): string | null {
    return this.email$.value;
  }

  set loading(value: boolean) {
    this.#loading$.next(value);
  }

  private access_token_save(token: string): Observable<boolean> {
    return this.storageService.set(USER_ACCOUNT_ACCESS_TOKEN, token).pipe(
      take(1),
    );
  }

  private refresh_token_save(token: string): Observable<boolean> {
    return this.storageService.set(USER_AUTH_REFRESH_TOKEN, token).pipe(
      take(1),
    );
  }

  private set accessToken(value: string) {
    this.#accessToken$.next(value);
    this.access_token_save(value).pipe(take(1)).subscribe();
  }

  private set refreshToken(value: string) {
    this.#refreshToken$.next(value);
    this.refresh_token_save(value).pipe(take(1)).subscribe();
  }

  private set authUser(value: Account) {
    this.email = value?.account?.email;
    this.#authUser$.next(value);
  }

  // auth_user_get(): Observable<Account> {
  //   const path = `account/profile`;
  //   return this.http.get(path).pipe(
  //     catchError(this.http.catch()),
  //     tap(this.http.error_msg_handler()),
  //     map((res: IHttpResponse) => {
  //       const account = new Account(res?.result);
  //       this.authUser = account;
  //       return account;
  //     }),
  //   );
  // }

  auth_user_get(): Observable<Account> {
    const path = `member/profile`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      map((res: IHttpResponse) => {
        const account = new Account(res?.result);
        this.authUser = account;
        return account;
      }),
    );
  }

  verify_email_send() {
    const path = `account/auth/verify/email/send`;
    return this.http.post(path, null).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  refer_url_get(): Observable<IHttpResponse> {
    const path = `account/refer/url/build`;
    return this.http.get(path).pipe(
      catchError(this.http.catch())
    );
  }

  password_token_get(): Observable<IHttpResponse> {
    const path = 'account/auth/password/token/retrieve';
    return this.http.post(path, null).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler())
    );
  }

  password_reset(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/password/reset/token`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      switchMap((res) => {
        if (res?.success) {
          return this.sign_out();
        } else {
          return of(res);
        }
      })
    );
  }

  password_recover_email(body: any) {
    const path = `${this.#endPoint}/password/forgot/token`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler())
    );
  }

  password_update(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/password`;
    return this.http.put(path, body).pipe(
      take(1),
      catchError(this.http.catch()),

    );
  }

  sign_up_email(body: any): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#endPoint}/sign-up/email`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      tap(() => this.loading = false)
    );
  }

  sign_up_email_check(body: { email: string }): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#endPoint}/email/check`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      tap(() => this.loading = false)
    );
  }

  email_verify_code(body: { email: string, code: string }): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#endPoint}/email/verify/code`;
    return this.http.post(path, body).pipe(

      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap(() => this.loading = false)
    );
  }

  email_verify_code_send(): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#endPoint}/email/verify/code/send`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap(() => this.loading = false)
    );
  }


  sign_in_email(body: any): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#endPoint}/sign-in`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap(() => this.loading = false),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          const { accessToken, refreshToken, account } = result;
          this.accessToken = accessToken;
          this.refreshToken = refreshToken;
          this.authUser = new Account(account);
        }
        return res;
      }),
    );
  }

  sign_out(): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/sign-out`;
    return this.http.post(path, null).pipe(
      take(1),
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      tap((res: IHttpResponse) => {
        if (res?.success) {
          return this.destroy();
        }
      }),
    );
  }

  send_invitation(body: any): Observable<IHttpResponse> {
    const path = `account/refer/url/send`;
    return this.http.post(path, body).pipe(
      take(1),
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
    );
  }

  destroy(): Observable<boolean> {
    this.accessToken = null;
    this.refreshToken = null;
    this.authUser = new Account();
    this.stateService.livemode = true;

    return of(true);
  }
}


