<ng-container *ngIf="regionServiceControl">
  <ng-container
    *ngIf="multiDestination; then MultiTpl; else SingleTpl"
  ></ng-container>
  <ng-template #MultiTpl>
    <group-form-multi
      [page]="page"
      [indexes]="indexes"
      [initiationRegion]="initiationRegion"
      [destinationRegion]="destinationRegion"
      [regionServiceControl]="regionServiceControl"
      [pakType]="pakType"
      (serviceChange)="service_panel($event)"
    ></group-form-multi>
  </ng-template>
  <ng-template #SingleTpl>
    <group-form-single
      [page]="page"
      [indexes]="indexes"
      [isLoading]="isLoading"
      [regionServiceControl]="regionServiceControl"
      [initiationRegion]="initiationRegion"
      [destinationRegion]="destinationRegion"
      [pakType]="pakType"
      (serviceChange)="service_panel($event)"
    ></group-form-single>
  </ng-template>
</ng-container>
