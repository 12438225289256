import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { IHttpResponse } from "src/app/common/interfaces/http";
import { IPickupTimetable } from "src/app/common/interfaces/pickup";
import { BTCPickup } from "src/app/common/models/pickup";
import { environment } from "src/environments/environment";
import { HttpService } from "../http";

@Injectable({
  providedIn: "root",
})
export class BtcPickupService {
  #endPoint: string;
  constructor(private http: HttpService) {
    this.#endPoint = environment.coreEndPoint;
  }

  search(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = res.result.map((item) => new BTCPickup(item));
        }
        return res;
      })
    );
  }

  detail(id: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/${id}/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BTCPickup(res.result);
        }
        return res;
      })
    );
  }

  create(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/create`;
    return this.http
      .post(path, body)
      .pipe(catchError(this.http.catch()), tap(this.http.msg_handler()));
  }

  update(id: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/${id}/update`;
    return this.http
      .put(path, body)
      .pipe(catchError(this.http.catch()), tap(this.http.msg_handler()));
  }

  submit(id: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/${id}/submit`;
    return this.http
      .post(path, body)
      .pipe(catchError(this.http.catch()), tap(this.http.msg_handler()));
  }

  delete(id: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/${id}/delete`;
    return this.http
      .delete(path, {})
      .pipe(catchError(this.http.catch()), tap(this.http.msg_handler()));
  }

  void(id: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/${id}/void`;
    return this.http
      .post(path, body)
      .pipe(catchError(this.http.catch()), tap(this.http.msg_handler()));
  }

  timetable(body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/pickup/timetable`;
    return this.http.post(path, body).pipe(
      tap(this.http.error_msg_handler()),
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const records = (res.result as IPickupTimetable[]) ?? [];

        res.result = records.map((item) => {
          const option = {
            label: item.label,
            value: item,
          };
          return option;
        });

        return res;
      })
    );
  }
}
