import { Injectable } from '@angular/core';
import { interval, of } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Notice } from 'src/app/common/models/auth/notice.model';
import { HttpService } from '../http';
import * as _ from 'lodash';
import { IHttpResponse } from '../http/interfaces';
@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  count$ = new BehaviorSubject<number>(0);
  constructor(
    private http: HttpService,
  ) {

  }

  notice_polling(timer: number) {
    const task = interval(timer).pipe(
      startWith(0),
      switchMap(() => {
        return this.count_sync({ read: false });
      })
    );
    return task;
  }


  count_sync(body?: any): Observable<number> {
    const path = 'member/notice/count';
    return this.http.post(path, body).pipe(
      switchMap((res: IHttpResponse) => {
        const { count } = res;
        return this.count_update(count);
      }),
      catchError(() => of(0))
    );
  }

  private count_update(num: number = 0): Observable<number> {
    this.count$.next(num);
    return of(num);
  }

  search(body?: any): Observable<{ count: number, rows: Notice[] }> {
    const path = 'member/notice/search';
    return this.http.post(path, body).pipe(
      map((res) => {
        res.rows = _.map(res && res.rows, row => new Notice(row));
        return res;
      }),
      catchError(() => of({ count: 0, rows: [] }))
    );
  }

  mark_as_read(id: string, body?: any): Observable<boolean> {
    const path = `member/notice/update/${id}`;
    return this.http.put(path, body).pipe(
      tap(this.http.msg_handler()),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  mark_as_delete(id: string, body?: any): Observable<boolean> {
    const path = `member/notice/delete/${id}`;
    return this.http.put(path, body).pipe(
      tap(this.http.msg_handler()),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
