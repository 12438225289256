import { ITag } from '../../interfaces/common';
import { IBtcOrder } from '../../interfaces/order';
import { IBonus, IBonusCashback } from '../../interfaces/rate/bonus';
import { BTCPayment } from '../payment';

export class BtcOrder {

    private _data: IBtcOrder;

    constructor(data: IBtcOrder) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id;
    }


    get type(): number {
        return this._data?.type;
    }

    get originId(): string | null {
        return this._data.origin ?? null;
    }


    get orderNumber(): string {
        return this._data?.orderNumber ?? '未知';
    }

    get createdAt(): string {
        return this._data?.createdAt ?? null;
    }

    get updatedAt(): string {
        return this._data?.updatedAt ?? null;
    }



    get paidAt(): Date {
        return this._data?.paidAt ?? null;
    }

    get invoicedAt(): Date {
        return this._data?.invoicedAt ?? null;
    }

    get voidedAt(): Date {
        return this._data?.voidedAt ?? null;
    }

    get refundedAt(): Date {
        return this._data?.refundedAt ?? null;
    }

    get status(): ITag[] {

        const items = [];
        let paid = null;
        if (this.paid) {
            paid = this.status_parse('paid', 1, ['cyan', '已支付'], ['default', '未支付']);
        }

        if (!this.paid) {
            paid = this.payment?.paid ? {
                color: 'orange',
                text: '支付处理中',
            } : {
                color: 'default',
                text: '未支付',
            };
        }


        items.push(paid);

        const invoiced = this.status_parse('invoiced', 1, ['default', '发票已生成']);
        const voided = this.status_parse('voided', 1, ['default', '已取消']);
        const voidFailed = this.status_parse('voided', 4, ['red', '取消失败']);
        const voidPending = this.status_parse('voided', 2, ['orange', '取消申请处理中']);
        const refunded = this.status_parse('refunded', 1, ['default', '全额退款']);
        const refundedPartial = this.status_parse('refunded', 3, ['default', '部分退款']);
        const refundPending = this.status_parse('refunded', 2, ['orange', '退款受理中']);
        const refundFailed = this.status_parse('refunded', 4, ['red', '退款失败']);

        if (invoiced && !voided) {
            items.push(invoiced);
        }

        if (voidFailed) {
            items.push(voidFailed);
        }

        if (voidPending) {
            items.push(voidPending);
        }
        if (voided) {
            items.push(voided);
        }
        if (refundPending) {
            items.push(refundPending);
        }
        if (refundedPartial && !refunded) {
            items.push(refundedPartial);
        }
        if (refunded) {
            items.push(refunded);
        }
        if (refundFailed) {
            items.push(refundFailed);
        }
        return items;

    }

    get refundPending(): boolean {
        return this._data?.refunded === 2 ? true : false;
    }

    get refunded(): boolean {
        return this._data?.refunded ? true : false;
    }

    get refundedPartial(): boolean {
        return this._data.refunded === 3 ? true : false;
    }

    get voidPending(): boolean {
        return this._data?.voided === 2 ? true : false;
    }

    get voided(): boolean {
        return this._data?.voided === 1 ? true : false;
    }

    get paid(): boolean {
        return this._data?.paid ? true : false;
    }

    get invoiced(): boolean {
        return this._data?.invoiced ? true : false;
    }


    get due(): number {
        return this._data?.due ?? 0;
    }

    get base(): number {
        return this._data?.base ?? 0;
    }

    get basePlus(): number {
        return this._data?.basePlus ?? 0;
    }

    get tax(): number {
        return this._data?.tax ?? 0;
    }

    get discount(): number {
        return this._data?.discount ?? 0;
    }

    get refundedAmount(): number {
        return this._data?.refundedAmount ?? 0;
    }

    get currency(): string {
        return this._data?.currency?.toUpperCase() ?? 'CAD';
    }

    get livemode(): boolean {
        return this._data?.livemode ? true : false;
    }

    get payment(): BTCPayment | null {
        if (!this._data.payment) {
            return null;
        }
        if (typeof this._data.payment === 'string') {
            return null;
        }

        return new BTCPayment(this._data.payment);
    }

    get paymentId(): string {
        if (!this._data.payment) {
            return null;
        }
        return typeof this._data.payment === 'string' ? this._data.payment : this.payment?.id;
    }

    get version(): number {
        return this._data.version;
    }

    get cashbackCount(): number {
        return this._data?.cashbackCount ?? 0;
    }

    get cashback(): IBonusCashback {
        return this._data?.cashback;
    }

    get pointCount(): number {
        return this._data?.pointCount ?? 0;
    }

    get point(): IBonus {
        return this._data?.point;
    }

    get supplementNote(): string | null {
        return this._data.supplementNote;
    }
    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {
        if (prop in this._data) {
            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }
}
