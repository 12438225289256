import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'reference-form',
  templateUrl: './reference-form.component.html',
  styleUrls: ['./reference-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferenceFormComponent implements OnInit {


  private _validateForm: UntypedFormGroup;

  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  types = [];

  constructor(


  ) {

  }


  ngOnInit(): void {
  }



}
