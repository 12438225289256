import { Injectable } from '@angular/core';
import { NzDrawerOptions, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { BreakpointHelperService } from '../breakpoint';

import {
  IndicatorAlertComponent,
  IndicatorConfirmComponent,
  IndicatorLoadingComponent
} from './components';

@Injectable({
  providedIn: 'root'
})
export class IndicatorHelperService {
  private _drawerRef: NzDrawerRef | undefined;
  private smallScreen: boolean | undefined;
  constructor(
    private ds: NzDrawerService,
    private breakpointService: BreakpointHelperService
  ) {
    this.breakpoint_watch();
  }

  private breakpoint_watch() {
    this.breakpointService.small$.subscribe({
      next: (small) => {
        this.smallScreen = small;
      }
    });
  }

  get drawerRef(): NzDrawerRef | undefined {
    return this._drawerRef;
  }
  set drawerRef(value: NzDrawerRef | undefined) {
    this.drawerRef?.close();
    this._drawerRef = value;
  }

  confirm(
    options: NzDrawerOptions<IndicatorConfirmComponent, {}>,
    cb: Partial<Record<'afterClose' | 'afterOpen', (data?: any) => void>> = {}): NzDrawerRef<IndicatorConfirmComponent, any> {
    let df: NzDrawerOptions = {
      nzContent: IndicatorConfirmComponent,
      nzPlacement: 'bottom',
      nzContentParams: {},
      nzHeight: this.smallScreen ? '100%' : 'auto',
      nzBodyStyle: {
        'min-height': '250px',
        'max-height': '100%'
      }
    };

    df = { ...df, ...options };
    if (df?.nzContentParams) {
      df.nzContentParams['drawer'] = true;
    }
    this.drawerRef?.close();
    this.drawerRef = this.ds.create(df);

    const { afterClose, afterOpen } = cb;
    if (afterClose) {
      this.drawerRef.afterClose.subscribe(afterClose);
    }

    if (afterOpen) {
      this.drawerRef.afterClose.subscribe(afterOpen);
    }
    return this.drawerRef;
  }

  loading(
    options: NzDrawerOptions<IndicatorLoadingComponent, {}>,
    cb: Partial<Record<'afterClose' | 'afterOpen', (data?: any) => void>> = {}): NzDrawerRef<IndicatorLoadingComponent, any> {
    let df: NzDrawerOptions = {
      nzContent: IndicatorLoadingComponent,
      nzContentParams: {},
      nzPlacement: 'bottom',
      nzHeight: 'auto',
      nzMaskClosable: false,
      nzClosable: false
    };
    df = { ...df, ...options };
    if (df?.nzContentParams) {
      df.nzContentParams['drawer'] = true;
    }
    this.drawerRef?.close();
    this.drawerRef = this.ds.create(df);
    const { afterClose, afterOpen } = cb;
    if (afterClose) {
      this.drawerRef.afterClose.subscribe(afterClose);
    }

    if (afterOpen) {
      this.drawerRef.afterClose.subscribe(afterOpen);
    }

    return this.drawerRef;
  }

  alert(
    options: NzDrawerOptions<IndicatorAlertComponent, {}>,
    cb: Partial<Record<'afterClose' | 'afterOpen', (data?: any) => void>> = {}): NzDrawerRef<IndicatorAlertComponent, any> {
    let df: NzDrawerOptions = {
      nzContent: IndicatorAlertComponent,
      nzContentParams: {},
      nzPlacement: 'bottom',
      nzHeight: this.smallScreen ? '100%' : 'auto',
      nzBodyStyle: {
        'max-height': '100%'
      },
      nzMaskClosable: false,
      nzClosable: false
    };
    df = { ...df, ...options };
    if (df?.nzContentParams) {
      df.nzContentParams['drawer'] = true;
    }
    this.drawerRef?.close();
    this.drawerRef = this.ds.create(df);
    const { afterClose, afterOpen } = cb;
    if (afterClose) {
      this.drawerRef.afterClose.subscribe(afterClose);
    }

    if (afterOpen) {
      this.drawerRef.afterClose.subscribe(afterOpen);
    }
    return this.drawerRef;
  }

}