import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { BrowserTracing } from "@sentry/tracing";



if (environment.production) {
  Sentry.init({
    dsn: "https://45fc3938226b4273904d4b684688aa62@o1204728.ingest.sentry.io/4504820244611072",
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // When using the plugin, either remove the `release`` property here entirely or
    // make sure that the plugin's release option and the Sentry.init()'s release
    // option match exactly.
    // release: "youyou-b2c-web",
    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        tracePropagationTargets: ['*'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  enableProdMode();
  // if (window) {
  //   window.console.log = function () { };
  // }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
