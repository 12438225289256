<nz-card [nzTitle]="titleTpl" nzSize="small" nzType="inner">
  <ng-template #titleTpl>
    <ng-container *ngIf="idcard?.address as address">
      收件人: {{ address?.contactPerson }} {{ address?.contactPersonLocale }}
    </ng-container>
  </ng-template>

  <form
    nzLayout="inline"
    nz-form
    [formGroup]="validateForm"
    (submit)="on_submit()"
  >
    <nz-form-item>
      <nz-form-label nzFor="docnumber" nzRequired>身份证号</nz-form-label>
      <nz-form-control [nzExtra]="validateForm | formError : 'docnumber'">
        <nz-input-group
          nzSearch
          [nzAddOnAfter]="addOnAfter"
          style="width: auto"
        >
          <input
            style="min-width: 210px"
            nz-input
            formControlName="docnumber"
            placeholder="请输入收件人证件号"
          />
        </nz-input-group>
        <ng-template #addOnAfter>
          <button nz-button nzType="primary" nzDanger [nzLoading]="isLoading">
            保存更新
          </button>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>

<ng-container *ngIf="idcard?.recto as recto">
  <nz-card
    nzTitle="正面"
    nzSize="small"
    nzType="inner"
    [nzLoading]="isLoading"
    [nzExtra]="rectoActionTpl"
  >
    <nz-row nzJustify="start" nzGutter="20" nzAlign="middle">
      <nz-col class="preview" (click)="preview(recto.src)">
        <img [src]="recto?.src" alt="" class="h-[80px]" />
      </nz-col>
      <nz-col>
        <ng-container *ngIf="recto">
          文件名:{{ recto.filename }}<br />
          文件类型: {{ recto.filetype }}
        </ng-container>
        <ng-container *ngIf="!recto"> 无 </ng-container>
      </nz-col>
    </nz-row>
  </nz-card>

  <ng-template #rectoActionTpl>
    <ng-container *ngIf="recto?.file">
      <button nz-button nzType="link" (click)="preview(recto?.src)">
        <i nz-icon nzType="file-image" nzTheme="outline"></i>
        查看
      </button>
      <button
        nz-button
        nzType="link"
        nzDanger
        (click)="delete('id_card_recto', recto.id)"
      >
        <i nz-icon nzType="delete" nzTheme="outline"></i>
        <span> 删除正面</span>
      </button>
    </ng-container>
    <ng-container *ngIf="!recto?.file">
      <nz-upload
        [nzData]="rectoData"
        [nzBeforeUpload]="beforeUpload"
        nzName="doc"
        [nzCustomRequest]="upload"
        [nzFileList]="fileList"
      >
        <button nz-button nzType="primary">
          <i nz-icon nzType="upload"></i>
          <span> 上传正面</span>
        </button>
      </nz-upload>
    </ng-container>
  </ng-template>
</ng-container>

<ng-container *ngIf="idcard?.verso as verso">
  <nz-card
    nzTitle="背面"
    nzSize="small"
    nzType="inner"
    [nzExtra]="versoActionTpl"
    [nzLoading]="isLoading"
  >
    <nz-row nzJustify="start" nzGutter="20" nzAlign="middle">
      <nz-col class="preview" (click)="preview(verso.src)">
        <img [src]="verso?.src" alt="" class="h-[80px]" />
      </nz-col>
      <nz-col>
        <ng-container *ngIf="verso">
          文件名:{{ verso.filename }}<br />
          文件类型: {{ verso.filetype }}
        </ng-container>
        <ng-container *ngIf="!verso"> 无 </ng-container>
      </nz-col>
    </nz-row>
  </nz-card>

  <ng-template #versoActionTpl>
    <ng-container *ngIf="verso?.file">
      <button nz-button nzType="link" (click)="preview(verso.src)">
        <i nz-icon nzType="file-image" nzTheme="outline"></i>
        查看
      </button>
      <button
        nz-button
        nzType="link"
        nzDanger
        (click)="delete('id_card_verso', verso.id)"
      >
        <i nz-icon nzType="delete" nzTheme="outline"></i>
        <span> 删除背面</span>
      </button>
    </ng-container>

    <ng-container *ngIf="!verso?.file">
      <nz-upload
        [nzBeforeUpload]="beforeUpload"
        nzName="doc"
        [nzData]="versoData"
        [nzCustomRequest]="upload"
        [nzFileList]="fileList"
      >
        <button nz-button nzType="primary">
          <i nz-icon nzType="upload"></i>
          <span> 上传背面</span>
        </button>
      </nz-upload>
    </ng-container>
  </ng-template>
</ng-container>
