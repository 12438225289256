import { ISmbShipmentPackageTracking, ISmbShipmentTrackingEvent, RouteDirection } from '../interfaces';

export class SmbShipmentPackageTracking implements ISmbShipmentPackageTracking {

    label: string;
    packageNumber: string;
    events: ISmbShipmentTrackingEvent[];
    forwardTo: RouteDirection;
    lclLabel?: string;
    isInnerPak: boolean;
    isOuterPak: boolean;
    initialPak: boolean;
    childPak: boolean;
    inTransmit: boolean;
    depotReceived: boolean;
    depotReleased: boolean;
    delivered: boolean;
    rts: boolean;
    carrierTrackingNumber?: string;
    externalUrl?: string;

    constructor(private data: ISmbShipmentPackageTracking) {
        Object.assign(this, this.data);
    }

    get PackageType() {
        if (this.isInnerPak) {
            return '内箱';
        }

        if (this.isOuterPak) {
            return '外箱';
        }

        return '包裹号';
    }

    get PackageTag() {
        let tag = null;


        if (this.lclLabel) {
            tag = `${this.lclLabel}`;
        }

        if (!tag) {
            tag = `${this.PackageType}`;
        }

        if (this.initialPak) {
            tag += ' (初始包裹,已分拆)';
        }

        if (this.childPak) {
            tag += ' (分拆子包裹)';
        }

        return `${tag}:`;
    }

    get PackageNumber() {
        return `${this.PackageTag} ${this.packageNumber}`;
    }

    get State() {

        if (this.ForwardToDepot) {

            if (this.rts) {
                return {
                    color: 'error',
                    text: '退回'
                };
            }

            if (this.depotReceived) {
                return {
                    color: 'processing',
                    text: '分拣中心已确认接收'
                };
            }

            if (this.delivered) {
                return {
                    color: 'default',
                    text: '已抵达分拣中心, 处理中'
                };
            }

            if (this.depotReleased) {
                return {
                    color: 'processing',
                    text: '分拣中心放行, 包裹转运中'
                };
            }

            if (this.inTransmit) {
                return {
                    color: 'default',
                    text: '在途'
                };
            }

        }

        if (this.ForwardToEnd) {


            if (this.rts && this.delivered) {
                return {
                    color: 'error',
                    text: '退回 & 送达'
                };
            }

            if (this.rts) {
                return {
                    color: 'error',
                    text: '退回'
                };
            }

            if (this.delivered) {
                return {
                    color: 'success',
                    text: '送达'
                };
            }

            if (this.depotReleased) {
                return {
                    color: 'processing',
                    text: '分拣中心放行, 转运中'
                };
            }

            if (this.depotReceived) {
                return {
                    color: 'processing',
                    text: '分拣中心确认接收'
                };
            }

            if (this.inTransmit) {
                return {
                    color: 'default',
                    text: '在途'
                };
            }

        }


        return null;
    }

    get ForwardToDepot() {
        if (this.forwardTo === RouteDirection.depot) {
            return true;
        } else {
            return false;
        }
    }

    get ForwardToEnd() {
        if (this.forwardTo === RouteDirection.end) {
            return true;
        } else {
            return false;
        }
    }

    get External() {
        if (!this.externalUrl) {
            return null;
        }

        return {
            url: this.externalUrl,
            trackingNumber: this.carrierTrackingNumber
        };
    }
}
