import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import {
  ServiceAdditionalFormComponent, ServiceAdditionalFormSoComponent,
  ServiceAdditionalFormDcComponent,
  ServiceAdditionalFormNdhComponent,
  ServiceAdditionalComponent,
} from './additional';
import { ServiceContentFormComponent } from './content';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { ServiceContentComponent } from './content/service-content/service-content.component';
import { ServiceContentEditComponent } from './content/service-content-edit/service-content-edit.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ServiceContentInsuranceComponent } from './content/insurance/service-content-insurance/service-content-insurance.component';
import { ServiceContentInsuranceFormComponent } from './content/insurance/service-content-insurance-form/service-content-insurance-form.component';
import { ServiceContentInsuranceEditComponent } from './content/insurance/service-content-insurance-edit/service-content-insurance-edit.component';
import { ServiceAdditionalEditComponent } from './additional/service-additional-edit/service-additional-edit.component';


@NgModule({
  declarations: [
    ServiceAdditionalComponent,
    ServiceAdditionalFormComponent,
    ServiceAdditionalFormSoComponent,
    ServiceAdditionalFormDcComponent,
    ServiceAdditionalFormNdhComponent,
    ServiceAdditionalEditComponent,
    ServiceContentFormComponent,
    ServiceContentComponent,
    ServiceContentEditComponent,
    ServiceContentInsuranceComponent,
    ServiceContentInsuranceFormComponent,
    ServiceContentInsuranceEditComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzDescriptionsModule,
    NzIconModule,
    NzTagModule,
    NzTypographyModule,
    NzToolTipModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    NzRadioModule,
    NzCheckboxModule,
    NzButtonModule,
    NzDividerModule,
    NzDrawerModule,
    IndicatorSharedModule
  ],
  exports: [
    ServiceAdditionalComponent,
    ServiceAdditionalFormComponent,
    ServiceAdditionalFormDcComponent,
    ServiceAdditionalFormNdhComponent,
    ServiceAdditionalFormSoComponent,
    ServiceAdditionalEditComponent,
    ServiceContentFormComponent,
    ServiceContentComponent,
    ServiceContentEditComponent,
    ServiceContentInsuranceComponent,
    ServiceContentInsuranceFormComponent,
    ServiceContentInsuranceEditComponent,
  ]
})
export class ShippingServiceSharedModule { }
