<ng-container *ngIf="!error; else ErrorTpl">
  <ng-container *ngIf="!isLoading; else LoadingTpl">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="on_submit()">
      <!-- PAK SIZE -->
      <nz-divider
        nzText="规格"
        nzPlain
        nzOrientation="left"
        nzDashed
      ></nz-divider>

      <entity-format-form
        [validateForm]="validateForm"
        [unit]="unit"
      ></entity-format-form>

      <nz-form-item nzJustify="end">
        <button nz-button nzType="primary">提交</button>
      </nz-form-item>
    </form>
  </ng-container>
</ng-container>

<ng-template #ErrorTpl>
  <indicator-alert
    [message]="error.message"
    [problems]="error.problems"
    [type]="'error'"
    (Close)="error_reset()"
  ></indicator-alert>
</ng-template>

<ng-template #LoadingTpl>
  <indicator-loading title="提交中, 请稍后..."></indicator-loading>
</ng-template>
