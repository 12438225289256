import { Component, HostBinding, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IHttpResponse } from './common/interfaces/http';
import { BreakpointService } from './core/breakpoint';
import { MessageService } from './core/message';
import { MessageListComponent } from './shared/message/components';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'YouYou Express 优邮 全加拿大任何地方 超低运费 寄件回国 | YouYou 优邮';

  isSmallScreen = false;
  @HostBinding('attr.app-version') appVersionAttr = environment.appVersion;
  constructor(
    private messageService: MessageService,
    private modalService: NzModalService,
    private bpService: BreakpointService,

  ) {
    this.bp_watch();
    // this.message_sync();
    const version = environment.appVersion;
    console.log(version);


  }

  ngOnInit(): void {

  }

  private bp_watch(): void {
    this.bpService.small$.subscribe({
      next: res => this.isSmallScreen = res
    });
  }

  private message_sync(): void {
    this.messageService.search<IHttpResponse>({}).pipe(
      take(1)
    ).subscribe({
      next: res => {
        if (res.count) {
          const modal: NzModalRef = this.modalService.create({
            nzTitle: `系统消息 (${res.count})`,
            nzContent: MessageListComponent,
            nzComponentParams: {
              list: res.result
            },
            nzMaskClosable: false,
            nzWidth: this.isSmallScreen ? undefined : '50%',
            nzFooter: [
              {
                label: '关闭',
                type: 'primary',
                onClick: () => modal.close()
              }
            ]
          });
        }
      }
    });
  }

}
