import { IUnitWeight, SizeUnitCode, SizeUnitName, SizeUnitPropItem, WeightUnitCode, WeightUnitName, WeightUnitPropItem } from '../../interfaces/setting';

export const sizeUnitOptions: SizeUnitPropItem<any>[] = [
    {
        name: SizeUnitName.cm,
        code: SizeUnitCode.cm,
        isCentimeter: true
    },
    {
        name: SizeUnitName.in,
        code: SizeUnitCode.in,
        isCentimeter: false
    }
];

export const weightUnitOptions: WeightUnitPropItem<any>[] = [
    {
        name: WeightUnitName.kg,
        code: WeightUnitCode.kg,
        isKilo: true
    },
    {
        name: WeightUnitName.lb,
        code: WeightUnitCode.lb,
        isKilo: false
    }
];

