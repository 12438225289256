<form nz-form [formGroup]="validateForm" nzLayout="inline">
  <nz-form-item
    nzFor="state"
    *ngIf="contentList.length && validateForm | hasControl: 'serviceContentId'"
  >
    <nz-form-label nzFor="serviceContentId" nzRequired>
      物品分类
    </nz-form-label>
    <nz-form-control
      [nzErrorTip]="fv.message(validateForm, 'serviceContentId')"
    >
      <nz-select
        formControlName="serviceContentId"
        nzPlaceHolder="请选择"
        style="width: 100%; min-width: 400px; max-width: 400px"
        (ngModelChange)="on_content_change($event)"
      >
        <nz-option
          *ngFor="let item of contentList"
          [nzLabel]="item.name"
          [nzValue]="item.id"
          [nzDisabled]="item.disabled"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item
    nzFor="state"
    *ngIf="indexes.length && validateForm | hasControl: 'code'"
  >
    <nz-form-control [nzErrorTip]="fv.message(validateForm, 'code')">
      <nz-form-label nzFor="code" nzRequired>物品分类</nz-form-label>
      <nz-select
        formControlName="code"
        nzPlaceHolder="请选择"
        style="width: auto; min-width: 300px"
      >
        <nz-option
          *ngFor="let item of indexes"
          [nzLabel]="item.label"
          [nzValue]="item.code"
          [nzDisabled]="item.disabled"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-container *ngIf="content && content.notices && content.notices.length">
  <nz-card nzBorderless nzSize="small" nzTitle="邮寄品注意事项">
    <ul>
      <li *ngFor="let notice of content.notices">
        <span nz-typography nzType="warning">{{ notice }}</span>
      </li>
    </ul>
  </nz-card>
</ng-container>
