import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formValue',
  pure: false
})
export class FormValuePipe implements PipeTransform {

  transform(value: UntypedFormGroup | UntypedFormArray | UntypedFormControl, ...args: unknown[]): unknown | null {
    const [f1] = args;
    const key = f1 as string;
    const form = value?.get(key);

    if (form) {
      return form.value;
    }

    return null;
  }
}
