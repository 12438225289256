import { ChangeDetectionStrategy, Component, Input, OnInit, Injector, ViewChild, TemplateRef } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { BtcOrder } from 'src/app/common/models/order';

@Component({
  selector: 'order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailComponent implements OnInit {
  @ViewChild('footerTpl') footerTpl: TemplateRef<any>;

  @Input() order: BtcOrder;
  @Input() drawer = false;
  @Input() fullscreen = false;

  constructor(
    public i18n: NzI18nService,
    private injector: Injector
  ) {


  }

  ngOnInit(): void {
    setTimeout(() => {
      this.fullscreen_switch();
    }, 300);
  }

  fullscreen_switch(): void {
    if (this.drawer) {
      // const drawerRef = this.injector.get(NzDrawerRef);
      // drawerRef.nzHeight = this.fullscreen ? '100%' : '256px';
      // this.fullscreen = !this.fullscreen;
    }
  }

}
