import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NzIconModule } from 'ng-zorro-antd/icon';



@NgModule({
  declarations: [
    PdfViewerComponent
  ],
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
    NzIconModule
  ],
  exports: [
    PdfViewerComponent
  ]
})
export class FileSharedModule { }
