<ng-container *ngIf="validateForm">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item *ngIf="validateForm | hasControl: 'region'">
      <nz-form-label [nzRequired]="validateForm | isRequired: 'region'">
        {{ label }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError: 'region'">
        <nz-select
          formControlName="region"
          [nzPlaceHolder]="placeholder"
          [nzAllowClear]="allowClear"
          nzShowSearch
          (ngModelChange)="regionChange.emit($event)"
        >
          <nz-option
            *ngFor="let item of regionList"
            [nzLabel]="item.label"
            [nzValue]="item.value"
            [nzDisabled]="item.disabled"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>
