import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingStepsComponent } from './components/tracking-steps/tracking-steps.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { MomentModule } from 'ngx-moment';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { LayoutModule } from 'src/app/shared/layout';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { TrackingCoreModule } from 'src/app/core/tracking';




@NgModule({
  declarations: [
    TrackingStepsComponent
  ],
  imports: [
    CommonModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
    NzEmptyModule,
    NzStepsModule,
    NzTypographyModule,
    NzDividerModule,
    NzPopoverModule,
    NzTagModule,
    NzI18nModule,
    MomentModule,
    LayoutModule,
    IndicatorSharedModule,
    TrackingCoreModule
  ],
  exports: [
    TrackingStepsComponent
  ]
})
export class ShippingTrackingSharedModule { }
