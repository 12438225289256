import { CarrierCode } from '../carrier';
import { ITag } from '../common';


export interface IBtcShippingPackageTracking {
    packageNumber: string;
    transit?: number;
    current: number;
    notices?: string[];
    steps: IBtcShippingTrackingStep[];
    split: 0 | 1;
}

export interface IBtcShippingTrackingStep {
    title: string;
    desc?: string;
    location?: string;
    eventTime?: Date;
    carrierCode?: CarrierCode;
    waybillNumber?: string;
    waybillReference: string | null;
    trackingUrl?: string;
    submitted?: number;
    submittedAt?: Date;
    inTransit?: number;
    inTransitAt?: Date;
    voided?: number;
    voidedAt?: Date;
    delivered?: number;
    deliveredAt?: Date;
    tsc?: number;
    tscAt?: Date;
    rts?: number;
    rtsAt?: Date;
    abnormal?: number;
    abnormalAt?: Date;
    abnormalNote?: any;
    deliveryOn?: any;
    deliveryOnExpected?: any;
}

export const trackingDataMock: IBtcShippingPackageTracking = {
    packageNumber: '',
    current: 3,
    notices: [`消息1, xxxx`, `消息2, xxxx`],
    split: 0,

    steps: [
        {
            title: '物流确认',
            carrierCode: CarrierCode.canpost,
            eventTime: new Date(),
            waybillReference: null,
        },
        {
            title: '运输中',
            desc: '包裹异常, 请联系客服',
            location: 'On the moon',
            waybillReference: null,
            inTransit: 1
        },
        {
            title: '中转中心',
            desc: '包裹异常, 请联系客服',
            location: 'Quebec',
            waybillReference: null,
            tsc: 3
        },
        {
            title: '运输中',
            desc: '尚未到达中国',
            waybillReference: null,
            carrierCode: CarrierCode.shunfeng,
            inTransit: 1
        },
        {
            title: '目的地',
            waybillReference: null,
            location: 'Ontario CA',
            delivered: 4
        }
    ]
};
