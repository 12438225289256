import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitiationAddressComponent } from './components/initiation-address/initiation-address.component';
import { InitiationAddressFormComponent } from './components/initiation-address-form/initiation-address-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { FormSharedModule } from '../../utils/form';



@NgModule({
  declarations: [
    InitiationAddressComponent,
    InitiationAddressFormComponent,
    AddressFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule
  ],
  exports: [
    InitiationAddressComponent,
    InitiationAddressFormComponent,
    AddressFormComponent
  ]
})
export class ServiceAddressSharedModule { }
