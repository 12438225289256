<nz-card nzTitle="Goods 货品分类" nzSize="small" nzType="inner">
  <index-selector
    [recordList]="indexes"
    [isLoading]="isLoading"
    (ngModel)="on_index_change($event)"
  ></index-selector>
</nz-card>

<ng-container *ngIf="page; then ShippingTpl; else RateTpl"></ng-container>

<ng-template #ShippingTpl>
  <nz-card
    nzTitle="Services 服务列表"
    nzSize="small"
    nzType="inner"
    style="margin-top: 16px"
    [nzExtra]="extraTpl"
  >
    <service-selector
      [recordList]="serviceList"
      [isLoading]="isLoading"
      (serviceChange)="serviceChange.emit($event)"
    ></service-selector>
  </nz-card>

  <ng-template #extraTpl>
    <ng-container *ngIf="serviceList?.length">
      <button nz-button nzType="link" nzDanger (click)="price_compare()">
        <i nz-icon nzTheme="outline" nzType="calculator"></i>
        对比价格
      </button>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #RateTpl>
  <br />
  <ng-container *ngIf="index">
    <service-rate-compare
      [regionServiceControl]="regionServiceControl"
      [initiationRegion]="initiationRegion"
      [destinationRegion]="destinationRegion"
      [multiDestination]="0"
      [pakType]="pakType"
      [index]="index"
      [services]="serviceList"
      (serviceChange)="serviceChange.emit($event)"
    ></service-rate-compare>
  </ng-container>
</ng-template>
