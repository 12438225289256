import { ITag } from '../../interfaces/common';

export const status = (data: any, prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null => {

    if (prop in data) {
        if (data[prop] === value) {
            return {
                color: tagTrue[0],
                text: tagTrue[1],
            };
        } else {
            if (tagFalse) {
                return {
                    color: tagFalse[0],
                    text: tagFalse[1],
                };
            }
        }
    }

    return null;
};
