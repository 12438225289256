import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IRate } from 'src/app/common/interfaces';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { Rate } from 'src/app/common/models/rate';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BTCRateService {
  #coreEndpoint: string;
  constructor(
    private http: HttpService
  ) {
    this.#coreEndpoint = environment.coreEndPoint;
  }


  public estimate(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/rate/estimate`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new Rate(res.result);
        }
        return res;
      }),

    );
  }

  public single_destination(body: any): Observable<IRate | null> {
    const path = `rate/estimate/single`;
    return this.http.post(path, body).pipe(
      map((res: IHttpResponse) => res.result),
      catchError(() => of(null))
    );
  }

  public multi_destination(body: any): Observable<IRate | null> {
    const path = `rate/estimate/single`;
    return this.http.post(path, body).pipe(
      map((res: IHttpResponse) => res.result),
      catchError(() => of(null))
    );
  }

}
