<ng-container *ngIf="!error; else errorTpl">
  <div nz-row nzJustify="center">
    <div nz-col>
      <form
        nz-form
        [formGroup]="validateForm"
        (ngSubmit)="on_submit()"
        nzLayout="horizontal"
      >
        <items-form
          [current]="current"
          [validateForm]="validateForm"
          [item]="serviceContent?.item"
          [unit]="unit$ | async"
        ></items-form>

        <nz-form-item nzJustify="end">
          <button
            nz-button
            nzType="primary"
            [nzLoading]="isLoading"
            (click)="on_submit()"
          >
            <i nz-icon nzType="check-circle"></i>
            提交
          </button>
        </nz-form-item>
      </form>
    </div>
  </div>
</ng-container>

<ng-template #errorTpl>
  <indicator-alert
    [message]="error.message"
    [problems]="error.problems"
    [type]="'error'"
    (Close)="error = null"
  ></indicator-alert>
</ng-template>
