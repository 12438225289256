import { ItemUnit, ItemUnitCanpost, ItemUnitOption, ItemUnitUni } from '../../interfaces/service';

const ITEM_UNITS_UNI: Record<ItemUnitUni, ItemUnitOption<ItemUnitUni>> = {
    L: {
        name: 'Liter',
        code: 'L'
    },
    M: {
        name: 'Meter',
        code: 'M'
    },
    BA: {
        name: 'Barrel',
        code: 'BA'
    },
    BE: {
        name: 'Bundle',
        code: 'BE'
    },
    BG: {
        name: 'Bag',
        code: 'BG'
    },
    BH: {
        name: 'Bunch',
        code: 'BH'
    },
    BT: {
        name: 'Bolt',
        code: 'BT'
    },
    BU: {
        name: 'Butt',
        code: 'BU'
    },
    CI: {
        name: 'Canister',
        code: 'CI'
    },
    CM: {
        name: 'Centimeter',
        code: 'CM'
    },
    CR: {
        name: 'Crate',
        code: 'CR'
    },
    CS: {
        name: 'Case',
        code: 'CS'
    },
    CT: {
        name: 'Carton',
        code: 'CT'
    },
    CY: {
        name: 'Cylinder',
        code: 'CY'
    },
    EA: {
        name: 'Each',
        code: 'EA'
    },
    EN: {
        name: 'Envelope',
        code: 'EN'
    },
    FT: {
        name: 'Feet',
        code: 'FT'
    },
    KG: {
        name: 'Kilogram',
        code: 'KG'
    },
    LB: {
        name: 'Pound',
        code: 'LB'
    },
    PA: {
        name: 'Packet',
        code: 'PA'
    },
    PC: {
        name: 'Piece',
        code: 'PC'
    },
    PF: {
        name: 'Proof Liters',
        code: 'PF'
    },
    PR: {
        name: 'Pair',
        code: 'PR'
    },
    RL: {
        name: 'Roll',
        code: 'RL'
    },
    TU: {
        name: 'Tube',
        code: 'TU'
    },
    YD: {
        name: 'Yard',
        code: 'YD'
    },
    BOX: {
        name: 'Box',
        code: 'BOX'
    },
    CON: {
        name: 'Container',
        code: 'CON'
    },
    DOZ: {
        name: 'Dozen',
        code: 'DOZ'
    },
    KGS: {
        name: 'Kilograms',
        code: 'KGS'
    },
    LBS: {
        name: 'Pounds',
        code: 'LBS'
    },
    NMB: {
        name: 'Number',
        code: 'NMB'
    },
    PAL: {
        name: 'Pallet',
        code: 'PAL'
    },
    PCS: {
        name: 'Pieces',
        code: 'PCS',
        default: true
    },
    // PKG: {
    //     name: 'Package',
    //     code: 'PKG'
    // },
    PRS: {
        name: 'Pairs',
        code: 'PRS'
    },
    SET: {
        name: 'Set',
        code: 'SET'
    },
    SME: {
        name: 'Square Meters',
        code: 'SME'
    },
    SYD: {
        name: 'Square Yards',
        code: 'SYD'
    },
};

export const ITEM_UNITS_CANPOST: Record<ItemUnitCanpost, ItemUnitOption<ItemUnitCanpost>> = {
    BAG: {
        name: 'Bag',
        code: 'BAG'
    },
    BIN: {
        name: 'Bin',
        code: 'BIN'
    },
    BOT: {
        name: 'Bottle',
        code: 'BOT'
    },
    BOX: {
        name: 'Box',
        code: 'BOX'
    },
    CTN: {
        name: 'Carton',
        code: 'CTN'
    },
    DZN: {
        name: 'Dozen',
        code: 'DZN'
    },
    ENV: {
        name: 'Envelope',
        code: 'ENV'
    },
    GRM: {
        name: 'Gram',
        code: 'GRM'
    },
    KGM: {
        name: 'Kilogram',
        code: 'KGM'
    },
    KIT: {
        name: 'Kit',
        code: 'KIT'
    },
    LTR: {
        name: 'Litre',
        code: 'LTR'
    },
    MLT: {
        name: 'Millilitre',
        code: 'MLT'
    },
    MMT: {
        name: 'Millimetre',
        code: 'MMT'
    },
    MTR: {
        name: 'Metre',
        code: 'MTR'
    },
    NMB: {
        name: 'Number',
        code: 'NMB'
    },
    PAR: {
        name: 'Pair',
        code: 'PAR'
    },
    PCE: {
        name: 'Piece',
        code: 'PCE',
        default: true
    },
    PKG: {
        name: 'Package',
        code: 'PKG'
    },
    SET: {
        name: 'Number of sets',
        code: 'SET'
    },
    TBE: {
        name: 'Tube',
        code: 'TBE'
    },
};

export const ITEM_UNITS: ItemUnit = {
    canpost: ITEM_UNITS_CANPOST,
    ups: ITEM_UNITS_UNI,
    fedex: ITEM_UNITS_UNI,
    dhl: ITEM_UNITS_UNI
};
