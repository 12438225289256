<ng-container *ngIf="!isLoading; else LoadingTpl">
  <ng-container *ngIf="!recordList.length">
    <nz-empty
      [nzNotFoundImage]="'assets/img/svg/shop/025-magnifying-glass.svg'"
      [nzNotFoundContent]="notFoundContentTpl"
    >
      <ng-template #notFoundContentTpl>
        <ng-container *ngIf="!isLoading"> 此路线没有可用的服务 </ng-container>
      </ng-template>
    </nz-empty>
  </ng-container>

  <ng-container *ngIf="recordList.length">
    <nz-list class="demo-loadmore-list">
      <ng-container
        *ngFor="let service of recordList; let i = index; trackBy: trackByIndex"
      >
        <ng-container *ngIf="!service.multiDestination">
          <nz-list-item
            *ngFor="
              let content of service.contents;
              let i = index;
              trackBy: trackByIndex
            "
          >
            <nz-list-item-meta [nzDescription]="ServiceName">
              <ng-template #ServiceName>
                <nz-tag [nzColor]="service.name.color">{{
                  service.name.text
                }}</nz-tag>
              </ng-template>

              <nz-list-item-meta-title>
                <div
                  nz-row
                  nzJustify="start"
                  nzAlign="middle"
                  [nzGutter]="[10, 20]"
                  style="margin-top: 20px"
                >
                  <div nz-col nzLg="8" nzXs="24">
                    <div nz-row nzGutter="16" nzAlign="middle">
                      <ng-container
                        *ngFor="let icon of service.RouteIcons; let i = index"
                      >
                        <div nz-col>
                          <img [src]="icon.src" class="max-h-6" />
                        </div>
                        <div nz-col *ngIf="i + 1 !== service.RouteIcons.length">
                          <span> &rarr;</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div nz-col nzLg="14" nzXs="24">
                    <service-restriction
                      [service]="service"
                      [content]="content"
                    ></service-restriction>
                  </div>
                </div>
              </nz-list-item-meta-title>
            </nz-list-item-meta>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a
                  nz-button
                  nzType="primary"
                  nzGhost
                  nzShape="round"
                  (click)="select(service, content)"
                >
                  选择
                </a>
              </nz-list-item-action>
            </ul>
          </nz-list-item>
        </ng-container>

        <ng-container *ngIf="service.multiDestination">
          <nz-list-item>
            <nz-list-item-meta [nzDescription]="ServiceName">
              <ng-template #ServiceName>
                <nz-tag [nzColor]="service.name.color">{{
                  service.name.text
                }}</nz-tag>
              </ng-template>

              <nz-list-item-meta-title>
                <div
                  nz-row
                  nzJustify="start"
                  nzAlign="middle"
                  [nzGutter]="[10, 20]"
                  style="margin-top: 20px"
                >
                  <div nz-col nzLg="8" nzXs="24">
                    <div nz-row nzGutter="16" nzAlign="middle">
                      <ng-container
                        *ngFor="let icon of service.RouteIcons; let i = index"
                      >
                        <div nz-col>
                          <img [src]="icon.src" class="max-h-6" />
                        </div>
                        <div nz-col *ngIf="i + 1 !== service.RouteIcons.length">
                          <span> &rarr;</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div nz-col nzLg="14" nzXs="24">
                    <service-restriction
                      [service]="service"
                    ></service-restriction>
                  </div>
                </div>
              </nz-list-item-meta-title>
            </nz-list-item-meta>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a
                  nz-button
                  nzType="primary"
                  nzGhost
                  nzShape="round"
                  (click)="select(service)"
                >
                  选择
                </a>
              </nz-list-item-action>
            </ul>
          </nz-list-item>
        </ng-container>
      </ng-container>
    </nz-list>
  </ng-container>
</ng-container>

<ng-template #LoadingTpl>
  <indicator-loading title="查询中..."></indicator-loading>
</ng-template>
