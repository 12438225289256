import { IQuote, IPriceDetail, RateTaxes, } from '../../interfaces/rate';
import { ITax, TaxCode } from '../../interfaces/rate/tax';

export class RateQuote {
    private _data: IQuote;

    constructor(value: IQuote) {
        this._data = value;
    }

    get currency(): string {
        return this._data.currency.toUpperCase();
    }

    get due(): number {
        return this._data?.due ?? 0;
    }

    get base(): number {
        return this._data?.base ?? 0;
    }

    get taxes(): ITax[] {
        const taxes: ITax[] = [];
        const raw: RateTaxes = this._data?.taxes ?? null;
        for (const key in TaxCode) {
            if (isNaN(Number(key))) {
                const tax = raw[key];
                if (!tax.amount) {
                    continue;
                }
                const rate = tax.rate > 1 ? tax.rate : Number((tax.rate * 100).toFixed(2));
                taxes.push({
                    name: `${key} (${rate}%)`,
                    amount: tax.amount,
                    rate,
                    currency: this._data.currency
                });
            }
        }
        return taxes;
    }

    get priceDetails(): IPriceDetail[] {
        if (!this._data?.priceDetails) {
            return null;
        }
        return this._data.priceDetails.filter(item => !item.hide);
    }

    get basePlus(): number {
        return this._data.basePlus ?? 0;
    }

    get msrp(): number {
        return this._data.msrp ?? 0;
    }

    get msrpSaving(): number {
        return this._data.msrpSaving ?? 0;
    }

    get discount(): number {
        return this._data.discount ?? 0;
    }
}

