import { Currency } from '../currency';

export interface IRateTaxItem {
    amount: number;
    rate: number;
}

export enum TaxCode {
    gst = 'gst',
    pst = 'pst',
    hst = 'hst',
    qst = 'qst',
    tax = 'tax'
}

export type Tax = keyof typeof TaxCode;

export interface ITax {
    name: string;
    amount: number;
    rate: number;
    currency: Currency;
}
