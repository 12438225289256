import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSharedModule } from './form';
import { FileSharedModule } from './file';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormSharedModule,
    FileSharedModule
  ],
  exports: [
    FormSharedModule,
    FileSharedModule
  ]
})
export class UtilsModule { }
