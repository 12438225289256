import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { PagesLoadedEvent } from 'ngx-extended-pdf-viewer';
import { take } from 'rxjs/operators';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { BtcLabelService } from 'src/app/core';

@Component({
  selector: 'label-preview',
  templateUrl: './label-preview.component.html',
  styleUrls: ['./label-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelPreviewComponent implements OnInit {
  @Input() waybillIds: string[];
  @Input() drawer = true;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  height = '100vh';
  base64src: string;
  error: IHttpResponseError;
  constructor(
    private detection: ChangeDetectorRef,
    private labelService: BtcLabelService
  ) { }

  ngOnInit(): void {
    this.base64_get({ waybills: this.waybillIds });
  }

  private base64_get(body: any): void {
    this.isLoading = true;
    this.labelService.base64(body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.base64src = res?.result;
        this.error = res?.error;
        this.isLoading = false;
      }
    });
  }


  public onPagesLoaded(event: PagesLoadedEvent): void {
    this.height = '100vh';
  }

}
