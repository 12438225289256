import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TrackByIndexFn } from 'src/app/common/functions';
import { KuaiDi100Step } from '../../interfaces';

@Component({
  selector: 'kuaidi100-tracking-step',
  templateUrl: './kuaidi100-tracking-step.component.html',
  styleUrls: ['./kuaidi100-tracking-step.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Kuaidi100TrackingStepComponent implements OnInit {
  trackByIndex = TrackByIndexFn;
  @Input() steps: KuaiDi100Step[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
