import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formArray'
})
export class FormArrayPipe implements PipeTransform {

  transform(value: UntypedFormGroup, ...args: string[]): UntypedFormArray | null {
    const [f1] = args;
    const form = value && value.get(f1) as UntypedFormArray;

    return form;
  }

}
