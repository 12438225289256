import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormGroupPipe, HasControlPipe } from './pipes';
import { FormArrayPipe } from './pipes/form-array/form-array.pipe';
import { FormErrorPipe } from './pipes/form-error/form-error.pipe';
import { IsRequiredPipe } from './pipes/is-required/is-required.pipe';
import { FormValuePipe } from './pipes/form-value/form-value.pipe';



@NgModule({
  declarations: [
    HasControlPipe,
    IsRequiredPipe,
    FormGroupPipe,
    FormArrayPipe,
    FormErrorPipe,
    FormValuePipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    HasControlPipe,
    IsRequiredPipe,
    FormGroupPipe,
    FormArrayPipe,
    FormErrorPipe,
    FormValuePipe
  ]
})
export class FormSharedModule { }
