
export enum BonusEvent {
    unknown = '未知事件',
    cashback = '返款',
    cashback_revoke = '返款撤销',
    cashback_agent = 'Agent 用户返款',
    cashback_business = 'Business 用户返款',
    check_out = '积分',
    payment_abort = '积分撤销',
    voucher_exchange = '代金券兑换',
    order_point = '积分(订单)',
    order_point_revoke = '积分撤销(订单)'
}

export type BonusEventCode = keyof typeof BonusEvent;

export interface IBonusEvent {
    code: BonusEventCode;
    name: BonusEvent;
}
