import {
    IPaymentMethod, IPaymentType, PaymentMethod, PaymentRefundMethodCode, PaymentRefundMethodName,
    PaymentRefundProp, PaymentStateCode, PaymentStateProps, PaymentType, PaymentTypeCode, PAYMENT_METHODS
} from '../../interfaces/payment';

export * from './payment-type.constant';
export * from './currency-filter.constant';



export const PAYMENT_TYPE: Record<PaymentType, IPaymentType> = {
    payout: {
        label: '消费支付',
        code: 'payout'
    },
    recharge: {
        label: '充值',
        code: 'recharge'
    },
    supplement: {
        label: '补差价',
        code: 'supplement'
    },
};


export const PAYMENT_METHOD_LIST: IPaymentMethod[] = [
    {
        name: PAYMENT_METHODS.account_balance,
        code: PaymentMethod.account_balance,
        type: [PaymentTypeCode.payout],
        currencies: ['CAD', 'EUR'],
        min: 0,
        icon: null,
        desc: null
    },
    {
        name: PAYMENT_METHODS.creditcard,
        type: [PaymentTypeCode.payout, PaymentTypeCode.recharge],
        code: PaymentMethod.creditcard,
        currencies: ['CAD', 'EUR'],
        min: 1,
        icon: null,
        desc: null
    },
    {
        name: PAYMENT_METHODS.alipay,
        type: [PaymentTypeCode.payout, PaymentTypeCode.recharge],
        code: PaymentMethod.alipay,
        currencies: ['CAD', 'EUR'],
        min: 1,
        icon: null,
        desc: null
    },
    {
        name: PAYMENT_METHODS.wechat,
        type: [PaymentTypeCode.payout, PaymentTypeCode.recharge],
        code: PaymentMethod.wechat,
        currencies: ['CAD', 'EUR'],
        min: 1,
        icon: null,
        desc: '使用移动版浏览器时, 请使用第二台移动设备扫码支付'
    },
    {
        name: PAYMENT_METHODS.applepay,
        type: [PaymentTypeCode.payout, PaymentTypeCode.recharge],
        code: PaymentMethod.applepay,
        currencies: ['CAD', 'EUR'],
        min: 1,
        icon: null,
        desc: null
    },
    {
        name: PAYMENT_METHODS.bancontact,
        type: [PaymentTypeCode.payout],
        code: PaymentMethod.bancontact,
        currencies: ['EUR'],
        min: 1,
        icon: null,
        desc: null
    },
    {
        name: PAYMENT_METHODS.group,
        type: [],
        code: PaymentMethod.group,
        currencies: ['CAD'],
        min: 1,
        icon: null,
        desc: null
    }
];


export const PAYMENT_STATES: PaymentStateProps = {
    pending: {
        text: '处理中',
        code: PaymentStateCode.pending,
        query: {
            pending: 1
        }
    },
    paid: {
        text: '支付成功',
        code: PaymentStateCode.paid,
        query: {
            paid: 1
        }
    },

    failed: {
        text: '失败',
        code: PaymentStateCode.failed,
        query: {
            failed: 1
        }
    },

    canceled: {
        text: '取消',
        code: PaymentStateCode.canceled,
        query: {
            canceled: 1
        }
    },

    expired: {
        text: '超时',
        code: PaymentStateCode.expired,
        query: {
            expired: 1
        }
    },
    abort: {
        text: '对话终止',
        code: PaymentStateCode.abort,
        query: {
            abort: 1
        }
    },
    refundPending: {
        text: '退款处理中',
        code: PaymentStateCode.refundPending,
        query: {
            refunded: 2
        }
    },
    refunded: {
        text: '已退款',
        code: PaymentStateCode.refunded,
        query: {
            refunded: [1, 3]
        }
    },
};



export const REFUND_METHODS: PaymentRefundProp = {
    rbc: {
        name: PaymentRefundMethodName.rbc,
        code: PaymentRefundMethodCode.rbc,
        note: '(5%手续费)',
        query: {
            rbc: 1
        },
        value: 1
    },
    rbb: {
        name: PaymentRefundMethodName.rbb,
        code: PaymentRefundMethodCode.rbb,
        query: {
            rbc: 0
        },
        value: 0
    }
};
