import { ISelectOption } from 'src/app/core/common/interfaces';
import { OptionFn } from '../../functions';
import { CarrierCode, CarrierPropChina } from '../../interfaces/carrier';

export const carrierChina: Partial<CarrierPropChina> = {
    shunfeng: {
        name: '顺丰',
        code: CarrierCode.shunfeng
    },
    zhongtong: {
        name: '中通',
        code: CarrierCode.zhongtong
    },
    shentong: {
        name: '申通',
        code: CarrierCode.shentong
    },
    yuantong: {
        name: '圆通',
        code: CarrierCode.yuantong
    },
    jd: {
        name: '京东物流',
        code: CarrierCode.jd
    },
    jingdongkuaiyun: {
        name: '京东快运',
        code: CarrierCode.jingdongkuaiyun
    },
    yunda: {
        name: '韵达',
        code: CarrierCode.yunda
    },
    youzhengbk: {
        name: '邮政标准快递',
        code: CarrierCode.youzhengbk
    }
};



const optionFn = (state): ISelectOption[] => {
    const options: ISelectOption[] = [];

    for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
            const code = key as any;
            const element = state[code];
            options.push({
                label: element.name,
                value: element.code
            });
        }
    }

    return options;
}

export const carrierChinaOptions = optionFn(carrierChina);