import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'issueLink'
})
export class IssueLinkPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string | null {

    const text = value as string ?? null;
    if (!text) {
      return text;
    }
    const livemode = (args[0] && typeof args[0] === 'boolean') ?? true;

    if (text.includes('包裹编号')) {
      const packageNumber = text.split(' ').join('').split(':')[1] ?? '';
      const shippingNumber = packageNumber.split('-')[0];

      if (shippingNumber && packageNumber) {
        const link = `/app/shippings/${shippingNumber}/packages/${packageNumber}`;
        return livemode ? link : `/test${link}`;
      }

    }
    return null;
  }

}
