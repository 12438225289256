import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { SupportContact } from 'src/app/common/models/support';
import { SupportContactAsset } from 'src/app/common/models/support';
import { environment } from 'src/environments/environment';

import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BtcContactService {
  #coreEndpoint: string;

  constructor(
    private http: HttpService,
    private sanitizer: DomSanitizer
  ) {
    this.#coreEndpoint = environment.coreEndPoint;
  }

  search(body: any): Observable<IHttpResponse> {

    const path = `${this.#coreEndpoint}/support/contact/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = res.result.map(row => new SupportContact(row));
        }

        return res;
      })
    );
  }

  asset_search(contactId: string, body: any) {
    const path = `${this.#coreEndpoint}/support/contact/${contactId}/assets/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const result = res?.result ?? [];
        res.result = result.map(row => new SupportContactAsset(row, this.sanitizer));
        return res;
      })
    );
  }
}
