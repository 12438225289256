import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import {
  IRegionListItem,
  IRegionProvinceListItem,
  IRegionServiceControl
} from 'src/app/common/interfaces/region';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class RegionService {


  #coreEndpoint: string;
  constructor(
    private http: HttpService
  ) {
    this.#coreEndpoint = environment.coreEndPoint;
  }

  search(type: 0 | 1, body: any): Observable<IHttpResponse> {
    if (type) {
      return this.destination_region_search(body);
    } else {
      return this.departure_region_search(body);
    }
  }


  departure_region_search(body?: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/region/initiation/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result: regions } = res;
        if (regions?.length) {
          res.result = regions.map(region => {
            return {
              label: region.nameLocale + '(' + region.isoCode2 + ')', value: region
            };
          });
        }
        return res;
      }),
    );
  }

  destination_region_search(body?: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/region/destination/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result: regions } = res;
        if (regions?.length) {
          res.result = regions.map(region => {
            return {
              label: region.nameLocale + '(' + region.isoCode2 + ')', value: region
            };
          });
        }
        return res;
      }),
    );
  }


  province_search(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/region/province/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result: provinces } = res;

        res.result = provinces.map(province => {
          const { name, nameLocale } = province;
          const label = nameLocale ? `${name}  ( ${nameLocale} )` : name;
          return {
            label, value: province
          };
        });

        return res;
      }),

    );
  }

  region_service_control(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/region/service/control`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch())
    );
  }

  doc_base64(body: any): Observable<IHttpResponse> {
    const path = `${this.#coreEndpoint}/region/docs/base64`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
    );
  }

}
