import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  #endPoint: string;
  #syncing$ = new BehaviorSubject<boolean>(false);
  syncing$ = this.#syncing$.asObservable();



  constructor(
    private http: HttpService
  ) {
    this.#endPoint = environment.saleEndPoint;
  }

  set syncing(state: boolean) {
    this.#syncing$.next(state);
  }

  search<T>(body: any): Observable<T> {
    this.syncing = true;
    const path = `${this.#endPoint}/message/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map(data => {

        if (data.error) {
          return { count: 0, result: [] };
        } else {
          return data;
        }
      }),
      tap(() => this.syncing = false)
    );
  }
}
