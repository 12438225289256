import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { take } from 'rxjs/operators';
import { Voucher } from 'src/app/common/models/auth';
import { VoucherCoreService } from 'src/app/core/account';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'voucher-selector',
  templateUrl: './voucher-selector.component.html',
  styleUrls: ['./voucher-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoucherSelectorComponent implements OnInit {

  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  validateForm = new UntypedFormGroup({

    serviceCode: this.fb.control(null, [Validators.required]),
    amount: this.fb.control(null, [Validators.min(0)])
  });

  @Input() serviceCode: string;
  @Input() amount = 0;
  @Output() valueChange = new EventEmitter<Voucher | null>();

  vouchers: Voucher[] = [];
  count = 0;
  queryParams = {
    page: 1,
    skip: 0,
    limit: 10,
    sorts: []
  };


  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private detection: ChangeDetectorRef,
    private voucherService: VoucherCoreService,
    public i18n: NzI18nService
  ) { }


  ngOnInit(): void {
    this.validateForm.patchValue({
      serviceCode: this.serviceCode,
      amount: this.amount
    });

    this.on_submit();
  }


  on_page_change(i: number): void {
    this.queryParams.skip = (i - 1) * this.queryParams.limit;
    this.on_submit();
  }

  on_size_change(size: number): void {
    this.queryParams.limit = size;
    this.queryParams.skip = 0;
    this.queryParams.page = 1;
    this.on_submit();
  }


  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      const body = this.validateForm.value;
      this.search({ ...body, ...this.queryParams });
    }

  }



  private search(body: any): void {
    this.isLoading = true;
    this.voucherService.search_by_user(body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        const vouchers = res?.result ?? [];
        this.vouchers = vouchers;
        this.count = res.count ?? 0;
        this.isLoading = false;
      }
    });
  }

  select(voucher): void {
    this.valueChange.emit(voucher);
  }

}
