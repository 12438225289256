/**
 * @NoDeliveryHandle
 */

import { Carrier, CarrierCode, CarrierServiceCanpost } from '../carrier';
import { AdditionalOption } from './btc-service-option.interface';

export type NoDeliveryHandleOption<T> = {
    label: string;
    note?: string;
    code: T;
};

export enum NoDeliveryHandleCodeCanpost {
    RASE = 'RASE',
    RTS = 'RTS',
    ABAN = 'ABAN'
}

export type NoDeliveryHandleCanpost = keyof typeof NoDeliveryHandleCodeCanpost;

export type NoDeliveryHandle = {
    [CarrierCode.canpost]: {
        [key in CarrierServiceCanpost]?: Partial<Record<NoDeliveryHandleCodeCanpost, AdditionalOption<NoDeliveryHandleCodeCanpost>>>
    };
};

export type NdhItem<T> = {
    hidden?: boolean;
    carrier: Carrier;
    blocked?: boolean;
    code?: {
        value?: T;
        blocked?: boolean;
        options?: T[];
    };
    carrierService: CarrierServiceCanpost
};

