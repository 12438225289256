import { IBtcShippingIdcard, ShippingDocTypeCode } from '../../interfaces/shipping';
import { BtcShippingAddress } from './btc-shipping.address.model';
import { BTCShippingDoc } from './btc-shipping.doc.model';

export class BtcShippingIdcard {
    private _data: IBtcShippingIdcard;
    private _recto: BTCShippingDoc<ShippingDocTypeCode.id_card_recto>;
    private _verso: BTCShippingDoc<ShippingDocTypeCode.id_card_verso>;
    private _address: BtcShippingAddress;

    constructor(data: IBtcShippingIdcard) {
        this._data = data;
        this._recto = data?.recto ? new BTCShippingDoc(data?.recto) : null;
        this._verso = data?.verso ? new BTCShippingDoc(data?.verso) : null;
        this._address = data?.address ? new BtcShippingAddress(data.address) : null;
    }

    get invalid(): boolean {

        if (!this.docnumber) {
            return true;
        }

        if (!this.recto?.file) {
            return true;
        }

        if (!this.verso?.file) {
            return true;
        }

        return false;
    }

    get docnumber(): string {
        return this._data?.docnumber;
    }

    get name(): string {
        return this._data?.name;
    }

    get recto(): BTCShippingDoc<ShippingDocTypeCode.id_card_recto> {
        return this._recto;
    }

    set recto(value: BTCShippingDoc<ShippingDocTypeCode.id_card_recto>) {
        this._recto = value;
    }

    get verso(): BTCShippingDoc<ShippingDocTypeCode.id_card_verso> {
        return this._verso;
    }

    set verso(value: BTCShippingDoc<ShippingDocTypeCode.id_card_verso>) {
        this._verso = value;
    }

    get address(): BtcShippingAddress {
        return this._address;
    }
}
