import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RateEstimateComponent } from './rate-estimate/rate-estimate.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTypographyModule } from 'ng-zorro-antd/typography';



@NgModule({
  declarations: [
    RateEstimateComponent
  ],
  imports: [
    CommonModule,
    NzAlertModule,
    NzIconModule,
    NzDescriptionsModule,
    IndicatorSharedModule,
    NzDividerModule,
    NzTypographyModule
  ],
  exports: [
    RateEstimateComponent
  ]
})
export class ShippingRateSharedModule { }
