export class InteractiveRetrieve {
    /* Default values of Retrieve v2.10 */
    protocol = 'https://'; // default protocol https
    version = '2.1'; // default version
    url = 'ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/';
    method = 'json3ex.ws';
    key = 'unknow';
    Id = '';  //

    Init(data) {
        if (data.key) {
            this.key = data.key;
        }

        switch (data.protocol) {
            case 'http':
                this.protocol = 'http://';
                break;
            default:
                break;
        }

        if (data.version) {
           this.version = data.version;
        }

        switch (data.method) {
            case 'xmla':
                this.method = 'xmla.ws';
                break;
            case 'xmle':
                this.method = 'xmle.ws';
                break;
            case 'json':
                this.method = 'json.ws';
                break;
            case 'json2':
                this.method = 'json2.ws';
                break;
            case 'json3':
                this.method = 'json3.ws';
                break;
            default:
                break;
        }


        if (data.Id) {
            this.Id = data.Id;
        }


        const params = [
            { name: 'Id', value: this.Id },
        ];

        return {
            protocol: this.protocol,
            url: this.url + this.version + '/',
            key: this.key,
            method: this.method,
            params
        };
    }
}
