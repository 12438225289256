import { IAddressRecipient, IAddressRecipientIdCard } from '../../interfaces/address';

export class RecipientIdCard {
    private _card: IAddressRecipientIdCard;

    constructor(data: IAddressRecipientIdCard) {
        this._card = data;
    }

    get contactPerson(): string {

        if (!this.recipient) {
            return null;
        }

        const { contactPerson, contactPersonLocalized } = this.recipient;

        return `${contactPerson} ${contactPersonLocalized ? contactPersonLocalized : ''}`;

    }

    get recipient(): IAddressRecipient {
        return this._card && typeof this._card.recipient !== 'string' ? this._card.recipient : null;
    }

    get card(): IAddressRecipientIdCard {
        return this._card;
    }
}
