import { IEta, IRate } from '../../interfaces/rate';
import { ChargeQuote } from './charge-quote.model';
import { PickupQuote } from './pickup-quote.model';
import { RateQuote } from './rate-quote.model';

export class Rate {

    private _data: IRate;

    constructor(data: IRate) {
        this._data = data;
    }

    get charge(): ChargeQuote {
        return this._data?.charge ? new ChargeQuote(this._data.charge) : null;
    }


    get cost(): RateQuote {
        return this._data?.cost ? new RateQuote(this._data.cost) : null;
    }

    get profit(): RateQuote {
        return this._data?.profit ? new RateQuote(this._data.profit) : null;
    }

    get pickup(): PickupQuote {
        return this._data?.pickup ? new PickupQuote(this._data.pickup) : null;
    }

    get waybills(): any[] {
        return this._data.waybills;
    }

    get eta(): string {
        let str = null;

        const { expectedDeliveryDate, expectedTransitTime, expectedTransitTimeMax } = this._data.eta;
        if (expectedDeliveryDate) {
            str = expectedDeliveryDate;
            return str;
        }

        if (expectedTransitTime) {
            str = `${expectedTransitTime}`;
        }

        if (expectedTransitTimeMax) {
            str += ` - ${expectedTransitTimeMax}`;
        }

        if (str) {
            str += '工作日';
        }

        return str;
    }

}
