<nz-steps [(nzCurrent)]="current" nzSize="small" nzDirection="horizontal">
  <nz-step [nzTitle]="'取货信息'" [nzDescription]="''"> </nz-step>
  <nz-step [nzTitle]="'包裹列表'" [nzDescription]="''"> </nz-step>
  <nz-step [nzTitle]="'提交'" [nzDescription]="''"> </nz-step>
</nz-steps>
<div class="steps-content">
  <ng-container *ngIf="current === 0">
    <form
      nz-form
      [formGroup]="validateForm"
      (ngSubmit)="to_waybill_list()"
      nzLayout="horizontal"
    >
      <carrier-selector
        [validateForm]="validateForm"
        [layout]="null"
        [allowed]="allowed"
        [name]="'waybillCarrierCode'"
        [placeholder]="'请选择...'"
        (valueChange)="on_carrier_change($event)"
      ></carrier-selector>
      <nz-form-item>
        <nz-form-label nzRequired>取件地址说明</nz-form-label>
        <nz-form-control
          [nzErrorTip]="validateForm | formError: 'instructions'"
        >
          <input
            type="text"
            nz-input
            formControlName="instructions"
            placeholder="例: Enter by Back door"
          />
        </nz-form-control>
      </nz-form-item>

      <ng-container *ngIf="validateForm | formGroup: 'date' as grp">
        <pickup-date-selector [validateForm]="grp"> </pickup-date-selector>
      </ng-container>

      <pickup-time-selector
        [validateForm]="validateForm"
        [waybillCarrierCode]="this.validateForm.value?.waybillCarrierCode"
      >
      </pickup-time-selector>

      <nz-form-item nzJustify="end">
        <button nz-button nzType="primary">
          下一步 <i nz-icon nzType="arrow-right"></i>
        </button>
      </nz-form-item>
    </form>
  </ng-container>

  <ng-container *ngIf="current === 1">
    <pickup-waybill-selector
      [pickup]="pickup"
      [waybillCarrierCode]="carrierCode"
      [unit]="unit$ | async"
      [(waybills)]="waybills"
    ></pickup-waybill-selector>

    <div nz-row nzJustify="end">
      <div nz-col>
        <button nz-button nzType="primary" nzGhost (click)="current = 0">
          <i nz-icon nzType="arrow-left"></i> 上一步
        </button>
        <nz-divider nzType="vertical"></nz-divider>
        <button
          nz-button
          nzType="primary"
          [disabled]="!waybills.length"
          (click)="to_summary()"
        >
          下一步 <i nz-icon nzType="arrow-right"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="current === 2">
    <nz-descriptions nzTitle="取货信息">
      <nz-descriptions-item nzTitle="承运商" nzSpan="3">
        {{
          this.validateForm.value.waybillCarrierCode
            | waybillName
            | nzSafeNull: "未知"
        }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="取货地说明" nzSpan="3">
        {{ this.validateForm.value.instructions }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="取货时间" nzSpan="3">
        {{
          this.validateForm.value.date.pickupDate
            | amLocale: i18n.getLocaleId()
            | amDateFormat: "ll"
        }}
        <nz-divider nzType="vertical"></nz-divider>
        从
        {{ this.validateForm.value.date.readyTime }}
        至
        {{ this.validateForm.value.date.closeTime }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="货物总重" nzSpan="3">
        {{ waybills | waybillWeight: (unit$ | async).weight:true }}
      </nz-descriptions-item>
    </nz-descriptions>

    <nz-list [nzDataSource]="waybills" [nzRenderItem]="item">
      <ng-template #item let-i>
        <nz-list-item>
          <nz-list-item-meta [nzTitle]="itemTitle" [nzDescription]="itemDesc">
            <ng-template #itemTitle>
              {{ i.shippingNumber }}
            </ng-template>
            <ng-template #itemDesc>
              {{ i.from.addressLine1 }}, {{ i.from.city }}
              {{ i.from.postalCode }}
            </ng-template>
          </nz-list-item-meta>
        </nz-list-item>
      </ng-template>
    </nz-list>

    <div nz-row nzJustify="end">
      <div nz-col>
        <button nz-button nzType="primary" nzGhost (click)="to_waybill_list()">
          <i nz-icon nzType="arrow-left"></i> 上一步
        </button>
        <nz-divider nzType="vertical"></nz-divider>
        <button
          nz-button
          nzType="primary"
          nzDanger
          [disabled]="!waybills.length"
          (click)="on_submit()"
        >
          <i nz-icon nzType="save"></i> 保存
        </button>
      </div>
    </div>
  </ng-container>
</div>
