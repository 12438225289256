import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'break'
})
export class BreakPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): string {
    const content = value ?? '';
    return content.split('\n').join('<br>');
  }

}
