<ng-template #listHeaderTpl>
  <nz-row nzJustify="end">
    <nz-col>
      <nz-pagination
        [nzPageIndex]="queryParams.page"
        [nzTotal]="count"
        nzShowSizeChanger
        nzSimple
        nzSize="small"
        [nzPageSize]="queryParams.limit"
        (nzPageIndexChange)="on_page_change($event)"
        (nzPageSizeChange)="on_size_change($event)"
      ></nz-pagination>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #listFooterTpl>
  <div nz-row nzGutter="[10,20]" nzJustify="end">
    <div nz-col nzLg="12" nzXs="24">
      <nz-list-pagination>
        <nz-pagination
          [nzPageIndex]="queryParams.page"
          [nzTotal]="count"
          nzShowSizeChanger
          [nzPageSize]="queryParams.limit"
          nzSimple
          nzSize="small"
          (nzPageIndexChange)="on_page_change($event)"
          (nzPageSizeChange)="on_size_change($event)"
        ></nz-pagination>
      </nz-list-pagination>
    </div>
  </div>
</ng-template>
<div nz-col nzSpan="24">
  <nz-list
    nzItemLayout="vertical"
    nzSize="small"
    [nzFooter]="listFooterTpl"
    [nzHeader]="listHeaderTpl"
    [nzLoading]="isLoading"
  >
    <nz-list-empty
      *ngIf="vouchers.length === 0"
      [nzNoResult]="EmptyList"
    ></nz-list-empty>

    <nz-list-item *ngFor="let item of vouchers" style="margin-top: 20px">
      <nz-skeleton
        *ngIf="!item"
        [nzAvatar]="true"
        [nzParagraph]="{ rows: 1 }"
      ></nz-skeleton>
      <ng-container *ngIf="item">
        <nz-list-item-meta [nzAvatar]="listItemAvatarTpl">
          <nz-list-item-meta-title>
            <div nz-row>
              <div nz-col nzFlex="200">
                {{ item.title }}<br />
                <small nz-typography nzDisabled *ngIf="item.expireAt">
                  有效期:
                  {{
                    item.expireAt
                      | amLocale : i18n.getLocaleId()
                      | amDateFormat : "ll"
                  }}
                  前使用
                </small>
                <small nz-typography nzDisabled *ngIf="!item.expireAt">
                  有效期: 永久
                </small>
              </div>
              <div nz-col nzFlex="fill">
                <a
                  (click)="select(item)"
                  nz-button
                  nzType="primary"
                  nzShape="round"
                  nzGhost
                >
                  <i nz-icon nzType="check"></i> 选择
                </a>
              </div>
            </div>
          </nz-list-item-meta-title>
          <ng-template #listItemAvatarTpl>
            <nz-avatar
              [nzShape]="'square'"
              [nzSrc]="'/assets/img/svg/shop/029-coupon.svg'"
              [nzAlt]="item.shipmentType"
              [nzText]="item.shipmentNumber"
              [nzSize]="25"
            ></nz-avatar>
          </ng-template>
          <nz-list-item-meta-description>
            <ng-container>
              <p>
                额度:
                <span *ngIf="item.rate"> {{ item.rate }} % </span>
                <span *ngIf="item.amount">
                  {{ item.amount | currency : "CAD" : "symbol-narrow" }}
                </span>
              </p>
            </ng-container>
            <p>
              {{ item.descriptionShort }}
            </p>
          </nz-list-item-meta-description>
        </nz-list-item-meta>

        <nz-list-item-extra> </nz-list-item-extra>
      </ng-container>
    </nz-list-item>
  </nz-list>
</div>

<!-- </nz-spin> -->
<ng-template #EmptyList>
  <nz-empty
    nzNotFoundImage="/assets/img/svg/shop/029-coupon.svg"
    [nzNotFoundContent]="contentTpl"
    [nzNotFoundFooter]="footerTpl"
  >
    <ng-template #contentTpl>
      <span *ngIf="!isLoading"> 您还没有代金券 </span>
    </ng-template>
    <ng-template #footerTpl>
      <!-- <a nz-button nzType="primary" [routerLink]="['/app/order/create']">
        寄快递
      </a> -->
    </ng-template>
  </nz-empty>
</ng-template>
