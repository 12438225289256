import { PAYMENT_METHOD_LIST, PAYMENT_TYPE } from '../../constants/payment';
import { ITag } from '../../interfaces/common';
import { IPaymentType, PaymentMethod } from '../../interfaces/payment';
import { IBtcPayment } from '../../interfaces/payment';
import { BTCPaymentRefund } from './btc-payment.refund.model';

export class BTCPayment {

    private _data: IBtcPayment;

    constructor(data: IBtcPayment) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id ?? '无效';
    }

    get methodName(): string {
        const method = PAYMENT_METHOD_LIST.find(item => item.code === this._data?.methodCode);

        return method ? method.name : null;
    }

    get methodCode(): PaymentMethod {
        return this._data?.methodCode ?? null;
    }


    get createdAt(): Date {
        return this._data?.createdAt ?? null;
    }

    get status(): ITag[] {

        const items = [];
        const paid = this.status_parse('paid', 1, ['blue', '支付成功'], ['default', '未支付']);
        const expired = this.status_parse('expired', 1, ['default', '过期']);
        const canceled = this.status_parse('canceled', 1, ['default', '已取消']);
        const failed = this.status_parse('failed', 1, ['default', '失败']);
        const refunded = this.status_parse('refunded', 1, ['default', '全额退款']);
        const refundedPending = this.status_parse('refunded', 2, ['orange', '退款处理中']);
        const refundedPartial = this.status_parse('refunded', 3, ['default', '部分退款']);
        const pending = this.status_parse('pending', 1, ['default', '待支付']);
        const abort = this.status_parse('abort', 1, ['orange', '支付对话终止']);
        if (paid && !failed && !pending) {
            items.push(paid);
        }

        if (refundedPartial) {
            items.push(refundedPartial);
        }

        if (refundedPending) {
            items.push(refundedPending);
        }

        if (refunded) {
            items.push(refunded);
        }

        if (canceled) {
            items.push(canceled);
        }

        if (expired) {
            items.push(expired);
        }

        if (failed) {
            items.push(failed);
        }

        if (pending) {
            items.push(pending);
        }

        if (abort) {
            items.push(abort);
        }

        return items;

    }

    get refunded(): boolean {
        return this._data.refunded ? true : false;
    }

    get refundedPartial(): boolean {
        return this._data.refunded === 3 ? true : false;
    }

    get canceled(): boolean {
        return this._data.canceled ? true : false;
    }

    get expired(): boolean {
        return this._data.expired ? true : false;
    }

    get failed(): boolean {
        return this._data.failed ? true : false;
    }

    get pending(): boolean {
        return this._data.pending ? true : false;
    }

    get paid(): boolean {
        return this._data.paid ? true : false;
    }

    get abort(): boolean {
        return this._data.abort ? true : false;
    }


    get chargeable(): boolean {
        return this._data.chargeable ? true : false;
    }


    get succeeded(): boolean {
        return this._data.succeeded ? true : false;
    }

    get due(): number {
        return this._data?.amount ?? 0;
    }

    get refundedAmount(): number {
        return this._data?.refundedAmount ?? 0;
    }

    get refundedReason(): string {
        return this._data?.refundedReason ?? '无';
    }

    get transaction(): string {
        return this._data?.transaction ?? '无';
    }

    get type(): IPaymentType | null {
        return PAYMENT_TYPE[this._data?.type];
    }


    get currency(): string {
        return this._data?.currency?.toUpperCase() ?? 'CAD';
    }

    get livemode(): boolean {
        return this._data?.livemode ? true : false;
    }

    get version(): number {
        return this._data?.version;
    }

    get refunds(): BTCPaymentRefund[] {
        const refunds = this._data?.refunds ?? [];
        return refunds.map(refund => new BTCPaymentRefund(refund));
    }

    status_parse(prop: string, value: string | number, tagTrue: string[], tagFalse?: string[]): ITag | null {

        if (prop in this._data) {

            if (this._data[prop] === value) {
                return {
                    color: tagTrue[0],
                    text: tagTrue[1],
                };
            } else {
                if (tagFalse) {
                    return {
                        color: tagFalse[0],
                        text: tagFalse[1],
                    };
                }
            }
        }

        return null;
    }
}
