import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { RfeDetailComponent } from './rfe-detail/rfe-detail.component';
import { RfeFormComponent } from './rfe-form/rfe-form.component';
import { RfeEditComponent } from './rfe-edit/rfe-edit.component';



@NgModule({
  declarations: [
    RfeDetailComponent,
    RfeEditComponent,
    RfeFormComponent
  ],
  providers: [
    CurrencyPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzDescriptionsModule,
    NzIconModule,
    NzTagModule,
    NzTypographyModule,
    NzToolTipModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    NzRadioModule,
    NzCheckboxModule,
    NzButtonModule,
    NzDividerModule,
    NzDrawerModule,
    IndicatorSharedModule
  ],
  exports: [
    RfeDetailComponent,
    RfeEditComponent,
    RfeFormComponent
  ]
})
export class ShippingRfeSharedModule { }
