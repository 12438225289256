import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityFormatComponent } from './entity-format/entity-format.component';
import { EntityFormatFormComponent } from './entity-format-form/entity-format-form.component';
import { EntityFormatEditComponent } from './entity-format-edit/entity-format-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { UnitModule } from 'src/app/shared/unit/unit.module';
import { UtilsModule } from 'src/app/shared/utils/utils.module';


@NgModule({
  declarations: [
    EntityFormatComponent,
    EntityFormatFormComponent,
    EntityFormatEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzCheckboxModule,
    NzRadioModule,
    NzCardModule,
    NzButtonModule,
    NzIconModule,
    NzDividerModule,
    NzGridModule,
    UnitModule,
    UtilsModule,
    IndicatorSharedModule
  ],
  exports: [
    EntityFormatComponent,
    EntityFormatFormComponent,
    EntityFormatEditComponent
  ],
})
export class ShippingFormatSharedModule { }
