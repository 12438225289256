import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

const AppRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule)
      }
    ]
  },
  {
    path: 'test',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule)
      }
    ]
  },
  { path: 'forwarding', loadChildren: () => import('./pages/app/forwarding/forwarding.module').then(m => m.ForwardingModule) },

  {
    path: '**',
    redirectTo: 'redirect/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, {
    scrollPositionRestoration: 'top'
  }), CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
