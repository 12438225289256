import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentPipe } from './pipes/payment/payment.pipe';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { IndicatorSharedModule } from '../indicator';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { MethodSelectorRechargeComponent } from './components/method-selector-recharge/method-selector-recharge.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PaymentSearchFormComponent } from './components/payment-search-form/payment-search-form.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormSharedModule } from '../utils/form';
import { PaymentPageHeaderComponent } from './components/payment-page-header/payment-page-header.component';
import { BalanceInsufficientPipe } from './pipes/balance-insufficient/balance-insufficient.pipe';
import { MethodSelectorComponent } from './components/method-selector/method-selector.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { HasCurrencyPipe } from 'src/app/core/payment/pipes/has-currency';



@NgModule({
  declarations: [
    PaymentPipe,
    QrCodeComponent,
    MethodSelectorRechargeComponent,
    MethodSelectorComponent,
    PaymentSearchFormComponent,
    PaymentPageHeaderComponent,
    BalanceInsufficientPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IndicatorSharedModule,
    FormSharedModule,
    QRCodeModule,
    HasCurrencyPipe,


    NzFormModule,
    NzRadioModule,
    NzInputNumberModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
    NzSpinModule,
    NzToolTipModule,
    NzSelectModule,
    NzDividerModule,
    NzRadioModule,
    NzSpinModule,
    NzTypographyModule,
    NzCheckboxModule,
  ],
  exports: [
    PaymentPipe,
    MethodSelectorRechargeComponent,
    MethodSelectorComponent,
    PaymentSearchFormComponent,
    PaymentPageHeaderComponent,
    BalanceInsufficientPipe
  ]
})
export class PaymentSharedModule { }
