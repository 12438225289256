import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LivemodeIndicatorComponent, LivemodeSwitchComponent } from './components';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FormsModule } from '@angular/forms';
import { NzTypographyModule } from 'ng-zorro-antd/typography';


@NgModule({
  declarations: [
    LivemodeSwitchComponent,
    LivemodeIndicatorComponent
  ],
  exports: [
    LivemodeSwitchComponent,
    LivemodeIndicatorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzSwitchModule,
    NzDividerModule,
    NzTypographyModule,
  ]
})
export class LivemodeModule { }
