<ng-container *ngIf="rate">
  <ng-template #beforeTaxTpl>
    <strong nz-typography>优邮价格</strong>
  </ng-template>
  <ng-template #taxTpl>
    <strong nz-typography>税</strong>
  </ng-template>
  <ng-template #totalTpl>
    <strong nz-typography>总计</strong>
  </ng-template>
  <nz-descriptions nzSize="small">
    <ng-container *ngIf="rate.charge.msrp">
      <nz-descriptions-item nzTitle="柜台价" [nzSpan]="3">
        <span nz-typography nzType="danger">
          <del>
            {{
              rate.charge.msrp
                | currency : rate.charge.currency : "symbol-narrow"
            }}
          </del>
        </span>
      </nz-descriptions-item>
    </ng-container>
    <nz-descriptions-item [nzTitle]="beforeTaxTpl" [nzSpan]="3">
      {{
        rate.charge.base + rate.charge.basePlus
          | currency : rate.charge.currency : "symbol-narrow"
      }}
    </nz-descriptions-item>

    <!-- <nz-descriptions-item nzTitle="基础运费" [nzSpan]="3">
      {{ rate.charge.base | currency: rate.charge.currency:"symbol-narrow" }}
    </nz-descriptions-item>

    <ng-container *ngIf="rate.charge.basePlus">
      <nz-descriptions-item nzTitle="其他" [nzSpan]="3">
        {{
          rate.charge.basePlus | currency: rate.charge.currency:"symbol-narrow"
        }}
      </nz-descriptions-item>
    </ng-container> -->

    <nz-descriptions-item [nzTitle]="taxTpl" [nzSpan]="3">
      <ng-container *ngIf="!rate.charge.taxes.length"> $0 </ng-container>
      <ng-container *ngFor="let tax of rate.charge.taxes">
        <nz-descriptions nzSize="small">
          <nz-descriptions-item [nzTitle]="tax.name" [nzSpan]="3">
            {{ tax.amount | currency : rate.charge.currency : "symbol-narrow" }}
          </nz-descriptions-item>
        </nz-descriptions>
      </ng-container>
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="totalTpl" [nzSpan]="3">
      {{ rate.charge.due | currency : rate.charge.currency : "symbol-narrow" }}
    </nz-descriptions-item>
  </nz-descriptions>

  <ng-container *ngIf="rate.charge.cashback || rate.charge.point">
    <nz-divider nzText="优邮 Bonus" nzPlain nzOrientation="left"></nz-divider>
    <nz-descriptions nzSize="small">
      <nz-descriptions-item
        nzTitle="积分"
        [nzSpan]="3"
        *ngIf="rate.charge.point as point"
      >
        {{ point.count }} 分
      </nz-descriptions-item>

      <nz-descriptions-item
        [nzTitle]="cashback.event | cashbackName | nzSafeNull : '未知'"
        [nzSpan]="3"
        *ngIf="rate.charge.cashback as cashback"
      >
        {{ cashback.count | currency : cashback.currency : "symbol-narrow" }}
      </nz-descriptions-item>
    </nz-descriptions>
  </ng-container>

  <div *hasRole="['devops_tester', 'admin_accounting']">
    <nz-divider></nz-divider>

    <nz-card nzTitle="管理可见" nzType="inner" nzSize="small">
      <ng-container *ngIf="rate.cost as cost">
        <nz-divider nzText="承运商" nzPlain nzOrientation="left"></nz-divider>
        <nz-descriptions nzSize="small">
          <nz-descriptions-item [nzTitle]="beforeTaxTpl" [nzSpan]="3">
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="基础运费" [nzSpan]="3">
            {{ cost.base | currency : cost.currency : "symbol-narrow" }}
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="附加运费" [nzSpan]="3">
            {{ cost.basePlus | currency : cost.currency : "symbol-narrow" }}
          </nz-descriptions-item>

          <nz-descriptions-item [nzTitle]="taxTpl" [nzSpan]="3">
            <ng-container *ngIf="!rate.charge.taxes.length"> $0 </ng-container>
          </nz-descriptions-item>

          <ng-container *ngFor="let tax of cost.taxes">
            <nz-descriptions-item [nzTitle]="tax.name" [nzSpan]="3">
              {{ tax.amount | currency : cost.currency : "symbol-narrow" }}
            </nz-descriptions-item>
          </ng-container>

          <nz-descriptions-item [nzTitle]="totalTpl" [nzSpan]="3">
            {{ cost.due | currency : cost.currency : "symbol-narrow" }}
          </nz-descriptions-item>

          <ng-container *ngFor="let item of cost.priceDetails">
            <nz-descriptions-item [nzTitle]="item.name" [nzSpan]="3">
              {{ item.price | currency : cost.currency : "symbol-narrow" }}
            </nz-descriptions-item>
          </ng-container>

          <nz-descriptions-item nzTitle="msrp" [nzSpan]="3">
            {{ cost.msrp | currency : cost.currency : "symbol-narrow" }}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="msrpSaving" [nzSpan]="3">
            {{ cost.msrpSaving | currency : cost.currency : "symbol-narrow" }}
          </nz-descriptions-item>
        </nz-descriptions>
      </ng-container>

      <ng-container *ngIf="rate.profit as profit">
        <nz-divider nzText="优邮" nzPlain nzOrientation="left"></nz-divider>
        <nz-descriptions nzSize="small">
          <nz-descriptions-item [nzTitle]="beforeTaxTpl" [nzSpan]="3">
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="基础运费" [nzSpan]="3">
            {{ profit.base | currency : profit.currency : "symbol-narrow" }}
          </nz-descriptions-item>

          <nz-descriptions-item nzTitle="附加运费" [nzSpan]="3">
            {{ profit.basePlus | currency : profit.currency : "symbol-narrow" }}
          </nz-descriptions-item>
          <ng-container *ngFor="let item of profit.priceDetails">
            <nz-descriptions-item [nzTitle]="item.name" [nzSpan]="3">
              {{ item.price | currency : profit.currency : "symbol-narrow" }}
            </nz-descriptions-item>
          </ng-container>
          <nz-descriptions-item [nzTitle]="taxTpl" [nzSpan]="3">
            <ng-container *ngIf="!rate.charge.taxes.length"> $0 </ng-container>
          </nz-descriptions-item>

          <ng-container *ngFor="let tax of profit.taxes">
            <nz-descriptions-item [nzTitle]="tax.name" [nzSpan]="3">
              {{ tax.amount | currency : profit.currency : "symbol-narrow" }}
            </nz-descriptions-item>
          </ng-container>
          <nz-descriptions-item [nzTitle]="totalTpl" [nzSpan]="3">
            {{ profit.due | currency : profit.currency : "symbol-narrow" }}
          </nz-descriptions-item>
        </nz-descriptions>
      </ng-container>

      <ng-container *ngIf="rate.pickup as pickup">
        <nz-divider nzText="预约取货" nzPlain nzOrientation="left"></nz-divider>
        <nz-descriptions nzSize="small">
          <nz-descriptions-item nzTitle="税前" [nzSpan]="3">
            {{ pickup.base | currency : pickup.currency : "symbol-narrow" }}
          </nz-descriptions-item>

          <nz-descriptions-item [nzTitle]="taxTpl" [nzSpan]="3">
            <ng-container *ngIf="!rate.charge.taxes.length"> $0 </ng-container>
          </nz-descriptions-item>

          <ng-container *ngFor="let tax of pickup.taxes">
            <nz-descriptions-item [nzTitle]="tax.name" [nzSpan]="3">
              {{ tax.amount | currency : pickup.currency : "symbol-narrow" }}
            </nz-descriptions-item>
          </ng-container>

          <ng-container *ngFor="let item of pickup.priceDetails">
            <nz-descriptions-item [nzTitle]="item.name" [nzSpan]="3">
              {{ item.price | currency : pickup.currency : "symbol-narrow" }}
            </nz-descriptions-item>
          </ng-container>

          <nz-descriptions-item nzTitle="总计" [nzSpan]="3">
            {{ pickup.due | currency : pickup.currency : "symbol-narrow" }}
          </nz-descriptions-item>
        </nz-descriptions>
      </ng-container>

      <ng-container *ngIf="rate.waybills as waybills">
        <nz-divider nzText="价格算法" nzPlain nzOrientation="left"></nz-divider>
        <pre *ngFor="let waybill of waybills">
          {{ waybill.algorithm | json }}
      </pre
        >

        <nz-divider
          nzText="Waybills Data"
          nzPlain
          nzOrientation="left"
        ></nz-divider>

        <pre *ngFor="let waybill of waybills">
        {{ waybill | json }}
    </pre
        >
      </ng-container>
    </nz-card>
  </div>
</ng-container>
