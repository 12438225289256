import { DomSanitizer } from '@angular/platform-browser';
import { ISupportContactAsset } from '../../interfaces/support';

export class SupportContactAsset implements ISupportContactAsset {
    private _sanitizer: DomSanitizer;
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    keys?: any;
    description?: any;
    filename?: string;
    size?: number;
    type?: string;
    data?: string;
    contact?: string;

    constructor(private rawData: ISupportContactAsset, sanitizer?: DomSanitizer) {

        Object.assign(this, this.rawData);

        this._sanitizer = sanitizer;
    }

    get safeImg() {
        const img = `data:${this.type};base64,${this.data}`;
        return this._sanitizer.bypassSecurityTrustResourceUrl(img);
    }

    get bgStyle() {
        const img = `data:${this.type};base64,${this.data}`;
        return {
            'background-image': `url("${img}")`,
            'background-size': 'cover',
            'background-position': 'center'
        };
    }
}
