import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { PickupEvent } from 'src/app/common/interfaces/pickup';
import { NavigationService } from 'src/app/core/navigation';
import { PageHeaderService } from 'src/app/core/page-header';

@Component({
  selector: 'pickup-header-create',
  templateUrl: './pickup-header-create.component.html',
  styleUrls: ['./pickup-header-create.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupHeaderCreateComponent implements OnInit, AfterViewInit {


  @Output() eventChange = new EventEmitter<PickupEvent>();
  @ViewChild('buttonsTpl') buttonsTpl: TemplateRef<any>;

  constructor(
    public ph: PageHeaderService,
    public nav: NavigationService,
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.view_update();
  }


  view_update(): void {
    this.ph.buttons = this.buttonsTpl;
  }


}

