export const GenderList = [
    {
        name: '先生',
        value: 'male'
    },
    {
        name: '女士',
        value: 'female'
    }
];
