export interface IBtcShippingDoc<T> {
    createdAt: Date;
    updatedAt: Date;
    id: string;
    livemode: number;
    version: number;
    status: number;
    doctype: T;
    docnumber?: any;
    originId: string;
    name?: string;
    filename?: any;
    filesize: number;
    filetype?: any;
    file?: any;
    token: string;
    user: string;
    address: string;
    shipping: string;
}

export enum ShippingDocTypeCode {
    id_card_recto = 'id_card_recto',
    id_card_verso = 'id_card_verso'
}

export type ShippingDocType = keyof typeof ShippingDocTypeCode;
export type ShippingDocPropsItem<T> = {
    name: string;
    code: T
};

export type ShippingDocProps = {
    [code in ShippingDocTypeCode]: ShippingDocPropsItem<code>
};


