<pickup-header-detail
  [pickup]="pickup"
  (eventChange)="on_event_change($event)"
></pickup-header-detail>

<ng-container *ngIf="!isLoading; else loadingTpl">
  <ng-container *ngIf="pickup; then pickupTpl; else emptyTpl"> </ng-container>
</ng-container>

<ng-template #loadingTpl>
  <i nz-icon nzType="loading"></i> 加载中...
</ng-template>

<ng-template #emptyTpl>
  <nz-empty
    nzNotFoundImage="/assets/img/svg/parcel/035-calendar.svg"
    [nzNotFoundContent]="contentTpl"
    [nzNotFoundFooter]="footerTpl"
  >
    <ng-template #contentTpl>
      <span> 没有记录 </span>
    </ng-template>
    <ng-template #footerTpl>
      <a
        [routerLink]="['/app/pickups']"
        nz-button
        nzType="primary"
        nzDanger
        nzGhost
      >
        <i nz-icon nzType="arrow-left"></i> 返回列表
      </a>
    </ng-template>
  </nz-empty>
</ng-template>

<ng-template #pickupTpl>
  <nz-descriptions nzTitle="取货信息">
    <nz-descriptions-item nzTitle="承运商" nzSpan="3">
      {{ pickup.carrier.name }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="取货地说明" nzSpan="3">
      {{ pickup.instructions }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="取货时间" nzSpan="3">
      {{
        pickup.date.pickupDate
          | amLocale: i18n.getLocaleId()
          | amDateFormat: "ll"
      }}
      <nz-divider nzType="vertical"></nz-divider>
      从
      {{ pickup.date.readyTime }}
      至
      {{ pickup.date.closeTime }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="货物总重" nzSpan="3">
      {{ pickup?.waybills | waybillWeight: (unit$ | async).weight:true }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="状态">
      <ng-container *ngFor="let tag of pickup.status">
        <nz-tag [nzColor]="tag.color">{{ tag.text }}</nz-tag>
      </ng-container>
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-list [nzDataSource]="pickup.waybills" [nzRenderItem]="item">
    <ng-template #item let-i>
      <nz-list-item>
        <nz-list-item-meta [nzTitle]="itemTitle" [nzDescription]="itemDesc">
          <ng-template #itemTitle>
            {{ i.shippingNumber }}
          </ng-template>
          <ng-template #itemDesc>
            {{ i.from.addressLine1 }}, {{ i.from.city }}
            {{ i.from.postalCode }}
          </ng-template>
        </nz-list-item-meta>
      </nz-list-item>
    </ng-template>
  </nz-list>
</ng-template>
