import { IButton } from "src/app/common/interfaces/common";

export const INDICATOR_CONFIRM_BUTTONS: IButton[] = [
    {
        label: '取消',
        icon: undefined,
        type: 'link',
        onClick: () => false,
        size: 'default'
    },
    {
        label: '确定',
        icon: undefined,
        type: 'primary',
        danger: true,
        onClick: () => true,
        size: 'default'
    }
];

export const INDICATOR_ALERT_BUTTONS: IButton[] = [
    {
        label: 'Ok 知道了',
        icon: undefined,
        type: 'primary',
        danger: true,
        size: 'large',
        block: true,
        onClick: () => true,
    }
];

export const INDICATOR_RESULT_BUTTONS: IButton[] = [
    {
        label: '关闭',
        icon: undefined,
        type: 'primary',
        danger: false,
        size: 'default',
        onClick: () => true,
    }
];
