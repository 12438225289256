import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShipmentTracking } from '../../interfaces';
import { TrackingCoreService } from '../../tracking.service';

@Component({
  selector: 'btc-tracking-result-timeline-detail',
  templateUrl: './btc-tracking-result-timeline-detail.component.html',
  styleUrls: ['./btc-tracking-result-timeline-detail.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BtcTrackingResultTimelineDetailComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  @Input() id: string | null = null;

  record: ShipmentTracking<any> | null = null;

  constructor(
    private detection: ChangeDetectorRef,
    private trackingService: TrackingCoreService
  ) { }

  ngOnInit(): void {
    if (this.id) {
      this.track(this.id);
    }
  }

  private track(id: string): void {
    this.isLoading = true;
    this.trackingService.btc_track_by_waybill(id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: res => {
        const record = res.result;
        this.record = record;
        this.isLoading = false;
      }
    });
  }

  refresh(): void {
    if (this.id) {
      this.track(this.id);
    }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.destroy$.next();
  }
}
