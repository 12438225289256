import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Validators, UntypedFormGroup, AbstractControl, FormControl, UntypedFormArray } from '@angular/forms';
import { PATTERNS } from './pattern';


@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  private readonly formSchema: any = {};

  constructor() { }

  parseValidations(modelOptions: any = {}, name: string | null = null) {

    const keys = _.keys(modelOptions);

    const vList = [];

    for (const key of keys) {
      switch (key) {
        case 'required':
          vList.push(Validators.required);
          break;
        case 'max':
          vList.push(Validators.max(modelOptions[key]));
          break;
        case 'min':
          vList.push(Validators.min(modelOptions[key]));
          break;
        case 'minLength':
          vList.push(Validators.minLength(modelOptions[key]));
          break;
        case 'maxLength':
          vList.push(Validators.maxLength(modelOptions[key]));
          break;
        case 'pattern':
          vList.push(Validators.pattern(modelOptions[key]));
          break;

        default:
          break;
      }
    }

    return Validators.compose(vList);
  }

  isRequired(form: UntypedFormGroup, name: string): boolean {
    const controlItem = form.get(name);
    if (!controlItem) {
      return false;
    }
    const obj = {} as AbstractControl;
    const validator = controlItem.validator && controlItem.validator(obj);
    if (validator && validator.required) {
      return true;
    }

    return false;
  }

  // isRequiredArray(form: FormArray, i: number, name: string) {

  //   if (!form[i].get(name).validator) {
  //     return false;
  //   }
  //   const validator = form.get(name).validator({} as AbstractControl);
  //   if (validator && validator.required) {
  //     return true;
  //   }
  // }

  hasControl(form: any, name: string) {
    form = form as UntypedFormGroup;
    if (!form.get(name)) {
      return false;
    } else {
      return true;
    }
  }

  placeholder(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;
    if (!formSchema) {
      return name;
    }

    if (typeof name === 'string') {
      const item = formSchema[name];
      return item && item.placeholder ? item.placeholder : name;
    }
    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && item.placeholder ? item.placeholder : name;
    }
  }

  hasError(form: UntypedFormGroup, name: string) {
    const controlItem = form.get(name);
    if (!controlItem) {
      return 'error';
    }

    if ((!controlItem.dirty && !controlItem.touched)) {
      return '';
    }

    return controlItem.errors ? 'error' : '';
  }

  show(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;

    if (!formSchema) {
      return name;
    }

    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && typeof item.show === 'boolean' && item.show ? true : false;
    } else {
      const item = formSchema[name];
      return item && typeof item.show === 'boolean' && item.show ? true : false;
    }
  }

  hide(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;

    if (!formSchema) {
      return name;
    }

    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && typeof item.hide === 'boolean' && item.hide ? true : false;
    } else {
      const item = formSchema[name];
      return item && typeof item.hide === 'boolean' && item.hide ? true : false;
    }
  }

  valueOnly(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;

    if (!formSchema) {
      return name;
    }
    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && typeof item.valueOnly === 'boolean' && item.valueOnly ? true : false;
    } else {
      const item = formSchema[name];
      return item && typeof item.valueOnly === 'boolean' && item.valueOnly ? true : false;
    }
  }

  disabled(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;
    if (!formSchema) {
      return name;
    }
    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && item.disabled ? true : false;
    } else {
      const item = formSchema[name];
      return item && item.disabled ? true : false;
    }
  }

  maxLength(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;
    if (!formSchema) {
      return name;
    }
    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && item.maxLength ? item.maxLength : Infinity;
    } else {
      const item = formSchema[name];
      return item && item.maxLength ? item.maxLength : Infinity;
    }
  }

  minLength(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;
    if (!formSchema) {
      return name;
    }
    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && item.minLength ? item.minLength : -Infinity;
    } else {
      const item = formSchema[name];
      return item && item.minLength ? item.minLength : -Infinity;
    }
  }

  tips(name: string | string[], schema = null) {
    const formSchema = schema ? schema : this.formSchema;
    if (!formSchema) {
      return null;
    }

    if (Array.isArray(name)) {
      const item = _.get(formSchema, name, null);
      return item && item.tips ? item.tips : null;
    } else {
      const item = formSchema[name];
      return item && item.tips ? item.tips : null;
    }
  }


  message(form: UntypedFormGroup, name: string, messageName: string[] = [], schema = null) {
    const formSchema = schema ? schema : this.formSchema;

    const controlItem = form.get(name);
    if (!controlItem) {
      return '未知错误';
    }

    const errors = controlItem.errors;

    if (!errors) {
      return null;
    }

    if ((!controlItem.dirty && !controlItem.touched)) {
      return null;
    }

    const keys = _.keys(errors);
    for (const key of keys) {
      let messageSchema = null;
      if (messageName.length > 0) {
        messageSchema = formSchema
          && _.get(formSchema, messageName, null)
          && _.get(formSchema, messageName, null).validation
          && _.get(formSchema, messageName, null).validation.messages
          ? _.get(formSchema, messageName, null).validation.messages : null;
      } else {
        messageSchema = formSchema
          && formSchema[name]
          && formSchema[name].validation
          && formSchema[name].validation.messages ? formSchema[name].validation.messages : null;
      }

      switch (key) {
        case 'max':
          return messageSchema && messageSchema.max ? messageSchema.max : '最大值' + errors[key].max;
        case 'min':
          return messageSchema && messageSchema.min ? messageSchema.min : '最小值' + errors[key].min;
        case 'required':
          return messageSchema && messageSchema.required ? messageSchema.required : '不能为空';
        case 'pattern':
          return messageSchema && messageSchema.pattern ? messageSchema.pattern : `文字/格式错误: ${errors[key].requiredPattern}`;
        case 'maxlength':
          return messageSchema && messageSchema.maxLength
            ? messageSchema.maxLength
            : '长度不能多于' + errors[key].requiredLength + ',当前长度' + errors[key].actualLength;
        case 'minlength':
          return messageSchema && messageSchema.minLength
            ? messageSchema.minLength
            : '长度不能少于' + errors[key].requiredLength + ',当前长度' + errors[key].actualLength;
        case 'passconf':
          return messageSchema && messageSchema.passconf ? messageSchema.passconf : ' 密码确认与登录密码不符';
        case 'occupied':
          return messageSchema && messageSchema.occupied ? messageSchema.occupied : ' 已存在';
        default:
          return null;
      }
    }



  }

  markAllAsDirty(form: UntypedFormGroup, debug: boolean = false): void {
    const key = 'controls';
    for (const i in form[key]) {
      if (Object.prototype.hasOwnProperty.call(form[key], i)) {
        const control = form[key][i];
        if (!(key in control)) {
          if (debug) {
            console.log(i, control.errors);
          }
          control.markAsDirty();
          control.updateValueAndValidity();
        } else {
          const subControl = control as UntypedFormGroup;
          this.markAllAsDirty(subControl, debug);
        }
      }
    }
  }

  removeAllItem(items: UntypedFormArray, debug = false): void {
    for (const [i, item] of items.controls.entries()) {
      items.removeAt(i);
    }
  }
  // updateFormSchema(data: any = {}) {
  //   this.formSchema = data;
  // }

  passconf_check(control: AbstractControl): { [key: string]: boolean } | null {
    const formGroup = control as UntypedFormGroup;
    const { password, passconf } = formGroup.value;
    for (const i in formGroup.controls) {
      if (Object.prototype.hasOwnProperty.call(formGroup.controls, i)) {
        const c = formGroup.controls[i];
        if (i === 'passconf' && password !== passconf) {
          c.setErrors({ passconf: true });
        }
      }
    }

    return null;
  }

  isEmail = (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    const reg = new RegExp(/^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/);
    if (
      !reg.test(control?.value)
    ) {
      return { pattern: { requiredPattern: PATTERNS.email.pattern } };
    }
    return null;
  }

  emailByLine = (maxLength: number = 1000, minLength: number = 1) => {

    return (control: AbstractControl) => {
      const reg = new RegExp(/^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/);
      if (!control.value) {
        return null;
      }

      let emailArray = control.value.split('\n') as string[];

      emailArray = emailArray.filter(email => email !== '');

      if (emailArray.length < minLength) {
        return { pattern: { requiredPattern: `最少输入${minLength}行` } };
      }
      if (emailArray.length > maxLength) {
        return { pattern: { requiredPattern: `最多可输入${maxLength}行` } };
      }

      if (!emailArray.every(email => reg.test(email))) {
        return { pattern: { requiredPattern: '含有无效的邮件地址' } };
      }

      return null;
    };
  }
}
