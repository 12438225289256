<form nz-form [formGroup]="validateForm" (ngSubmit)="on_submit()">
  <nz-form-item>
    <nz-form-control>
      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixButton"
        style="width: 100%"
      >
        <input
          nz-input
          formControlName="keywords"
          placeholder="姓名,电话,地址等,多个关键字使用 ',' 逗号分隔."
          style="width: 100%"
        />
      </nz-input-group>
      <ng-template #suffixButton>
        <nz-button-group>
          <button nz-button nzType="primary" nzSearch>搜索</button>
          <button
            nz-button
            nzType="primary"
            nzGhost
            nzDanger
            (click)="keyword_reset()"
            *ngIf="validateForm.value.keywords"
            nzSearch
          >
            <i nz-icon nzType="close-circle" nzTheme="outline"></i>
          </button>
        </nz-button-group>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</form>
