import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocListComponent } from './doc-list/doc-list.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { DocEditComponent } from './doc-edit/doc-edit.component';
import { DocFormComponent } from './doc-form/doc-form.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';



@NgModule({
  declarations: [
    DocListComponent,
    DocEditComponent,
    DocFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzFormModule,
    NzInputModule,
    NzUploadModule,
    NzEmptyModule,
    NzCardModule,
    NzButtonModule,
    NzIconModule,
    NzGridModule,
    NzTypographyModule,
    NzDescriptionsModule,
    NzMessageModule,
    NzModalModule
  ],
  exports: [
    DocListComponent,
    DocEditComponent,
    DocFormComponent
  ]
})
export class ShippingDocSharedModule { }
