import { option } from '../../common/functions';
import { ISelectOption } from '../../common/interfaces';
import { Contractor, ContractorCode } from '../interfaces';

export const CONTRACTORS: Contractor = {
    canpost: {
        text: 'Canada Post',
        code: ContractorCode.canpost,
        query: ContractorCode.canpost,
    },
    ups: {
        text: 'UPS',
        code: ContractorCode.ups,
        query: ContractorCode.ups
    },
    vipshipper: {
        text: 'Vip Shipper',
        code: ContractorCode.vipshipper,
        query: ContractorCode.vipshipper
    },
    jiayun: {
        text: '加运',
        code: ContractorCode.jiayun,
        query: ContractorCode.jiayun
    }
};


export const CONTRACTOR_OPTIONS: ISelectOption[] = option(CONTRACTORS);
