import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PakType } from 'src/app/common/interfaces/pak';
import { IRegion, IRegionServiceControl } from 'src/app/common/interfaces/region';
import { IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { Rate } from 'src/app/common/models/rate';
import { BtcService } from 'src/app/common/models/service';
import { FormValidationService } from 'src/app/core/form';
import { sortBy } from 'lodash';
import { Output } from '@angular/core';
import { IndicatorService } from 'src/app/shared/indicator';

@Component({
  selector: 'service-rate-compare',
  templateUrl: './service-rate-compare.component.html',
  styleUrls: ['./service-rate-compare.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRateCompareComponent implements OnInit {
  private _index: IBtcServiceIndex;
  private _destinationRegion: IRegion;
  private _pakType: PakType | null = null;
  @Input() drawer = false;
  @Input()
  set index(value: IBtcServiceIndex) {
    this._index = value;
    this.build();
    this.current = 0;
  }
  get index(): IBtcServiceIndex {
    return this._index;
  }
  @Input() services: BtcService[];
  @Input() regionServiceControl: IRegionServiceControl;
  @Input() initiationRegion: IRegion;
  @Input()
  set destinationRegion(value: IRegion) {
    this._destinationRegion = value;
    this.current = 0;
  }
  get destinationRegion(): IRegion {
    return this._destinationRegion;
  }
  @Input() multiDestination: number;
  @Input() indexes: IBtcServiceIndex[] = [];
  @Input()
  set pakType(value: PakType | null) {
    this._pakType = value;
    this.current = 0;
  }
  get pakType(): PakType | null {
    return this._pakType ?? null;
  }
  @Output() serviceChange = new EventEmitter<any>();
  current = 0;
  countMax = 1;
  countMin = 1;
  validateForm = new UntypedFormGroup({
    initiation: this.fb.group({
      region: this.fb.control(null, [Validators.required]),
      regionCode: this.fb.control(null, [Validators.required])
    }),
    multiDestination: this.fb.control(null, [Validators.required]),
    paks: this.fb.array([], [Validators.required]),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private detection: ChangeDetectorRef,
    private indicator: IndicatorService
  ) { }

  ngOnInit(): void {
    this.build();
  }

  private build(): void {

    if (!this.multiDestination) {
      this.validateForm.addControl('destination', this.fb.group({
        region: this.fb.control(null, [Validators.required]),
        regionCode: this.fb.control(null, [Validators.required])
      }));
      this.validateForm.addControl('code', this.fb.control(null, [Validators.required]));

      this.countMax = this.pakType === 'parcel' ? this.regionServiceControl.parcelMax : this.regionServiceControl.envMax;
      this.countMin = this.pakType === 'parcel' ? this.regionServiceControl.parcelMin : this.regionServiceControl.envMin;

      this.validateForm.patchValue({
        destination: {
          region: this.destinationRegion,
          regionCode: this.destinationRegion?.isoCode2
        },
        code: this.index?.code
      });

    } else {
      this.validateForm.removeControl('destination');
      this.validateForm.removeControl('code');
      this.countMax = this.regionServiceControl.multiDestinationMax;
      this.countMin = this.regionServiceControl.multiDestinationMin;
    }

    this.validateForm.get('paks').setValidators([Validators.required, Validators.min(this.countMin), Validators.max(this.countMax)]);

    this.validateForm.patchValue({
      multiDestination: this.multiDestination,
      initiation: {
        region: this.initiationRegion,
        regionCode: this.initiationRegion?.isoCode2
      }
    });

  }

  on_index_change(index: number): void {
    this.current = index;
  }

  next_to_service_list(): void {

    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
      const pakForms = this.validateForm.get('paks') as UntypedFormArray;
      const problems = [];
      for (const [i, pakForm] of pakForms.controls.entries()) {
        const group = pakForm as UntypedFormGroup;
        if (group.invalid) {
          problems.push(`包裹 #${i + 1}`);
        }
      }
      this.indicator.alert({
        nzContentParams: {
          message: '请完善包裹规格信息',
          problems,
          type: 'warning'
        }
      });
    } else {
      this.validateForm.enable();
      this.next();
    }
  }

  next_to_package_list(): void {
    const initiation = this.validateForm.get('initiation') as UntypedFormGroup;
    const destination = !this.multiDestination && this.validateForm.get('destination') as UntypedFormGroup;
    if (initiation?.invalid || destination?.invalid) {
      this.fv.markAllAsDirty(initiation);
      this.fv.markAllAsDirty(destination);
    } else {

      this.next();
    }
  }

  next(step?: number) {

    const element = document.querySelector('#scrollId');
    element.scrollIntoView();
    if (typeof step === 'number') {
      this.current = step;
    } else {
      this.current++;
    }

  }

  on_rate_change(i, rate: Rate | null): void {
    this.services[i].rate = rate;
    this.services = sortBy(this.services, [(service) => {
      if (!service.rate) {
        return null;
      }
      return service.rate.charge.due;
    }]);
    this.detection.detectChanges();
  }

  on_service_select(data: any): void {
    if (this.drawer) {
      // this.drawerRef.close(data);
    } else {
      this.serviceChange.emit(data);
    }
  }

  address_selector(type: number): void {

  }

  trackByIndex = (i: number, _: any): number => {
    return i;
  }

}
