import { IBtcShippingPackageTracking } from '../../interfaces/shipping';
import { BtcShippingTrackingStep } from './btc-shipping.tracking.step.model';

export class BtcShippingPackageTracking {
    private _data: IBtcShippingPackageTracking;

    constructor(data: IBtcShippingPackageTracking) {
        this._data = data;
    }

    get data(): IBtcShippingPackageTracking {
        return this._data;
    }

    get current(): number {
        return this._data.current;
    }

    get detail(): boolean {
        return false;
    }

    get notices(): string[] {
        return this._data?.notices ?? [];
    }
    get steps(): BtcShippingTrackingStep[] {
        return this._data?.steps?.map(s => new BtcShippingTrackingStep(s));
    }
}
