import { FormSchema } from '../form';
import { Pattern } from '../pattern';

export * from './service-control.interface';

export enum RegionCode {
    int = 'int',
    cn = 'cn',
    us = 'us',
    ca = 'ca',
    be = 'be',
    eu = 'eu',
    eu0 = 'eu0',
    eu1 = 'eu1',
    eu2 = 'eu2',
    eu3 = 'eu3',
}

export enum ZoneCode {
    int = 'int',
    cn = 'cn',
    us = 'us',
    ca = 'ca',
    be = 'be',
    eu = 'eu',
    eu0 = 'eu0',
    eu1 = 'eu1',
    eu2 = 'eu2',
    eu3 = 'eu3',
}


export type Region = keyof typeof RegionCode;
export type Zone = keyof typeof ZoneCode;

export interface IRegion {
    id: string;
    name: string;
    nameLocale: string;
    position: number;
    countryCode: string;
    code: Region;
    zoneCode: Zone;
    isoCode2: ISOCode2;
    isoCode3: string;
    quoteSchema?: AddressSchema;
    shipmentSchema?: AddressSchema;
    rateSchema?: AddressSchema;
    addressSchema?: AddressSchema;
}

export type AddressProp =
    'city' | 'state' | 'cityLocale' | 'stateLocale'
    | 'stateCode' | 'postalCode' | 'addressType'
    | 'addressLine1' | 'addressLine2' | 'addressLocale'
    | 'contactPhone' | 'contactPerson' | 'contactPersonLocale'
    | 'company' | 'location' | 'pca';


export type AddressSchema = {
    [code in AddressProp]?: FormSchema
};

export interface IRegionListItem {
    label: string;
    value: IRegion;
    disabled?: boolean;
}

export interface IRegionProvince {
    id: number;
    code: string;
    name: string;
    nameLocalized?: string;
    state?: number;
}


export interface IRegionProvinceListItem {
    label: string;
    value: IRegionProvince;
    disabled?: boolean;
}

export interface IRegionState {
    id: number;
    code: string;
    name: string;
    nameLocale?: string;
    status: number;
}

export interface IRegionStateListItem {
    label: string;
    value: IRegionState;
    disabled?: boolean;
}

export enum ISOCode2 {
    CA = 'CA',
    CN = 'CN',
    US = 'US',
    AT = 'AT',
    AU = 'AU',
    BE = 'BE',
    CH = 'CH',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    ES = 'ES',
    FI = 'FI',
    FR = 'FR',
    LU = 'LU',
    GB = 'GB',
    GR = 'GR',
    HK = 'HK',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IT = 'IT',
    JP = 'JP',
    KR = 'KR',
    LT = 'LT',
    NL = 'NL',
    NO = 'NO',
    NZ = 'NZ',
    PL = 'PL',
    PT = 'PT',
    SE = 'SE',
    SG = 'SG',
    Si = 'SI',
    SK = 'SK',
    TW = 'TW'
}
