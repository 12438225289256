<nz-steps
  [nzCurrent]="current"
  (nzIndexChange)="on_index_change($event)"
  nzSize="small"
  id="scrollId"
>
  <nz-step nzTitle="(收/寄)地址"></nz-step>
  <nz-step
    nzTitle="规格"
    [nzDisabled]="
      (validateForm | formGroup: 'initiation')?.invalid ||
      (validateForm | formGroup: 'destination')?.invalid
    "
  ></nz-step>
  <nz-step nzTitle="运费" [nzDisabled]="validateForm.invalid"></nz-step>
</nz-steps>

<nz-tabset [nzSelectedIndex]="current">
  <nz-tab>
    <div nz-row nzJustify="space-between" [nzGutter]="[16, 16]">
      <ng-template #regionInitiationExtraTpl>
        <!-- <button nz-button nzType="link" nzDanger (click)="address_selector(0)">
          <i nz-icon nzType="contacts"></i> 通讯录
        </button> -->
      </ng-template>
      <ng-template #regionDestinationExtraTpl>
        <!-- <button nz-button nzType="link" nzDanger (click)="address_selector(1)">
          <i nz-icon nzType="contacts"></i> 通讯录
        </button> -->
      </ng-template>
      <div nz-col nzXl="12" nzXs="24">
        <nz-card
          [nzTitle]="initiationTitleTpl"
          nzType="inner"
          nzSize="small"
          [nzExtra]="regionInitiationExtraTpl"
        >
          <address-form
            [validateForm]="validateForm | formGroup: 'initiation'"
            [type]="0"
            [schema]="initiationRegion?.quoteSchema"
          ></address-form>
          <ng-template #initiationTitleTpl>
            始发地: {{ initiationRegion?.nameLocale }} ({{
              initiationRegion?.code.toUpperCase()
            }})
          </ng-template>
        </nz-card>
      </div>

      <div nz-col nzXl="12" nzXs="24">
        <nz-card
          [nzTitle]="destinationTitleTpl"
          nzType="inner"
          nzSize="small"
          [nzExtra]="regionDestinationExtraTpl"
        >
          <ng-container *ngIf="!multiDestination">
            <address-form
              [validateForm]="validateForm | formGroup: 'destination'"
              [type]="1"
              [schema]="destinationRegion?.quoteSchema"
            ></address-form>
          </ng-container>
          <ng-container *ngIf="multiDestination"> 多目的地 </ng-container>
          <ng-template #destinationTitleTpl>
            目的地: {{ destinationRegion?.nameLocale }} ({{
              destinationRegion?.code.toUpperCase()
            }})
          </ng-template>
        </nz-card>
      </div>

      <div nz-col nzSpan="24" style="text-align: right">
        <button nz-button nzType="primary" (click)="next_to_package_list()">
          下一步
        </button>
      </div>
    </div>
  </nz-tab>
  <nz-tab>
    <ng-container>
      <div nz-row nzGutter="8">
        <div nz-col nzSpan="24">
          <shipping-package
            [validateForm]="validateForm | formArray: 'paks'"
            [multiDestination]="multiDestination"
            [destinationRegion]="destinationRegion"
            [indexes]="indexes"
            [index]="index"
            [countMax]="countMax"
            [countMin]="countMin"
          ></shipping-package>
        </div>
        <div nz-col nzSpan="24" style="text-align: right" *ngIf="current === 1">
          <button nz-button nzType="primary" (click)="next(0)">上一步</button>
          <nz-divider nzType="vertical"> </nz-divider>
          <button nz-button nzType="primary" (click)="next_to_service_list()">
            下一步
          </button>
        </div>
      </div>
    </ng-container>
  </nz-tab>
  <nz-tab>
    <ng-container *ngIf="current === 2">
      <div nz-row [nzGutter]="[8, 20]">
        <div nz-col nzSpan="24">
          <nz-card nzBorderless>
            <div nz-row nzJustify="start" [nzGutter]="[20, 20]">
              <div nz-col>
                <strong>始发地:</strong> <br />
                {{ initiationRegion?.nameLocale }} ({{
                  initiationRegion?.code.toUpperCase()
                }})
              </div>
              <div nz-col>
                <strong>目的地:</strong> <br />
                {{ destinationRegion?.nameLocale }} ({{
                  destinationRegion?.code.toUpperCase()
                }})
              </div>
              <div nz-col>
                <!-- <strong>邮寄类型:</strong> <br />
                <ng-container *ngIf="multiDestination">
                  <nz-divider nzType="vertical"></nz-divider>
                  拼箱服务 (代购专线)
                </ng-container>
                <br /> -->
                <strong>数量:</strong>
                <br />
                {{ validateForm?.value?.paks.length }}
              </div>
            </div>
          </nz-card>
        </div>
        <div nz-col nzSpan="24">
          <service-rate
            [service]="service"
            [reqBody]="validateForm.value"
            (serviceChange)="on_service_select($event)"
            (rateChange)="on_rate_change(i, $event)"
            *ngFor="let service of services; let i = index"
            #item_{{i}}
          ></service-rate>
        </div>
        <div nz-col nzSpan="24" style="text-align: right">
          <button nz-button nzType="primary" (click)="next(1)">上一步</button>
        </div>
      </div>
    </ng-container>
  </nz-tab>
</nz-tabset>
