<ng-container *ngIf="validateForm">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item *ngIf="validateForm | hasControl : 'addressType'">
      <nz-form-label
        nzFor="addressType"
        [nzRequired]="validateForm | isRequired : 'addressType'"
      >
        地址类型
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError : 'addressType'">
        <nz-select [nzPlaceHolder]="" formControlName="addressType">
          <ng-container *ngFor="let o of addressTypes">
            <nz-option [nzValue]="o.value" [nzLabel]="o.label"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- PCA FORM -->
    <pca-search-form
      [validateForm]="validateForm"
      [regionCode]="validateForm?.value?.region?.isoCode2"
      (pcaChange)="pca_change($event)"
    ></pca-search-form>
    <!-- LOCATION -->
    <nz-form-item *ngIf="validateForm | hasControl : 'location'">
      <nz-form-label
        nzFor="location"
        [nzRequired]="validateForm | isRequired : 'location'"
      >
        位置
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError : 'location'">
        <input
          nz-input
          [placeholder]="schema?.location?.placeholder"
          formControlName="location"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'addressLine1'">
      <nz-form-label
        nzFor="addressLine1"
        [nzTooltipTitle]="schema?.addressLine1?.tip?.title | nzSafeNull : ''"
        [nzTooltipIcon]="schema?.addressLine1?.tip?.icon"
        [nzRequired]="validateForm | isRequired : 'addressLine1'"
      >
        地址
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError : 'addressLine1'">
        <input
          nz-input
          [placeholder]="schema?.addressLine1?.placeholder"
          formControlName="addressLine1"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'addressLine2'">
      <nz-form-label
        nzFor="addressLine2"
        [nzRequired]="validateForm | isRequired : 'addressLine2'"
      >
        地址
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError : 'addressLine2'">
        <input
          nz-input
          [placeholder]="schema?.addressLine2?.placeholder"
          formControlName="addressLine2"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'postalCode'">
      <nz-form-label
        nzFor="postalCode"
        [nzRequired]="validateForm | isRequired : 'postalCode'"
      >
        邮编
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError : 'postalCode'">
        <input
          nz-input
          [placeholder]="schema?.postalCode?.placeholder"
          formControlName="postalCode"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'city'">
      <nz-form-label
        nzFor="city"
        [nzRequired]="validateForm | isRequired : 'city'"
      >
        城市
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError : 'city'">
        <input
          nz-input
          [placeholder]="schema?.city?.placeholder"
          formControlName="city"
        />
      </nz-form-control>
    </nz-form-item>

    <!-- STATE SELECTOR -->
    <region-state-selector
      [validateForm]="validateForm"
      [regionCode]="validateForm?.value?.region?.isoCode2"
      [stateCode]="address?.stateCode"
      [(stateList)]="stateList"
      (stateChange)="state_change($event)"
    ></region-state-selector>

    <nz-form-item *ngIf="validateForm | hasControl : 'contactPerson'">
      <nz-form-label
        nzFor="contactPerson"
        [nzTooltipTitle]="schema?.contactPerson?.tip?.title | nzSafeNull : ''"
        [nzTooltipIcon]="schema?.contactPerson?.tip?.icon"
        [nzRequired]="validateForm | isRequired : 'contactPerson'"
      >
        {{ type ? "收件人" : "寄件人" }}
      </nz-form-label>
      <nz-form-control
        [nzErrorTip]="validateForm | formError : 'contactPerson'"
      >
        <input
          nz-input
          [placeholder]="schema?.contactPerson.placeholder"
          formControlName="contactPerson"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'contactPhone'">
      <nz-form-label
        nzFor="contactPhone"
        [nzRequired]="validateForm | isRequired : 'contactPhone'"
      >
        联系电话
      </nz-form-label>
      <nz-form-control
        [nzExtra]="
          type
            ? '如没有收件人/收件机构电话, 请填写目的地国家/地区物流可联系到的电话.'
            : ''
        "
        [nzErrorTip]="validateForm | formError : 'contactPhone'"
      >
        <nz-input-group
          [nzAddOnBefore]="'+' + validateForm.value.region.countryCode"
        >
          <input
            nz-input
            [placeholder]="type ? '收件人电话' : '寄件人电话'"
            formControlName="contactPhone"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-divider
      nzText="中文地址"
      nzPlain
      nzOrientation="left"
      *ngIf="validateForm | hasControl : 'addressLocale'"
    ></nz-divider>

    <nz-form-item *ngIf="validateForm | hasControl : 'cityLocale'">
      <nz-form-label
        nzFor="cityLocale"
        [nzRequired]="validateForm | isRequired : 'cityLocale'"
      >
        城市
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError : 'cityLocale'">
        <input
          nz-input
          [placeholder]="schema?.cityLocale?.placeholder"
          formControlName="cityLocale"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'addressLocale'">
      <nz-form-label
        nzFor="addressLocale"
        [nzRequired]="validateForm | isRequired : 'addressLocale'"
      >
        地址
      </nz-form-label>
      <nz-form-control
        [nzErrorTip]="validateForm | formError : 'addressLocale'"
      >
        <input
          nz-input
          [placeholder]="schema?.addressLocale?.placeholder"
          formControlName="addressLocale"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'contactPersonLocale'">
      <nz-form-label
        nzFor="contactPersonLocale"
        [nzTooltipTitle]="
          schema?.contactPersonLocale?.tip?.title | nzSafeNull : ''
        "
        [nzTooltipIcon]="schema?.contactPersonLocale?.tip?.icon"
        [nzRequired]="validateForm | isRequired : 'contactPersonLocale'"
      >
        {{ type ? "收件人" : "寄件人" }}
      </nz-form-label>
      <nz-form-control
        [nzErrorTip]="validateForm | formError : 'contactPersonLocale'"
      >
        <input
          nz-input
          [placeholder]="schema?.contactPersonLocale.placeholder"
          formControlName="contactPersonLocale"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="validateForm | hasControl : 'idCardOption'">
      <nz-form-label nzFor="idCardOption"> 收件人证件 </nz-form-label>
      <nz-form-control
        nzExtra="当使用“包税联运”或者“代购专线”服务时，需提供收件人身份证信息"
      >
        <label nz-checkbox formControlName="idCardOption">
          上传收件人证件信息
        </label>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>
