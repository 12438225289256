<form
  nz-form
  [formGroup]="validateForm"
  (ngSubmit)="formChange.emit()"
  nzLayout="inline"
>
  <nz-form-item *ngIf="validateForm | hasControl: 'keywords'">
    <nz-form-label nzFor="keywords">关键字</nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'keywords'">
      <nz-input-group nzSearch [nzAddOnAfter]="nzAddOnAfterTpl">
        <input
          nz-input
          type="text"
          formControlName="keywords"
          placeholder="运单号 / 订单号 "
        />
      </nz-input-group>
      <ng-template #nzAddOnAfterTpl>
        <nz-button-group>
          <button nz-button nzType="primary">查询</button>
          <button
            (click)="keywords_reset()"
            nz-button
            nzType="primary"
            *ngIf="validateForm.value.keywords"
            nzGhost
            nzDanger
          >
            <i nz-icon nzType="close"></i>
          </button>
        </nz-button-group>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="validateForm | hasControl: 'waybillCarrierCode'">
    <nz-form-label nzFor="waybillCarrierCode">物流</nz-form-label>
    <nz-form-control
      [nzErrorTip]="validateForm | formError: 'waybillCarrierCode'"
    >
      <nz-select
        formControlName="waybillCarrierCode"
        nzPlaceHolder="所有物流"
        nzAllowClear
        (ngModelChange)="formChange.emit()"
        style="min-width: 150px"
      >
        <nz-option
          *ngFor="let item of carriers"
          [nzLabel]="item.name"
          [nzValue]="item.code"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>
