import { CashbackEvent, ICashback, ICashbackEvent } from '../../interfaces/cashback';
import { Currency } from '../../interfaces/rate/currency';

export class Cashback {
    _data: ICashback;

    constructor(private data: ICashback) {
        this._data = this.data;
    }

    get id(): string {
        return this._data.id;
    }

    get income(): { count: number } {
        return this._data.income >= 0 && !this._data.outcome ? { count: this._data.income } : null;
    }

    get outcome(): { count: number } {
        return this._data.outcome < 0 ? { count: this._data.outcome } : null;
    }

    get event(): ICashbackEvent {
        const event: ICashbackEvent = {
            code: 'unknown',
            name: CashbackEvent.unknown,
        };
        const code = this._data.event;

        if (code in CashbackEvent) {
            event.code = code;
            event.name = CashbackEvent[code];
        }

        return event;

    }

    get orderNumber(): string {
        return this._data?.orderNumber ?? null;
    }

    get createdAt(): Date {
        return this._data?.createdAt;
    }

    get updatedAt(): Date {
        return this._data?.updatedAt;
    }

    get currency(): Currency {
        return this._data?.currency;
    }

}
