import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PickupRoutingModule } from './pickup-routing.module';
import { PickupPageListComponent } from './pickup-page-list/pickup-page-list.component';
import { PickupPageCreateComponent } from './pickup-page-create/pickup-page-create.component';
import { PickupSharedModule } from 'src/app/shared/pickup';
import { PickupPageDetailComponent } from './pickup-page-detail/pickup-page-detail.component';
import { PickupPageEditComponent } from './pickup-page-edit/pickup-page-edit.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { MomentModule } from 'ngx-moment';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ShippingSharedModule } from 'src/app/shared/shipping';
import { UnitModule } from 'src/app/shared/unit/unit.module';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTagModule } from 'ng-zorro-antd/tag';


@NgModule({
  declarations: [
    PickupPageListComponent,
    PickupPageCreateComponent,
    PickupPageDetailComponent,
    PickupPageEditComponent
  ],
  imports: [
    CommonModule,
    PickupRoutingModule,
    PickupSharedModule,
    IndicatorSharedModule,
    NzIconModule,
    NzEmptyModule,
    NzDescriptionsModule,
    NzI18nModule,
    MomentModule,
    NzDividerModule,
    ShippingSharedModule,
    UnitModule,
    NzListModule,
    NzTagModule
  ]
})
export class PickupModule { }
