import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { IAddressListItem, IPcaOption, IPcaResult } from 'src/app/common/interfaces/pca';
import { ISOCode2 } from 'src/app/common/interfaces/region';
import { PcaService } from 'src/app/core/pca';

@Component({
  selector: 'pca-search-form',
  templateUrl: './pca-search-form.component.html',
  styleUrls: ['./pca-search-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PcaSearchFormComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  private _validateForm: UntypedFormGroup;

  @Input()
  set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
  }

  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }


  private _regionCode: ISOCode2;
  @Input()
  set regionCode(value: ISOCode2) {
    this._regionCode = value;
    this.detection.detectChanges();
  }
  get regionCode(): ISOCode2 {
    return this._regionCode;
  }

  @Output() pcaChange = new EventEmitter<IPcaResult>();

  addressList: IAddressListItem[] = [];
  pcaPanel = false;
  constructor(
    private detection: ChangeDetectorRef,
    private pca: PcaService,
  ) { }

  ngOnInit(): void {
    this.validateForm?.valueChanges.subscribe({
      next: value => this.detection.detectChanges()
    });
  }

  on_address_search(value: string = null, LastId = ''): void {
    this.isLoading = true;

    this.pca.find(value, this.regionCode, LastId).pipe(
      take(1),
      map((items: IPcaOption[]) => {
        const options = items.map(item => {
          return {
            label: item.Text + ' ' + item.Description,
            value: item
          };
        });
        if (LastId !== '') {
          this.pcaPanel = true;
        }
        return options;
      }),
    ).subscribe({
      next: data => {
        this.addressList = data;
        this.isLoading = false;
      }
    });
  }


  on_address_change(model) {
    if (!model) {
      return false;
    }
    const { Next, Id: LastId } = model;
    const { region } = this.validateForm.value;
    if (Next === 'Retrieve') {
      const pathData = this.pca.optionParse(model, region);
      this.pcaChange.emit(pathData);
    }

    if (Next === 'Find') {
      this.on_address_search(null, LastId);
    }

  }


}
