import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { IBtcShippingPackagePreset } from 'src/app/common/interfaces/shipping';
import { Rate } from 'src/app/common/models/rate';
import { BtcService, BtcServiceContent } from 'src/app/common/models/service';
import { BtcShippingPackage } from 'src/app/common/models/shipping';
import { BtcShipping } from 'src/app/common/models/shipping';
import { BtcShippingAddress } from 'src/app/common/models/shipping';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import { NavigationService } from '../navigation';

@Injectable({
  providedIn: 'root'
})
export class BtcShippingService {
  #endPoint: string;
  #shippingLoading = new BehaviorSubject<boolean>(false);
  shippingLoading$ = this.#shippingLoading.asObservable();

  constructor(
    private http: HttpService,
    private nav: NavigationService,
    private router: Router
  ) {
    this.#endPoint = environment.coreEndPoint;
  }

  set shippingLoading(value: boolean) {
    this.#shippingLoading.next(value);
  }

  search(body: any): Observable<IHttpResponse> {
    const path = `shipping/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        res.result = res.result.map(item => new BtcShipping(item));
        return res;
      }),
    );
  }

  group_get(id: string): Observable<BtcShipping> {
    this.shippingLoading = true;
    const path = `${this.#endPoint}/b2c/shipping/${id}/detail`;
    return this.http.post(path, {}).pipe(
      catchError((err) => of({ error: err })),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          return new BtcShipping(result);
        } else {
          return null;
        }
      }),
      tap(() => {
        this.shippingLoading = false;
      })
    );
  }

  /**
   * @Add
   * @returns shipping
   */

  package_add(shippingId: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/package/add`;
    return this.http.post(path, {
      ...body, ...{
        shippingId
      }
    }).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BtcShippingPackage(result);
        }
        return res;
      }),
    );
  }

  package_get(shippingId: string, packageId: string): Observable<BtcShippingPackage> {
    const path = `${this.#endPoint}/b2c/package/${packageId}/detail`;
    return this.http.post(path, {}).pipe(
      catchError((err) => of({ error: err })),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          return new BtcShippingPackage(result);
        }
      }),

    );
  }

  package_search(shippingId: string, body: any = {}): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/package/search`;
    return this.http.post(path, {
      ...body, ...{
        shippingId
      }
    }).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        res.result = result.map(item => new BtcShippingPackage(item));
        return res;
      }),
    );
  }

  package_delete(shippingId: string, packageId: string): Observable<IHttpResponse> {
    // const path = `shipping/${shippingId}/packages/${packageId}/delete`;
    const path = `b2c/package/${packageId}/delete`;
    return this.http.delete(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
    );
  }


  detail(id: string): Observable<BtcShippingPackage | BtcShipping> {
    const path = `shipping/${id}/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result && result.group) {
          return new BtcShippingPackage(result);
        }

        if (result && !result.group) {
          return new BtcShipping(result);
        }

        return null;
      }),
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const id = route.params?.shippingNumber ?? null;
    return of(id);
  }

  create(body: any, presets: IBtcShippingPackagePreset[] = []): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/shipping/create`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      take(1),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BtcShipping(result);
        }
        return res;
      }),
      tap((res) => {
        const shipping = res.result;
        if (shipping) {
          const url = `/app/shippings/${shipping.number}/packages/add`;
          this.router.navigate([url], {
            queryParams: { presets: encodeURI(JSON.stringify(presets)) }
          });
        }
      }),
    );
  }

  delete(id: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/shipping/${id}/delete`;
    return this.http.delete(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
    );
  }

  void(id: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/shipping/${id}/cancel`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
    );
  }



  address_get(addressId: string, shippingId: string, packageId?: string): Observable<IHttpResponse> {
    let path = ``;
    if (packageId) {
      path = `shipping/${shippingId}/packages/${packageId}/addresses/${addressId}/detail`;
    } else {
      // path = `shipping/${shippingId}/addresses/${addressId}/detail`;
      path = `shipping/address/${addressId}/detail`;
    }
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingAddress(res.result);
        }
        return res;
      }),
    );
  }

  address_add(body: any, shippingId: string, packageId?: string): Observable<IHttpResponse> {
    let path = '';
    if (packageId) {
      path = `shipping/${shippingId}/packages/${packageId}/addresses/add`;
    } else {
      path = `shipping/${shippingId}/addresses/add`;
    }

    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BtcShippingAddress(result);
        }
        return res;
      }),
    );
  }


  address_delete(id: string, addressId: string): Observable<boolean> {
    const path = `shipping/${id}/addresses/${addressId}/delete`;
    return this.http.delete(path).pipe(
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        return true;
      }),
      catchError(() => of(false))
    );
  }



  /**
   *
   * @Size
   */
  size_update(packageId: string, body: any): Observable<IHttpResponse> {
    const path = `b2c/package/${packageId}/size/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingPackage(res.result);
        }
        return res;
      })
    );
  }

  /**
   *
   * @Service
   */

  service_get(serviceId: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/shipping/service/${serviceId}/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcService(res.result);
        }
        return res;
      })
    );
  }

  /**
   *
   * @ServiceContent
   */

  service_content_get(serviceId: string, contentId: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/shipping/service/${serviceId}/content/${contentId}/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcServiceContent(res.result);
        }
        return res;
      })
    );
  }

  service_content_update(id: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/shipping/${id}/service/content/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingPackage(res.result);
        }
        return res;
      })
    );
  }


  /**
   *
   * @Additional
   */

  additional_update(packageId: string, body: any): Observable<IHttpResponse> {
    const path = `b2c/package/${packageId}/additional/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingPackage(res.result);
        }
        return res;
      })
    );
  }

  /**
   *
   * @Reference
   */

  reference_update(packageId: string, body: any): Observable<IHttpResponse> {
    const path = `b2c/package/${packageId}/reference/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingPackage(res.result);
        }
        return res;
      })
    );
  }

  /**
   *
   * @Memo
   */
  memo_update(packageId: string, body: any): Observable<IHttpResponse> {
    const path = `b2c/package/${packageId}/memo/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingPackage(res.result);
        }
        return res;
      })
    );
  }
  /**
   *
   * @Insurance
   */

  insurance_update(packageId: string, body: any): Observable<IHttpResponse> {
    const path = `b2c/package/${packageId}/insurance/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.error_msg_handler()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingPackage(res.result);
        }
        return res;
      })
    );
  }


  /**
   *
   * @Rfe
   */

  rfe_update(packageId: string, body: any): Observable<IHttpResponse> {
    const path = `b2c/package/${packageId}/rfe/update`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res.result) {
          res.result = new BtcShippingPackage(res.result);
        }
        return res;
      })
    );
  }

  /**
   * @Rate Estimate
   */

  rate_estimate(id: string, body: any = {}): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/shipping/${id}/rate/estimate`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new Rate(result);
        }
        return res;
      }),
    );
  }
}
