import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { PakType } from 'src/app/common/interfaces/pak';
import { IRegion, IRegionServiceControl } from 'src/app/common/interfaces/region';
import { IBtcServiceIndex } from 'src/app/common/interfaces/service';
import { BtcService } from 'src/app/common/models/service';
import { BtcServiceService } from 'src/app/core/btc';
import { ServiceRateCompareDrawerComponent } from 'src/app/shared/service/rate/components';

@Component({
  selector: 'group-form-multi',
  templateUrl: './group-form-multi.component.html',
  styleUrls: ['./group-form-multi.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupFormMultiComponent implements OnInit {

  @Input() page = 1;
  @Input() initiationRegion: IRegion;
  @Input() destinationRegion: IRegion;
  @Input() pakType: PakType;
  @Input() isLoading = false;
  @Input() regionServiceControl: IRegionServiceControl;
  @Input() indexes: IBtcServiceIndex[] = [];
  @Output() serviceChange = new EventEmitter<any>();
  serviceList: BtcService[] = [];

  constructor(
    private btcService: BtcServiceService,
    private drawerService: NzDrawerService,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const body = {
      initiationRegionCode: this.initiationRegion.code,
      destinationRegionCode: this.destinationRegion.code,
      multiDestination: 1,
      parcel: this.pakType === 'parcel' ? 1 : 0,
      env: this.pakType === 'env' ? 1 : 0,
      sort: [{ position: 'asc' }]
    };

    this.service_search(body);
  }

  private service_search(body: any): void {
    this.isLoading = true;
    this.btcService.search(body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.serviceList = res;
        this.detection.detectChanges();
      }
    });
  }

  price_compare(): void {
    const drawerRef = this.drawerService.create({
      nzTitle: '对比价格',
      nzContent: ServiceRateCompareDrawerComponent,
      nzContentParams: {
        regionServiceControl: this.regionServiceControl,
        initiationRegion: this.initiationRegion,
        destinationRegion: this.destinationRegion,
        multiDestination: 1,
        pakType: this.pakType,
        services: this.serviceList,
        indexes: this.indexes
      },
      nzKeyboard: false,
      nzHeight: '100%',
      nzPlacement: 'bottom',
      nzMaskClosable: false
    });

    drawerRef.afterClose.pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        if (res) {
          this.serviceChange.emit(res);
        }
      }
    });
  }


}
