<ng-template #contentTpl>
  <nz-descriptions>
    <nz-descriptions-item [nzTitle]="orderTpl" *ngIf="order">
      <ng-template #orderTpl> 订单 / 支付 / Bonus </ng-template>
      <!-- {{ order?.orderNumber | nzSafeNull: "无" }} -->
      <button nz-button nzType="link" nzSize="small" (click)="order_detail()">
        查看详情
      </button>
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions nzColon="false">
    <nz-descriptions-item [nzTitle]="eventTimeTpl">
      <ng-template #eventTimeTpl>
        预录:
        {{
          group?.createdAt | amLocale : i18n.getLocaleId() | amDateFormat : "ll"
        }}
        <nz-divider nzType="vertical"></nz-divider>
        更新:
        {{
          group?.updatedAt | amLocale : i18n.getLocaleId() | amDateFormat : "ll"
        }}
        <ng-container *ngIf="group?.submitted">
          <nz-divider nzType="vertical"></nz-divider>
          提交:
          {{
            group?.submittedAt
              | amLocale : i18n.getLocaleId()
              | amDateFormat : "ll"
          }}
        </ng-container>
        <ng-container *ngIf="group?.voided">
          <nz-divider nzType="vertical"></nz-divider>
          取消:
          {{
            group?.voidedAt
              | amLocale : i18n.getLocaleId()
              | amDateFormat : "ll"
          }}
        </ng-container>
      </ng-template>
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions>
    <nz-descriptions-item nzTitle="类别">
      {{ group?.type?.label }}
    </nz-descriptions-item>

    <ng-container *ngIf="group.count as count">
      <nz-descriptions-item nzTitle="数量">
        {{ count.packageCount }}
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle="总重" *ngIf="count.weight as weight">
        {{ weight | weightUnit : unit?.weight?.code : true }}
      </nz-descriptions-item>
    </ng-container>
  </nz-descriptions>

  <nz-descriptions>
    <nz-descriptions-item nzTitle="状态" nzSpan="3">
      <ng-container *ngFor="let tag of group?.status">
        <nz-tag [nzColor]="tag.color" *ngIf="tag">
          {{ tag.text }}
        </nz-tag>
      </ng-container>
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions>
    <ng-template #nmmTpl>
      <h5 nz-typography nzType="warning">禁运说明</h5>
    </ng-template>

    <ng-template #handleTpl>
      <h5 nz-typography nzType="warning">投递方法</h5>
    </ng-template>
    <ng-template #pickupTpl>
      <h5 nz-typography nzType="warning">上门取货</h5>
    </ng-template>
    <nz-descriptions-item [nzTitle]="nmmTpl" nzSpan="3">
      <a
        nz-button
        nzType="link"
        (click)="nmm_preview(group?.destinationRegionCode)"
      >
        <i nz-icon nzType="file-pdf"></i> 点击查看
      </a>
    </nz-descriptions-item>
    <ng-container *ngIf="group.submitted && !group.voided">
      <nz-descriptions-item [nzTitle]="handleTpl" nzSpan="3">
        <a nz-button nzType="link" (click)="locate_parcel_shop()">
          <i nz-icon nzType="environment"></i> 自助投递
        </a>
        <nz-divider nzType="vertical"></nz-divider>
        <!-- <ng-container *ngIf="expression"> -->
        <a nz-button nzType="link" (click)="create_pickup()">
          <i nz-icon nzType="calendar"></i> 预约上门取货
        </a>
        <!-- </ng-container> -->
      </nz-descriptions-item>
    </ng-container>
  </nz-descriptions>
</ng-template>

<ng-template #buttonsTpl>
  <button
    nz-button
    nzType="text"
    [nzSize]="size$ | async"
    (click)="nav.back('/app/shippings')"
  >
    <i nz-icon nzType="arrow-left"></i> 返回
  </button>
  <ng-container *ngIf="!group?.submitted">
    <ng-container *ngIf="packageCount < service?.count?.max">
      <button nz-button [nzSize]="size$ | async" (click)="package_add()">
        <i nz-icon nzType="plus-circle"></i>
        添加包裹
      </button>
    </ng-container>

    <button
      nz-button
      nzType="primary"
      nzGhost
      [nzSize]="size$ | async"
      (click)="delete()"
      nzDanger
    >
      <i nz-icon nzType="delete"></i>
      删除预录
    </button>

    <a
      nz-button
      [routerLink]="['submit']"
      [nzSize]="size$ | async"
      nzType="primary"
    >
      <i nz-icon nzType="check-circle"></i>
      提交预录
    </a>
  </ng-container>

  <ng-container *ngIf="order && !order.paid">
    <button
      nz-button
      nzType="primary"
      [nzSize]="size$ | async"
      (click)="order_delete()"
      nzDanger
    >
      删除订单
    </button>
    <ng-container>
      <button
        nz-button
        nzType="primary"
        [nzSize]="size$ | async"
        (click)="orderCheckout()"
      >
        <i nz-icon nzType="dollar-circle"></i>
        支付订单
      </button>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="order && order.paid && !order.voided && !order.voidPending"
  >
    <button
      nz-button
      nzType="primary"
      [nzSize]="size$ | async"
      nzDanger
      (click)="order_void()"
    >
      <i nz-icon nzType="close-circle"></i>
      取消订单
    </button>
  </ng-container>
</ng-template>

<ng-template #subtitleTpl>
  <div style="vertical-align: middle">
    <div nz-row nzAlign="middle" nzGutter="16">
      <div nz-col>
        <small nz-typography [nzType]="service.name.color">
          {{ service.name.text }}
        </small>
      </div>
      <div nz-col>
        <nz-avatar-group>
          <ng-container *ngFor="let icon of service.RouteIcons; let i = index">
            <nz-avatar
              nzSize="small"
              [nzSrc]="icon.src"
              nzShape="square"
            ></nz-avatar>
            <nz-avatar
              nzSize="small"
              nzShape="square"
              style="background-color: transparent; color: black"
              nzIcon="swap-right"
              *ngIf="i + 1 !== service.RouteIcons.length"
            ></nz-avatar>
          </ng-container>
        </nz-avatar-group>
      </div>
    </div>
  </div>
</ng-template>
