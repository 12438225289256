import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { ShippingDocType, ShippingDocTypeCode } from 'src/app/common/interfaces/shipping';
import { BtcServiceContent } from 'src/app/common/models/service';
import { BtcShippingIdcard } from 'src/app/common/models/shipping';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'doc-form',
  templateUrl: './doc-form.component.html',
  styleUrls: ['./doc-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocFormComponent implements OnInit {
  @Input() idcard: BtcShippingIdcard;
  @Input() serviceContent: BtcServiceContent;
  @Input() validateForm: UntypedFormGroup;
  @Input() isLoading = false;
  @Input() upload: () => void;
  @Output() docnumberChange = new EventEmitter<never>();
  @Output() deleteChange = new EventEmitter<string>();
  @Input() fileList: File[] = [];
  constructor(
    private msg: NzMessageService,
    private modalService: NzModalService,
  ) { }

  ngOnInit(): void {
  }

  on_submit(): void {
    this.docnumberChange.emit();
  }

  beforeUpload = (file: File) => {

    return new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = ['image/jpeg', 'image/png'].includes(file.type);

      if (!isJpgOrPng) {
        this.msg.error('支持上传的文件类型:image/jpeg, image/png ');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.msg.error('最大上传 5MB!');
        observer.complete();
        return;
      }

      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });
  }

  rectoData = () => ShippingDocTypeCode.id_card_recto;

  versoData = () => ShippingDocTypeCode.id_card_verso;

  delete(side: ShippingDocType, id: string) {
    const sideText = side === ShippingDocTypeCode.id_card_recto ? '正面' : '背面';
    const modal = this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: `确认删除身份证${sideText}?`,
      nzOkDanger: true,
      nzOnOk: () => {
        return new Promise(resolve => {
          resolve(true);
        });
      }
    });

    modal.afterClose.subscribe({
      next: (res) => {
        if (res) {
          this.deleteChange.emit(id);
        }
      }
    });
  }

  preview(src: string) {
    if (!src) {
      return false;
    }
    this.modalService.create({
      nzFooter: null,
      nzContent: `<img src=${src} width="100%"/>`,
      nzTitle: null
    });
  }



}
