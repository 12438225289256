import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { MomentModule } from 'ngx-moment';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { PaymentSharedModule } from 'src/app/shared/payment';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { OrderVoidFormComponent } from './order-void-form/order-void-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CashbackSharedModule } from 'src/app/shared/bonus/cashback';
import { NzPipesModule } from 'ng-zorro-antd/pipes';

@NgModule({
  declarations: [
    OrderDetailComponent,
    OrderVoidFormComponent,
    OrderSummaryComponent,

  ],
  imports: [
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    PaymentSharedModule,
    FormSharedModule,
    CashbackSharedModule,

    NzCheckboxModule,
    NzRadioModule,
    NzFormModule,
    NzIconModule,
    NzEmptyModule,
    NzI18nModule,
    NzTagModule,
    NzButtonModule,
    NzTypographyModule,
    NzGridModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzPipesModule,

  ],
  exports: [
    OrderDetailComponent,
    OrderVoidFormComponent,
    OrderSummaryComponent
  ]
})
export class ShippingOrderSharedModule { }
