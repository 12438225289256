<ng-container *ngIf="!error; else ErrorTpl">
  <ng-container *ngIf="!isLoading; else LoadingTpl">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="on_submit()">
      <!-- Service Content -->
      <ng-container *ngIf="content?.insurance">
        <service-content-insurance-form
          [current]="current"
          [option]="content.insurance"
          [validateForm]="validateForm | formGroup : 'insurance'"
        ></service-content-insurance-form>
      </ng-container>
      <nz-form-item nzJustify="end">
        <button (click)="on_submit()" nz-button nzType="primary">提交</button>
      </nz-form-item>
    </form>
  </ng-container>
</ng-container>

<ng-template #ErrorTpl>
  <indicator-alert
    [message]="error.message"
    [problems]="error.problems"
    [type]="'error'"
    (Close)="error_reset()"
  ></indicator-alert>
</ng-template>

<ng-template #LoadingTpl>
  <indicator-loading title="提交中, 请稍后..."></indicator-loading>
</ng-template>
