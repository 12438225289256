
export enum BalanceEvent {
    unknown = '未知事件',
    cashback = '返款',
    recharge = '现金充值',
    cashback_revoke = '返款撤销',
    checkout = '支付',
    check_out = '支付',
    payout = '消费支出',
    payment_abort = '支付撤销',
    refund_by_balance = '余额退款',
    payment_refund = '余额退款',
    transfer_from_cashback = '由返款金额转入',
}

export type BalanceEventCode = keyof typeof BalanceEvent;

export interface IBalanceEvent {
    code: BalanceEventCode;
    name: BalanceEvent;
}
