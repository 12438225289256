import { SafeUrl } from '@angular/platform-browser';
import { INotice } from '../../interfaces/account';

export class Notice {
    private _notice: INotice;
    private _isLoading = false;
    constructor(data: INotice) {
        this._notice = data;
    }

    get title(): string {
        return this._notice && this._notice.title;
    }
    get content(): string {
        return this._notice && this._notice.content;
    }

    get readAt(): string {
        return this._notice && this._notice.readAt;
    }
    set readAt(value: string) {
        this._notice.readAt = value;
    }

    get iconSrc(): SafeUrl {
        if (!this._notice) {
            return null;
        }

        let src = '';
        switch (this._notice.type) {
            case 'error':
                src = '/assets/img/svg/notice/024-error.svg';
                break;
            case 'info':
                src = '/assets/img/svg/notice/002-information.svg';
                break;
            case 'warning':
                src = '/assets/img/svg/notice/022-danger.svg';
                break;
            case 'success':
                src = '/assets/img/svg/notice/011-check.svg';
                break;
            default:
                break;
        }
        return src;
    }

    get id(): string {
        return this._notice && this._notice.id;
    }

    set isLoading(value: boolean) {
        this._isLoading = value;
    }
    get isLoading(): boolean {
        return this._isLoading;
    }
}
