<ng-container *ngIf="!isLoading; else loadingTpl">
  <ng-container *ngIf="record">
    <ng-container *ngIf="record.kuaidi100">
      <kuaidi100-tracking [record]="record.kuaidi100"></kuaidi100-tracking>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!record">
    <nz-empty [nzNotFoundContent]="notFoundContent">
      <ng-template #notFoundContent>
        <button
          nz-button
          nzSize="small"
          nzType="primary"
          nzShape="round"
          nzGhost
          (click)="refresh()"
        >
          <i nz-icon nzType="sync"></i> 刷新
        </button>
      </ng-template>
    </nz-empty>
  </ng-container>
</ng-container>

<ng-template #loadingTpl>
  <i nz-icon nzType="loading"></i> 查询中...
</ng-template>
