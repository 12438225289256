import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ADDRESS_TYPES } from 'src/app/common/constants/address';
import { PATTERNS } from 'src/app/common/constants/pattern';
import { FormSchema } from 'src/app/common/interfaces/form';
import { IPcaResult } from 'src/app/common/interfaces/pca';
import { AddressSchema, IRegionState, IRegionStateListItem } from 'src/app/common/interfaces/region';
import { BTCAddress } from 'src/app/common/models/address';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormComponent implements OnInit {
  private _schema: AddressSchema;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  @Input() validateForm: UntypedFormGroup;
  @Input() type: 0 | 1; // 0 sender, 1 recipient
  @Input()
  set schema(value: AddressSchema) {
    this._schema = value;
    this.build(this.schema);
    this.detection.detectChanges();
  }

  get schema(): AddressSchema {
    return this._schema;
  }
  @Input() address: BTCAddress;

  @Output() valueChange = new EventEmitter<any>();

  addressTypes = ADDRESS_TYPES.filter(item => item.value);
  stateList: IRegionStateListItem[] = [];

  constructor(
    private detection: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.validateForm?.valueChanges.subscribe({
      next: value => this.detection.detectChanges()
    });
  }


  private build(schema?: AddressSchema): any {

    for (const i in this.validateForm?.controls) {
      if (Object.prototype.hasOwnProperty.call(this.validateForm.controls, i)) {
        const control = this.validateForm.controls[i];
        if (!['region', 'regionId', 'regionCode'].includes(i)) {
          this.validateForm.removeControl(i);
        }
      }
    }

    if (!schema) {
      return false;
    }

    for (const i in schema) {
      if (Object.prototype.hasOwnProperty.call(schema, i)) {
        const item = schema[i] as FormSchema;
        if (!item) {
          return false;
        }

        this.validateForm.addControl(i, this.fb.control(null));

        if (i === 'state') {
          this.validateForm.addControl('stateCode', this.fb.control(null));
          this.validateForm.addControl('stateName', this.fb.control(null));
          this.validateForm.addControl('stateNameLocale', this.fb.control(null));
        }


        const { value, required, blocked, maxLength, minLength, max, min, pattern } = item;
        const validatorFn: ValidatorFn[] = [];


        if (required) {
          validatorFn.push(Validators.required);
        }

        if (maxLength) {
          validatorFn.push(Validators.maxLength(maxLength));
        }

        if (minLength) {
          validatorFn.push(Validators.minLength(minLength));
        }

        if (max && typeof max === 'number') {
          validatorFn.push(Validators.max(max));
        }

        if (min && typeof min === 'number') {
          validatorFn.push(Validators.min(min));
        }

        if (PATTERNS[pattern]) {
          validatorFn.push(Validators.pattern(PATTERNS[pattern].pattern));
        }

        this.validateForm.get(i).setValidators(validatorFn);

        if (typeof value !== 'undefined') {
          this.validateForm.get(i).setValue(value);
        }

        if (this.address && this.address[i]) {
          this.validateForm.get(i).setValue(this.address[i]);
        }

        if (blocked) {
          this.validateForm.get(i).disable();
        }
        this.validateForm.get(i).updateValueAndValidity();

      }
    }

  }

  pca_change(data: IPcaResult): void {
    const { stateCode } = data;
    const selectedState = this.stateList.find(({ value }) => value.code === stateCode)?.value;

    this.validateForm.patchValue({
      ...data, ...{
        state: selectedState,
        stateCode: selectedState?.code,
        stateName: selectedState?.name,
        stateNameLocale: selectedState?.nameLocale
      }
    });



  }

  state_change(data: IRegionState): void {
    this.validateForm.patchValue({
      stateCode: data?.code,
      stateName: data?.name,
      stateNameLocale: data?.nameLocale
    });
  }
}
