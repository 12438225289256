import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherFormComponent } from './voucher-form/voucher-form.component';
import { VoucherSelectorComponent } from './voucher-selector/voucher-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { VoucherPreviewComponent } from './voucher-preview/voucher-preview.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { MomentModule } from 'ngx-moment';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';



@NgModule({
  declarations: [
    VoucherFormComponent,
    VoucherSelectorComponent,
    VoucherPreviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzModalModule,
    NzGridModule,
    NzDrawerModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzListModule,
    NzEmptyModule,
    NzCardModule,
    NzIconModule,
    NzTypographyModule,
    NzAvatarModule,
    NzDividerModule,
    MomentModule,
    FormSharedModule,
    NzSkeletonModule,

    NzAvatarModule,
    NzPaginationModule,
  ],
  exports: [
    VoucherFormComponent,
    VoucherSelectorComponent,
    VoucherPreviewComponent
  ]
})
export class ShippingVoucherSharedModule { }
