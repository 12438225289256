import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickupListComponent } from './components/pickup-list/pickup-list.component';
import { PickupSearchFormComponent } from './components/pickup-search-form/pickup-search-form.component';
import { PickupHeaderListComponent } from './components/pickup-header-list/pickup-header-list.component';
import { PickupHeaderCreateComponent } from './components/pickup-header-create/pickup-header-create.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSharedModule } from '../utils/form';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { RouterModule } from '@angular/router';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { LayoutModule } from '../layout';
import { MomentModule } from 'ngx-moment';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { CarrierSharedModule } from '../carrier';
import { PickupHeaderEditComponent } from './components/pickup-header-edit/pickup-header-edit.component';
import { PickupHeaderDetailComponent } from './components/pickup-header-detail/pickup-header-detail.component';
import { PickupDetailComponent } from './components/pickup-detail/pickup-detail.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { PickupDateSelectorComponent } from './components/pickup-date-selector/pickup-date-selector.component';
import { PickupTimeRangeSelectorComponent } from './components/pickup-time-range-selector/pickup-time-range-selector.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { PickupWaybillSelectorComponent } from './components/pickup-waybill-selector/pickup-waybill-selector.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UnitModule } from '../unit/unit.module';
import { ShippingSharedModule } from '../shipping';
import { IndicatorSharedModule } from '../indicator';
import { PickupFormComponent } from './components/pickup-form/pickup-form.component';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { PickupTimeSelectorComponent } from './components/pickup-time-selector/pickup-time-selector.component';



@NgModule({
  declarations: [
    PickupListComponent,
    PickupSearchFormComponent,
    PickupHeaderListComponent,
    PickupHeaderCreateComponent,

    PickupHeaderEditComponent,
    PickupHeaderDetailComponent,
    PickupDetailComponent,
    PickupDateSelectorComponent,
    PickupTimeRangeSelectorComponent,
    PickupWaybillSelectorComponent,
    PickupFormComponent,
    PickupTimeSelectorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    LayoutModule,
    UnitModule,
    CarrierSharedModule,
    ShippingSharedModule,
    IndicatorSharedModule,
    MomentModule,
    NzI18nModule,
    NzIconModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzRadioModule,
    NzSelectModule,
    NzCheckboxModule,
    NzEmptyModule,
    NzListModule,
    NzAvatarModule,
    NzPaginationModule,
    NzDividerModule,
    NzTypographyModule,
    NzTagModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzDescriptionsModule,
    NzStepsModule,
    NzGridModule,
    NzTableModule,
    NzCheckboxModule,
    NzPipesModule
  ],
  exports: [
    PickupListComponent,
    PickupSearchFormComponent,
    PickupHeaderListComponent,
    PickupHeaderCreateComponent,
    PickupHeaderEditComponent,
    PickupHeaderDetailComponent,
    PickupDetailComponent,
    PickupDateSelectorComponent,
    PickupTimeRangeSelectorComponent,
    PickupWaybillSelectorComponent,
    PickupFormComponent,
    PickupTimeSelectorComponent
  ]
})
export class PickupSharedModule { }
