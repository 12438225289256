import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { take } from 'rxjs/operators';
import { BTCPickup } from 'src/app/common/models/pickup/btc-pickup.model';
import { BtcPickupService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'pickup-list',
  templateUrl: './pickup-list.component.html',
  styleUrls: ['./pickup-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupListComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  queryParams = {
    page: 1,
    skip: 0,
    limit: 10,
    sort: []
  };

  count = 0;

  recordList: BTCPickup[] = [];

  validateForm = new UntypedFormGroup({
    keywords: this.fb.control(null, [Validators.minLength(4)]),
    states: this.fb.control(null),
    waybillCarrierCode: this.fb.control(null),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private pickupService: BtcPickupService,
    private detection: ChangeDetectorRef,
    public i18n: NzI18nService
  ) {
  }

  ngOnInit(): void {
    this.on_submit();
  }

  on_page_change(i: number): void {
    this.queryParams.skip = (i - 1) * this.queryParams.limit;
    this.on_submit();
  }

  on_size_change(size: number): void {
    this.queryParams.limit = size;
    this.queryParams.skip = 0;
    this.queryParams.page = 1;
    this.on_submit();
  }

  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }
    if (this.validateForm.valid) {
      const body = { ...this.validateForm.value, ...this.queryParams };
      this.search(body);
    }
  }

  private search(body: any): void {
    this.isLoading = true;
    this.pickupService.search(body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.recordList = res?.result ?? [];
        this.count = res?.count ?? 0;
        this.isLoading = false;
      }
    });
  }

  trackByIndex(i: number): number {
    return i;
  }
}
