import { ISmbShipmentPackageTracking, ISmbShipmentTracking } from '../interfaces';
import { SmbShipmentPackageTracking } from './smb-shipment-package-tracking.model';

export class SmbShipmentTracking implements ISmbShipmentTracking {

    notes: string[] = [];
    shipmentNumber: string = null;
    paks: ISmbShipmentPackageTracking[] = [];
    lcl: boolean;
    shipmentType: string;
    Paks: SmbShipmentPackageTracking[] = [];

    constructor(private data: ISmbShipmentTracking) {
        Object.assign(this, this.data);
        for (const pak of this.paks) {
            this.initPak(pak);
        }
    }

    private initPak(pak: ISmbShipmentPackageTracking) {
        this.Paks.push(new SmbShipmentPackageTracking(pak));
    }

    get ShipmentNumber() {
        return `单号: ${this.shipmentNumber}`;
    }

    get ShipmentType() {
        switch (this.shipmentType) {
            case 'parcel':
                return {
                    color: 'default',
                    text: '包裹'
                };
            case 'env':
                return {
                    color: 'default',
                    text: '信件'
                };
            default:
                return null;
        }
    }

    get LclTag() {
        if (!this.lcl) {
            return null;
        }

        return {
            color: 'default',
            text: '拼箱'
        };
    }
}
