<ng-container *ngIf="voucher">
  <voucher-preview [voucher]="voucher" (event)="reset()"></voucher-preview>
</ng-container>

<ng-container *ngIf="!voucher">
  <form
    nz-form
    [formGroup]="validateForm"
    (ngSubmit)="on_submit()"
    nzLayout="horizontal"
  >
    <nz-form-item nzFor="code">
      <nz-input-group
        nzCompact
        nzSearch
        [nzSuffix]="AddOnAfterTpl"
        [nzAddOnAfter]="searchBtn"
      >
        <input nz-input formControlName="code" placeholder="代金券代码" />
        <ng-template #AddOnAfterTpl>
          <ng-container *ngIf="validateForm | formValue : 'code'">
            <i nz-icon nzType="close-circle" (click)="code_reset()"></i>
          </ng-container>
        </ng-template>
      </nz-input-group>
      <ng-template #searchBtn>
        <button
          nz-button
          nzType="primary"
          [disabled]="validateForm.invalid"
          [nzLoading]="isLoading"
        >
          使用
        </button>
      </ng-template>
    </nz-form-item>
    <nz-form-item nzJustify="center">
      <button nz-button nzType="link" nzBlock (click)="selector()">
        <i nz-icon nzType="tags"></i> 我的代金券中查找
      </button>
    </nz-form-item>
  </form>

  <nz-drawer
    [(nzVisible)]="selectorVisible"
    [nzTitle]="'我的代金券'"
    nzPlacement="top"
    nzHeight="100vh"
    (nzOnClose)="close()"
  >
    <ng-template nzDrawerContent>
      <voucher-selector
        [serviceCode]="serviceCode"
        [amount]="amount"
        (valueChange)="on_voucher_change($event)"
      ></voucher-selector>
    </ng-template>
  </nz-drawer>
</ng-container>
