import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationAddressComponent } from './destination-address/destination-address.component';
import { InitiationAddressComponent } from './initiation-address/initiation-address.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { UnitModule } from 'src/app/shared/unit/unit.module';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AddressPanelComponent } from './address-panel/address-panel.component';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { NzTypographyModule } from 'ng-zorro-antd/typography';



@NgModule({
  declarations: [
    InitiationAddressComponent,
    DestinationAddressComponent,
    AddressPanelComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    IndicatorSharedModule,
    UnitModule,
    NzListModule,
    NzEmptyModule,
    NzFormModule,
    NzDividerModule,
    NzBadgeModule,
    NzSkeletonModule,
    NzGridModule,
    NzPaginationModule,
    NzCardModule,
    NzDrawerModule,
    NzButtonModule,
    NzDescriptionsModule,
    NzIconModule,
    NzTypographyModule,
  ],
  exports: [
    InitiationAddressComponent,
    DestinationAddressComponent,
    AddressPanelComponent
  ]
})
export class ShippingAddressSharedModule { }
