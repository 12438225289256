<ng-container *ngIf="validateForm">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item *ngIf="validateForm | hasControl: 'state'">
      <nz-form-label
        nzFor="state"
        [nzRequired]="validateForm | isRequired: 'state'"
      >
        省/州
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError: 'state'">
        <nz-select
          nzAllowClear
          nzShowSearch
          nzPlaceHolder="请选择..."
          formControlName="state"
          (ngModelChange)="stateChange.emit($event)"
        >
          <ng-container *ngFor="let o of stateList">
            <nz-option
              [nzValue]="o.value"
              [nzLabel]="o.label"
              [nzDisabled]="o.disabled"
            ></nz-option>
          </ng-container>
          <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
            <i nz-icon nzType="loading" class="loading-icon"></i> 搜索中...
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>
