import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { trackingDataMock } from 'src/app/common/interfaces/shipping';
import { BtcShippingPackageTracking } from 'src/app/common/models/shipping';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BtcTrackingService {
  #trackingEndpoint: string;
  constructor(
    private http: HttpService
  ) {
    this.#trackingEndpoint = environment.trackingEndPoint;
  }

  tracking(entityId: string, mock = false): Observable<IHttpResponse> {
    if (mock) {
      const result = new BtcShippingPackageTracking(trackingDataMock);
      const res: IHttpResponse = {
        result,
      };
      return of(res);
    }
    const path = `${this.#trackingEndpoint}/btc/${entityId}`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BtcShippingPackageTracking(res.result);
        }
        return res;
      })
    );

  }
}
