import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SupportContactAssetModalComponent } from './support-contact-asset-modal/support-contact-asset-modal.component';
import { SupportContactAssetContainerComponent } from './support-contact-asset-container/support-contact-asset-container.component';
import { SupportContactComponent } from './support-contact/support-contact.component';
import { AffixShortCutBlockComponent } from './affix-short-cut-block/affix-short-cut-block.component';

import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    SupportContactAssetContainerComponent,
    SupportContactAssetModalComponent,
    SupportContactComponent,
    AffixShortCutBlockComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzBreadCrumbModule,
    NzGridModule,
    NzLayoutModule,
    NzCardModule,
    NzDividerModule,
    NzSpinModule,
    NzIconModule,
    NzButtonModule,
    NzModalModule,
    NzAffixModule,
    NzDropDownModule,
    NzBackTopModule,
    NzToolTipModule
  ],
  exports: [
    SupportContactComponent,
    SupportContactAssetContainerComponent,
    SupportContactAssetModalComponent,
    AffixShortCutBlockComponent
  ]
})
export class ContactModule { }
