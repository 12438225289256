import { IPaymentType, PaymentType } from "../../interfaces/payment";

export const paymentTypes: Record<PaymentType, IPaymentType> = {
    payout: {
        label: '消费支付',
        code: 'payout'
    },
    recharge: {
        label: '充值',
        code: 'recharge'
    },
    supplement: {
        label: '补差价',
        code: 'supplement'
    },
};

