import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { BTCPickup } from 'src/app/common/models/pickup';
import { BtcPickupService } from 'src/app/core';
import { NavigationService } from 'src/app/core/navigation';
import { IndicatorService } from 'src/app/shared/indicator';

@Component({
  templateUrl: './pickup-page-edit.component.html',
  styleUrls: ['./pickup-page-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupPageEditComponent implements OnInit {
  private drawerRef: NzDrawerRef;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  private pickupId: string;
  pickup: BTCPickup;
  constructor(
    private nav: NavigationService,
    private pickupService: BtcPickupService,
    private route: ActivatedRoute,
    private detection: ChangeDetectorRef,
    private indicatorService: IndicatorService,
  ) {
    const { id } = this.route.snapshot.params;
    this.pickupId = id;
  }

  ngOnInit(): void {
    this.detail_get(this.pickupId);
  }


  private detail_get(id: string): void {
    this.isLoading = true;
    this.pickupService.detail(id).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.pickup = res?.result;
        this.isLoading = false;
      }
    });
  }

  update_confirm(body: any): void {
    this.drawerRef = this.indicatorService.confirm({
      nzContentParams: {
        title: '保存修改',
        desc: '确认保存当前修改?'
      }
    });

    this.drawerRef.afterClose.subscribe({
      next: res => {
        if (res) {
          this.update(this.pickupId, body);
        }
      }
    });

  }


  private update(id: string, body: any): void {

    this.drawerRef = this.indicatorService.loading({});

    this.pickupService.update(id, body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.drawerRef?.close();
        if (res?.error) {
          this.drawerRef = this.indicatorService.alert({
            nzContentParams: {
              message: res.error.message,
              problems: res.error.problems,
              type: 'error'
            }
          });
        }
        if (res?.result) {
          this.nav.to(`/app/pickups/detail/${res.result.id}`);
        }
      }
    });
  }
}
