
import { AddressType } from '../address';

export enum shippingAddress {
    sender,
    recipient
}

export type ShippingAddressType = shippingAddress;
export interface IBtcShippingAddress {
    createdAt: Date;
    updatedAt: Date;
    id: string;
    livemode: number;
    version: number;
    status: number;
    type: shippingAddress;
    originId: string;
    label: string;
    addressType?: AddressType;
    company?: string;
    location?: string;
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLocale?: string;
    city: string;
    stateCode: string;
    state: string;
    postalCode: string;
    contactPerson: string;
    contactPersonLocale?: string;
    companyLocale?: string;
    cityLocale?: string;
    stateLocale?: string;
    contactPhone: string;
    regionCode: string;
    regionName: string;
    regionNameLocale: string;
    token: string;
    user: string;
}


export type ShippingAddressPropsItems<T> = {
    name: string;
    code: T;
};

export type ShippingAddressProps = {
    [code in ShippingAddressType]: ShippingAddressPropsItems<code>;
};

