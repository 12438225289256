import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';

import { RegionServiceControlComponent } from './components/region-service-control/region-service-control.component';
import { RegionDocViewerComponent } from './components/region-doc-viewer/region-doc-viewer.component';
import { FileSharedModule } from '../utils/file';
import { FormSharedModule } from '../utils/form';
import { RegionSelectorComponent } from './components/region-selector/region-selector.component';
import { RegionStateSelectorComponent } from './components/region-state-selector/region-state-selector.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';



@NgModule({
  declarations: [
    RegionServiceControlComponent,
    RegionDocViewerComponent,
    RegionSelectorComponent,
    RegionStateSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    FileSharedModule,
    NzAlertModule
  ],
  exports: [
    RegionServiceControlComponent,
    RegionDocViewerComponent,
    RegionSelectorComponent,
    RegionStateSelectorComponent
  ]
})
export class RegionSharedModule { }
