import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormSharedModule } from 'src/app/shared/utils/form';
import { IndicatorSharedModule } from 'src/app/shared/indicator';
import { ShippingEntityAddComponent } from './shipping-entity-add/shipping-entity-add.component';
import { ShippingEntityComponent } from './shipping-entity/shipping-entity.component';
import { ShippingEntityAddBtnComponent } from './shipping-entity-add-btn/shipping-entity-add-btn.component';
import { ShippingEntityContainerComponent } from './shipping-entity-container/shipping-entity-container.component';
import { ShippingFormatSharedModule } from '../format';
import { ShippingServiceSharedModule } from '../service';
import { ShippingReferenceSharedModule } from '../reference';
import { ShippingWaybillSharedModule } from '../waybill';
import { ShippingAddressSharedModule } from '../address';
import { ShippingLabelSharedModule } from '../label';
import { ShippingInfoSharedModule } from '../info';
import { ShippingExportationSharedModule } from '../exportation';
import { ShippingTrackingSharedModule } from '../tracking';



@NgModule({
  declarations: [
    ShippingEntityAddComponent,
    ShippingEntityContainerComponent,
    ShippingEntityAddBtnComponent,
    ShippingEntityComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IndicatorSharedModule,
    NzFormModule,
    NzInputModule,
    NzTypographyModule,
    NzDrawerModule,
    NzDividerModule,
    NzCardModule,
    NzButtonModule,
    NzIconModule,
    NzEmptyModule,
    NzAlertModule,
    NzCollapseModule,
    NzTagModule,
    NzGridModule,
    FormSharedModule,
    NzTabsModule,
    ShippingInfoSharedModule,
    ShippingFormatSharedModule,
    ShippingAddressSharedModule,
    ShippingServiceSharedModule,
    ShippingReferenceSharedModule,
    ShippingWaybillSharedModule,
    ShippingLabelSharedModule,
    ShippingExportationSharedModule,
    ShippingTrackingSharedModule
  ],
  exports: [
    ShippingEntityAddComponent,
    ShippingEntityContainerComponent,
    NzDrawerModule,
    ShippingEntityComponent,
  ]
})
export class ShippingEntitySharedModule { }
