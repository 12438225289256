import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BtcShippingPackageTracking, BtcShippingTracking } from 'src/app/common/models/shipping';
import { environment } from 'src/environments/environment';
import { HttpService } from '..';
import { ShipmentTracking } from './interfaces';
import { KuaiDi100Tracking } from './kuaidi100/interfaces';
import { SmbShipmentTracking } from './models';

@Injectable({
  providedIn: 'root'
})
export class TrackingCoreService {
  #endPoint: string;
  constructor(
    private http: HttpService
  ) {
    this.#endPoint = environment.trackingEndPoint;
  }

  smb_summary(body: { numbers: string[] }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/smb`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = res.result.map(item => new SmbShipmentTracking(item));
        }
        return res;
      })
    );
  }

  btc_summary(body: { numbers: string[] }): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/btc`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = res.result.map(item => new BtcShippingTracking(item));
        }
        return res;
      })
    );
  }

  btc_detail(id: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/btc/${id}`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BtcShippingPackageTracking(res.result);
        }
        return res;
      })
    );
  }

  btc_track_by_waybill(id: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/btc/waybills/${id}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        let record: ShipmentTracking<any> | null = null;

        if (res.error) {
          return res;
        }

        if (res.result.kuaidi100) {
          record = res.result as ShipmentTracking<KuaiDi100Tracking>;
        }

        res.result = record;
        return res;
      })
    );
  }

}
