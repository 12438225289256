import { IPaymentMethod, PaymentMethod } from '../../interfaces/payment';
import { Currency, CurrencyCode } from '../../interfaces/rate/currency';
import { CurrencyPipe } from '@angular/common';
export class BTCPaymentMethod {
    private _data: IPaymentMethod;
    private _count = 0;
    private _currency = 'CAD';

    constructor(
        data: IPaymentMethod, currency: Currency = 'CAD'
    ) {
        this._data = data;
        this._currency = currency;
    }

    set count(value: number) {
        this._count = value;
    }

    get count(): number {
        return this._count;
    }

    get min(): number {
        return this._data.min;
    }

    get currency(): string {
        return this._currency;
    }


    set currency(value: string) {
        this._currency = value;
    }

    get currencies(): Currency[] {
        return this._data.currencies;
    }

    get name(): string {
        const cur = new CurrencyPipe('en').transform(this.count, this._currency, 'symbol-narrow');
        if (this._data.code === 'account_balance') {
            return `${this._data.name} (当前 ${cur})`;
        } else {
            return `${this._data.name}`;
        }
    }

    get code(): PaymentMethod {
        return this._data.code;
    }

    get icon(): string {
        return this._data.icon;
    }


    get desc(): string {
        return this._data.desc;
    }
}
