import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { delay } from 'rxjs/operators';

import { PATTERNS } from 'src/app/common/constants/pattern';
import { REASON_FOR_EXPORT } from 'src/app/common/constants/service/service-option';
import { IBtcShippingAdditionalItem, RfeItem } from 'src/app/common/interfaces/service';

@Component({
  selector: 'rfe-form',
  templateUrl: './rfe-form.component.html',
  styleUrls: ['./rfe-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RfeFormComponent implements OnInit {
  @Input() current: IBtcShippingAdditionalItem;
  @Input() option: RfeItem<any>;

  @Input() validateForm: UntypedFormGroup;
  @Input() rfeEvent = new EventEmitter<IBtcShippingAdditionalItem>();
  codes = [];
  cappedValues = [];
  constructor(
    private fb: UntypedFormBuilder,
    private detection: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe
  ) {

  }


  ngOnInit(): void {
    this.type_set(this.option);
  }

  on_code_change(code: string) {

    if (this.validateForm.get('code')) {
      if (code === 'OTH') {
        const { note } = this.option;
        const validators: ValidatorFn[] = [Validators.required];
        if (note?.pattern) {
          validators.push(Validators.pattern(PATTERNS[note.pattern]?.pattern));
        }

        if (note?.maxLength) {
          validators.push(Validators.maxLength(note.maxLength));
        }
        if (note?.minLength) {
          validators.push(Validators.maxLength(note.minLength));
        }

        this.validateForm.get('note').setValidators(validators);
        this.validateForm.get('note').updateValueAndValidity();

        const value = this.current?.note ? this.current.note : note?.value;
        this.validateForm.patchValue({
          note: value
        });

        if (note?.blocked) {
          if (note?.value) {
            this.validateForm.patchValue({
              note: note.value
            });
          }
          this.validateForm.get('note').disable();
        }

      } else {
        this.validateForm.get('note').clearValidators();
        this.validateForm.patchValue({
          note: null
        });
      }

      this.detection.detectChanges();

    }
  }

  type_set(value: RfeItem<any>): void {
    const { carrier, code, note, blocked, amount, cappedValue } = value;

    for (const i in REASON_FOR_EXPORT[carrier]) {
      if (Object.prototype.hasOwnProperty.call(REASON_FOR_EXPORT[carrier], i)) {
        const item = REASON_FOR_EXPORT[carrier][i];
        if (code?.options?.length && !code.options.some((key) => key === i)) {
          continue;
        }
        this.codes.push(item);
      }
    }

    if (code) {
      this.validateForm.addControl('code', this.fb.control(code?.value, [Validators.required]));
      this.validateForm.addControl('note', this.fb.control(note?.value));
    }

    const amountValidators: ValidatorFn[] = [];
    if (cappedValue) {
      this.cappedValues = [
        {
          value: true,
          label: `${this.currencyPipe.transform(cappedValue?.value, 'CAD', 'symbol-narrow', '1.0-0')} 以上`
        },
        {
          value: false,
          label: `${this.currencyPipe.transform(cappedValue?.value, 'CAD', 'symbol-narrow', '1.0-0')} 以下`
        },
      ];
      this.validateForm.addControl('cappedValue', this.fb.control(null));
      this.validateForm.addControl('amount', this.fb.control(null));
      amountValidators.push(Validators.max(cappedValue?.value));
      amountValidators.push(Validators.required);
    }

    if (amount && !cappedValue) {
      this.validateForm.addControl('amount', this.fb.control(amount?.value));
      // TODO amount set validators

      if (amount?.max) {
        amountValidators.push(Validators.max(amount.max));
      }
      if (amount?.min) {
        amountValidators.push(Validators.max(amount.min));
      }
      if (amount?.required) {
        amountValidators.push(Validators.required);
      }
    }

    this.validateForm.get('amount').setValidators(amountValidators);
    this.validateForm.get('amount').updateValueAndValidity();

    if (this.current) {
      this.validateForm.patchValue(this.current);
      this.on_code_change(this.current.code);
    } else if (code?.value) {

      this.on_code_change(code?.value);
    }



    if (blocked) {
      this.validateForm.disable();
    }

    // TODo set blocked value
    if (code?.blocked) {
      this.validateForm.get('code').disable();
    }

    if (amount?.blocked) {
      if (amount.value) {
        this.validateForm.patchValue({
          amount: amount.value
        });
      }
      this.validateForm.get('amount').disable();
    }
    setTimeout(() => {
      this.rfeEvent.emit(this.validateForm.value);
    }, 500);

  }

  on_capped_change(capped: boolean): void {

    if (capped) {
      this.validateForm.patchValue({
        amount: this.option.cappedValue.value
      });
    } else {
      this.validateForm.patchValue({
        amount: null
      });
    }
    this.rfeEvent.emit(this.validateForm.value);
  }

  on_amount_change() {
    this.rfeEvent.emit(this.validateForm.value);
  }
}
