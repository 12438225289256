<form nz-form [formGroup]="validateForm">
  <div formGroupName="pak">
    <div nz-row nzJustify="start" nzGutter="8" formGroupName="size">
      <div nz-col *ngIf="fv.hasControl(pakForm | formGroup: 'size', 'length')">
        <nz-form-item>
          <nz-form-control
            [nzErrorTip]="fv.message(pakForm | formGroup: 'size', 'length')"
          >
            <nz-input-group
              nzAddOnBefore="长度"
              [nzAddOnAfter]="sizeUnit.code"
              style="width: auto"
            >
              <nz-input-number
                nzPrecision="2"
                nzStep="0.1"
                nzMin="0"
                formControlName="length"
                (ngModelChange)="on_size_change($event)"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col *ngIf="fv.hasControl(pakForm | formGroup: 'size', 'width')">
        <nz-form-item>
          <nz-form-control
            [nzErrorTip]="fv.message(pakForm | formGroup: 'size', 'width')"
          >
            <nz-input-group
              nzAddOnBefore="宽度"
              [nzAddOnAfter]="sizeUnit.code"
              style="width: auto"
            >
              <nz-input-number
                nzPrecision="2"
                nzStep="0.1"
                nzMin="0"
                formControlName="width"
                (ngModelChange)="on_size_change($event)"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col *ngIf="fv.hasControl(pakForm | formGroup: 'size', 'height')">
        <nz-form-item>
          <nz-form-control
            [nzErrorTip]="fv.message(pakForm | formGroup: 'size', 'height')"
          >
            <nz-input-group
              nzAddOnBefore="高度"
              [nzAddOnAfter]="sizeUnit.code"
              style="width: auto"
            >
              <nz-input-number
                nzPrecision="2"
                nzStep="0.1"
                nzMin="0"
                formControlName="height"
                (ngModelChange)="on_size_change($event)"
              ></nz-input-number>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-form-item *ngIf="fv.hasControl(pakForm, 'weight')">
      <nz-form-control [nzErrorTip]="fv.message(pakForm, 'weight')">
        <nz-input-group
          nzAddOnBefore="重量"
          [nzAddOnAfter]="weightUnit.code"
          style="width: auto"
        >
          <nz-input-number
            nzPrecision="3"
            nzStep="0.1"
            nzMin="0"
            formControlName="weight"
          ></nz-input-number>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>

  <nz-divider nzPlain></nz-divider>
</form>
