import { Pipe, PipeTransform } from '@angular/core';
import { IUnitWeight } from 'src/app/common/interfaces/setting';
import { BtcShippingWaybill } from 'src/app/common/models/shipping';

@Pipe({
  name: 'waybillWeight'
})
export class WaybillTotalWeightPipe implements PipeTransform {

  transform(
    value: unknown,
    unit: IUnitWeight,
    returnString: boolean = false,
  ): number | string {

    const waybills = value as BtcShippingWaybill[] ?? [];

    const weight = waybills.reduce((sum, c) => {
      sum = sum + c.weight[unit.code];
      return Number((sum).toFixed(3));
    }, 0);

    if (returnString) {
      return `${weight} ${unit.name}`;
    }

    return weight;
  }

}
