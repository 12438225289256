import { Component, Input, OnInit } from '@angular/core';
import { RestrictionSchema } from 'src/app/common/interfaces/service';
import { BtcService, BtcServiceContent } from 'src/app/common/models/service';
import { UnitService } from 'src/app/core/unit';

@Component({
  selector: 'service-restriction',
  templateUrl: './service-restriction.component.html',
  styleUrls: ['./service-restriction.component.less']
})
export class ServiceRestrictionComponent implements OnInit {
  @Input() service: BtcService;
  @Input() content: BtcServiceContent;

  restriction: RestrictionSchema;
  unit$ = this.unitService.unit$;

  constructor(
    private unitService: UnitService
  ) { }

  ngOnInit(): void {

    if (this.content && this.content.restriction) {
      this.restriction = this.content.restriction;
    } else if (this.service && this.service.restriction) {
      this.restriction = this.service.restriction;
    } else {
      this.restriction = null;
    }

  }

}
