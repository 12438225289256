import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { AuthCoreService } from 'src/app/core/auth';
import { map, switchMap } from 'rxjs/operators';
import { ACL } from 'src/app/common/constants/acl';

import { ISOCode2, Zone } from 'src/app/common/interfaces/region';


@Injectable({
  providedIn: 'root'
})
export class AclService {

  constructor(private authService: AuthCoreService) {
  }

  /**
   * Check if current user have permission in params.
   * @param permission string   Permission to check.
   * @return boolean    True if has permission.
   */
  can(permission: string): Observable<boolean> {
    return this.authService.authUser$.pipe(
      map(user => {
        const roles = ACL[permission];
        if (!roles) {
          return false;
        }
        if (_.indexOf(roles, '*') >= 0) {
          return true;
        }

        const codes = user.roles.map((role) => role.code);
        const hasRoles = _.intersection(codes, roles);
        return hasRoles.length > 0 ? true : false;
      })
    );
  }

  hasZone(codes: Zone[]): Observable<boolean> {
    return this.authService.authUser$.pipe(
      switchMap(authUser => {
        if (!authUser) {
          return of(false);
        }

        const hasRegion = authUser.hasZone(codes);
        return of(hasRegion);

      })
    );
  }

  hasCountry(codes: ISOCode2[]): Observable<boolean> {
    return this.authService.authUser$.pipe(
      switchMap(authUser => {
        if (!authUser) {
          return of(false);
        }

        const hasRegion = authUser.hasCountry(codes);
        return of(hasRegion);

      })
    );
  }

  isRole(roles: string[]): Observable<boolean> {
    return this.authService.watchUser().pipe(
      switchMap(authUser => {
        if (!authUser) {
          return of(false);
        }

        const hasRole = authUser.hasRole(roles);
        return of(hasRole);

      })
    );
  }

  isGroup(groups: string[]): Observable<boolean> {
    return this.authService.watchUser().pipe(
      switchMap(authUser => {
        if (!authUser) {
          return of(false);
        }

        const hasGroup = authUser.hasGroup(groups);
        return of(hasGroup);

      })
    );

  }

  businessAccount(value: boolean): Observable<boolean> {
    return this.authService.watchUser().pipe(
      switchMap(authUser => {
        if (!authUser) {
          return of(false);
        }

        const result = authUser.businessAccount === value
        return of(result);

      })
    );
  }



}
