<service-rate-compare
  [regionServiceControl]="regionServiceControl"
  [initiationRegion]="initiationRegion"
  [destinationRegion]="destinationRegion"
  [multiDestination]="multiDestination"
  [pakType]="pakType"
  [index]="index"
  [indexes]="indexes"
  [services]="services"
  (serviceChange)="on_service_select($event)"
></service-rate-compare>
