import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzAlertModule } from 'ng-zorro-antd/alert';

import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { IndicatorAlertComponent } from './components/indicator-alert/indicator-alert.component';
import { IndicatorConfirmComponent } from './components/indicator-confirm/indicator-confirm.component';
import { IndicatorLoadingComponent } from './components/indicator-loading/indicator-loading.component';
import { IndicatorResultComponent } from './components/indicator-result/indicator-result.component';
import { IndicatorService } from './services';
import { IssueLinkPipe } from './pipes/issue-link/issue-link.pipe';
import { RouterModule } from '@angular/router';
import { IssueTextPipe } from './pipes/issue-text/issue-text.pipe';

@NgModule({
  declarations: [
    IndicatorLoadingComponent,
    IndicatorConfirmComponent,
    IndicatorAlertComponent,
    IndicatorResultComponent,
    IssueLinkPipe,
    IssueTextPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzIconModule,
    NzButtonModule,
    NzResultModule,
    NzAlertModule,
    NzCardModule,
    NzIconModule,
    NzDrawerModule,
  ],
  providers: [
    IndicatorService,
  ],
  exports: [
    IndicatorLoadingComponent,
    IndicatorConfirmComponent,
    IndicatorAlertComponent,
    IndicatorResultComponent,
    NzIconModule,
    NzButtonModule,
    NzResultModule,
    NzAlertModule,
    NzCardModule,
    NzIconModule,
    NzDrawerModule,
    IssueLinkPipe,
    IssueTextPipe
  ]
})
export class IndicatorSharedModule { }
