import { OnDestroy } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { ShippingEvent } from 'src/app/common/interfaces/shipping';
import { Voucher } from 'src/app/common/models/auth';
import { Rate } from 'src/app/common/models/rate';
import { ChargeQuote } from 'src/app/common/models/rate/charge-quote.model';
import { BtcShippingService } from 'src/app/core';

@Component({
  selector: 'rate-estimate',
  templateUrl: './rate-estimate.component.html',
  styleUrls: ['./rate-estimate.component.less']
})
export class RateEstimateComponent implements OnInit, OnDestroy {
  private _voucher: Voucher | null = null;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }
  private destroy$ = new Subject<void>();
  rate: Rate;
  @Input() error: IHttpResponseError;
  @Output() errorChange = new EventEmitter<IHttpResponseError>();

  @Input() shippingId: string;
  @Input() shippingEvent: EventEmitter<ShippingEvent>;

  @Input() amount = 0;
  @Output() amountChange = new EventEmitter<number>();

  @Input() quote: ChargeQuote | null;
  @Output() quoteChange = new EventEmitter<ChargeQuote | null>();

  @Input() set voucher(value: Voucher | null) {
    this._voucher = value;

    this.onSubmit();

  }

  get voucher(): Voucher | null {
    return this._voucher;
  }

  constructor(
    private shippingService: BtcShippingService,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.eventChange();
    // this.on_submit();
  }

  private eventChange(): void {
    this.shippingEvent?.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: event => {
        if (event === ShippingEvent.rate_estimate) {
          this.onSubmit();
        }
      }
    });
  }

  onSubmit(): void {
    this.estimate(this.shippingId, { voucherId: this.voucher?.id });
  }

  private estimate(id: string, body: any = {}) {
    this.isLoading = true;
    this.error = null;
    this.rate = null;
    this.shippingService.rate_estimate(id, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        const { result, error } = res;
        this.rate = result;

        if (this.rate) {
          this.amountChange.emit(this.rate.charge.due);
          this.quoteChange.emit(this.rate.charge);

        } else {
          this.amountChange.emit(0);
          this.quoteChange.emit(null);
        }


        this.errorChange.emit(error);
        this.isLoading = false;

      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
