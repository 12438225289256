import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, tap } from 'rxjs';
import { IVoucherSeal } from 'src/app/common/interfaces/account';
import { HttpHelperService } from 'src/app/helpers/http';
import { IHttpResponse } from 'src/app/helpers/http/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VoucherExchangeCoreService {
  #saleEndPoint = environment.saleEndPoint;
  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();


  constructor(
    private http: HttpHelperService,

  ) { }


  set loading(value: boolean) {
    this.#loading$.next(value);
  }


  get list$() {
    return this.getList<IVoucherSeal[]>().pipe(
      map(res => res.result as IVoucherSeal[] ?? [])
    )
  }

  getList<T>(): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `${this.#saleEndPoint}/discount/voucher/exchange/search`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false)
    );
  }


  exchange(body: { modelId: string, exchangeQty: number }): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#saleEndPoint}/discount/voucher/exchange`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false)
    );
  }

}
