export * from './ticket-counter.interface';
export * from './btc-service-ticket.interface';
export * from './btc-service-ticket-dialog.interface';

export enum ServiceTicketAuthorCode {
    user = 0,
    admin = 1
}

export type ServiceTicketAuthor = 0 | 1;
export type ServiceTicketLevel = 0 | 1;
export type ServiceTicketStatus = 1 | 2 | 3;

export enum ServiceTicketTypeCode {
    order = 'order',
    invoice = 'invoice',
    shipping = 'shipping',
    waybill = 'waybill',
    payment = 'payment',
    doc = 'doc',
    initiation = 'initiation',
    destination = 'destination',
    other = 'other'
}

export type ServiceTicketType = keyof typeof ServiceTicketTypeCode;

export type ServiceTicketPropItem<T> = {
    name: string;
    code: T;
    disabled?: boolean;
};


export type ServiceTicketProp = {
    [code in ServiceTicketType]?: ServiceTicketPropItem<code>
};

export enum ServiceTicketStateCode {
    pending = 1, // 处理中,
    replied = 2, // 已答复,
    completed = 3, // 已关闭
    open = 4 // 需处理
}

export type ServiceTicketState = keyof typeof ServiceTicketStateCode;

export type ServiceTicketStatePropItem<T> = {
    name: string;
    code: T;
    query?: any;
};

export type ServiceTicketStateProp = {
    [code in ServiceTicketStateCode]: ServiceTicketStatePropItem<code>
};


