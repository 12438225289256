import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { take } from 'rxjs/operators';
import { Carrier } from 'src/app/common/interfaces/carrier';
import { IUnit } from 'src/app/common/interfaces/setting';
import { BTCPickup } from 'src/app/common/models/pickup';
import { BtcShippingWaybill } from 'src/app/common/models/shipping';
import { BtcPickupService, BtcWaybillService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'pickup-waybill-selector',
  templateUrl: './pickup-waybill-selector.component.html',
  styleUrls: ['./pickup-waybill-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupWaybillSelectorComponent implements OnInit {
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  @Input() pickup: BTCPickup;
  @Input() unit: IUnit;

  @Input() set waybillCarrierCode(value: Carrier) {
    this.validateForm.patchValue({
      waybillCarrierCode: value
    });
  }

  @Input() waybills: BtcShippingWaybill[] = [];
  @Output() waybillsChange = new EventEmitter<BtcShippingWaybill[]>();

  queryParams = {
    page: 1,
    skip: 0,
    limit: 10,
    sort: []
  };

  count = 0;
  recordList: BtcShippingWaybill[] = [];

  validateForm = new UntypedFormGroup({
    keywords: this.fb.control(null, [Validators.minLength(4)]),
    waybillCarrierCode: this.fb.control(null, [Validators.required])
  });

  // TODO checkbox
  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;

  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private wbService: BtcWaybillService,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.on_submit();
  }

  on_page_change(i: number): void {
    this.queryParams.skip = (i - 1) * this.queryParams.limit;
    this.on_submit();
  }

  on_size_change(size: number): void {
    this.queryParams.limit = size;
    this.queryParams.skip = 0;
    this.queryParams.page = 1;
    this.on_submit();
  }

  on_query_change(data: NzTableQueryParams): void {
    const { sort } = data;

    this.queryParams.sort = sort.filter(({ value }) => value).map((item) => {
      const element = [item.key, item.value === 'ascend' ? 'asc' : 'desc'];
      return element.join(':');
    });
    this.on_submit();
  }

  keywords_reset(): void {
    this.validateForm.patchValue({
      keywords: null
    });
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.recordList
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    const wb = this.recordList.find((v => v.id === id));
    if (wb) {
      if (checked) {
        this.setOfCheckedId.add(id);
      } else {
        this.setOfCheckedId.delete(id);
      }
    }
    this.waybills = this.recordList.filter(({ id: wbId }) => this.setOfCheckedId.has(wbId));

    this.waybillsChange.emit(this.waybills);
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.recordList;
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }


  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      const body = {
        ...this.validateForm.value, ... {
          groupId: { '!=': null },
          states: {
            submitted: 1,
            voided: {
              '!=': [1, 2]
            },

            waybillNumber: {
              '!=': null
            }
          }
        },
        ...this.queryParams
      };

      if (!this.pickup) {
        body.states.pickup = null;
      }

      this.search(body);
    }
  }

  private search(body: any): void {
    this.isLoading = true;
    this.wbService.search(body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.recordList = res?.result ?? [];
        this.count = res?.count ?? 0;
        this.waybills.forEach(item => {
          this.updateCheckedSet(item.id, true);
        });
        this.isLoading = false;
      }
    });
  }

}
