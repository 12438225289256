<form nz-form [formGroup]="validateForm" (ngSubmit)="on_submit()">
  <div nz-row [nzGutter]="[40, 20]" nzJustify="space-between">
    <div nz-col nzXs="24" nzXl="12">
      <!-- Service Content -->
      <ng-container *ngIf="indexes.length">
        <nz-divider
          nzText="物品分类"
          nzPlain
          nzOrientation="left"
          nzDashed
        ></nz-divider>
        <service-content-form
          [indexes]="indexes"
          [validateForm]="validateForm"
          [contentList]="serviceContentList"
        ></service-content-form>
      </ng-container>

      <!-- PAK SIZE -->
      <nz-divider
        nzText="Format 规格"
        nzPlain
        nzOrientation="left"
        nzDashed
      ></nz-divider>

      <entity-format-form
        [validateForm]="validateForm"
        [unit]="unit"
      ></entity-format-form>

      <!-- Service Rfe -->
      <ng-container *ngIf="validateForm | hasControl: 'insurance'">
        <nz-divider
          nzText="Insurance 保险"
          nzPlain
          nzOrientation="left"
          nzDashed
        ></nz-divider>

        <service-content-insurance-form
          [option]="insurance"
          [validateForm]="validateForm | formGroup: 'insurance'"
        ></service-content-insurance-form>
      </ng-container>
    </div>

    <div nz-col nzXs="24" nzXl="12">
      <!-- DESTINATION -->
      <ng-container *ngIf="validateForm | hasControl: 'destination'">
        <nz-divider
          [nzText]="destinationTitleTpl"
          nzPlain
          nzOrientation="left"
          nzDashed
        ></nz-divider>
        <ng-template #destinationTitleTpl>
          目的地: {{ destinationRegion?.nameLocale }} ({{
            destinationRegion?.code.toUpperCase()
          }})
        </ng-template>
        <address-form
          [validateForm]="validateForm | formGroup: 'destination'"
          [schema]="destinationRegion?.quoteSchema"
          [type]="1"
        ></address-form>
      </ng-container>

      <!-- Service Additional -->
      <!-- <ng-container *ngIf="validateForm | hasControl: 'additional'">
        <nz-divider
          nzText="Options 选项"
          nzPlain
          nzOrientation="left"
          nzDashed
        ></nz-divider>

        <service-additional-form
          [additional]="additional"
          [validateForm]="validateForm"
        ></service-additional-form>
      </ng-container> -->
    </div>
  </div>
</form>
