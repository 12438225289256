import { ItemUnitUni, ItemUnitCanpost } from '../../interfaces/service';
import { IBtcShippingItem, ItemWeight } from '../../interfaces/shipping';

export class BTCShippingItem {
    private _data: Partial<IBtcShippingItem>;

    constructor(data: Partial<IBtcShippingItem>) {
        this._data = data;
    }

    get id(): string {
        return this._data.id;
    }

    get memo(): string {
        return this._data?.memo;
    }

    get name(): string {
        return this._data?.name;
    }


    get qty(): number {
        return this._data?.qty;
    }
    get price(): number {
        if (this._data.avgPrice) {
            return 0;
        } else {
            return this._data?.price;
        }
    }

    get avgPrice(): number {
        if (!this._data.avgPrice) {
            return 0;
        } else {
            return this._data?.price;
        }
    }

    get currency(): string {
        return this._data?.currency.toUpperCase();
    }

    get weight(): ItemWeight {
        if (this._data.avgWeight) {
            return null;
        } else {
            return this._data?.weight;
        }
    }

    get avgWeight(): ItemWeight {
        if (!this._data.avgWeight) {
            return null;
        } else {
            return this._data?.weight;
        }
    }

    get unit(): ItemUnitUni | ItemUnitCanpost {
        return this._data?.unit;
    }

    get version(): number {
        return this._data?.version;
    }

    get data(): Partial<IBtcShippingItem> {
        return this._data;
    }
}
