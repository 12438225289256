export const euVatRegex = {
    BE: {
        label: '请使用标准格式, 例: 0123456789',
        pattern: `^\\d{10}$`,
        code: 'eu_vat_be'
    },
    NL: {
        label: '请使用标准格式, 例: 123456789B01',
        pattern: `^\\d{9}B\d{2}$`,
        code: 'eu_vat_nl'
    },
    FR: {
        label: '请使用标准格式, 例: FRXX123456789',
        pattern: `^\\d{9}B\d{2}$`,
        code: 'eu_vat_fr'
    },
    ES: {
        label: '请使用标准格式, 例: X1234567X',
        pattern: `^[A-Z0-9]\\d{7}[A-Z0-9]$`,
        code: 'eu_vat_es'
    },
    IT: {
        label: '请使用标准格式, 例: IT12345678901',
        pattern: `^\\d{11}$`,
        code: 'eu_vat_it'
    },
    LU: {
        label: '请使用标准格式, 例: LU12345678',
        pattern: `^\\d{8}$`,
        code: 'eu_vat_lu'
    },

} as const;