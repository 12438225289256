export interface ISmbShipmentTracking {
    notes: string[];
    shipmentNumber: string;
    paks: ISmbShipmentPackageTracking[];
    lcl: boolean;
    shipmentType: string;
}

export interface ISmbShipmentPackageTracking {
    label: string;
    packageNumber: string;
    events: ISmbShipmentTrackingEvent[];
    forwardTo: RouteDirection;
    lclLabel?: string;
    isInnerPak: boolean;
    isOuterPak: boolean;
    initialPak: boolean;
    childPak: boolean;
    inTransmit: boolean;
    depotReceived: boolean;
    depotReleased: boolean;
    delivered: boolean;
    rts: boolean;
    carrierTrackingNumber?: string;
    externalUrl?: string;
}

export interface ISmbShipmentTrackingEvent {
    createdAt: string;
    id: string;
    name: string;
    code: string;
    eventTime: string;
    eventTimeHide: boolean;
    description: null | string;
    location: null | string;
    notice: null;
}

export enum RouteDirection {
    depot = 'depot',
    end = 'end'
}

// export enum Code {
//     DispatchCenterPakProcessing = "DISPATCH_CENTER_PAK_PROCESSING",
//     DispatchCenterPakReleased = "DISPATCH_CENTER_PAK_RELEASED",
//     DispatchCenterParcelInTransmit = "DISPATCH_CENTER_PARCEL_IN_TRANSMIT",
//     Info = "INFO",
//     LabelCreated = "LABEL_CREATED",
// }

// export enum Name {
//     分拣中心包裹处理中 = "分拣中心: 包裹处理中",
//     分拣中心包裹放行 = "分拣中心: 包裹放行",
//     包裹国际转运中 = "包裹国际转运中",
//     在途 = "在途",
//     揽收 = "揽收",
//     派件 = "派件",
//     物流运单已创建 = "物流:运单已创建",
//     签收 = "签收",
// }
