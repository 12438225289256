import { EventEmitter, ChangeDetectorRef } from "@angular/core";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { startsWith } from "lodash";
import { NzButtonSize } from "ng-zorro-antd/button";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzI18nService } from "ng-zorro-antd/i18n";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IUnit } from "src/app/common/interfaces/setting";
import { ShippingEvent } from "src/app/common/interfaces/shipping";
import { BtcOrder } from "src/app/common/models/order";
import { BreakpointService } from "src/app/core/breakpoint";
import { NavigationService } from "src/app/core/navigation";
import { PageHeaderService } from "src/app/core/page-header";
import { Service } from "src/app/core/service/models";
import { Shipping } from "src/app/core/shipping/models";
import { RegionDocViewerComponent } from "src/app/shared/region/components";

@Component({
  selector: "group-header",
  templateUrl: "./group-header.component.html",
  styleUrls: ["./group-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupHeaderComponent implements OnInit, AfterViewInit {
  private _packageCount = 0;
  private _order: BtcOrder | null = null;
  @Input() unit: IUnit;
  @Input() service: Service;
  @Input() group: Shipping;
  @Input()
  set order(value: BtcOrder) {
    this._order = value;
    this.detection.detectChanges();
  }

  get order(): BtcOrder {
    return this._order;
  }
  @Input()
  set packageCount(value: number) {
    this._packageCount = value;
    this.detection.detectChanges();
  }
  get packageCount(): number {
    return this._packageCount;
  }
  @ViewChild("subtitleTpl") subtitleTpl: TemplateRef<any>;
  @ViewChild("buttonsTpl") buttonsTpl: TemplateRef<any>;
  @ViewChild("contentTpl") contentTpl: TemplateRef<any>;

  @Input() shippingEvent: EventEmitter<ShippingEvent>;
  size$: Observable<NzButtonSize> = this.bpService.small$.pipe(
    map((sm) => {
      if (sm) {
        return "small";
      } else {
        return "default";
      }
    })
  );
  constructor(
    public ph: PageHeaderService,
    public i18n: NzI18nService,
    public nav: NavigationService,
    private drawerService: NzDrawerService,
    private detection: ChangeDetectorRef,
    private bpService: BreakpointService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.view_update();
  }

  view_update(): void {
    this.ph.avatar = this.group.avatar;
    // this.ph.tags = this.group.status;
    this.ph.subtitle = this.subtitleTpl;
    this.ph.buttons = this.buttonsTpl;
    this.ph.content = this.contentTpl;
  }

  delete(): void {
    this.shippingEvent.emit(ShippingEvent.shipping_delete);
  }

  package_add(): void {
    this.shippingEvent.emit(ShippingEvent.package_add);
  }

  order_delete(): void {
    this.shippingEvent.emit(ShippingEvent.order_delete);
  }

  order_void(): void {
    this.shippingEvent.emit(ShippingEvent.order_void);
  }

  order_detail(): void {
    this.shippingEvent.emit(ShippingEvent.order_detail);
  }

  orderCheckout(): void {
    this.shippingEvent.emit(ShippingEvent.order_checkout);
  }

  locate_parcel_shop(): void {
    const carrierCode = this.service.data.transitService0.carrierCode;
    const path = "https://supports.youyou-express.com/drop-off/locators";

    location.href = `${path}/${carrierCode.split("_")[0]}`;

    // this.shippingEvent.emit(ShippingEvent.locate_parcel_shop);
  }

  create_pickup(): void {
    this.shippingEvent.emit(ShippingEvent.create_pickup);
  }

  nmm_preview(isoCode2: string): void {
    const d = this.drawerService.create({
      nzTitle: `禁运说明`,
      nzContent: RegionDocViewerComponent,
      nzContentParams: {
        isoCode2,
      },
      nzHeight: "auto",
      nzPlacement: "top",
    });
  }
}
