/**
 * @ReasonForExport
 */

import { Carrier, CarrierCode } from '../carrier';
import { PatternCode } from '../pattern';
import { Region } from '../region';
import { AdditionalOption } from './btc-service-option.interface';


export enum ReasonForExportCanpostCode {
    DOC = 'DOC',
    SAM = 'SAM',
    REP = 'REP',
    SOG = 'SOG',
    OTH = 'OTH'
}

export type ReasonForExportCanpost = keyof typeof ReasonForExportCanpostCode;

export type ReasonForExportCode<T> = {
    code: T
};


export type ReasonForExport = {
    [CarrierCode.canpost]: Record<ReasonForExportCanpostCode, AdditionalOption<ReasonForExportCanpostCode>>;
};


export type RfeItem<T> = {
    carrier: Carrier;
    blocked?: boolean;
    hidden?: boolean;
    shipTo?: Region;
    code?: {
        value?: T;
        required?: boolean;
        blocked?: boolean;
        hidden?: boolean;
        options?: T[]
    };
    note?: {
        value?: string;
        pattern?: PatternCode
        maxLength?: number;
        minLength?: number;
        blocked?: boolean;
        hidden?: boolean;
    };
    amount?: {
        value?: number;
        max?: number;
        min?: number;
        required?: boolean;
        blocked?: boolean;
        hidden?: boolean;
    },
    cappedValue?: {
        value?: number;
    }
};
