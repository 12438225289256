import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { CarrierCode } from 'src/app/common/interfaces/carrier';
import { BtcPickupService } from 'src/app/core';
import { NavigationService } from 'src/app/core/navigation';
import { IndicatorService } from 'src/app/shared/indicator';

@Component({
  templateUrl: './pickup-page-create.component.html',
  styleUrls: ['./pickup-page-create.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupPageCreateComponent implements OnInit {

  carrierCode: CarrierCode | null = null;
  private drawerRef: NzDrawerRef;
  constructor(
    private indicatorService: IndicatorService,
    private pickupService: BtcPickupService,
    private nav: NavigationService,
    private route: ActivatedRoute
  ) {
    const { queryParams } = this.route.snapshot;
    this.carrierCode = queryParams.carrierCode ?? null;
  }


  ngOnInit(): void {
  }


  create(body: any): void {

    this.drawerRef = this.indicatorService.loading({});

    this.pickupService.create(body).pipe(
      take(1)
    ).subscribe({
      next: res => {
        this.drawerRef?.close();
        if (res?.error) {
          this.drawerRef = this.indicatorService.alert({
            nzContentParams: {
              message: res.error.message,
              problems: res.error.problems,
              type: 'error'
            }
          });
        }
        if (res?.result) {
          this.nav.to(`/app/pickups/detail/${res.result.id}`);
        }
      }
    });
  }
}
