<form nz-form [formGroup]="validateForm">
  <nz-form-item>
    <nz-form-label nzFor="initiation" nzRequired> 从 </nz-form-label>
    <nz-form-control>
      <nz-select
        formControlName="initiation"
        nzShowSearch
        nzPlaceHolder="请选择 始发地国家/地区"
        (ngModelChange)="on_initiation_region_change($event)"
      >
        <nz-option
          *ngFor="let item of initiationRegionList"
          [nzLabel]="item?.label"
          [nzValue]="item?.value"
          [nzDisabled]="item.disabled"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="destination" nzRequired> 至 </nz-form-label>
    <nz-form-control>
      <nz-select
        formControlName="destination"
        nzShowSearch
        nzPlaceHolder="请选择 目的地国家/地区"
        (ngModelChange)="on_destination_region_change($event)"
      >
        <nz-option
          *ngFor="let item of destinationRegionList"
          [nzLabel]="item?.label"
          [nzValue]="item?.value"
          [nzDisabled]="item.disabled"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-container *ngIf="error">
  <nz-alert nzType="warning" nzBanner [nzMessage]="error.message"></nz-alert>
</ng-container>
