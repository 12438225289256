import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'indicator-loading',
  templateUrl: './indicator-loading.component.html',
  styleUrls: ['./indicator-loading.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndicatorLoadingComponent implements OnInit {

  @Input() title = '加载中...';
  @Input() desc = null;

  constructor() { }

  ngOnInit(): void {
  }

}
