export enum CarrierCode {
  canpost = "canpost",
  fedex = "fedex",
  ups = "ups",
  dhl = "dhl",
  dhl_be = "dhl_be",
  postnl_be = "postnl_be",
  purolator = "purolator",
  jiayun = "jiayun",
  shunfeng = "shunfeng",
  zhongtong = "zhongtong",
  youzhengguonei = "youzhengguonei",
  igcaexpress = "igcaexpress",
}

export type Carrier = keyof typeof CarrierCode;

export enum CarrierServiceCodeCanpost {
  dom_ep = "dom_ep",
  usa_ep = "usa_ep",
  usa_xp = "usa_xp",
  usa_tp = "usa_tp",
  int_xp = "int_xp",
  int_tp = "int_tp",
}

export type CarrierServiceCanpost = keyof typeof CarrierServiceCodeCanpost;

export type CarrierPropItem<T> = {
  text: string;
  code: CarrierCode;
  query?: any;
  website?: string;
  trackingUrl?: string;
};

export type CarrierProp = {
  [code in Carrier]: CarrierPropItem<code>;
};
