import { SizeUnitProp, SizeUnitCode, WeightUnitProp, WeightUnitCode, WeightUnitName, SizeUnitName } from '../../interfaces/setting';

export const WEIGHT_UNITS: WeightUnitProp = {
    lb: {
        name: WeightUnitName.lb,
        code: WeightUnitCode.lb,
        isKilo: false
    },
    kg: {
        name: WeightUnitName.kg,
        code: WeightUnitCode.kg,
        isKilo: true
    }
};

export const SIZE_UNITS: SizeUnitProp = {
    cm: {
        name: SizeUnitName.cm,
        code: SizeUnitCode.cm,
        isCentimeter: true
    },
    in: {
        name: SizeUnitName.in,
        code: SizeUnitCode.in,
        isCentimeter: false
    }
};
