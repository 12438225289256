import { IBtcAgentOfficeAsset } from '../../interfaces/agent';

export class BTCAgentOfficeAsset implements IBtcAgentOfficeAsset {
    createdAt: string;
    updatedAt: string;
    id: string;
    keys?: string;
    description?: any;
    filename: string;
    size: number;
    type: string;
    data: string;
    office: string;

    constructor(private asset: IBtcAgentOfficeAsset) {
        Object.assign(this, this.asset);
    }


    get safeImg() {
        const img = `data:${this.type};base64,${this.data}`;
        return img;
    }

    get bgStyle() {
        const img = `data:${this.type};base64,${this.data}`;
        return {
            'background-image': `url("${img}")`,
            'background-size': 'cover',
            'background-position': 'center'
        };
    }
}
