import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PICKUP_STATES } from 'src/app/common/constants/pickup';

import { CarrierCode } from 'src/app/common/interfaces/carrier';
import { PickupStateCode } from 'src/app/common/interfaces/pickup';
import { StateSchema } from 'src/app/common/interfaces/state';

@Component({
  selector: 'pickup-search-form',
  templateUrl: './pickup-search-form.component.html',
  styleUrls: ['./pickup-search-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupSearchFormComponent implements OnInit {
  @Input() validateForm: UntypedFormGroup;
  @Output() formChange = new EventEmitter<never>();
  allowed = [CarrierCode.canpost, CarrierCode.dhl, CarrierCode.ups];
  states: StateSchema<PickupStateCode>[] = [];

  constructor() {
    for (const key in PICKUP_STATES) {
      if (Object.prototype.hasOwnProperty.call(PICKUP_STATES, key)) {
        const item = PICKUP_STATES[key];
        this.states.push(item);
      }
    }
  }

  ngOnInit(): void {
  }

  keywords_reset(): void {
    this.validateForm.patchValue({
      keywords: null
    });
    this.formChange.emit();
  }


  on_state_change(): void {

  }
}
