<form nz-form [formGroup]="validateForm" nzLayout="horizontal">
  <doc-form
    [validateForm]="validateForm"
    [serviceContent]="serviceContent"
    [idcard]="idcard"
    [isLoading]="isLoading"
    [fileList]="fileList"
    [upload]="upload"
    (docnumberChange)="on_submit()"
    (deleteChange)="delete($event)"
  ></doc-form>
</form>
