import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaybillListComponent } from './components/waybill-list/waybill-list.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzButtonModule } from 'ng-zorro-antd/button';


@NgModule({
  declarations: [
    WaybillListComponent
  ],
  imports: [
    CommonModule,
    NzIconModule,
    NzCardModule,
    NzGridModule,
    NzTypographyModule,
    NzTagModule,
    NzEmptyModule,
    NzButtonModule
  ],
  exports: [
    WaybillListComponent
  ]
})
export class ShippingWaybillSharedModule { }
