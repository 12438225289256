<ng-container *ngIf="page; then ShippingTpl; else RateTpl"></ng-container>
<ng-template #ShippingTpl>
  <nz-card
    nzTitle="Services 服务列表"
    nzSize="small"
    nzType="inner"
    [nzExtra]="extraTpl"
  >
    <service-selector
      [recordList]="serviceList"
      [isLoading]="isLoading"
      (serviceChange)="serviceChange.emit($event)"
    ></service-selector>
  </nz-card>

  <ng-template #extraTpl>
    <ng-container *ngIf="serviceList?.length">
      <button nz-button nzType="link" nzDanger (click)="price_compare()">
        <i nz-icon nzTheme="outline" nzType="calculator"></i>
        对比价格
      </button>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #RateTpl>
  <service-rate-compare
    [regionServiceControl]="regionServiceControl"
    [initiationRegion]="initiationRegion"
    [destinationRegion]="destinationRegion"
    [multiDestination]="1"
    [pakType]="pakType"
    [services]="serviceList"
    [indexes]="indexes"
    (serviceChange)="serviceChange.emit($event)"
  ></service-rate-compare>
</ng-template>
