import { Injectable } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, catchError, Observable, of, switchMap, tap } from 'rxjs';
import { IBtcPayment } from 'src/app/common/interfaces/payment';
import { HttpHelperService } from 'src/app/helpers/http';
import { IHttpResponse } from 'src/app/helpers/http/interfaces';
import { IndicatorHelperService } from 'src/app/helpers/indicator';
import { QrCodeComponent } from 'src/app/shared/payment/components';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BalanceRechargeCoreService {
  #saleEndPoint = environment.saleEndPoint;
  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  #payment$ = new BehaviorSubject<IBtcPayment | null>(null);
  payment$ = this.#payment$.asObservable();

  constructor(
    private http: HttpHelperService,
    private msg: NzMessageService,
    private drawerService: NzDrawerService,
    private indicator: IndicatorHelperService
  ) { }

  set loading(value: boolean) {
    this.#loading$.next(value);
  }

  set payment(value: IBtcPayment) {
    this.#payment$.next(value);
  }


  create(body: any): Observable<IHttpResponse> {
    this.loading = true;
    const path = `${this.#saleEndPoint}/payment/recharge/create`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      switchMap((res) => {
        const { error, result } = res;
        if (result) {
          const payment = result;
          this.payment = payment;
          this.msg.loading('支付处理中...')
          return this.checkout(payment.id, { version: payment.version });
        }

        if (error) {
          this.payment = null;
          return of(res);
        }

      }),
      tap(() => this.loading = false)
    );
  }

  checkout(id: string, body: any): Observable<IHttpResponse> {

    const path = `${this.#saleEndPoint}/payment/recharge/pay/${id}`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap((res) => {
        if (res) {

          const { result } = res;



          if (result) {
            const { source, session } = result;

            if (source?.redirect) {
              window.location.href = source.redirect.url;
              this.showLoader();
            }

            if (session?.url) {
              window.location.href = session.url;
              this.showLoader();
            }

          }
        }
      })
    );
  }

  abort(id: string): Observable<IHttpResponse> {
    const path = `${this.#saleEndPoint}/payment/recharge/abort/${id}`;
    return this.http.delete(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap((res) => {
        if (res.success) {
          this.payment = null;
        }
      })
    );
  }

  getPayment(id: string): Observable<IHttpResponse> {
    const path = `${this.#saleEndPoint}/payment/${id}/detail`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap((res) => {
        const { error, result } = res;
        if (result) {
          this.payment = result;
        }
      })
    );
  }


  private showLoader(): void {
    this.indicator.loading({
      nzContentParams: {
        title: '跳转中, 请稍后....'
      }
    })
  }

}
