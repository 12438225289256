<form
  nz-form
  [formGroup]="validateForm"
  (ngSubmit)="on_submit()"
  nzLayout="horizontal"
>
  <nz-form-item>
    <nz-form-label nzRequired>问题分类</nz-form-label>
    <nz-form-control [nzErrorTip]="fv.message(validateForm, 'type')">
      <nz-select formControlName="type" nzPlaceHolder="请选择...">
        <nz-option
          *ngFor="let item of list"
          [nzLabel]="item.name"
          [nzValue]="item.code"
          [nzDisabled]="item.disabled"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="fv.message(validateForm, 'content')">
      <nz-textarea-count [nzMaxCharacterCount]="255">
        <textarea
          nz-input
          formControlName="content"
          [nzAutosize]="true"
          nzSize="large"
          [rows]="5"
          placeholder="请输入"
        ></textarea>
      </nz-textarea-count>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <button nz-button nzType="primary" nzDanger nzBlock [nzLoading]="isLoading">
      <i nz-icon nzType="check-circle"></i> 提交工单
    </button>
  </nz-form-item>
</form>
