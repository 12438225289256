import { Pipe, PipeTransform } from '@angular/core';
import { WEIGHT_UNITS } from 'src/app/common/constants/unit';
import { WeightUnit } from 'src/app/common/interfaces/setting';
import { IShippingWeight } from 'src/app/common/interfaces/shipping';

@Pipe({
  name: 'weightUnit'
})
export class WeightUnitPipe implements PipeTransform {

  transform(value: IShippingWeight, unit: WeightUnit, returnString: boolean = false): number | string {
    if (!returnString) {
      return Number((value[unit]).toFixed(3));
    } else {
      return `${Number((value[unit]).toFixed(3))} ${WEIGHT_UNITS[unit]?.name}`;
    }
  }

}
