import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TrackByIndexFn } from 'src/app/common/functions';
import { KuaiDi100Tracking } from '../../interfaces';
import { Kuaidi100TrackingStepComponent } from '../kuaidi100-tracking-step/kuaidi100-tracking-step.component';

@Component({
  selector: 'kuaidi100-tracking',
  templateUrl: './kuaidi100-tracking.component.html',
  styleUrls: ['./kuaidi100-tracking.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Kuaidi100TrackingComponent implements OnInit {
  sliceCount = 4;
  trackByIndex = TrackByIndexFn;
  @Input() record: KuaiDi100Tracking | null = null;

  constructor(
    private drawService: NzDrawerService
  ) { }

  ngOnInit(): void {
  }

  show_all(): void {
    const drawerRef = this.drawService.create({
      nzTitle: 'Tracking 物流信息',
      nzContent: Kuaidi100TrackingStepComponent,
      nzContentParams: {
        steps: this.record?.steps ?? []
      },
      nzPlacement: 'top',
      nzHeight: '100vh'
    });
  }

}
