import { IBtcShippingDoc, ShippingDocType, ShippingDocTypeCode } from '../../interfaces/shipping';

export class BTCShippingDoc<T> {
    private _data: IBtcShippingDoc<T>;

    constructor(data: IBtcShippingDoc<T>) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id;
    }

    get src(): string {
        return this.file ? this.file : 'assets/img/png/business.png';
    }

    get file(): string {
        return this._data?.file ?? null;
    }

    get filename(): string {
        return this._data?.filename;
    }

    get filesize(): number {
        return this._data.filesize ?? 0;
    }

    get filetype(): string {
        return this._data?.filetype;
    }

    get doctype(): T {
        return this._data.doctype;
    }
}
