<div class="indicator-body">
  <div class="indicator-item">
    <p>
      <i nz-icon nzType="loading" nzTheme="outline"></i>
    </p>
    <p *ngIf="title">
      {{ title }}
    </p>
    <p *ngIf="desc">
      {{ desc }}
    </p>
  </div>
</div>
