import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { differenceInCalendarDays } from 'date-fns';
@Component({
  selector: 'pickup-date-selector',
  templateUrl: './pickup-date-selector.component.html',
  styleUrls: ['./pickup-date-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupDateSelectorComponent implements OnInit {

  @Input() validateForm: UntypedFormGroup;
  @Input() layout = 'horizontal';
  private today = new Date();
  constructor() { }

  ngOnInit(): void {
  }

  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
  {

    if ([0, 6].includes(current.getDay())) {
      return true;
    }
    return differenceInCalendarDays(current, this.today) <= 0;
  }


}
