import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { NavigationService } from 'src/app/core/navigation';
import { PageHeaderService } from 'src/app/core/page-header';


@Component({
  selector: 'address-header-list',
  templateUrl: './address-header-list.component.html',
  styleUrls: ['./address-header-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressHeaderListComponent implements OnInit, AfterViewInit {
  @Input() type: 0 | 1 = 0; // 0 sender 1 recipient
  @ViewChild('buttonsTpl') buttonsTpl: TemplateRef<any>;

  constructor(
    public ph: PageHeaderService,
    public nav: NavigationService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.ph.buttons = this.buttonsTpl;

  }


}
