import { ISupportContact } from '../../interfaces/support';

export class SupportContact {
    private _data: ISupportContact;

    constructor(data: ISupportContact) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id;
    }

    get title(): string {
        return this._data?.title ?? '未知';
    }

    get status(): number {
        return this._data?.status;
    }

    get position(): number {
        return this._data?.position;
    }

    get content(): string {
        return this._data?.content;
    }


}
