import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, interval, Observable, startWith, switchMap, tap, zip } from 'rxjs';
import { HttpHelperService } from 'src/app/helpers/http';
import { environment } from 'src/environments/environment';
import { IHttpResponse } from '../http/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthCoreBindMobileService {
  #authEndPoint = environment.authEndPoint;

  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();
  #timeToWait$ = new BehaviorSubject<number>(0);
  timeToWait$ = this.#timeToWait$.asObservable();
  interval: any = null;

  constructor(
    private http: HttpHelperService
  ) { }

  set loading(value: boolean) {
    this.#loading$.next(value)
  }

  set timeToWait(value: number) {
    this.#timeToWait$.next(value)
  }


  startTimer(fn: any, intervalTimer: number) {
    const interval = setInterval(fn, intervalTimer);
    return interval;
  }

  endTimer() {
    clearInterval(this.interval);
    this.interval = null;
  }


  sendCode<T>(body: { number: string }): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `${this.#authEndPoint}/mobile/bind/code/send`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false),
      tap(this.http.msg_handler()),
      tap((res) => {
        const timer = res?.result?.timeToWait ?? 0;
        this.timeToWait = timer;
        this.startCount(timer);
      })
    )
  }


  verifyCode<T>(body: { token: string; code: string }): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `${this.#authEndPoint}/mobile/bind/code/verify`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading = false),
      tap(this.http.msg_handler()),
    )
  }




  private startCount(timer: number): void {
    this.interval = this.startTimer(() => {
      let cur = this.#timeToWait$.value as number;

      this.timeToWait = cur - 1;
    }, 1000);

    setTimeout(() => {
      this.endTimer();
    }, timer * 1000);
  }


}


