import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Injector } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { AlertType, CallbackFunction, IButton } from 'src/app/common/interfaces/common';
import { CallbackFunctionVoid } from 'src/app/core/common/interfaces';

import { StateHelperService } from 'src/app/helpers/state';
import { INDICATOR_ALERT_BUTTONS } from '../../constants';


@Component({
  selector: 'indicator-alert',
  templateUrl: './indicator-alert.component.html',
  styleUrls: ['./indicator-alert.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndicatorAlertComponent implements OnInit {
  private _buttons: IButton[] = INDICATOR_ALERT_BUTTONS;
  @Input() type: AlertType = 'info';
  @Input() drawer = false;

  @Input() set buttons(value: IButton[]) {
    this._buttons = value;
  }
  get buttons(): IButton[] {
    return this._buttons;
  }

  @Input() problems: any[] | undefined = [];
  @Input() message: string | null = null;
  @Input() desc: string | null | undefined = null;
  @Output() Close = new EventEmitter<never | boolean | CallbackFunction | CallbackFunctionVoid>();

  livemode$ = this.stateService.livemode$;

  constructor(
    private injector: Injector,
    private stateService: StateHelperService
  ) { }

  ngOnInit(): void {
  }

  clicked(data: boolean | CallbackFunction | CallbackFunctionVoid | undefined): void {
    if (data) {
      if (this.drawer) {
        const drawerRef = this.injector.get(NzDrawerRef);
        drawerRef.close(data);
      } else {
        this.Close.emit(data);
      }
    }
  }

}
