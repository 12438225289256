import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { IRegion, ISOCode2 } from 'src/app/common/interfaces/region';
import { AddressService } from 'src/app/core/address';
import { FormValidationService } from 'src/app/core/form';


@Component({
  selector: 'address-add',
  templateUrl: './address-add.component.html',
  styleUrls: ['./address-add.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressAddComponent implements OnInit {

  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
  }
  @Input() event: EventEmitter<any>;
  @Input() regionCode: ISOCode2;
  @Input() type: 0 | 1;
  @Input() redirect: string;
  validateForm: UntypedFormGroup = new UntypedFormGroup({
    region: this.fb.control(null, [Validators.required]),
    regionId: this.fb.control(null, [Validators.required])
  });

  region: IRegion;

  constructor(
    private addressService: AddressService,
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private detection: ChangeDetectorRef,
    private router: Router,
  ) {
  }

  ngOnInit(): void { }

  region_change($event: IRegion): void {
    this.region = $event;
    this.validateForm.patchValue({
      regionId: this.region?.id
    });
    this.detection.detectChanges();
  }

  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      this.validateForm.enable();
      this.isLoading = true;
      const { value } = this.validateForm;

      const body = { ...value, ...{ pca: undefined, region: undefined, state: undefined } };
      if (this.type) {
        this.recipient_create(body);
      } else {
        this.sender_create(body);
      }
    }
  }

  private sender_create(body: any): void {
    this.addressService.sender_create(body).pipe(
      take(1)
    )
      .subscribe(res => {
        this.isLoading = false;
        if (res?.result) {
          this.close(res?.result);
        }
      });
  }

  private recipient_create(body: any): void {
    this.addressService.recipient_create(body).pipe(
      take(1)
    )
      .subscribe(res => {
        this.isLoading = false;
        if (res?.result) {
          this.close(res?.result);
        }
      });
  }

  close(data?: any): void {
    if (this.redirect) {
      this.router.navigate([this.redirect]);
    } else {
      this.event?.emit(data);
    }
  }

}
