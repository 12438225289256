
export * from './btc-shipping.address.interface';
export * from './btc-shipping.item.interface';
export * from './btc-shipping.interface';
export * from './btc-shipping.package.interface';
export * from './btc-shipping.waybill.interface';
export * from './btc-shipping.doc.interface';
export * from './btc-shipping.idcard.interface';
export * from './btc-shipping-package.tracking.interface';
export * from './btc-shipping.tracking.interface';


export type ShippingType = 'parcel' | 'env';

export interface IShippingType {
    label: string;
    code: ShippingType;
    icon: string;
}

export interface IShippingSize {
    sum: number;
    girth: number;
    width: number;
    height: number;
    length: number;
}
export interface IShippingWeight {
    kg: number;
    lb: number;
}

export enum ShippingEvent {
    shipping_delete = 'shipping_delete',
    shipping_submit = 'shipping_submit',
    locate_parcel_shop = 'locate_parcel_shop',
    package_add = 'package_add',
    package_delete = 'package_delete',
    order_checkout = 'order_checkout',
    order_delete = 'order_delete',
    order_detail = 'order_detail',
    order_void = 'order_void',
    rate_estimate = 'rate_estimate',
    create_pickup = 'create_pickup'
}

export enum ShippingGroupStateCode {
    pending = 'pending',
    submitted = 'submitted',
    invoiced = 'invoiced',
    voided = 'voided',
    voidedPending = 'voidedPending',
    voidedFailed = 'voidedFailed',
    refunded = 'refunded',
    refundedPending = 'refundedPending',
    refundedFailed = 'refundedFailed'
}

export type ShippingGroupState = keyof typeof ShippingGroupStateCode;
export type ShippingGroupStateItemProps<T> = {
    text: string;
    code: T;
    query?: any;
};
export type ShippingGroupStateProps = {
    [type in ShippingGroupStateCode]: ShippingGroupStateItemProps<type>;
};

