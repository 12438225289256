import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { AddressEvent, AddressEventCode } from 'src/app/common/interfaces/address';
import { ISOCode2 } from 'src/app/common/interfaces/region';
import { shippingAddress } from 'src/app/common/interfaces/shipping';
import { BTCAddress } from 'src/app/common/models/address';
import { BtcShippingAddress } from 'src/app/common/models/shipping';
import { BtcShippingService } from 'src/app/core';
import { AddressSelectorComponent } from 'src/app/shared/address/components';
import { AddressAddComponent } from 'src/app/shared/address/components/address-add/address-add.component';
import { IndicatorService } from 'src/app/shared/indicator';

@Component({
  selector: 'address-panel',
  templateUrl: './address-panel.component.html',
  styleUrls: ['./address-panel.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressPanelComponent implements OnInit {
  private drawerRef: NzDrawerRef;
  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }
  @Input() shippingNumber: string;
  @Input() packageNumber: string;
  @Input() addressId: string;
  @Input() regionCode: ISOCode2;
  @Input() multiDestination = false;
  @Input() type: shippingAddress;
  @Input() borderless = false;
  @Input() showExtra = false;

  @Output() addressChange = new EventEmitter<BtcShippingAddress>();

  address: BtcShippingAddress;
  constructor(
    private detection: ChangeDetectorRef,
    private drawerService: NzDrawerService,
    private indicator: IndicatorService,
    private shippingService: BtcShippingService
  ) { }

  ngOnInit(): void {
    if (this.shippingNumber && this.addressId) {
      this.address_get(this.addressId, this.shippingNumber, this.packageNumber);
    }
  }

  reset(): void {
    this.drawerRef = this.indicator.confirm({
      nzContentParams: {
        title: `重置${this.type ? '收件地址' : '寄件地址'}`,
        desc: '请从下面选项中选择',
        buttons: [
          {
            label: '添加新地址',
            // block: true,
            type: 'primary',
            ghost: true,
            icon: 'plus-circle',
            onClick: () => this.event_change(AddressEventCode.create, this.type)
          },
          {
            label: '地址簿中选择',
            // block: true,
            ghost: true,
            type: 'primary',
            onClick: () => this.event_change(AddressEventCode.search, this.type)
          }
        ]
      }
    });

    this.drawerRef.afterClose.subscribe({
      next: res => res && res()
    });
  }

  event_change(event: AddressEvent, type: 0 | 1): void {
    switch (event) {
      case AddressEventCode.create:
        this.address_add(type);
        break;

      case AddressEventCode.search:
        this.address_search(type);
        break;

      default:
        break;
    }
  }

  private address_add(type: shippingAddress): void {
    const addressChange = new EventEmitter<BTCAddress>();
    this.drawerRef = this.drawerService.create({
      nzTitle: type ? '新的收件地址' : '新的寄件地址',
      nzContent: AddressAddComponent,
      nzContentParams: {
        regionCode: this.regionCode,
        type: this.type,
        event: addressChange
      },
      nzPlacement: 'top',
      nzHeight: '100%',
      nzBodyStyle: {
        padding: '20px'
      },
    });

    addressChange.subscribe({
      next: res => {
        this.drawerRef?.close();
        if (res) {
          this.address_set(res.id, this.type, this.shippingNumber, this.packageNumber);
        }
      }
    });

  }

  private address_search(type: shippingAddress): void {
    const addressChange = new EventEmitter<BTCAddress>();
    this.drawerRef = this.drawerService.create({
      nzTitle: type ? '查询收件地址' : '查询寄件地址',
      nzContent: AddressSelectorComponent,
      nzContentParams: {
        regionCode: this.regionCode,
        type: this.type,
        addressChange
      },
      nzPlacement: 'top',
      nzHeight: '100%',
      nzBodyStyle: {
        padding: '20px'
      },
    });

    addressChange.subscribe({
      next: res => {
        this.drawerRef?.close();
        if (res) {
          this.address_set(res.id, this.type, this.shippingNumber, this.packageNumber);
        }
      }
    });

  }


  private address_set(originId: string, type: shippingAddress, shippingId: string, packageId?: string): void {
    this.drawerRef = this.indicator.loading({
      nzContentParams: {
        title: `${type ? '收件地址' : '寄件地址'}保存中, 请稍后...`
      }
    });

    this.shippingService.address_add({ type, originId }, shippingId, packageId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.drawerRef?.close();
        if (res?.error) {
          this.drawerRef = this.indicator.alert({
            nzContentParams: {
              message: res.error.message,
              problems: res.error.problems,
              type: 'error'
            }
          });
        }
        if (res?.result) {
          const address = res.result as BtcShippingAddress;
          this.address = res.result;
          this.addressChange.emit(address);
          this.detection.detectChanges();
        }

      }
    });
  }

  private address_get(addressId: string, shippingId: string, packageId?: string): void {
    this.isLoading = true;
    this.shippingService.address_get(addressId, shippingId, packageId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.address = res?.result;
        this.isLoading = false;
      }
    });
  }


}
