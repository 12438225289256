import { ISelectOption } from 'src/app/core/common/interfaces';

export function OptionFn(state: any): ISelectOption[] {
    const options: ISelectOption[] = [];

    for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
            const code = key as any;
            const element = state[code];
            if (typeof element === 'string') {
                options.push({
                    label: element,
                    value: code
                });
            } else {

                options.push({
                    label: element.text,
                    value: element.query
                });
            }
        }
    }

    return options;
}
