import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { interval, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BTCPayment } from 'src/app/common/models/payment/btc-payment.model';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class BTCPaymentService {
  #saleEndpoint = '';
  constructor(
    private http: HttpService
  ) {
    this.#saleEndpoint = environment.saleEndPoint;
  }

  state_polling(id: string) {
    const p = interval(3000).pipe(
      startWith(0),
      switchMap(() => {
        return this.state(id);
      })
    );
    return p;
  }

  search(body: any): Observable<IHttpResponse> {
    const path = `payment/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        res.result = result.map(item => new BTCPayment(item));
        return res;
      }),
    );
  }

  detail(id: string): Observable<IHttpResponse> {
    const path = `payment/${id}/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BTCPayment(result);
        }
        return res;
      }),
    );
  }

  state(id: string): Observable<IHttpResponse> {
    const path = `payment/${id}/state`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BTCPayment(result);
        }
        return res;
      }),
    );
  }

  abort(id: string): Observable<IHttpResponse> {
    const path = `${this.#saleEndpoint}/payment/${id}/abort`;
    return this.http.delete(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler())
    );
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {

    const id = route.params?.paymentId ?? null;
    return of(id);
  }

  payout_create(body: any): Observable<IHttpResponse> {
    const path = `payment/payout/create`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BTCPayment(result);
        }
        return res;
      }),
    );
  }

  recharge_create(body: any): Observable<IHttpResponse> {
    const path = `payment/recharge/create`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BTCPayment(result);
        }
        return res;
      }),
    );
  }

  checkout(id: string, body: any): Observable<IHttpResponse> {
    const path = `payment/${id}/checkout`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      // map((res: IHttpResponse) => {
      //   const { result } = res;
      //   if (result) {
      //     res.result = new BTCPayment(result);
      //   }
      //   return res;
      // }),
    );
  }
}
