import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, EventEmitter, Output, TemplateRef, ViewChild, Input } from '@angular/core';
import { PickupEvent } from 'src/app/common/interfaces/pickup';
import { BTCPickup } from 'src/app/common/models/pickup';
import { NavigationService } from 'src/app/core/navigation';
import { PageHeaderService } from 'src/app/core/page-header';

@Component({
  selector: 'pickup-header-edit',
  templateUrl: './pickup-header-edit.component.html',
  styleUrls: ['./pickup-header-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupHeaderEditComponent implements OnInit, AfterViewInit {
  @Input() pickup: BTCPickup;
  @Output() eventChange = new EventEmitter<PickupEvent>();
  @ViewChild('buttonsTpl') buttonsTpl: TemplateRef<any>;
  constructor(
    public ph: PageHeaderService,
    public nav: NavigationService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.view_update();
  }


  view_update(): void {
    this.ph.buttons = this.buttonsTpl;
  }


}

