import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IUnit } from 'src/app/common/interfaces/setting';
import { FormValidationService } from 'src/app/core/form';
import { ISizeUnit, IWeightUnit } from 'src/domain/unit.interface';

@Component({
  selector: 'entity-format-form',
  templateUrl: './entity-format-form.component.html',
  styleUrls: ['./entity-format-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityFormatFormComponent implements OnInit {


  @Input() set unit(value: IUnit) {
    if (value) {
      this.sizeUnit = value.size;
      this.weightUnit = value.weight;
    } else {
      this.sizeUnit = null;
      this.weightUnit = null;
    }

  }

  sizeUnit: ISizeUnit | null = null;
  weightUnit: IWeightUnit | null = null;
  pakForm: UntypedFormGroup;
  private _validateForm: UntypedFormGroup;

  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
    this.pakForm = this._validateForm && this._validateForm.controls.pak as UntypedFormGroup;
  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }


  constructor(
    public fv: FormValidationService,

  ) {

  }

  ngOnInit(): void {

  }

  on_size_change(i: number) {

  }



}
