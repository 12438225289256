import { Pipe, PipeTransform } from '@angular/core';
import { PAYMENT_METHOD_LIST } from 'src/app/common/constants/payment';
import { PaymentMethod } from 'src/app/common/interfaces/payment';
const list = PAYMENT_METHOD_LIST;
@Pipe({
  name: 'payment'
})
export class PaymentPipe implements PipeTransform {

  transform(value: unknown, allProp: boolean = false): unknown {
    const methodCode = value as PaymentMethod ?? null;
    const payment = list.find(item => item.code === methodCode);
    if (!payment) {
      return null;
    }

    return allProp ? payment : payment.name;

  }

}
