import { ITag } from "../../interfaces/common"

export const businessType = {
    1: '普通企业',
    2: '教育机构 / 政府 / 非盈利性组织',
    3: '个体经营'
}

export const businessState: Record<number, ITag> = {
    0: {
        text: '未知',
        color: 'default',
    },
    1: {
        text: '验证通过',
        color: 'success'
    },
    2: {
        text: '验证中',
        color: 'orange'
    },
    4: {
        text: '未通过验证',
        color: 'magenta'
    },
}