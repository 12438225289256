import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrierSelectorComponent } from './components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { FormHelperModule } from 'src/app/helper/form-helper';
import { CarrierNamePipe } from './pipes';



@NgModule({
  declarations: [
    CarrierSelectorComponent,
    CarrierNamePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormHelperModule,
    NzFormModule,
    NzSelectModule,
    NzLayoutModule
  ],
  exports: [
    CarrierSelectorComponent,
    CarrierNamePipe
  ]
})
export class CarrierCoreModule { }
