import { IBtcShippingPackageTracking } from '../../interfaces/shipping';
import { IBtcShippingTracking } from '../../interfaces/shipping';
import { BtcShippingPackageTracking } from './btc-shipping-package.tracking.model';
import { BtcShippingTrackingStep } from './btc-shipping.tracking.step.model';

export class BtcShippingTracking {
    private _data: IBtcShippingTracking;

    constructor(data: IBtcShippingTracking) {
        this._data = data;
    }

    get data(): IBtcShippingTracking {
        return this._data;
    }

    get packages(): BtcShippingPackageTracking[] {
        return this._data?.packages?.map(p => new BtcShippingPackageTracking(p));
    }
}
