import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationService } from 'src/app/core/navigation';
import { PageHeaderService } from 'src/app/core/page-header';

@Component({
  selector: 'group-header-list',
  templateUrl: './group-header-list.component.html',
  styleUrls: ['./group-header-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupHeaderListComponent implements OnInit, AfterViewInit {

  @ViewChild('buttonsTpl') buttonsTpl: TemplateRef<any>;

  constructor(
    public ph: PageHeaderService,
    public nav: NavigationService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.ph.buttons = this.buttonsTpl;

  }


}
