import { IHttpResponse } from '../../interfaces/http';

export const DEFAULT_SEARCH_RESULT: IHttpResponse = {
    rows: [],
    count: 0,
    result: []
};

export const DEFAULT_RESULT: IHttpResponse = {
    rows: [],
    count: 0,
    result: null
};
