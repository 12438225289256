import { IAddressTypeItem } from '../../interfaces/address';

export const ADDRESS_TYPES: IAddressTypeItem[] = [
    {
        label: '所有类型',
        value: null
    },
    {
        label: '居民住宅',
        value: 'resident'
    },
    {
        label: '商业地址',
        value: 'commercial'
    }
];
