import { BalanceEvent, IBalanceAccount, IBalanceEvent } from '../../interfaces/balance';
import { Currency } from '../../interfaces/rate/currency';

export class BalanceAccount {
    private _data: IBalanceAccount;
    constructor(data: IBalanceAccount) {
        this._data = data;
    }

    get id(): string {
        return this._data.id;
    }

    get income(): { count: number } {
        return this._data.income >= 0 && !this._data.outcome ? { count: this._data.income } : null;
    }

    get outcome(): { count: number } {
        return this._data.outcome < 0 ? { count: this._data.outcome } : null;
    }

    get event(): IBalanceEvent {
        const event: IBalanceEvent = {
            code: 'unknown',
            name: BalanceEvent.unknown,
        };
        const code = this._data.event;

        if (code in BalanceEvent) {
            event.code = code;
            event.name = BalanceEvent[code];
        }

        return event;

    }

    get createdAt(): string {
        return this._data?.createdAt;
    }

    get updatedAt(): string {
        return this._data?.updatedAt;
    }

    get currency(): Currency {
        return this._data?.currency;
    }



}
