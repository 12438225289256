import { ISelectOption } from "../../common/interfaces";
import { CarrierCode, CarrierProp } from "../interfaces";
import { option } from "src/app/core/common/functions";

export const CARRIERS: CarrierProp = {
  canpost: {
    text: "Canada Post",
    code: CarrierCode.canpost,
    query: CarrierCode.canpost,
  },
  ups: {
    text: "UPS Canada",
    code: CarrierCode.ups,
    query: CarrierCode.ups,
  },
  dhl: {
    text: "DHL Canada",
    code: CarrierCode.dhl,
    query: CarrierCode.dhl,
  },
  dhl_be: {
    text: "DHL International",
    code: CarrierCode.dhl_be,
    query: CarrierCode.dhl_be,
  },
  postnl_be: {
    text: "Post NL",
    code: CarrierCode.postnl_be,
    query: CarrierCode.postnl_be,
  },
  fedex: {
    text: "Fedex Canada",
    code: CarrierCode.fedex,
    query: CarrierCode.fedex,
  },
  purolator: {
    text: "Purolator",
    code: CarrierCode.purolator,
    query: CarrierCode.purolator,
  },
  shunfeng: {
    text: "顺丰",
    code: CarrierCode.shunfeng,
    query: CarrierCode.shunfeng,
  },
  zhongtong: {
    text: "中通",
    code: CarrierCode.zhongtong,
    query: CarrierCode.zhongtong,
  },
  youzhengguonei: {
    text: "邮政快递包裹",
    code: CarrierCode.youzhengguonei,
    query: CarrierCode.youzhengguonei,
  },
  jiayun: {
    text: "优邮转运",
    code: CarrierCode.jiayun,
    query: CarrierCode.jiayun,
  },
  igcaexpress: {
    text: "优邮转运",
    code: CarrierCode.igcaexpress,
    query: CarrierCode.igcaexpress,
  },
};

export const CARRIER_OPTIONS: ISelectOption[] = option(CARRIERS);
