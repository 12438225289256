import { ISelectOption } from "../interfaces";

export function option(state: any): ISelectOption[] {
    const options: ISelectOption[] = [];
    for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
            const code = key as any;
            const element = state[code];
            options.push({
                label: element.text,
                value: element.query
            })
        }
    }

    return options;
}