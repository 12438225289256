import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateRoute'
})
export class StateRoutePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const livemode = (args[0] && typeof args[0] === 'boolean') ?? true;

    const url = livemode ? value : `/test${value}`;

    return url;
  }

}
