<form
  nz-form
  [formGroup]="validateForm"
  (ngSubmit)="formChange.emit()"
  nzLayout="inline"
>
  <nz-form-item *ngIf="validateForm | hasControl: 'keywords'">
    <nz-form-label nzFor="types">关键字查询</nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'keywords'">
      <nz-input-group nzSearch [nzAddOnAfter]="nzAddOnAfterTpl">
        <input
          style="width: 100%"
          nz-input
          type="text"
          formControlName="keywords"
          placeholder="取货地址, 邮编, 城市 等 "
        />
      </nz-input-group>
      <ng-template #nzAddOnAfterTpl>
        <nz-button-group>
          <button nz-button nzType="primary">查询</button>
          <button
            (click)="keywords_reset()"
            nz-button
            nzType="primary"
            *ngIf="validateForm.value.keywords"
            nzGhost
            nzDanger
          >
            <i nz-icon nzType="close"></i>
          </button>
        </nz-button-group>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="validateForm | hasControl: 'states'">
    <nz-form-label nzFor="states">预约状态</nz-form-label>
    <nz-form-control [nzErrorTip]="validateForm | formError: 'states'">
      <nz-select
        formControlName="states"
        nzPlaceHolder="所有状态"
        nzAllowClear
        (ngModelChange)="formChange.emit()"
        style="min-width: 150px"
      >
        <nz-option
          *ngFor="let item of states"
          [nzLabel]="item.text"
          [nzValue]="item.query"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <carrier-selector
    [validateForm]="validateForm"
    [name]="'waybillCarrierCode'"
    [allowed]="allowed"
    (valueChange)="formChange.emit()"
  ></carrier-selector>

</form>
