<ng-container *ngIf="validateForm">
  <form nz-form [formGroup]="validateForm" [nzLayout]="layout">
    <nz-form-item *ngIf="validateForm | hasControl: name">
      <nz-form-label
        nzFor="carrier"
        [nzRequired]="validateForm | isRequired: name"
      >
        承运商
      </nz-form-label>
      <nz-form-control [nzErrorTip]="validateForm | formError: name">
        <nz-select
          [formControlName]="name"
          [nzPlaceHolder]="placeholder"
          (ngModelChange)="valueChange.emit($event)"
          style="min-width: 150px"
          [nzAllowClear]="allowClear"
        >
          <nz-option
            *ngFor="let item of carriers"
            [nzLabel]="item.name"
            [nzValue]="item.code"
            [nzDisabled]="item.disabled"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>
