<ng-container *ngIf="validateForm">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item *ngIf="validateForm | hasControl: 'pca'">
      <nz-form-label
        nzFor="pca"
        [nzRequired]="validateForm | isRequired: 'pca'"
      >
        搜索地址
      </nz-form-label>
      <nz-form-control>
        <nz-select
          [nzOpen]="pcaPanel"
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="关键字: 区, 街 城市，省, 邮编"
          formControlName="pca"
          (nzOnSearch)="on_address_search($event)"
          (ngModelChange)="on_address_change($event)"
        >
          <ng-container *ngFor="let o of addressList">
            <nz-option [nzValue]="o.value" [nzLabel]="o.label"></nz-option>
          </ng-container>
          <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
            <i nz-icon nzType="loading" class="loading-icon"></i> 请输入...
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>
