import { Pipe, PipeTransform } from '@angular/core';
import { CARRIERS } from '../constants';

import { CarrierCode, } from '../interfaces';

@Pipe({
  name: 'carrierName'
})
export class CarrierNamePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    if (value === 'dhlen') {
      value = 'dhl_be';
    }
    const code = value as CarrierCode;

    const carrier = CARRIERS[code];

    return carrier.text;
  }

}
