import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Injector, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { INDICATOR_ALERT_BUTTONS } from 'src/app/common/constants/indicator';
import { IButton, CallbackFunction, ResultStatus } from 'src/app/common/interfaces/common';

@Component({
  selector: 'indicator-result',
  templateUrl: './indicator-result.component.html',
  styleUrls: ['./indicator-result.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndicatorResultComponent implements OnInit {
  private _buttons: IButton[] = INDICATOR_ALERT_BUTTONS;

  @Input() drawer = false;

  @Input()
  set buttons(value: IButton[]) {
    this._buttons = value;
  }
  get buttons(): IButton[] {
    return this._buttons;
  }

  @Input() title: TemplateRef<void> | string = '结果';
  @Input() subTitle: TemplateRef<void> | string = null;
  @Input() status: ResultStatus = 'info';
  @Input() icon: TemplateRef<void> | string = null;
  @Input() extra: TemplateRef<any> | string;

  @Output() Close = new EventEmitter<never | boolean | CallbackFunction>();

  @ViewChild('extra') extraTpl: TemplateRef<any>;

  constructor(
    private injector: Injector,
  ) { }

  ngOnInit(): void {

  }


  clicked(data: boolean | CallbackFunction): void {
    if (this.drawer) {
      const drawerRef = this.injector.get(NzDrawerRef);
      drawerRef.close(data);
    } else {
      this.Close.emit(data);
    }
  }

}
