<form nz-form [formGroup]="validateForm" nzLayout="horizontal">
  <!-- <nz-form-item>
    <nz-form-control [nzErrorTip]="fv.message(validateForm, 'customReference')">
      <nz-input-group
        nzAddOnBefore="Reference"
        [nzAddOnAfter]="AddOnAfterRef"
        style="width: 100%"
      >
        <input
          type="text"
          nz-input
          formControlName="customReference"
          placeholder="可选填"
        />
        <ng-template #AddOnAfterRef>
          <span
            nz-tooltip
            nzTooltipColor="red"
            nzTooltipTitle="内容将会显示在包裹面单上"
          >
            <i nz-icon nzType="question-circle"></i>
          </span>
        </ng-template>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item> -->

  <nz-form-item>
    <nz-form-control
      [nzExtra]="extraTpl"
      [nzErrorTip]="validateForm | formError: 'customMemo'"
    >
      <ng-template #extraTpl>
        <i nz-icon nzType="info-circle"></i>
        <span nz-typography nzType="warning">
          备注信息不会出现在面单中, 仅供用户个人使用
        </span>
      </ng-template>

      <nz-input-group nzAddOnBefore="备注" style="width: 100%">
        <input
          type="text"
          nz-input
          formControlName="customMemo"
          placeholder="可以不填"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</form>
