<div nz-row nzJustify="center">
  <div nz-col nzSpan="24" style="text-align: center">
    <h5 nz-typography>请选择退款方式</h5>
  </div>
  <div nz-col nzSpan="24" style="text-align: center">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="on_submit()">
      <nz-form-item>
        <nz-form-control
          nzSpan="24"
          [nzErrorTip]="fv.message(validateForm, 'rbc')"
        >
          <nz-radio-group
            formControlName="rbc"
            (ngModelChange)="on_method_change($event)"
          >
            <label *ngFor="let item of list" nz-radio [nzValue]="item.value">
              {{ item.name }}
              <span
                nz-typography
                nzType="secondary"
                *ngIf="method?.value === item.value"
              >
                {{ method?.note }}
              </span>
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nzJustify="center">
        <button nz-button nzType="link" (click)="close()">
          <i nz-icon nzType="close-circle"></i> 取消
        </button>
        <nz-divider nzType="vertical"></nz-divider>
        <button nz-button nzType="primary" (click)="on_submit()" nzDanger>
          <i nz-icon nzType="check-circle"></i> 提交
        </button>
      </nz-form-item>
    </form>
  </div>
</div>
