import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { PATTERNS } from 'src/app/common/constants/pattern';
import { Pattern } from 'src/app/common/interfaces/pattern';

@Pipe({
  name: 'formError',
  pure: false
})
export class FormErrorPipe implements PipeTransform {

  transform(group: UntypedFormGroup, ...args: (string | ValidationErrors)[]): string {

    const controlName = args[0] as string;
    const messages = args[1] as ValidationErrors;
    const control = group.get(controlName);
    if (!control) {
      return '未知错误';
    }

    const errors = control.errors;

    if (!errors) {
      return null;
    }

    for (const i in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, i)) {
        const error = errors[i];
        switch (i) {
          case 'max':
            return messages?.max ? messages.max : '最大值' + error.max;
          case 'min':
            return messages?.min ? messages.min : '最小值' + error.min;
          case 'required':
            return messages?.required ? messages.required : '不能为空';
          case 'pattern':
            let pattern: Pattern = null;

            for (const p in PATTERNS) {
              if (Object.prototype.hasOwnProperty.call(PATTERNS, p)) {
                const item: Pattern = PATTERNS[p];
                if (item.pattern === error.requiredPattern.replace('^', '') || item.pattern === error.requiredPattern) {
                  pattern = item;
                }
              }
            }

            return messages?.pattern ? messages.pattern : pattern ? pattern.label : ` 文字/格式错误: ${error.requiredPattern}`;
          case 'maxlength':
            return messages?.maxLength
              ? messages.maxLength
              : `长度不能多于${error.requiredLength}, 当前长度${error.actualLength}`;
          case 'minlength':
            return messages?.minLength
              ? messages.minLength
              : `长度不能少于${error.requiredLength}, 当前长度${error.actualLength}`;
          case 'passconf':
            return messages?.passconf ? messages.passconf : '密码确认与登录密码不符';
          case 'occupied':
            return messages?.occupied ? messages.occupied : ' 已存在';
          default:
            return null;
        }

      }
    }


    return null;
  }

}
