import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { IHttpResponse } from 'src/app/common/interfaces/http';
import { BtcOrder } from 'src/app/common/models/order';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from 'src/app/helpers/http';

@Injectable({
  providedIn: 'root'
})
export class BtcOrderService {
  #endPoint: string;
  #supplementCount$ = new BehaviorSubject(0);
  supplementCount$ = this.#supplementCount$.asObservable();

  constructor(
    private http: HttpHelperService
  ) {
    this.#endPoint = environment.coreEndPoint;
  }

  getSupplementCount(): Observable<number> {
    const body = {
      skip: 0,
      limit: 0,
      type: 1,
      voided: 0,
      paid: 0
    };


    return this.search(body).pipe(
      map(res => {
        const count = res?.count ?? 0;
        this.#supplementCount$.next(count)
        return count;
      })
    )
  }

  syncSupplementCount(): void {
    this.getSupplementCount().subscribe();
  }


  search(body: any): Observable<IHttpResponse> {
    const path = `b2c/order/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = result.map(item => new BtcOrder(item));
        }
        return res;
      }),
    );
  }

  detail(id: string): Observable<IHttpResponse> {
    const path = `b2c/order/${id}/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (res.result) {
          res.result = new BtcOrder(result);
        }
        return res;
      }),
    );
  }

  submit(id: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/order/${id}/submit`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map(res => {
        if (res.result) {
          res.result = new BtcOrder(res.result);
        }
        return res;
      })
    );
  }

  void(id: string, body: any): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/b2c/order/${id}/void`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map(res => {
        if (res?.result?.order) {
          res.result = new BtcOrder(res.result.order);
        }
        return res;
      })
    );
  }

  delete(id: string): Observable<IHttpResponse> {
    const path = `b2c/order/${id}/delete`;
    return this.http.delete(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
    );
  }

  supplement_search(body: any, id?: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/order/${id ? id + '/' : ''}supplement/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        res.result = result.map(item => new BtcOrder(item));
        return res;
      }),
    );
  }

  supplement_detail(id: string): Observable<IHttpResponse> {
    const path = `${this.#endPoint}/order/supplement/:id/detail`;
    return this.http.post(path, {}).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        const { result } = res;
        res.result = result.map(item => new BtcOrder(item));
        return res;
      }),
    );
  }
}
