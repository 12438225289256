import { IBtcShippingAddress, shippingAddress } from '../../interfaces/shipping';

export class BtcShippingAddress {
    private _data: IBtcShippingAddress | null;

    constructor(data: IBtcShippingAddress | null) {
        this._data = data;
    }

    get id(): string {
        return this._data?.id;
    }

    get data(): IBtcShippingAddress | null {
        return this._data;
    }

    get address(): string {
        let str = '';
        str += this.company ? `${this.company}, ` : '';
        str += this.contactPerson;
        str += this.addressLine1;
        str += this.addressLine2 ? this.addressLine2 : '';
        str += this.addressLine3 ? this.addressLine3 : '';

        return str;
    }

    get addressWithoutPerson(): string {
        let str = '';
        str += this.company ? `${this.company}, ` : '';
        str += this.addressLine1;
        str += this.addressLine2 ? this.addressLine2 : '';
        str += this.addressLine3 ? this.addressLine3 : '';
        str += `${this.city}, `;
        str += `${this.postalCode ? this.postalCode + ', ' : ''}`;
        if (this.stateCode && this.regionCode !== 'CN') {
            str += `${this.stateCode}, `;
        }
        str += `${this.regionCode} `;
        return str;
    }


    get label(): string {
        let str = '';
        str += `${this.company ? this.company + ', ' : ''}`;
        str += `${this.contactPerson}, `;
        str += `${this.addressLine1}, `;
        str += `${this.addressLine2 ? this.addressLine2 + ', ' : ''}`;
        str += `${this.addressLine3 ? this.addressLine3 + ', ' : ''}`;
        str += `${this.city}, `;
        str += `${this.postalCode ? this.postalCode + ', ' : ''}`;
        if (this.stateCode && this.regionCode !== 'CN') {
            str += `${this.stateCode}, `;
        }
        str += `${this.regionCode} `;
        return str;
    }


    get addressLocaleWithoutPerson(): string {
        let str = '';
        str += `${this.regionNameLocale ? this.regionNameLocale + ', ' : ''}`;
        str += `${this.stateLocale ? this.stateLocale + ', ' : ''}`;
        str += `${this.cityLocale ? this.cityLocale + ', ' : ''}`;
        str += `${this.addressLocale ? this.addressLocale + ', ' : ''}`;
        str += `${this.companyLocale ? this.companyLocale + ', ' : ''}`;
        return str;
    }


    get labelLocale(): string {
        let str = '';

        str += `${this.regionNameLocale ? this.regionNameLocale + ', ' : ''}`;
        str += `${this.stateLocale ? this.stateLocale + ', ' : ''}`;
        str += `${this.cityLocale ? this.cityLocale + ', ' : ''}`;
        str += `${this.addressLocale ? this.addressLocale + ', ' : ''}`;
        str += `${this.companyLocale ? this.companyLocale + ', ' : ''}`;
        str += `${this.contactPersonLocale ? this.contactPersonLocale + ' ' : ''}`;

        return str;
    }

    get company(): string {
        return this._data?.company;
    }

    get companyLocale(): string {
        return this._data?.companyLocale;
    }

    get addressLine1(): string {
        return this._data?.addressLine1;
    }

    get addressLine2(): string {
        return this._data?.addressLine2;
    }

    get addressLine3(): string {
        return this._data?.addressLine3;
    }

    get addressLocale(): string {
        return this._data?.addressLocale;
    }

    get contactPerson(): string {
        return this._data?.contactPerson;
    }

    get contactPersonLocale(): string {
        return this._data?.contactPersonLocale;
    }

    get contactPhone(): string {
        return this._data?.contactPhone;
    }

    get postalCode(): string {
        return this._data?.postalCode;
    }

    get city(): string {
        return this._data?.city;
    }

    get cityLocale(): string {
        return this._data?.cityLocale;
    }

    get state(): string {
        return this._data?.state;
    }

    get stateLocale(): string {
        return this._data?.stateLocale;
    }

    get stateCode(): string {
        return this._data?.stateCode;
    }

    get regionName(): string {
        return this._data?.regionName;
    }

    get regionNameLocale(): string {
        return this._data?.regionNameLocale;
    }

    get regionCode(): string {
        return this._data?.regionCode;
    }

    get sender(): boolean {
        return this._data?.type === shippingAddress.sender ? true : false;
    }

    get recipient(): boolean {
        return this._data?.type === shippingAddress.recipient ? true : false;
    }
}
