import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHelperService } from 'src/app/helpers/http';
import { IHttpResponse } from 'src/app/helpers/http/interfaces';
import { environment } from 'src/environments/environment';
import { IApiStatus } from './interfaces';
import { ApiStatusModel } from './models';

@Injectable({
  providedIn: 'root'
})
export class ApiStatusCoreService {
  #coreEndpoint: string;
  #authEndpoint: string;
  #saleEndpoint: string;
  #trackingEndpoint: string;
  private _coreApiStatus$ = new BehaviorSubject<ApiStatusModel>(new ApiStatusModel(null));
  coreApiStatus$ = this._coreApiStatus$.asObservable();

  constructor(
    private http: HttpHelperService
  ) {
    this.#authEndpoint = environment.authEndPoint;
    this.#coreEndpoint = environment.coreEndPoint;
    this.#saleEndpoint = environment.saleEndPoint;
    this.#trackingEndpoint = environment.trackingEndPoint;
  }

  set coreApiStatus(value: ApiStatusModel) {
    this._coreApiStatus$.next(value);
  }

  core_api_status_get(): Observable<ApiStatusModel> {
    const path = `${this.#coreEndpoint}/status`;
    return this.http.get(path)
      .pipe(
        catchError(this.http.catch()),
        map((res: IHttpResponse) => {
          const record = res.result as IApiStatus ?? null;
          const status = new ApiStatusModel(record);
          this.coreApiStatus = status;
          return status;
        })
        // tap((res) => {
        //   if (!res) {
        //     this.router.navigate(['/redirect/maintenance']);
        //   }
        // })
      );
  }
}
