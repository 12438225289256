import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NO_DELIVERY_HANDLE } from 'src/app/common/constants/service/service-option';
import { CarrierCode, CarrierServiceCodeCanpost } from 'src/app/common/interfaces/carrier';
import { NdhItem } from 'src/app/common/interfaces/service/additional-ndh.interface';
import { BtcShippingAdditional } from 'src/app/common/models/shipping';
import { FormValidationService } from 'src/app/core/form';

@Component({
  selector: 'service-additional-form-ndh',
  templateUrl: './service-additional-form-ndh.component.html',
  styleUrls: ['./service-additional-form-ndh.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAdditionalFormNdhComponent implements OnInit {

  @Input() current: BtcShippingAdditional;

  @Input() option: NdhItem<any>;

  private _validateForm: UntypedFormGroup;

  @Input() set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
    this.type_set(this.option);
  }
  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  codes = [];

  constructor(
    public fv: FormValidationService,
    private fb: UntypedFormBuilder,
  ) {

  }


  ngOnInit(): void {
  }

  on_state_change(state: boolean) {

  }

  type_set(value: NdhItem<any>): void {
    const { code, carrier, blocked, carrierService } = value;
    this._validateForm.addControl('code', this.fb.control(code?.value, [Validators.required]));
    const items = NO_DELIVERY_HANDLE[carrier] && NO_DELIVERY_HANDLE[carrier][carrierService];
    if (items) {
      for (const i in items) {
        if (Object.prototype.hasOwnProperty.call(items, i)) {
          const item = items[i];
          if (code?.options?.length && !code.options.some((key) => key === i)) {
            continue;
          }
          this.codes.push(item);
        }
      }
    }

    if (blocked) {
      this._validateForm.disable();
    }

    if (this.current?.ndh) {
      this.validateForm.patchValue(this.current.ndh);
    }
  }
}
