import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { carriers } from 'src/app/common/constants/carrier';
import { Carrier, CarrierPropItem } from 'src/app/common/interfaces/carrier';

@Component({
  selector: 'carrier-selector',
  templateUrl: './carrier-selector.component.html',
  styleUrls: ['./carrier-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarrierSelectorComponent implements OnInit {
  carriers: CarrierPropItem<Carrier>[] = [];
  @Input() allowed: Carrier[] = [];
  @Input() allowClear = true;
  @Input() name = 'carrier';
  @Input() placeholder = '所有物流';
  @Input() layout = 'inline';
  @Input() validateForm: UntypedFormGroup;
  @Output() valueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    for (const i in carriers) {
      if (Object.prototype.hasOwnProperty.call(carriers, i)) {
        const carrier = carriers[i];
        if (this.allowed.length && !this.allowed.includes(carrier.code)) {
          continue;
        }
        this.carriers.push(carrier);
      }
    }
  }

}
