import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ITag } from 'src/app/common/interfaces/common';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {
  private _title$ = new BehaviorSubject<string | TemplateRef<any>>(null);
  private _subtitle$ = new BehaviorSubject<string | TemplateRef<any>>(null);
  private _backIcon$ = new BehaviorSubject<string | TemplateRef<any>>(null);
  private _back$ = new BehaviorSubject<EventEmitter<void>>(null);
  private _content$ = new BehaviorSubject<TemplateRef<any>>(null);
  private _avatar$ = new BehaviorSubject<string>(null);
  private _buttons$ = new BehaviorSubject<TemplateRef<any>>(null);
  private _tags$ = new BehaviorSubject<ITag[]>([]);
  title$ = this._title$.asObservable();
  subtitle$ = this._subtitle$.asObservable();
  backIcon$ = this._backIcon$.asObservable();
  back$ = this._back$.asObservable();
  content$ = this._content$.asObservable();
  avatar$ = this._avatar$.asObservable();
  buttons$ = this._buttons$.asObservable();
  tags$ = this._tags$.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe((event) => {
        // Construct the breadcrumb hierarchy
        this.reset();
      });
  }

  set title(value: string | TemplateRef<any>) {
    this._title$.next(value);
  }

  set subtitle(value: string | TemplateRef<any>) {
    this._subtitle$.next(value);
  }

  set backIcon(value: string | TemplateRef<any>) {
    this._backIcon$.next(value);
  }

  set back(value: EventEmitter<void>) {
    this._back$.next(value);
  }

  set content(value: TemplateRef<any>) {
    this._content$.next(value);
  }

  set avatar(value: string) {
    this._avatar$.next(value);
  }

  set buttons(value: TemplateRef<any>) {
    this._buttons$.next(value);
  }

  set tags(value: ITag[]) {
    this._tags$.next(value);
  }

  reset(): void {

    this.title = null;
    this.subtitle = null;
    this.avatar = null;
    this.content = null;
    this.buttons = null;
    this.tags = [];
    this.back = null;
    this.backIcon = null;
  }

}
