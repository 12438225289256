import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Injector } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { INDICATOR_CONFIRM_BUTTONS } from 'src/app/common/constants/indicator';
import { CallbackFunction, IButton } from 'src/app/common/interfaces/common';

@Component({
  selector: 'indicator-confirm',
  templateUrl: './indicator-confirm.component.html',
  styleUrls: ['./indicator-confirm.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndicatorConfirmComponent implements OnInit {
  private _buttons: IButton[] = INDICATOR_CONFIRM_BUTTONS;
  @Input() title = '加载中...';
  @Input() desc = null;

  @Input() set buttons(value: IButton[]) {
    this._buttons = value;
  }
  get buttons(): IButton[] {
    return this._buttons;
  }

  @Input() drawer = false;

  @Output() Close = new EventEmitter<never | boolean | CallbackFunction>();

  constructor(
    private injector: Injector
  ) { }

  clicked(data: boolean | CallbackFunction): void {
    if (this.drawer) {
      const drawerRef = this.injector.get(NzDrawerRef);
      drawerRef.close(data);
    } else {
      this.Close.emit(data);
    }
  }

  ngOnInit(): void {
  }

}
