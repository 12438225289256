import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Kuaidi100TrackingComponent } from './components/kuaidi100-tracking/kuaidi100-tracking.component';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { StepColorPipe } from './pipes/step-color.pipe';
import { CarrierCoreModule } from '../../carrier';
import { Kuaidi100TrackingStepComponent } from './components/kuaidi100-tracking-step/kuaidi100-tracking-step.component';
import { ShowAllModule } from '../../layout/show-all';
import { HtmlHelperModule } from 'src/app/shared/html-helper';


@NgModule({
  declarations: [
    Kuaidi100TrackingComponent,
    StepColorPipe,
    Kuaidi100TrackingStepComponent
  ],
  imports: [
    CommonModule,
    CarrierCoreModule,
    ShowAllModule,
    HtmlHelperModule,
    NzCardModule,
    NzTypographyModule,
    NzButtonModule,
    NzDividerModule,
    NzTimelineModule,

  ],
  exports: [
    Kuaidi100TrackingComponent,
    StepColorPipe
  ]
})

export class Kuaidi100TrackingCoreModule { }
