import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingPackageComponent } from './components';
import { ShippingPackageFormComponent } from './components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShippingFormatSharedModule } from '../../shipping/components/format';
import { ShippingServiceSharedModule } from '../../shipping/components/service';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ShippingReferenceSharedModule } from '../../shipping/components/reference';
import { FormSharedModule } from '../../utils/form';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { AddressSharedModule } from '../../address';



@NgModule({
  declarations: [
    ShippingPackageComponent,
    ShippingPackageFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSharedModule,
    ShippingFormatSharedModule,
    ShippingReferenceSharedModule,
    ShippingServiceSharedModule,
    AddressSharedModule,
    NzTabsModule,
    NzDividerModule,
    NzTypographyModule,
    NzGridModule,
    NzFormModule
  ],
  exports: [
    ShippingPackageComponent,
    ShippingPackageFormComponent,
  ]
})
export class ServiceShippingPackageModule { }
