import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakPipe } from './pipes/break/break.pipe';
import { SpacePipe } from './pipes';
import { SafePipe } from './pipes/safe/safe.pipe';



@NgModule({
  declarations: [
    BreakPipe,
    SpacePipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BreakPipe,
    SpacePipe,
    SafePipe
  ]
})
export class HtmlHelperModule { }
