import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LivemodeModule } from '../livemode';
import { PageHeaderComponent } from './components';

import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { BreadcrumbModule } from '../breadcrumb';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [
    PageHeaderComponent,
    PageLoaderComponent
  ],
  exports: [
    PageHeaderComponent,
    PageLoaderComponent
  ],
  imports: [
    CommonModule,
    LivemodeModule,
    BreadcrumbModule,
    NzPageHeaderModule,
    NzTagModule,
    NzAvatarModule,
    NzIconModule,
    NzAlertModule
  ]
})
export class HeaderModule { }
