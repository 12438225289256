import { IBtcAddress } from '../../interfaces/address';

export class BTCAddress {
    private _data: IBtcAddress;
    constructor(data: IBtcAddress) {
        this._data = data;
    }


    get id(): string {
        return this._data?.id;
    }

    get regionName(): string {
        return this._data.regionName;
    }

    get regionNameLocale(): string {
        return this._data.regionNameLocale;
    }

    get regionCode(): string {
        return this._data.regionCode;
    }

    get address(): string {
        let str = '';
        str += this.company ? `${this.company}, ` : '';
        str += this.contactPerson;
        str += this.addressLine1;
        str += this.addressLine2 ? this.addressLine2 : '';

        return str;
    }

    get label(): string {
        let str = '';
        str += `${this.company ? this.company + ', ' : ''}`;
        str += `${this.contactPerson}, `;
        str += `${this.addressLine1}, `;
        str += `${this.addressLine2 ? this.addressLine2 + ', ' : ''}`;
        str += `${this.city}, `;
        str += `${this.postalCode ? this.postalCode + ', ' : ''}`;
        if (this.stateCode && this.regionCode !== 'CN') {
            str += `${this.stateCode}, `;
        }
        str += `${this.regionCode} `;
        return str;
    }

    get labelLocale(): string {
        let str = '';

        str += `${this.regionNameLocale ? this.regionNameLocale + ', ' : ''}`;
        str += `${this.stateNameLocale ? this.stateNameLocale + ', ' : ''}`;
        str += `${this.cityLocale ? this.cityLocale + ', ' : ''}`;
        str += `${this.addressLocale ? this.addressLocale + ', ' : ''}`;
        str += `${this.companyLocale ? this.companyLocale + ', ' : ''}`;
        str += `${this.contactPersonLocale ? this.contactPersonLocale + ' ' : ''}`;

        return str;
    }

    get company(): string {
        return this._data.company;
    }

    get companyLocale(): string {
        return this._data.companyLocale;
    }

    get addressLine1(): string {
        return this._data.addressLine1;
    }

    get addressLine2(): string {
        return this._data.addressLine2;
    }

    get addressLocale(): string {
        return this._data.addressLocale;
    }

    get contactPerson(): string {
        return this._data.contactPerson;
    }

    get contactPersonLocale(): string {
        return this._data.contactPersonLocale;
    }

    get contactPhone(): string {
        return this._data.contactPhone;
    }

    get postalCode(): string {
        return this._data.postalCode;
    }

    get city(): string {
        return this._data.city;
    }

    get cityLocale(): string {
        return this._data.cityLocale;
    }

    get stateName(): string {
        return this._data.stateName;
    }

    get stateNameLocale(): string {
        return this._data.stateNameLocale;
    }

    get stateCode(): string {
        return this._data.stateCode;
    }

    get idCardBtn(): boolean {
        return this._data?.regionCode === 'CN' ? true : false;
    }

    get idCardId(): string {
        if (!this._data?.idCard) {
            return null;
        }
        if (typeof this._data.idCard === 'string') {
            return this._data.idCard;
        }
    }


}
