import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBreadcrumbComponent } from './components';

import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    NavBreadcrumbComponent
  ],
  exports: [
    NavBreadcrumbComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzBreadCrumbModule
  ]
})
export class BreadcrumbModule { }
