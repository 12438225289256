
import { BtcServiceSize, BtcServiceWeight, DocItem, IBtcServiceContent, Item, RestrictionSchema, RfeItem } from '../../interfaces/service';
import { InsuranceItem } from '../../interfaces/service/additional-insurance';

export class BtcServiceContent {
    private _data: IBtcServiceContent;


    constructor(data: IBtcServiceContent) {
        this._data = data;
    }

    get data(): IBtcServiceContent {
        return this._data;
    }

    set service(value: IBtcServiceContent) {
        this._data = value;
    }

    get id(): string {
        return this._data.id;
    }


    get name(): string {
        return this._data?.name;
    }

    get notices(): string[] {
        return this._data?.notices;
    }

    get restriction(): RestrictionSchema {
        return this._data.restriction;
    }

    get rfe(): RfeItem<any> {
        return this._data.rfe;
    }

    get insurance(): InsuranceItem {
        return this._data.insurance;
    }

    get doc(): DocItem {
        return this._data.doc;
    }

    get item(): Item {
        return this._data.item;
    }

    get serviceId(): string {
        if (typeof this._data.service === 'string') {
            return this._data.service;
        }

        return null;
    }

    get code(): string {
        return this._data?.code;
    }

    get weight(): BtcServiceWeight {
        return this._data?.weight;
    }

    get size(): BtcServiceSize {
        return this._data?.size;
    }
}
