import { BonusEvent, IBonusConsumptionPoint, IBonusEvent } from '../../interfaces/bonus';


export class BonusConsumptionPoint {
    private _data: IBonusConsumptionPoint;
    constructor(data: IBonusConsumptionPoint) {
        this._data = data;
    }
    get id(): string {
        return this._data.id;
    }

    get income(): { count: number } {
        return this._data.income >= 0 && !this._data.outcome ? { count: this._data.income } : null;
    }

    get outcome(): { count: number } {
        return this._data.outcome < 0 ? { count: this._data.outcome } : null;
    }

    get event(): IBonusEvent {
        const event: IBonusEvent = {
            code: 'unknown',
            name: BonusEvent.unknown,
        };
        const code = this._data.event;

        if (code in BonusEvent) {
            event.code = code;
            event.name = BonusEvent[code];
        }

        return event;

    }

    get createdAt(): Date {
        return this._data?.createdAt;
    }

    get updatedAt(): Date {
        return this._data?.updatedAt;
    }



}

