<ng-template #buttonsTpl>
  <button nz-button nzType="text" (click)="nav.to('/app/pickups')">
    <i nz-icon nzType="arrow-left"></i> 返回
  </button>
  <ng-container *ngIf="pickup">
    <ng-container *ngIf="!pickup?.submitted && !pickup?.submittedPending">
      <button nz-button nzType="primary" nzGhost (click)="delete()">
        <i nz-icon nzType="delete"></i> 删除
      </button>
      <button nz-button nzType="primary" nzGhost (click)="edit()">
        <i nz-icon nzType="edit"></i> 修改
      </button>
      <button nz-button nzType="primary" nzGhost nzDanger (click)="submit()">
        <i nz-icon nzType="check-circle"></i> 提交
      </button>
    </ng-container>
    <ng-container
      *ngIf="pickup?.submitted && !pickup?.voided && !pickup?.voidedPending"
    >
      <button nz-button nzType="primary" nzGhost nzDanger (click)="void()">
        <i nz-icon nzType="check-circle"></i> 取消
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #subtitleTpl>
  <div style="vertical-align: middle">
    <div nz-row nzAlign="middle" nzGutter="16">
      <div nz-col>
        <span nz-typography>
          {{ pickup?.carrier?.name }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentTpl>
  <ng-container *ngIf="pickup">
    <div>
      <small nz-typography> 数量: {{ pickup.qty }} </small>
      <nz-divider nzType="vertical"></nz-divider>
      <small nz-typography>取件地址: {{ pickup?.location.label }}</small>
    </div>
    <div>
      <small>
        创建:
        {{
          pickup.createdAt | amLocale: i18n.getLocaleId() | amDateFormat: "lll"
        }}
      </small>
      <nz-divider nzType="vertical"></nz-divider>
      <small>
        更新:
        {{ pickup.updatedAt | amLocale: i18n.getLocaleId() | amTimeAgo }}
      </small>
    </div>
  </ng-container>
</ng-template>
