import { ChangeDetectorRef, EventEmitter, Injector, OnDestroy, } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PATTERNS } from 'src/app/common/constants/pattern';
import { ShippingDocTypeCode } from 'src/app/common/interfaces/shipping';
import { BtcShippingDocService } from 'src/app/core/btc/btc-shipping-doc.service';
import { FormValidationService } from 'src/app/core/form';
import { ServiceContent } from 'src/app/core/service/models';
import { ShippingIdcard } from 'src/app/core/shipping/models';
import { BreakpointService } from 'src/app/helper/breakpoint';

@Component({
  selector: 'doc-edit',
  templateUrl: './doc-edit.component.html',
  styleUrls: ['./doc-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocEditComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private drawerRef: NzDrawerRef;
  private smallscreen = false;
  @Input() drawer = true;
  @Input() shippingId: string;
  @Input() idcard: ShippingIdcard;
  @Input() serviceContent: ServiceContent;
  @Input() editEvent = new EventEmitter<string>();
  isLoading = false;
  validateForm = new UntypedFormGroup({
    addressId: this.fb.control(null, [Validators.required]),
    docnumber: this.fb.control(null, [Validators.pattern(PATTERNS.id_number_cn.pattern)])
  });
  fileList: File[] = [];
  upload: (item) => Subscription = (item: any) => {
    const { name, file, data } = item;
    const formData = new FormData();
    formData.append(name, file);
    const body = formData;

    const doc = data === ShippingDocTypeCode.id_card_recto ? this.idcard.recto : this.idcard.verso;
    return this.docService.idcard_upload(this.shippingId, doc.id, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.fileList = [];
        if (res) {
          this.editEvent.emit();
          this.search(this.shippingId, { addressId: this.idcard?.address?.id });
        }
      }
    });
  }

  constructor(
    private fv: FormValidationService,
    private fb: UntypedFormBuilder,
    private docService: BtcShippingDocService,
    private detection: ChangeDetectorRef,
    private bpService: BreakpointService,
    private injector: Injector
  ) {
    this.bp_watch();
  }

  ngOnInit(): void {
    this.drawer_update();
    this.validateForm.patchValue({
      addressId: this.idcard?.address?.id,
      docnumber: this.idcard?.docnumber
    });
  }

  private bp_watch(): void {
    this.bpService.small$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (small) => this.smallscreen = small
    });
  }

  private drawer_update(): void {
    if (this.drawer) {
      this.drawerRef = this.injector.get(NzDrawerRef);
      setTimeout(() => {
        this.drawerRef.nzHeight = this.smallscreen ? '100%' : 'auto';
        this.drawerRef.nzBodyStyle = { 'max-height': '100%' };
      }, 200);

    }
  }


  on_submit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      const shippingId = this.shippingId;
      const body = this.validateForm.value;
      this.update(shippingId, body);
    }
  }

  private update(groupId: string, body: any): void {
    this.isLoading = true;
    this.docService.idcard_update(groupId, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.editEvent.emit();
        this.detection.detectChanges();
      }
    });
  }

  private search(groupId: string, body: any): void {
    this.isLoading = true;
    this.docService.idcard_search(groupId, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        const idcard = res?.result;
        if (idcard) {
          this.idcard = idcard;
        }
        this.detection.detectChanges();
      }
    });
  }


  delete(docId: string): void {
    this.isLoading = true;
    this.docService.idcard_delete(this.shippingId, docId).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        if (res?.success) {
          this.editEvent.emit();
          this.search(this.shippingId, { addressId: this.idcard?.address?.id });
        }
        this.detection.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
