export enum CarrierCode {
  canpost = "canpost",
  fedex = "fedex",
  ups = "ups",
  dhl = "dhl",
  dhl_be = "dhl_be",
  postnl_be = "postnl_be",
  purolator = "purolator",
  igcaexpress = "igcaexpress",
  jiayun = "jiayun",
  shunfeng = "shunfeng",
  shentong = "shentong",
  zhongtong = "zhongtong",
  yuantong = "yuantong",
  jingdongkuaiyun = "jingdongkuaiyun",
  jd = "jd",
  youzhengbk = "youzhengbk",
  youzhengguonei = "youzhengguonei",
  yunda = "yunda",
}

export type Carrier = keyof typeof CarrierCode;
export type CarrierChina = keyof Pick<
  typeof CarrierCode,
  | "shentong"
  | "jd"
  | "shunfeng"
  | "youzhengbk"
  | "jingdongkuaiyun"
  | "yunda"
  | "yuantong"
  | "zhongtong"
>;

export enum CarrierServiceCodeCanpost {
  dom_ep = "dom_ep",
  usa_ep = "usa_ep",
  usa_xp = "usa_xp",
  usa_tp = "usa_tp",
  int_xp = "int_xp",
  int_tp = "int_tp",
}

export type CarrierServiceCanpost = keyof typeof CarrierServiceCodeCanpost;

export type CarrierPropItem<T> = {
  name: string;
  code: CarrierCode;
  website?: string;
  trackingUrl?: string;
};

export type CarrierProp = {
  [code in Carrier]: CarrierPropItem<code>;
};

export type CarrierPropChina = {
  [code in CarrierChina]: CarrierPropItem<code>;
};
