<nz-result
  [nzStatus]="status"
  [nzTitle]="title"
  [nzSubTitle]="subTitle"
  [nzExtra]="extra"
>
  <div nz-result-extra>
    <ng-container *ngFor="let btn of buttons">
      <button nz-button [nzType]="btn.type" (click)="btn.onClick()">
        {{ btn.label }}
      </button>
    </ng-container>
  </div>
</nz-result>
