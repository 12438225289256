import { IVoucher } from '../../interfaces/account/voucher';
import * as moment from 'moment';
export class Voucher {
    private _voucher: IVoucher;

    constructor(data: IVoucher) {
        this._voucher = data;
    }

    get data(): IVoucher {
        return this._voucher;
    }

    get id(): string {
        return this._voucher ? this._voucher.voucher : null;
    }

    get title(): string {
        return this._voucher ? this._voucher.title : '未知';
    }

    get description(): string {
        return this._voucher ? this._voucher.description : '';
    }

    get descriptionShort(): string {
        return this._voucher ? this._voucher.shortDescription : '';
    }

    get amount(): number {
        if (!this._voucher) {
            return null;
        }

        if (this._voucher.algorithm.type) {
            return +this._voucher.algorithm.amount;
        }
    }

    get rate(): number {
        if (!this._voucher) {
            return null;
        }

        if (!this._voucher.algorithm.type) {
            return +this._voucher.algorithm.rate * 100;
        }
    }

    get expireAt(): string {
        return this._voucher.expireAt ? moment(this._voucher.expireAt, 'X').utc().format() : null;
    }

}
