<nz-list [nzDataSource]="list" [nzRenderItem]="item" [nzLoading]="syncing">
  <ng-template #item let-i>
    <nz-list-item>
      <nz-list-item-meta
        [nzTitle]="itemTitle"
        [nzAvatar]="i.avatar"
        [nzDescription]="contentTpl"
      >
        <ng-template #itemTitle>
          <a>{{ i.title }}</a>
        </ng-template>
      </nz-list-item-meta>
    </nz-list-item>
    <ng-template #contentTpl>
      <span [innerHTML]="i.content"></span>
    </ng-template>
  </ng-template>
</nz-list>
