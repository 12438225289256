import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { take } from 'rxjs/operators';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { BtcShippingService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';
import { IPackageAdditionalItem } from 'src/app/core/package/interfaces';
import { ServiceContent } from 'src/app/core/service/models';

@Component({
  selector: 'service-content-insurance-edit',
  templateUrl: './service-content-insurance-edit.component.html',
  styleUrls: ['./service-content-insurance-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceContentInsuranceEditComponent implements OnInit {


  @Input() drawer = false;
  @Input() current: IPackageAdditionalItem;
  @Input() content: ServiceContent;
  @Input() key: string;
  @Input() set version(value: number) {

    this.validateForm.patchValue({
      version: value,
    });

  }

  validateForm = new UntypedFormGroup({
    version: this.fb.control(null, [Validators.required]),
  });

  error: IHttpResponseError;
  isLoading = false;

  @Output() MaskClosable = new EventEmitter<boolean>();
  @Output() Close = new EventEmitter<any>();


  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private shippingService: BtcShippingService,
    private drawerRef: NzDrawerRef,
    private detection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.build();
  }

  on_submit(): void {

    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm, true);
    }

    if (this.validateForm.valid) {
      const { value } = this.validateForm;
      this.update(this.key, value);
    }

  }


  private update(key: string, body: any) {
    this.isLoading = true;
    this.error = null;
    this.maskClosable(false);
    this.shippingService.insurance_update(key, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        const { error } = res;
        this.error = error;
        if (res.result) {
          this.close(res.result);
        }
        this.detection.detectChanges();
      }
    });
  }

  private build(): void {
    if (this.content?.insurance) {
      this.validateForm.addControl('insurance', this.fb.group({}));
    } else {
      this.validateForm.removeControl('insurance');
    }

  }


  error_reset(): void {
    this.maskClosable(true);
    this.error = null;
  }


  private maskClosable(state: boolean) {
    if (this.drawer) {
      this.drawerRef.nzClosable = state;
      this.drawerRef.nzMaskClosable = state;
    } else {
      this.MaskClosable.emit(state);
    }
  }

  private close(data: any) {
    if (this.drawer) {
      this.drawerRef.close(data);
    } else {
      this.Close.emit(data);
    }
  }
}
