import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BtcService, BtcServiceContent } from 'src/app/common/models/service';

@Component({
  selector: 'service-selector',
  templateUrl: './service-selector.component.html',
  styleUrls: ['./service-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceSelectorComponent implements OnInit {

  private _isLoading = false;
  private _recordList: BtcService[] = [];

  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set recordList(value: BtcService[]) {
    this._recordList = value;
  }
  get recordList(): BtcService[] {
    return this._recordList;
  }

  @Output() serviceChange = new EventEmitter<any>();



  constructor(
    private fb: UntypedFormBuilder,
    private detection: ChangeDetectorRef
  ) { }



  ngOnInit(): void {
  }

  trackByIndex = (i: number, _: any): number => {
    return i;
  }

  select(service: BtcService, content?: BtcServiceContent): void {
    this.serviceChange.emit({ service, content });
  }

}
