import { IVoucherSeal } from '../../interfaces/account';
import * as moment from 'moment';
export class VoucherSeal {

    private _seal: IVoucherSeal;

    constructor(data: IVoucherSeal) {
        this._seal = data;
    }

    get seal(): IVoucherSeal {
        return this._seal;
    }

    set seal(value: IVoucherSeal) {
        this._seal = value;
    }

    get expireAt(): string {
        return this.seal.expireAt ? moment(this.seal.expireAt, 'X').utc().format() : null;
    }

    get expireIn(): string {
        if (!this.seal.expireIn) {
            return null;
        }
        return moment().add(this.seal.expireIn, 's').format();

    }

    get id(): string {
        return this.seal ? this.seal.id : null;
    }

    get title(): string {
        return this.seal ? this.seal.title : '未知';
    }

    get pointExchangeRate(): number {
        return this.seal ? this.seal.exchangePoint : 0;
    }

    get qty(): number {
        return this.seal ? this.seal.issueQty : 0;
    }

    get description(): string {
        return this.seal ? this.seal.description : '';
    }

    get descriptionShort(): string {
        return this.seal ? this.seal.shortDescription : '';
    }
}
