import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IHttpResponseError } from 'src/app/common/interfaces/http';
import { SizeProp } from 'src/app/common/interfaces/pak';
import { IUnit } from 'src/app/common/interfaces/setting';
import { BtcShippingService } from 'src/app/core';
import { FormValidationService } from 'src/app/core/form';
import { UnitService } from 'src/app/core/unit';

@Component({
  selector: 'entity-format-edit',
  templateUrl: './entity-format-edit.component.html',
  styleUrls: ['./entity-format-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityFormatEditComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private _unit: IUnit;
  @Input() key: string;
  @Input() drawer: boolean;
  @Input() set version(value: number) {
    this.validateForm.patchValue({
      version: value
    });
  }

  @Input() set unit(value: IUnit) {
    this._unit = value;
    if (value) {
      this.validateForm.get('pak').patchValue({
        isCentimeter: value.size.isCentimeter,
        isKilo: value.weight.isKilo
      });
    }
  }
  get unit(): IUnit {
    return this._unit;
  }

  @Input() set size(value: Record<SizeProp, number>) {
    if (value) {
      this.validateForm.get('pak').patchValue({
        size: value
      });
    } else {
      this.validateForm.get('pak').patchValue({
        size: {
          length: null,
          width: null,
          height: null
        }
      });
    }
  }

  @Input() set weight(value: number) {
    if (value) {
      this.validateForm.get('pak').patchValue({
        weight: value
      });
    } else {
      this.validateForm.get('pak').patchValue({
        weight: null
      });
    }
  }

  @Output() MaskClosable = new EventEmitter<boolean>();
  @Output() Close = new EventEmitter<any>();


  error: IHttpResponseError;
  isLoading = false;

  validateForm = new UntypedFormGroup({
    pak: this.fb.group({
      size: this.fb.group({
        length: this.fb.control(null, [Validators.required]),
        width: this.fb.control(null, [Validators.required]),
        height: this.fb.control(null, [Validators.required]),
      }),
      weight: this.fb.control(null, [Validators.required]),
      isKilo: this.fb.control(null, [Validators.required]),
      isCentimeter: this.fb.control(null, [Validators.required]),
    }),
    version: this.fb.control(null, [Validators.required])
  });

  constructor(
    private fb: UntypedFormBuilder,
    private fv: FormValidationService,
    private shippingService: BtcShippingService,
    private detection: ChangeDetectorRef,
    private unitService: UnitService,
    private drawerRef: NzDrawerRef
  ) {
    this.watch_unit();
  }

  ngOnInit(): void {
  }

  on_submit(): void {

    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm, true);
    }

    if (this.validateForm.valid) {
      const { value } = this.validateForm;
      this.update(this.key, value);
    }

  }

  error_reset(): void {
    this.maskClosable(true);
    this.error = null;
  }

  private watch_unit(): void {
    this.unitService.unit$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (values) => {
        this.unit = values;
      }
    });

  }

  private update(key: string, body: any) {
    this.isLoading = true;
    this.error = null;
    this.maskClosable(false);
    this.shippingService.size_update(key, body).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.isLoading = false;
        const { error } = res;
        this.error = error;
        if (res.result) {
          this.close(res.result);
        }
        this.detection.detectChanges();
      }
    });
  }

  private maskClosable(state: boolean) {
    if (this.drawer) {
      this.drawerRef.nzClosable = state;
      this.drawerRef.nzMaskClosable = state;
    } else {
      this.MaskClosable.emit(state);
    }
  }

  private close(data: any) {
    if (this.drawer) {
      this.drawerRef.close(data);
    } else {
      this.Close.emit(data);
    }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.destroy$.next();
  }


}
