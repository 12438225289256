<ng-container *ngIf="restriction">
  <nz-descriptions nzSize="small">
    <ng-container *ngIf="service.count">
      <nz-descriptions-item
        nzTitle="包裹数量"
        *ngIf="restriction?.length?.max"
        nzSpan="3"
      >
        <ng-container *ngIf="service.count.max !== service.count.min">
          {{ service.count.min }} - {{ service.count.max }}个
        </ng-container>

        <ng-container *ngIf="service.count.max === service.count.min">
          {{ service.count.max }}个
        </ng-container>
      </nz-descriptions-item>
    </ng-container>

    <ng-container *ngIf="restriction">
      <nz-descriptions-item [nzTitle]="restrictionTitleTpl">
        <ng-template #restrictionTitleTpl>
          <ng-container *ngIf="service.multiDestination">
            每个包裹
          </ng-container>
          <ng-container *ngIf="!service.multiDestination">
            规格限制
          </ng-container>
        </ng-template>

        <nz-descriptions nzSize="small">
          <ng-container *ngIf="restriction?.length">
            <nz-descriptions-item nzTitle="边长" nzSpan="3">
              <ng-container *ngIf="restriction?.length?.min">
                Min.
                {{
                  restriction?.length?.min
                    | sizeUnit: (unit$ | async)?.size.code
                }}
                {{ (unit$ | async)?.size.code }}
              </ng-container>
              <ng-container *ngIf="restriction?.length?.max">
                Max.
                {{
                  restriction?.length?.max
                    | sizeUnit: (unit$ | async)?.size.code
                }}
                {{ (unit$ | async)?.size.code }}
              </ng-container>
            </nz-descriptions-item>
          </ng-container>

          <ng-container *ngIf="restriction?.girth">
            <nz-descriptions-item nzTitle="三边长" nzSpan="3">
              <ng-container *ngIf="restriction?.girth?.min">
                Min.
                {{
                  restriction?.girth?.min | sizeUnit: (unit$ | async)?.size.code
                }}
                {{ (unit$ | async)?.size.code }}
              </ng-container>
              <ng-container *ngIf="restriction?.girth?.max">
                Max.
                {{
                  restriction?.girth?.max | sizeUnit: (unit$ | async)?.size.code
                }}
                {{ (unit$ | async)?.size.code }}
              </ng-container>
            </nz-descriptions-item>
          </ng-container>

          <ng-container *ngIf="restriction?.weight">
            <nz-descriptions-item nzTitle="重量" nzSpan="3">
              <ng-container *ngIf="restriction?.weight?.min">
                Min.
                {{
                  restriction?.weight?.min
                    | weightUnit: (unit$ | async)?.weight.code
                }}
                {{ (unit$ | async)?.weight.code }}
              </ng-container>
              <ng-container *ngIf="restriction?.weight?.max">
                Max.
                {{
                  restriction?.weight?.max
                    | weightUnit: (unit$ | async)?.weight.code
                }}
                {{ (unit$ | async)?.weight.code }}
              </ng-container>
            </nz-descriptions-item>
          </ng-container>
        </nz-descriptions>
      </nz-descriptions-item>
    </ng-container>
  </nz-descriptions>
</ng-container>
