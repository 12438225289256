import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { IdCardType, } from 'src/app/common/interfaces/address';
import { BTCAddress, Recipient, RecipientIdCard, Sender } from 'src/app/common/models/address';
import { HttpService } from '../http';
import { IHttpResponse } from 'src/app/common/interfaces/http';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private _senderCount$ = new BehaviorSubject<number>(0);
  private _recipientCount$ = new BehaviorSubject<number>(0);
  senderCount$ = this._senderCount$.asObservable();
  recipientCount$ = this._recipientCount$.asObservable();

  countSyncingState$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpService
  ) {
    this.count_sync();
  }

  set senderCount(value: number) {
    this._senderCount$.next(value);
  }

  set recipientCount(value: number) {
    this._recipientCount$.next(value);
  }


  private count_sync(): void {
    this.count_sync_state_update(true).pipe(
      switchMap(() => zip(this.recipient_count_sync(), this.sender_count_sync())),
      take(1),
      catchError(() => of(false)),
      switchMap(() => this.count_sync_state_update(false)),
    )
      .subscribe();
  }

  sender_count_sync(): Observable<number> {
    const path = 'address/sender/count';
    return this.http.post(path, null).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        this.senderCount = res?.count ?? 0;
      }),
      map((res: IHttpResponse) => res?.count ?? 0)
    );
  }



  sender_search(body: any): Observable<IHttpResponse> {
    const path = `address/sender/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res) => {
        const result = res?.result ?? [];
        res.result = result.map(item => new BTCAddress(item));
        return res;
      }),
    );
  }

  sender_detail(id: string): Observable<IHttpResponse> {
    const path = `address/sender/detail/${id}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BTCAddress(res.result);
        }
        return res;
      }),

    );
  }

  sender_create(body: any): Observable<IHttpResponse> {
    const path = `address/sender`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BTCAddress(res.result);
        }
        return res;
      })
    );
  }

  sender_update(id: string, body: any): Observable<IHttpResponse> {
    const path = `address/sender/${id}`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap(this.http.error_msg_handler())
    );
  }

  sender_delete(id: string): Observable<IHttpResponse> {
    const path = `address/sender/${id}`;
    return this.http.delete(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap((res) => {
        if (res?.success) {
          this.sender_count_sync().pipe(
            take(1)
          ).subscribe();
        }
      }),
    );
  }

  recipient_count_sync(): Observable<number> {
    const path = 'address/recipient/count';
    return this.http.post(path, null).pipe(
      catchError(this.http.catch()),
      tap((res: IHttpResponse) => {
        this.recipientCount = res?.count ?? 0;
      }),
      map((res: IHttpResponse) => res?.count ?? 0)
    );
  }


  recipient_search(body: any): Observable<IHttpResponse> {
    const path = `address/recipient/search`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      map((res) => {
        const result = res?.result ?? [];
        res.result = result.map(item => new BTCAddress(item));
        return res;
      }),
    );
  }

  recipient_detail(id: string): Observable<IHttpResponse> {
    const path = `address/recipient/detail/${id}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      map((res: IHttpResponse) => {
        if (res?.result) {
          res.result = new BTCAddress(res.result);
        }
        return res;
      }),

    );
  }

  recipient_create(body: any): Observable<IHttpResponse> {
    const path = `address/recipient`;
    return this.http.post(path, body).pipe(
      catchError((res: IHttpResponse) => of(res)),
      tap(this.http.msg_handler()),
      map((res: IHttpResponse) => {
        const { result } = res;
        if (result) {
          res.result = new BTCAddress(result);
        }
        return res;
      }),
    );
  }

  recipient_update(id: string, body: any): Observable<IHttpResponse> {
    const path = `address/recipient/${id}`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
    );
  }

  recipient_delete(id: string): Observable<IHttpResponse> {
    const path = `address/recipient/${id}`;
    return this.http.delete(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.msg_handler()),
      tap((res) => {
        if (res?.success) {
          this.recipient_count_sync().pipe(
            take(1)
          ).subscribe();
        }
      }),
    );
  }


  private count_sync_state_update(value: boolean): Observable<boolean> {
    this.countSyncingState$.next(value);
    return of(true);
  }

  recipient_idcard_get(id: string): Observable<RecipientIdCard> {
    const path = `address/recipient/idcard/${id}`;
    return this.http.get(path).pipe(
      map((res) => new RecipientIdCard(res)),
      catchError(() => of(null))
    );
  }

  recipient_idcard_upload(id: string, body: any) {
    const path = `address/recipient/idcard/upload/${id}`;
    return this.http.post(path, body).pipe(
      tap(this.http.msg_handler()),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  recipient_idcard_delete(id: string, side: IdCardType): Observable<boolean> {
    const path = `address/recipient/idcard/${id}?side=${side}`;
    return this.http.delete(path).pipe(
      tap(this.http.msg_handler()),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  recipient_idcard_update(id: string, body: any): Observable<boolean> {
    const path = `address/recipient/idcard/${id}`;
    return this.http.put(path, body).pipe(
      tap(this.http.msg_handler()),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }



}
