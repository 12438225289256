import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { IRegionState, IRegionStateListItem, ISOCode2 } from 'src/app/common/interfaces/region';
import { RegionService } from 'src/app/core/region';

@Component({
  selector: 'region-state-selector',
  templateUrl: './region-state-selector.component.html',
  styleUrls: ['./region-state-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionStateSelectorComponent implements OnInit {
  private _isLoading = false;
  private _validateForm: UntypedFormGroup;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  private _stateCode: string;
  @Input()
  set stateCode(value: string) {
    this._stateCode = value;

  }
  get stateCode(): string {
    return this._stateCode;
  }

  private _regionCode: ISOCode2;
  @Input()
  set regionCode(value: ISOCode2) {
    this._regionCode = value;
    if (this._regionCode) {
      this.search();
    } else {
      this.stateList = [];
    }
    this.detection.detectChanges();
  }
  get regionCode(): ISOCode2 {
    return this._regionCode;
  }

  @Input()
  set validateForm(value: UntypedFormGroup) {
    this._validateForm = value;
  }

  get validateForm(): UntypedFormGroup {
    return this._validateForm;
  }

  @Input() stateList: IRegionStateListItem[] = [];
  @Output() stateListChange = new EventEmitter<IRegionStateListItem[]>();
  @Output() stateChange = new EventEmitter<IRegionState>();

  constructor(
    private detection: ChangeDetectorRef,
    private regionService: RegionService
  ) { }

  ngOnInit(): void {
    this.validateForm.get('state')?.valueChanges.subscribe({
      next: value => this.detection.detectChanges()
    });
  }


  search(stateCode?: string): void {
    this.isLoading = true;

    this.regionService.province_search({
      regionCode: this.regionCode,
      code: stateCode
    }).pipe(
      take(1)
    ).subscribe(res => {

      this.stateList = res?.result;
      if (this.stateCode && this.stateCode.length) {
        const state = this.stateList.find(({ value }) => value?.code === this.stateCode)?.value;
        this.validateForm.patchValue({
          state
        });

      }
      this.isLoading = false;
      this.stateListChange.emit(this.stateList);

    });
  }

}
