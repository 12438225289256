
export enum CashbackEvent {
    cashback_sale_agent = 'Agent 用户返款',
    cashback_sale_agent_revoke = '撤销 Agent 用户返款',
    cashback_user_general = 'Pioneer 用户返款',
    cashback_user_general_revoke = '撤销 Pioneer 用户返款',
    cashback_user_vip = 'VIS 用户返款',
    cashback_user_vip_revoke = '撤销 VIS 用户返款',
    cashback_user_business = 'Business 用户返款',
    cashback_user_business_revoke = '撤销 Business 用户返款',
    cashback_user_enterprise = 'Enterprise 用户返款',
    cashback_user_enterprise_revoke = '撤销 Enterprise 用户返款',
    transfer_to_balance = '转入账户余额',
    withdraw = '提现',
    cashback = '返款',
    cashback_consume = '消费返款',
    cashback_revoke = '撤销返款',
    unknown = '未知事件',
}

export type CashbackEventType = keyof typeof CashbackEvent;

export interface ICashbackEvent {
    code: CashbackEventType;
    name: CashbackEvent;
}
